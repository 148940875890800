/**!
 *  This widget hijacks content from default Fuse widgets.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

 import React from "react";
 import Widget from "../../widget.js";
 import "./hijack.scss";
 import Broadcast from "Class/Broadcast";
 import Fuse from "Class/Fuse";
 import Globals from "Class/Globals";
 import ContentItem from "Components/UI/ContentItem";
 import ContentListItem from "Components/UI/ContentListItem";
 import Link from "Components/UI/Link";
 import Slider from "Components/UI/Slider";
 import Spinner from "Components/Feedback/Spinner";
 import User from "Components/UI/User"
 import WidgetWrapper from "Components/UI/WidgetWrapper";

 class WidgetHijack extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            source:
            {
                filter: [
                    "Leaderboard Widget",
                    "Recently Added Content Widget",
                    "Recommendations Widget",
                    "Topics Widget",
                    "Trending Widget"
                ],
                label: "Source Widget",
                type: "initial"
            },
            hideSource:
            {
                label: "Hide Source Widget",
                type: "checkbox",
                default: true
            },
            externalLink:
            {
                label: "Open content in a new tab",
                type: "checkbox"
            },
            listView:
            {
                default: true,
                label: "Display content and topics as list",
                type: "checkbox"
            },
            hideManagers:
            {
                label: "Hide Managers in Leaderboard",
                type: "checkbox"
            }
        });
        this.Hidden = false;
        this.Name = "Hijack";
        this.state =
        {
            community: false,
            error: false,
            loading: false,
            managers: false,
            sources: [],
            type: false
        };
    }

    /**
     * Add listeners and request source widgets on mount.
     * @return void
     */

    componentDidMount()
    {
        Globals.Listen(`initial-${this.props.id}`, this.OnInitial);
        this.RequestInitial();
    }

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {community} = this.state;
        const {hideManagers: h1} = c1 || {};
        const {hideManagers: h2} = c2 || {};
        if (h1 && !h2 && community)
        {
            this.LoadManagers(community);
        }
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        Globals.Remove(`initial-${this.props.id}`, this.OnInitial);
    }

    /**
     * Filter and format widget content.
     * @param array widgets - Widgets as [[type, html, id] ...]
     * @return array - Filtered and formatted widgets
     */

    Format = (widgets) =>
    {
        const Formatted = [];
        widgets.forEach(([type, html, id]) =>
        {
            switch (type)
            {
                case "Leaderboard Widget":
                    Formatted.push({type, id, content: this.FormatLeaderboard(html)})
                    break;
                case "Recently Added Content Widget":
                    Formatted.push({type, id, content: this.FormatRecentlyAddedContent(html)})
                    break;
                case "Recommendations Widget":
                    Formatted.push({type, id, content: this.FormatRecommendations(html)})
                    break;
                case "Topics Widget":
                    Formatted.push({type, id, content: this.FormatTopics(html)})
                    break;
                case "Trending Widget":
                    Formatted.push({type, id, content: this.FormatTrending(html)})
                    break;
                default:
            }
        });
        return Formatted;
    }

    /**
     * Format leaderboard HTML into JS object.
     * @param string html - Leaderboard HTML.
     * @return object - Leaderboard data.
     */

    FormatLeaderboard = (html) =>
    {
        const Content = document.createElement("div");
        Content.innerHTML = html;
        const Title = Content.getElementsByClassName("b-widget-title-link")[0];
        const List = Content.getElementsByClassName("l-user");
        const Formatted = {
            community: parseInt(Title.href.replace(/.*\/|[^0-9]/g, ""), 10),
            leaderboard: []
        }
        for (let i = 0; i < List.length; i++)
        {
            const Row = List[i];
            const Name = Row.getElementsByClassName("b-name")[0];
            const Points = Row.getElementsByClassName("b-score")[0];
            const Uri = this.ExtractUri(Row.href);
            Formatted.leaderboard.push({
                id: parseInt(Uri.replace(/.*\/|[^0-9]/g, ""), 10),
                name: Name ? Name.innerHTML : "",
                points: Points ? parseInt(Points.innerHTML, 10) : 0
            });
        }
        this.LoadManagers(Formatted.community);
        return Formatted;
    }

    /**
     * Format recently added content HTML into JS object.
     * @param string html - Recently added content HTML.
     * @return object - Recently added content data.
     */

    FormatRecentlyAddedContent = (html) =>
    {
        const Content = document.createElement("div");
        Content.innerHTML = html;
        const Title = Content.getElementsByClassName("b-widget-title")[0].getElementsByTagName("a")[0];
        const List = Content.getElementsByClassName("b-trending-content");
        const Formatted = {
            community: parseInt(Title.href.replace(/:\d*\/|[^0-9]/g, ""), 10),
            content: []
        }
        for (let i = 0; i < List.length; i++)
        {
            const Row = List[i];
            const Thumbnail = Row.getElementsByTagName("img")[0];
            const Title = Row.getElementsByClassName("b-contents-widget-title")[0].getElementsByTagName("a")[0];
            const User = Row.getElementsByClassName("b-contents-widget-user")[0].getElementsByTagName("a")[0];
            const UriTitle = this.ExtractUri(Title.href);
            const UriUser = this.ExtractUri(User.href);
            Formatted.content.push({
                thumbnail: Thumbnail ? this.ExtractUri(Thumbnail.src) : "",
                id: parseInt(UriTitle.replace(/[^0-9]/g, ""), 10),
                title: Title.innerHTML,
                user: parseInt(UriUser.replace(/[^0-9]/g, ""), 10),
                uri: UriTitle
            });
        }
        return Formatted;
    }

    /**
     * Format recommendations HTML into JS object.
     * @param string html - Recommendations HTML.
     * @return object - Recommendations data.
     */

    FormatRecommendations = (html) =>
    {
        const Content = document.createElement("div");
        Content.innerHTML = html;
        const Title = Content.getElementsByClassName("b-widget-title")[0].getElementsByTagName("a")[0];
        const List = Content.getElementsByClassName("b-recommended-content");
        const Formatted = {
            community: parseInt(Title.href.replace(/:\d*\/|[^0-9]/g, ""), 10),
            content: []
        }
        for (let i = 0; i < List.length; i++)
        {
            const Row = List[i];
            const Thumbnail = Row.getElementsByTagName("img")[0];
            const Link = Row.getElementsByClassName("b-recommended-content-anchor")[0];
            const Title = Row.getElementsByClassName("b-recommended-content-name")[0];
            const UriTitle = this.ExtractUri(Link.href);
            Formatted.content.push({
                thumbnail: Thumbnail ? this.ExtractUri(Thumbnail.src) : "",
                id: parseInt(UriTitle.replace(/[^0-9]/g, ""), 10),
                title: Title.innerHTML,
                uri: UriTitle
            });
        }
        return Formatted;
    }

    /**
     * Format topics HTML into JS object.
     * @param string html - Topics HTML.
     * @return object - Topics data.
     */

    FormatTopics = (html) =>
    {
        const Content = document.createElement("div");
        Content.innerHTML = html;
        const List = Content.getElementsByClassName("b-topic");
        const Formatted = {
            topics: []
        }
        for (let i = 0; i < List.length; i++)
        {
            const Row = List[i];
            const Avatar = Row.getElementsByTagName("img")[0];
            const Link = Row.getElementsByTagName("a")[0];
            const Title = Row.getElementsByClassName("b-topic-title")[0].getElementsByTagName("span")[0];
            const Description = Row.getElementsByClassName("b-topic-description")[0].getElementsByTagName("span")[0];
            const Uri = this.ExtractUri(Link.href);
            Formatted.topics.push({
                avatar: Avatar ? this.ExtractUri(Avatar.src) : "",
                id: parseInt(Uri.replace(/[^0-9]/g, ""), 10),
                title: Title.innerHTML,
                description: Description.innerHTML,
                uri: Uri
            });
        }
        return Formatted;
    }

    /**
     * Format trending contents HTML into JS object.
     * @param string html - Trending HTML.
     * @return object - Trending contents data.
     */

    FormatTrending = (html) =>
    {
        const Content = document.createElement("div");
        Content.innerHTML = html;
        const Title = Content.getElementsByClassName("b-widget-title")[0].getElementsByTagName("a")[0];
        const List = Content.getElementsByClassName("b-trending-content");
        const Formatted = {
            community: parseInt(Title.href.replace(/:\d*\/|[^0-9]/g, ""), 10),
            trending: []
        }
        for (let i = 0; i < List.length; i++)
        {
            const Row = List[i];
            const Preview = Row.getElementsByTagName("img")[0];
            const Link = Row.getElementsByTagName("a")[0];
            const Title = Row.getElementsByClassName("b-name")[0];
            const Uri = this.ExtractUri(Link.href);
            Formatted.trending.push({
                id: parseInt(Uri.replace(/[^0-9]/g, ""), 10),
                preview: Preview ? this.ExtractUri(Preview.src) : "",
                title: Title.innerHTML,
                uri: Uri
            });
        }
        return Formatted;
    }

    /**
     * Hide source widget (if we should)
     * @return void
     */

    Hide = (id) =>
    {
        const {hideSource} = this.Content();
        if (!hideSource && this.Hidden)
        {
            Broadcast.SendMessage({
                id: this.Hidden,
                type: "show"
            });
            this.Hidden = false;
        }
        else if (hideSource && this.Hidden !== id)
        {
            if (this.Hidden)
            {
                Broadcast.SendMessage({
                    id: this.Hidden,
                    type: "show"
                });
            }
            Broadcast.SendMessage({
                id,
                type: "hide"
            });
            this.Hidden = id;
        }
    }

    LoadManagers = (community) =>
    {
        const {hideManagers} = this.Content();
        const {community: c, managers} = this.state;
        if (!hideManagers && community !== c)
        {
            this.setState({community});
            return;
        }
        if (!hideManagers || !community || (community === c && managers))
        {
            return;
        }
        this.setState({
            community,
            error: false,
            loading: true,
            managers: false
        });
        Fuse.Request(`communities/${community}/admins`, admins =>
        {
            if (!Array.isArray(admins))
            {
                this.setState({
                    error: true,
                    loading: false,
                });
            }
            else
            {
                const Managers = [];
                admins.forEach(admin => Managers.push(admin.id));
                this.setState({
                    loading: false,
                    managers: Managers
                });
            }
        });
    }

    /**
     * Callback when initial widget contents is received.
     * @param array widgets - Widgets as [[type, html, id] ...]
     * @return void
     */

    OnInitial = (widgets) =>
    {
        this.setState({sources: this.Format(widgets)});
    }

    ExtractUri = (url) =>
    {
        return url.replace(/^https?:\/\/[^\/]*/, "");
    }

    RenderLeaderboard = (content, id) =>
    {
        const {loading, managers} = this.state;
        const WidgetContent = this.Content();
        const {externalLink, hideManagers, title} = WidgetContent;
        const CA = ["WidgetHijackWrapper", "HijackLeaderboard"];
        const {leaderboard} = content;
        let Content;
        if (hideManagers && loading)
        {
            Content = <Spinner className="WidgetHijackLeaderboardSpinner" overlay={true}/>;
        }
        else
        {
            const Items = [];
            let Place = 0;
            leaderboard.forEach((user, index) =>
            {
                if (hideManagers && managers && managers.indexOf(user.id) >= 0)
                {
                    return;
                }
                Items.push(
                    <div className="WidgetHijackLeaderboardItem" key={user.id}>
                        <User
                            className="ItemPreview WidgetHijackLeaderboardItemAvatar"
                            target={externalLink ? "_blank" : "_top"}
                            user={user.id}
                        />
                        <div className="ItemContent WidgetHijackLeaderboardItemContent">
                            <div className="ItemTitle WidgetHijackLeaderboardItemLabel">{++Place}. {user.name}</div>
                            <div className="ItemInfo WidgetHijackLeaderboardItemPoints">{user.points} points</div>
                        </div>
                    </div>
                );
            });
            Content = <div className="WidgetHijackLeaderboardItems">{Items}</div>;
        }
        return (
            <WidgetWrapper
                {...WidgetContent}
                className={CA.join(" ")}
                key={id}
                title={title || "Leaderboard"}
            >
                {Content}
            </WidgetWrapper>
        );
    }

    RenderRecentlyAddedContent = (content, id) =>
    {
        const {rowHeight} = this.props;
        const WidgetContent = this.Content();
        const {externalLink, listView, title} = WidgetContent;
        const CA = ["WidgetHijackWrapper", "HijackRecentlyAddedContent"];
        const {community, content: contentItems} = content;
        if (listView)
        {
            CA.push("ListView");
        }
        const Items = [];
        (contentItems || []).forEach(content =>
        {
            if (listView)
            {
                Items.push(
                    <ContentListItem       
                        content={{content: [content.id]}}
                        key={content.id}
                        target={externalLink ? "_blank" : "_top"}
                    />
                );
            }
            else
            {
                Items.push(
                    <div className="WidgetHijackContentItem" key={content.id}>
                        <ContentItem
                            content={{content: [content.id]}}
                            width={200}
                            height={250}
                            sizeMultiplier={Math.min(1, rowHeight)}
                            target={externalLink ? "_blank" : "_top"}
                        />
                    </div>   
                ); 
            }
        });
        return (
            <WidgetWrapper
                {...WidgetContent}
                className={CA.join(" ")}
                key={id}
                title={title || "Recently Added Content"}
                toolbar={community ? <Link href={Fuse.Url(`communities/${community}/contents?type=all`, false)} target="_top">View more</Link> : ""}
            >
                {listView ? (
                    <div className="WidgetHijackContentList">
                        {Items}
                    </div>
                ) : (
                    <div className="WidgetHijackContentAlign">
                        <Slider className="WidgetHijackContentItems" style={{height: 280 * rowHeight}}>
                            {Items}
                        </Slider>
                    </div>
                )}
            </WidgetWrapper>
        );
    }

    RenderRecommendations = (content, id) =>
    {
        const {rowHeight} = this.props;
        const WidgetContent = this.Content();
        const {externalLink, listView, title} = WidgetContent;
        const CA = ["WidgetHijackWrapper", "HijackRecommendations"];
        const {community, content: contentItems} = content;
        if (listView)
        {
            CA.push("ListView");
        }
        const Items = [];
        (contentItems || []).forEach(content =>
        {
            if (listView)
            {
                Items.push(
                    <ContentListItem       
                        content={{content: [content.id]}}
                        key={content.id}
                        showTray={true}
                        target={externalLink ? "_blank" : "_top"}
                    />
                );
            }
            else
            {
                Items.push(
                    <div className="WidgetHijackContentItem" key={content.id}>
                        <ContentItem
                            content={{content: [content.id]}}
                            width={200}
                            height={250}
                            sizeMultiplier={Math.min(1, rowHeight)}
                            target={externalLink ? "_blank" : "_top"}
                        />
                    </div>   
                ); 
            }
        });
        return (
            <WidgetWrapper
                {...WidgetContent}
                className={CA.join(" ")}
                key={id}
                title={title || "While you were away"}
                toolbar={community ? <Link href={Fuse.Url(`communities/${community}/contents?type=all`, false)} target="_top">View more</Link> : ""}
            >
                {listView ? (
                    <div className="WidgetHijackContentList">
                        {Items}
                    </div>
                ) : (
                    <div className="WidgetHijackContentAlign">
                        <Slider className="WidgetHijackContentItems" style={{height: 280 * rowHeight}}>
                            {Items}
                        </Slider>
                    </div>
                )}
            </WidgetWrapper>
        );
    }

    RenderTopics = (content, id) =>
    {
        const {rowHeight} = this.props;
        const WidgetContent = this.Content();
        const {externalLink, listView, title} = WidgetContent;
        const CA = ["WidgetHijackWrapper", "HijackTopics"];
        const {topics} = content;
        if (listView)
        {
            CA.push("ListView");
        }
        const Items = [];
        (topics || []).forEach(topic =>
        {
            if (listView)
            {
                Items.push(
                    <ContentListItem       
                        content={{content: [topic.id, "topic"]}}
                        key={topic.id}
                        target={externalLink ? "_blank" : "_top"}
                    />
                );
            }
            else
            {
                Items.push(
                    <div className="WidgetHijackContentItem" key={topic.id}>
                        <ContentItem
                            content={{content: [topic.id, "topic"]}}
                            width={200}
                            height={250}
                            sizeMultiplier={Math.min(1, rowHeight)}
                            target={externalLink ? "_blank" : "_top"}
                        />
                    </div>   
                ); 
            }
        });
        return (
            <WidgetWrapper
                {...WidgetContent}
                className={CA.join(" ")}
                key={id}
                title={title || "Topics"}
                toolbar={<Link href={Fuse.Url("topics", false)} target="_top">View more</Link>}
            >
                {listView ? (
                    <div className="WidgetHijackContentList">
                        {Items}
                    </div>
                ) : (
                    <div className="WidgetHijackContentAlign">
                        <Slider className="WidgetHijackContentItems" style={{height: 280 * rowHeight}}>
                            {Items}
                        </Slider>
                    </div>
                )}
            </WidgetWrapper>
        );
    }

    RenderTrending = (content, id) =>
    {
        const {rowHeight} = this.props;
        const WidgetContent = this.Content();
        const {externalLink, listView, title} = WidgetContent;
        const CA = ["WidgetHijackWrapper", "HijackTrending"];
        const {community, trending: contentItems} = content;
        if (listView)
        {
            CA.push("ListView");
        }
        const Items = [];
        (contentItems || []).forEach(content =>
        {
            if (listView)
            {
                Items.push(
                    <ContentListItem       
                        content={{content: [content.id]}}
                        key={content.id}
                        showTray={true}
                        target={externalLink ? "_blank" : "_top"}
                    />
                );
            }
            else
            {
                Items.push(
                    <div className="WidgetHijackContentItem" key={content.id}>
                        <ContentItem
                            content={{content: [content.id]}}
                            width={200}
                            height={250}
                            sizeMultiplier={Math.min(1, rowHeight)}
                            target={externalLink ? "_blank" : "_top"}
                        />
                    </div>   
                ); 
            }
        });
        return (
            <WidgetWrapper
                {...WidgetContent}
                className={CA.join(" ")}
                key={id}
                title={title || "Trending Content"}
                toolbar={community ? <Link href={Fuse.Url(`communities/${community}/contents?type=all`, false)} target="_top">View more</Link> : ""}
            >
                {listView ? (
                    <div className="WidgetHijackContentList">
                        {Items}
                    </div>
                ) : (
                    <div className="WidgetHijackContentAlign">
                        <Slider className="WidgetHijackContentItems" style={{height: 280 * rowHeight}}>
                            {Items}
                        </Slider>
                    </div>
                )}
            </WidgetWrapper>
        );
    }

    /**
     * Request the contents of the initial (default) widgets in the current landing page.
     * @return void
     */

    RequestInitial = () =>
    {
        Broadcast.SendMessage({
            id: this.props.id,
            type: "initial"
        });
    }

    render()
    {
        const {source} = this.Content();
        const {sources} = this.state;
        const CA = ["WidgetHijack", "BorderRadius"];
        let Content = "";
        let Source = false;
        for (let i in sources)
        {
            if (sources[i].id === source)
            {
                Source = sources[i];
                break;
            }
            else if (!Source)
            {
                Source = sources[i];
            }
        }
        if (!Source)
        {
            CA.push("NoSource");
        }
        else
        {
            this.Hide(Source.id);
            CA.push("Hijack" + Source.type.replace(/ .*/g, ""));
            switch (Source.type)
            {
                case "Leaderboard Widget":
                    Content = this.RenderLeaderboard(Source.content, Source.id);
                    break;
                case "Recently Added Content Widget":
                    Content = this.RenderRecentlyAddedContent(Source.content, Source.id);
                    break;
                case "Recommendations Widget":
                    Content = this.RenderRecommendations(Source.content, Source.id);
                    break;
                case "Topics Widget":
                    Content = this.RenderTopics(Source.content, Source.id);
                    break;
                case "Trending Widget":
                    Content = this.RenderTrending(Source.content, Source.id);
                    break;
                default:
            }
        }
        const CS = this.ClassNames(CA, true);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                {Content}
            </div>
        );
    }
}

export default WidgetHijack;