/**!
 *  View overview tree.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";

class EditorTree extends React.Component
{
    constructor (props)
    {
        super(props);
        this.Fertile = ["Conditional", "Multi", "Tabs"];  
    }

    /**
     * Recursively output widgets/sub-widgets to an array.
     * @param integer|string parentId - Filter widgets with this parent. 0 = no parent.
     * @param array ancestors - Read only. An array containing the ancestors of parentId.
     * @param integer level - Read only. Recursion depth.
     * @return void
     */

    Branch = (parentId = 0, ancestors = [], level = 0) =>
    {
        const {active, views} = this.props;
        const Branch = [];
        const Views = [];
        // Block endless recursion.
        if (ancestors.indexOf(parentId) >= 0)
        {
            return Branch;
        }
        ancestors.push(parentId);
        for (let id in views)
        {
            if (views[ id ].parent !== parentId)
            {
                continue;
            }
            Views.push(views[id]);
        }
        Views.sort((a, b) =>
        {
            if (b.y < 0) return 1;
            if (a.y < 0) return -1;
            if (b.x < 0) return 1;
            if (a.x < 0) return -1;
            if (b.y < a.y) return 1;
            if (a.y < b.y) return -1;
            if (b.x < a.x) return 1;
            if (a.x < b.x) return -1;
            return 0;
        });

        Views.forEach(view => {
            const {draft, id, label} = view;
            const {content: c1, unsaved} = draft || {};
            const {content: c2} = c1 || {};
            const {id: contentId, template} = c2 || {};
            const CA = ["EditorTreeBranch"];
            const IsFertile = this.Fertile.indexOf(template) >= 0;
            const SubBranches = IsFertile ? this.Branch(contentId || id , ancestors, level + 1) : [];
            if (id === active)
            {
                CA.push("Active");
            }
            Branch.push(

                <div className={CA.join(" ")} key={id} >
                    <div
                        className="EditorTreeBranchLabel"
                        onClick={e => this.OnClick(e, id)}
                        onMouseEnter={e => this.OnMouseEnter(e, id)}
                        onMouseLeave={e => this.OnMouseLeave(e, id)}  
                    >
                        {level ? <div
                            className="EditorTreeBranchPad"
                            style={{width: level * 10}}
                        /> : ""}
                        {label}
                        {unsaved ? <div className="EditorTreeBranchUnsaved">[unsaved]</div> : ""}
                    </div>
                    {SubBranches.length ? <div className="EditorTreeSubBranches">{SubBranches}</div> : ""}
                </div>
            );
        });
        level--;
        return Branch;
    }

    /**
     * Callback when a widget item is clicked.
     * @param object e - The event object.
     * @param string|number id - The unique id of the widget.
     * @return void
     */

    OnClick = (e, id) =>
    {
        const {disabled, onClick} = this.props;
        if (disabled)
        {
            return;
        }
        onClick(e, id);
    }

    /**
     * Callback when the cursor enters a widget item.
     * @param object e - The event object.
     * @param string|number id - The unique id of the widget.
     * @return void
     */

    OnMouseEnter = (e, id) =>
    {
        const {disabled, onMouseEnter} = this.props;
        if (disabled)
        {
            return;
        }
        onMouseEnter(e, id);
    }

    /**
     * Callback when the cursor leaves a widget item.
     * @param object e - The event object.
     * @param string|number id - The unique id of the widget.
     * @return void
     */

    OnMouseLeave = (e, id) =>
    {
        const {disabled, onMouseLeave} = this.props;
        if (disabled)
        {
            return;
        }
        onMouseLeave(e, id);
    }

    render()
    {
        const {label} = this.props;
        const Branches = this.Branch();
        return (
            <div className="EditorTree">
                {label ? <label>{label}</label> : ""}
                <div className="EditorTreeBranches">
                    {Branches}
                </div>
            </div>
        );
    }
}

EditorTree.propTypes =
{
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    views: PropTypes.object
};

EditorTree.defaultProps =
{
    active: "",
    disabled: false,
    label: "",
    onClick: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    views: {}
};

export default EditorTree;

