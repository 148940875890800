/*!
 *  Youtube video player.
 *
 *  @param string className - Append a class name.
 *  @param string id - A Youtube video ID. (Priority #2)
 *  @param string label - Label under the Play/Pause button.
 *  @param function onDone - Callback for when playback is finished.
 *  @param function onMount - Callback for when the player mounts. Sends the player component.
 *  @param function onPause - Callback for when playback pauses.
 *  @param function onPlay - Callback for when playback starts.
 *  @param array playlist - An array of Youtube video URLs. (Priority #1)
 *  @param string url - A Youtube video URL. (Priority #3)
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./youtubeplayer.scss";
import Globals from "Class/Globals";
import {ObjectCompare} from "Functions";
import Icon from "Components/Layout/Icon";
import LoadImage from "Components/Layout/LoadImage";
import {default as YtPlayer} from "react-youtube";

class YoutubePlayer extends React.Component
{
    constructor(props)
    {
        super( props );
        this.Player = false;
        // https://developers.google.com/youtube/player_parameters
        this.PlayerVars =
        {
            autoplay: 1,
            color: "white",
            fs: 0,
            rel: 0
        };
        this.Playing = false;
        this.state =
        {
            id: 0,
            playing: false,
            playlist: [],
            provider: "youtube",
            loading: false
        }
    }

    /**
     * Set YouTube video ID on mount.
     * @return void
     */

    componentDidMount()
    {
        const {autoplay, id, onMount, playlist, url} = this.props;
        const Id = id || this.ParseUrl(url);
        const Playlist = this.ParseList(playlist);
        this.setState({
            fullScreen: Globals.Fullscreen,
            id: Id,
            playlist: Playlist,
            ready: false,
            show: autoplay && (Id || Playlist)
        });
        onMount(this);
    }

    /**
     * Update the video credentials.
     * @param object nextProps - The updated component properties.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {id: i1, playlist: p1, url: u1} = this.props;
        const {id: i2, playlist: p2, url: u2} = prevProps;
        if (i1 !== i2 || u1 !== u2)
        {
            this.setState({id: i1 || this.ParseUrl(u1)});
        }
        if (!ObjectCompare(p1, p2))
        {
            this.setState({playlist: this.ParseList(p1)});
        }
    }

    /**
     * @return void
     */

    OnClick = () =>
    {
        const {id, playlist} = this.state;
        if (!id && !playlist.length)
        {
            return;
        } 
        this.setState({show: true});
    }

    OnKeyDown = (e) =>
    {
        switch (e.which)
        {
            case 13:
            case 32:
                e.stopPropagation();
                e.preventDefault();
                this.OnClick();
                break;
            default:
                return;
        }
    }

    /**
     * Callback for when the player is fully loaded.
     * @param object e - Event object.
     * @return void
     */

    OnReady = (e) =>
    {
        this.Player = e.target;
        this.setState({ready: true});
    }

    /**
     * Callback for when the player changes state.
     * @param object e - Event object.
     * @return void
     */

    OnStateChange = (e) =>
    {
        const {onDone, onPause, onPlay} = this.props;
        switch (e.data)
        {
            // Done
            case 0:
                this.Reset();
                onDone(this);
                break;
            // Play
            case 1:
                this.Playing = true;
                onPlay(this);
                break;
            // Pause
            case 2:
                this.Playing = false;
                onPause(this);
                break;
            default:
        }
        this.setState({playing: this.Playing});
    }

    /**
     * Extract the ID from a video URL.
     * @param string url - The video URL.
     * @return string - The ID.
     */

    ParseUrl = (url) =>
    {
        if (typeof url !== "string")
        {
            return false;
        }
        const Id = url.match(/[a-z0-9\-_]{8,}/i);
        return Id ? Id[0] : false;
    }

    /**
     * Extract the IDs from an array of video URLs.
     * @param array urls - Video URLs.
     * @return array - Array of video IDs.
     */

    ParseList = (urls) =>
    {
        if (typeof urls !== "object" || !urls.length)
        {
            return false;
        }
        const Parsed = [];
        urls.forEach(url =>
        {
            const Id = this.ParseUrl(url);
            if (!Id)
            {
                return;
            }
            Parsed.push(Id);
        });
        return Parsed;
    }

    /**
     * Toggle play/pause.
     * @return void
     */

    Play = () =>
    {
        if (!this.Player)
        {
            this.OnClick();
        }
        else
        {
            if (this.Playing)
            {
                this.Player.pauseVideo();
            }
            else
            {
                this.Player.playVideo();
            }
        }
    }

    /**
     * Reset to initial state.
     * @return void
     */

    Reset = () =>
    {
        this.Player = false;
        this.Playing = false;

        this.setState({
            playing: false,
            ready: false,
            show: false
        });
    }

    render()
    {
        const {className, label, title} = this.props;
        const {id, playing, playlist, ready, show} = this.state;
        const CA = ["VideoPlayerContainer"];
        if (label)
        {
            CA.push("HasLabel");
        }
        if (playing)
        {
            CA.push("Playing");
        }
        if (ready)
        {
            CA.push("Ready");
        }
        const Content = [];
        const PlayerVars = Object.assign(this.PlayerVars, {});
        let VideoId;
        if (playlist.length)
        {
            const Playlist = Array.from(playlist);
            VideoId = Playlist.shift();
            PlayerVars.playlist = Playlist.join(",");
        }
        else
        {
            VideoId = id;
        }
        if (!VideoId)
        {
            CA.push("Disabled");
        }
        else if (show)
        {
            CA.push("Show");

            Content.push(
                <div className="VideoPlayer" key="player">
                    <YtPlayer
                        onReady={this.OnReady}
                        onStateChange={this.OnStateChange}
                        opts={{playerVars: PlayerVars}}
                        videoId={VideoId}
                    />
                </div>
            );
        }
        if (className)
        {
            CA.push(className);
        }
        const Poster = VideoId ? `https://img.youtube.com/vi/${VideoId}/hqdefault.jpg` : "";
        return (
            <div className={CA.join(" ")}>
                <div className="VideoPlayerContent">
                    {Content}
                    <div
                        className="VideoPlayerPoster"
                        onClick={this.OnClick}
                        onKeyDown={this.OnKeyDown}
                        tabIndex="0"
                        title={title}
                    >
                        {Poster ? <LoadImage className="VideoPlayerPosterImage" src={Poster} /> : ""}
                        <Icon className="VideoPlayerPosterIcon content-block-item" feather="Play"/>
                        {label ? <div className="VideoPlayerPosterLabel">{label}</div> : ""}
                    </div>
                </div>
            </div>
        );
    }
}

YoutubePlayer.propTypes =
{
    autoplay: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    onDone: PropTypes.func,
    onMount: PropTypes.func,
    onPause: PropTypes.func,
    onPlay: PropTypes.func,
    playlist: PropTypes.array,
    url: PropTypes.string
};

YoutubePlayer.defaultProps =
{
    autoplay: true,
    className: "",
    id: "",
    label: "",
    onDone: () => {},
    onMount: () => {},
    onPause: () => {},
    onPlay: () => {},
    playlist: [],
    title: "",
    url: ""
}

export default YoutubePlayer;