import React from "react";
import "./preview.scss";
import Parser from "Class/Parser";
import Button from "Components/UI/Button";
import CheckboxField from "Components/UI/Field/CheckboxField";
import CodeField from "Components/UI/Field/CodeField";
import TabMenu from "Components/UI/TabMenu";

class ViewArticlesPreview extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            content: "",
            edited: false,
            message: "",
            minify: false,
            size: 0,
            tab: 0
        };
    }

    componentDidMount()
    {
        const {content, tab} = this.props;
        const {minify} = this.state;
        const Formatted = this.FormatContent(content, minify);
        this.setState({
            content: Formatted,
            size: this.ContentSize(Formatted),
            tab: String(tab)
        });
    }

    ContentSize = (content) =>
    {
        return (new Blob([content]).size / 1024).toFixed(2);
    }

    FormatContent = (content, minify = false) =>
    {
        return Parser.FormatHTML(Parser.ParseRaw(content, false), 0, minify);
    }

    OnCopy = () =>
    {
        const C = this;
        const {content, tab} = this.state;
        const CopyContent = async function CopyContent(content)
        {
            let Result, Copied = false;
            try
            {
                Result = await navigator.clipboard.writeText(content);
                Copied = true;
            }
            catch(e)
            {
                Copied = false;
            }
            C.setState({
                message: Copied ? "Copied to clipboard!" : "Failed to copy to clipboard!",
                tab: Copied ? tab : "1"
            });
        }
        CopyContent(content);
    }

    OnEdit = (e, content) =>
    {
        this.setState({
            content,
            edited: true,
            size: this.ContentSize(content)
        });
    }

    OnMinify = (e, minify) =>
    {
        const {content: c1} = this.props;
        const {content: c2} = this.state;
        const {edited} = this.state;
        const Content = edited ? c2.replace(/(^|[\n]) *|[\n\t]/g, "") : c1;
        const Formatted = this.FormatContent(Content, minify);
        this.setState({
            content: Formatted,
            minify,
            size: this.ContentSize(Formatted)
        });
    }

    OnTab = (e, tab) =>
    {
        this.setState({tab});
    }

    render()
    {
        const {className, onClose} = this.props;
        const {content, message, minify, tab, size} = this.state;
        const CA = ["ViewArticlesPreview"];
        let Content;
        if (className)
        {
            CA.push(className);
        }
        switch (parseInt(tab, 10))
        {
            case 1:
                Content = (
                    <div className="ViewArticlesPreviewTab">
                        <CodeField
                            className="ViewArticlesPreviewCode"
                            onInput={this.OnEdit}
                            value={content}
                        />
                    </div>
                );
                break;
            default:
                Content = (
                    <div className="ViewArticlesPreviewTab">
                        <div className="ViewArticlesPreviewFrameWrapper">
                            <iframe className="ViewArticlesPreviewFrame" ref={frame =>
                            {
                                if (!frame)
                                {
                                    return;
                                }
                                frame.contentWindow.document.open();
                                frame.contentWindow.document.write(`<div class="preview-wrapper">${content}</div>`);
                                frame.contentWindow.document.close();
                            }}></iframe>
                        </div>
                    </div>
                );
        }
        return (
            <div className="ViewArticlesPreview">
                <TabMenu
                    className="ViewArticlesPreviewTabMenu"
                    items={["Visual", "Code"]}
                    onClick={this.OnTab}
                    selected={tab}
                />
                {Content}
                <div className="ViewArticlesPreviewButtons Buttons">
                    <Button label="Copy Code" onClick={this.OnCopy}/>
                    <Button hollow={true} label="Close" onClick={onClose}/>
                    {message ? <div className="ViewArticlesPreviewMessage">{message}</div> : ""}
                    <div className="ViewArticlesPreviewMinify">
                       <div>Filesize is {size} kb</div>
                        <CheckboxField
                            checked={minify}
                            onChange={this.OnMinify}
                            text="Minify code to reduce size"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ViewArticlesPreview.defaultProps =
{
    className: "",
    content: "",
    onClose: () => {},
    publish: false,
    tab: 0
};

export default ViewArticlesPreview;