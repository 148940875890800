/**!
 *  Progress bar.
 *  @prop string className - Append a class name.
 *  @prop boolean full - Whether the progress should be 100% width.
 *  @prop boolean percent - Whether to show percent completed in the left label.
 *  @prop float progress - Progress 0-1.
 *  @prop string leftLabel - Label to the left of the progress bar.
 *  @prop string rightLabel - Label to the right of the progress bar.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./progress.scss";

class Progress extends React.Component
{
    OnMouseDown = (e) =>
    {
        const {
            id,
            interactive,
            onInteractionStart
        } = this.props;
        if (!interactive)
        {
            return;
        }
        onInteractionStart(e, id);
        window.addEventListener("mousemove", this.OnMouseMove);
        window.addEventListener("mouseup", this.OnMouseUp);
        document.addEventListener("mouseleave", this.OnMouseUp);
        this.OnMouseMove(e);
    }

    OnMouseMove = (e) =>
    {
        if (!this.Bar)
        {
            return;
        }
        const {
            id,
            onInteraction
        } = this.props;
        const {pageX} = e;
        const {left, width} = this.Bar.getBoundingClientRect();
        const Progress = Math.max(0, Math.min(1, (pageX - left) / width));
        onInteraction(e, Progress, id);
    }

    OnMouseUp = (e) =>
    {
        const {
            id,
            onInteractionEnd
        } = this.props;
        window.removeEventListener("mousemove", this.OnMouseMove);
        window.removeEventListener("mouseup", this.OnMouseUp);
        document.removeEventListener("mouseleave", this.OnMouseUp);
        onInteractionEnd(e, id);
    }

    render()
    {
        const {
            className,
            full,
            interactive,
            leftLabel,
            percent,
            progress,
            rightLabel
        } = this.props;
        const CA = ["Progress"];
        if (full)
        {
            CA.push("Full");
        }
        if (interactive)
        {
            CA.push("Interactive");
        }
        if (className)
        {
            CA.push(className);
        }
        let LeftLabel = leftLabel;
        if (!LeftLabel && percent && !full)
        {
            LeftLabel = Math.round(progress * 100) + "%";
        }
        return (
            <div className={CA.join(" ")} onMouseDown={this.OnMouseDown}>
                <div className="ProgressLeft">
                    {LeftLabel}
                </div>
                <div className="ProgressRight">
                    {rightLabel}
                </div>
                <div
                    className="ProgressBar"
                    ref={bar => this.Bar = bar}
                >
                    <div className="ProgressBarFill HighlightBackground cb-i" style={{width: (progress * 100) + "%"}}/>
                </div>
            </div>
        );
    }
}

Progress.propTypes =
{
    className: PropTypes.string,
    full: PropTypes.bool,
    interactive: PropTypes.bool,
    onInteraction: PropTypes.func,
    onInteractionEnd: PropTypes.func,
    onInteractionStart: PropTypes.func,
    percent: PropTypes.bool,
    progress: PropTypes.number,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string
};

Progress.defaultProps =
{
    className: "",
    full: false,
    id: "",
    interactive: false,
    onInteraction: () => {},
    onInteractionEnd: () => {},
    onInteractionStart: () => {},
    percent: true,
    progress: 0,
    leftLabel: "",
    rightLabel: "",
}

export default Progress;