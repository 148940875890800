/**!
 *  Search for content.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./search.scss";
import Fuse from "Class/Fuse";
import {RandomToken} from "Functions";
import ContentItem from "Components/UI/ContentItem";
import ContentListItem from "Components/UI/ContentListItem";
import Slider from "Components/UI/Slider";
import TextField from "Components/UI/Field/TextField";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetSearch extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Search",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            listView:
            {
                default: true,
                label: "Display as list",
                type: "checkbox"
            },
            community:
            {
                label: "Community",
                placeholder: "Search for communities...",
                type: "content",
                types: ["community"]
            },
            types:
            {
                label: "Type Filter",
                multiple: true,
                type: "contenttype"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Name = "Search";
        this.Timeout = false;
        this.state =
        {
            filter: "",
            results: [],
            searching: false
        };
    }

    OnSearch = (e, query) =>
    {
        if (query === this.state.filter)
        {
            return;
        }
        clearTimeout(this.Timeout);
        const {community, types} = this.Content();
        const Community = community.length ? community[0][0] : false;
        if (query.length < 3)
        {
            return this.setState({filter: query, searching: false});
        }
        this.setState({filter: query, searching: true});
        this.Timeout = setTimeout(() =>
        {
            Fuse.Search(query, Community, types, response =>
            {
                this.setState({searching: false});
                console.log("Response", response);
            });
        }, 300);
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover, rowHeight} = this.props;
        const {results = [], searching} = this.state;
        const {listView, title} = WidgetContent;
        const CA = ["Widget", "WidgetSearch", "White", "BorderRadius"];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (listView)
        {
            CA.push("ListView");
        }
        const Items = [];
        results.forEach(link =>
        {
            // Assign a unique id/key to each link to avoid re-render.            
            if (!link.id)
            {
                link.id = RandomToken();
            }
            if (listView)
            {
                Items.push(
                    <ContentListItem       
                        content={link}
                        key={link.id}
                    />
                );
            }
            else
            {
                Items.push(
                    <div className="WidgetSearchItem" key={link.id}>
                        <ContentItem
                            content={link}
                            width={200}
                            height={250}
                            sizeMultiplier={Math.min(1, rowHeight)}
                            text={link.description}
                        />
                    </div>   
                ); 
            }
        });
        let Content;
        if (!Items.length)
        {
            Content = (
                <div className="WidgetSearchForm">
                    <TextField
                        className="WidgetSearchFormInput"
                        feather="Search"
                        loading={searching}
                        onChange={this.OnSearch}
                        onInput={this.OnSearch}
                        placeholder="Search..."
                    />
                </div>
            );
        }
        else if (listView)
        {
            Content = (
                <div className="WidgetSearchResults">
                    {Items}
                </div>
            );
        }
        else
        {
            Content = (
                <div className="WidgetSearchAlign">
                    <Slider className="WidgetSearchResults" style={{height: 280 * rowHeight}}>
                        {Items}
                    </Slider>
                </div>
            );
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetSearchWrapper"
                    title={title}
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetSearch;