/*!
 *  Fuse content preview.
 *  @prop string className - Append a class name.
 *  @prop string content - Fuse content field.
 *  @prop string image - Fuse preview field.
 *  @prop number|string size - Preview size.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./preview.scss";
import Icon from "Components/Layout/Icon";
import LoadImage from "Components/Layout/LoadImage";

class Preview extends React.Component
{
    render()
    {
        const {className, content, image, size} = this.props;
        const CA = ["Preview", "ItemBackground"];
        const Content = content.toLowerCase();
        if (image)
        {
            CA.push("HasImage");
        }
        if (className)
        {
            CA.push(className);
        }
        let Feather;
        switch (Content)
        {
            case "error":
                Feather = "AlertCircle";
                break;
            case "community":
                Feather = "Users";
                break;
            case "content":
            case "content::article":
                Feather = "FileText";
                break;
            case "error":
                Feather = "AlertCircle";
                break;
            case "event":
                Feather = "Calendar";
                break;
            case "externallink":
                Feather = "ExternalLink";
                break;
            case "file":
                Feather = "File";
                break;
            case "learningplan":
                Feather = "Award";
                break;
            case "question":
                Feather = "HelpCircle";
                break;
            case "uploadedfile":
                Feather = "File";
                break;
            case "scormcourse":
                Feather = "Award";
                break;
            case "topic":
                Feather = "Tag";
                break;
            case "user":
                Feather = "User";
                break;
            case "video":
            case "capturedvideo":
                Feather = "Youtube";
                break;
            case "widget-assignedlearning":
                Feather = "Clipboard";
                break;
            case "widget-bannerlinks":
                Feather = "Layers";
                break;
            case "widget-blank":
                Feather = "Square";
                break;
            case "widget-button":
                Feather = "Navigation";
                break;
            case "widget-carousel":
                Feather = "BookOpen";
                break;
            case "widget-communities":
                Feather = "Users";
                break;
            case "widget-conditional":
                Feather = "Cpu";
                break;
            case "widget-content":
                Feather = "FileText";
                break;
            case "widget-conversation":
                Feather = "MessageCircle";
                break;
            case "widget-dropdown":
                Feather = "ChevronDown";
                break;
            case "widget-events":
                Feather = "Calendar";
                break;
            case "widget-favourites":
                Feather = "Heart";
                break;
            case "widget-hijack":
                Feather = "Copy";
                break;
            case "widget-imageandtext":
                Feather = "Image";
                break;
            case "widget-leaderboard":
                Feather = "List";
                break;
            case "widget-learningplans":
                Feather = "Award";
                break;
            case "widget-link":
                Feather = "ExternalLink";
                break;
            case "widget-multi":
                Feather = "Grid";
                break;
            case "widget-poll":
                Feather = "BarChart2";
                break;
            case "widget-question":
            case "widget-ask":
                Feather = "HelpCircle";
                break;
            case "widget-recommended":
                Feather = "Gift";
                break;
            case "widget-search":
                Feather = "Search";
                break;
            case "widget-successfactors":
                Feather = "Calendar";
                break;
            case "widget-tabs":
                Feather = "CreditCard";
                break;
            case "widget-trending":
                Feather = "TrendingUp";
                break;
            case "widget-useractivities":
                Feather = "Radio";
                break;
            case "widget-video":
                Feather = "Play";
                break;
            case "widget-wysiwyg":
                Feather = "Type";
                break;
            default:
                console.error("Unknown content:", Content);
                Feather = "File";
        }
        return (
            <div className={CA.join(" ")} style={{width: size, height: size}}>
                {image ? <LoadImage
                    className="PreviewImage"
                    src={typeof image === "string" ? image : ""}
                    srcId={typeof image === "number" ? image : 0}
                /> : ""}
                <Icon className="PreviewIcon" feather={Feather}/>
            </div>
        );
    }
}

Preview.propTypes =
{
    className: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Preview.defaultProps =
{
    className: "",
    content: "",
    image: "",
    size: 60
};

export default Preview;