/*!
 *  Multiple choice field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback function.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop array|object options - Field options.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./choicefield.scss";
import {ObjectAssign, ObjectCompare} from "Functions";

class ChoiceField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Input = false;
        this.Options = {};
        this.Updating = false;
        this.state = {
            focus: false,
            value: false
        };
    }

    /**
     * Set initial value on mount.
     * @return void
     */

    componentDidMount()
    {
        const {options, value} = this.props;
        this.SetValue(value, options, true);
    }

    /**
     * Update value and options.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        if (this.Updating)
        {
            return;
        }
        const {options: o1, value: v1} = this.props;
        const {options: o2, value: v2} = prevProps;
        if (v1 !== v2 || !ObjectCompare(o1, o2))
        {
            this.SetValue(v1, o1, true);
        }
    }

    /**
     * Output a option.
     * @param string label - The option label.
     * @param string key - The option key.
     * @param boolean selected - Whether this option is selected.
     * @return JSX - The option.
     */

    Item = (label, key, selected) =>
    {
        const CA =["Option"];
        if (selected)
        {
            CA.push( "Selected" );
        }
        return (
            <div
                key={key}
                className={CA.join(" ")}
                onClick={() => this.SetValue(key)}
                title={typeof label === "string" ? label : ""}
            >
                {label}   
            </div>
        );
    }

    /**
     * Callback for when the field loses focus.
     * @param object e - The event object.
     * @return void
     */

    OnBlur = (e) =>
    {
        const {id, onBlur} = this.props;
        const {value} = this.state;
        onBlur(e, value, id);
        this.setState({focus: false});
        window.removeEventListener("keydown", this.OnKeyDown);
        window.removeEventListener("keyup", this.OnKeyUp);
    }

    /**
     * Callback for when the field gains focus.
     * @param object e - The event object.
     * @return void
     */

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const {value} = this.state;
        onFocus(e, value, id);
        this.setState({focus: true});
        // Listen for key presses while focused.
        window.addEventListener("keydown", this.OnKeyDown);
        window.addEventListener("keyup", this.OnKeyUp);
    }

    /**
     * Callback for when a key is pressed while the field has focus.
     * @param object e - The event object.
     * @return void
     */

    OnKeyDown = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        if (disabled)
        {
            return;
        }
        const {value} = this.state;
        const Options = this.Options;
        const Keys = Object.keys(Options);
        const Limit = Keys.length - 1;
        const Index = Keys.indexOf(value);
        let Value;
        switch (e.which)
        {
            // Up/Left = Previous option.
            case 37:
            case 38:
                Value = Keys[Index <= 0 ? 0 : Index - 1];
                onChange(e, Value, id);
                this.setState({value: Value});
                break;
            // Down/Right = Next option.
            case 39:
            case 40:
                Value = Keys[Index >= Limit ? Limit : Index + 1];
                onChange(e, Value, id);
                this.setState({value: Value});
                break;
            default:
                return;
        }
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Callback for when a key is released while the field has focus.
     * @param object e - The event object.
     * @return void
     */

    OnKeyUp = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Adjust the width of the dropdown menu when the client resizes.
     * @return void
     */

    SetSize = () =>
    {
        if (!this.Input)
        {
            return;
        }
        this.setState({width: this.Input.offsetWidth});
    }

    /**
     * Update the selected value and options.
     * @param string key - The selected option key.
     * @param array|object options - Select options.
     * @param boolean noCallback - Whether to skip onChange().
     * @return string|array - The selected options label(s).
     */

    SetValue = (key, options, noCallback = false) =>
    {
        const {id, onChange, multiple, placeholder} = this.props;
        const {value} = this.state;
        this.Options = ObjectAssign({}, options || this.Options);
        const Keys = Object.keys(this.Options);
        if (!Keys.length)
        {
            return false;
        }
        let NewValue;
        if (multiple)
        {
            const Reset = Array.isArray(key);
            NewValue = (Reset ? key : Array.isArray(value) ? value : []).filter(k => typeof k === "string");
            const Index = NewValue.indexOf(key);
            if (!Reset && Index < 0)
            {
                NewValue.push(key);
            }
            else if (!Reset)
            {
                NewValue.splice(Index, 1);
            }
        }
        else
        {
            const Key = Array.isArray(key) ? key[0] : key;
            NewValue = this.Options[Key] === "undefined" ? (placeholder ? -1 : Keys[0]) : Key;
        }
        if (!noCallback)
        {
            onChange(null, NewValue, id);
        }
        this.Updating = true;
        this.setState({value: NewValue}, () => this.Updating = false);
    }

    /**
     * Get the selected option label.
     * @return string - The selected options label.
     */

    Value = () =>
    {
        const {multiple} = this.props;
        const {value} = this.state;
        const Options = this.Options;
        const Keys = Object.keys(Options);
        if (!Keys.length)
        {
            return multiple ? [] : "";
        }
        if (!multiple)
        {
            return Options[value] || Options[Keys[0]];
        }
        const Value = [];
        (Array.isArray(value) ? value : [value]).forEach(key =>
        {
            if (Options[key] === undefined)
            {
                return;
            }
            Value.push(Options[key]);
        });
        return Value;
    }

    render()
    {
        const {className, disabled, error, label, multiple, placeholder} = this.props;
        const {focus, value} = this.state;
        const CA = ["Field", "ChoiceField"];
        if (disabled || !this.Options.length)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (className)
        {
            CA.push(className);
        }
        const Items = [];
        let Index = 0;
        if (placeholder)
        {
            Items.push(this.Item(placeholder, -1, value === -1));
        }
        for (let key in this.Options)
        {
            let Item = this.Options[key];
            let IsSelected = multiple ? (Array.isArray(value) ? value.indexOf(key) >= 0 : false) : ((!Index && !key) || key === value);
            Items.push(this.Item(Item, key, IsSelected));
            Index++;
        }
        return (
            <div className={CA.join(" ")}>
                {label ? <label onClick={this.OnToggle}>{label}</label> : ""}
                <div
                    className="Input"
                    onBlur={this.OnBlur}
                    onClick={this.OnToggle}
                    onFocus={this.OnFocus}
                    ref={input => this.Input = input}
                    tabIndex="0"
                >
                    {Items}
                </div>
            </div>            
        );
    }
}

ChoiceField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    placeholder: PropTypes.string
};

ChoiceField.defaultProps =
{
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    multiple: false,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    options: [],
    placeholder: "",
    value: -1
};

export default ChoiceField;