/**!
 *  Custom HTML widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./customhtml.scss";

class WidgetCustomHtml extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = {
            html:
            {
                label: "HTML",
                type: "code"
            },
            css:
            {
                label: "CSS",
                language: "css",
                type: "code"
            },
        };
    }

    render()
    {
        const {css, html} = this.Content();
        const CA = ["Widget", "WidgetCustomHtml", "White", "BorderRadius"];
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget}>
                {this.Toolbar()}
                <style>
                    {css}
                </style>
                <div
                    className="WidgetCustomHtmlContent"
                    dangerouslySetInnerHTML={{__html: html}}
                />
            </div>
        );
    }
}

export default WidgetCustomHtml;