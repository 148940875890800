/*!
 *  Checkbox form field.
 *  @prop boolean|integer checked - Whether the field is checked.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value. 
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onChange - Callback function.
 *  @prop string text - Text beside the radio button field.
 *  @prop string title - The checkbox title attribute.
 *  @prop mixed valueTrue - Return value when checked. Defaults to 'true'.
 *  @prop mixed valueFalse - Return value when checked. Defaults to 'false'.
 *  @prop JSX [ ...children ] - Field content.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./checkboxfield.scss";
import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";

class CheckboxField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            checked: false
        };
    }

    /**
     * Set initial value.
     * @return void
     */

    componentDidMount()
    {
        const {checked} = this.props;
        this.setState({checked});
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {checked: c1} = this.props;
        const {checked: c2} = prevProps;
        if (c1 !== c2)
        {
            this.setState({checked: c1});
        }
    }

    /**
     * Clear the field value.
     * @param object e - The event object.
     * @return void
     */

    OnClearField = (e) =>
    {
        e.preventDefault();
        e.stopPropagation();
        const {id, onClear} = this.props;
        onClear(e, id);
    }

    /**
     * Toggle value when clicked.
     * @param object e - Click event.
     * @return void
     */

    OnClick = (e) =>
    {
        const {disabled, id, onChange, valueTrue, valueFalse} = this.props;
        let {checked} = this.state;
        if (disabled)
        {
            return;
        }
        checked = !checked;
        this.setState({checked});
        onChange(e, checked ? valueTrue : valueFalse, id);
    }

    /**
     * Reset to inital state.
     * @return void
     */

    Reset = () =>
    {
        const {checked} = this.props;
        this.setState({checked});
    }

    /**
     * Get the field value.
     * @return mixed - The field value.
     */

    Value = () =>
    {
        const {valueTrue, valueFalse} = this.props;
        const {checked} = this.state;
        return checked ? valueTrue : valueFalse;
    }

    render()
    {
        const {
            children,
            className,
            clear,
            disabled,
            error,
            label,
            text,
            title
        } = this.props;
        const {checked} = this.state;
        const CA = ["Field", "CheckboxField"];
        // Behaviour and appearance changes depending on if props.label is set.
        if (checked)
        {
            CA.push("Checked");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        else
        {
            CA.push("NoLabel");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                {label ? (
                    <label onClick={this.OnClick}>
                        {label}
                        {clear ? (
                            <IconButton
                                disabled={disabled}
                                feather="XCircle"
                                onClick={this.OnClearField}
                                title="Reset this field"
                            />
                        ) : ""}
                    </label>
                ) : ""}
                <div className="CheckboxFieldContent">
                    <div
                        className="Input" onClick={this.OnClick}
                        onMouseDown={e => e.stopPropagation()}
                        title={title || label} 
                    >
                        {checked ? <Icon feather="Check" /> : ""}
                    </div>
                    <span onClick={text ? this.OnClick : () => {}}>
                        {text || children}
                    </span>
                </div>
            </div>
        );
    }
}

CheckboxField.propTypes =
{
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    className: PropTypes.string,
    clear: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string
};

CheckboxField.defaultProps =
{
    checked: false,
    clear: false,
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onChange: () => {},
    onClear: () => {},
    text: "",
    title: "",
    valueTrue: true,
    valueFalse: false
};

export default CheckboxField;