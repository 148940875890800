import React from "react";
import IconButton from "Components/UI/IconButton";

class ToolbarAlign extends React.Component
{
    OnClick = () =>
    {
        const {currentState, onChange} = this.props;
        const {textAlignment} = currentState;
        let Set;
        switch (textAlignment)
        {
            case "center":
                Set = "right";
                break;
            case "right":
                Set = "left";
                //Set = "justify";
                break;
            /*case "justify":
                Set = "justify-all";
                break;
            case "justify-all":
                Set = "left";
                break;*/
            default:
                Set = "center";
        }
        onChange(Set);
    }

    render()
    {
        const {currentState} = this.props;
        const {textAlignment} = currentState;
        let IconSrc;
        switch (textAlignment)
        {
            case "center":
                IconSrc = "AlignCenter";
                break;
            case "justify":
                IconSrc = "AlignJustify";
                break;
            case "justify-all":
                IconSrc = "Menu";
                break;
            case "right":
                IconSrc = "AlignRight";
                break;
            default:
                IconSrc = "AlignLeft";
        }
        return (
            <div className="WywiwygToolbarAlign WywiwygToolbarButtons" onClick={e => e.stopPropagation()}>
                <IconButton
                    className="WywiwygToolbarButton"
                    feather={IconSrc}
                    onClick={this.OnClick}
                    title="Change horizontal text alignment"
                />
            </div>
        );  
    }
}

export default ToolbarAlign;