/*!
 *  Collapsable view.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean collapsed - Whether the view should be collapsed.
 *  @prop integer collapseHeight - Height when the view is collapsed.
 *  @prop float duration - Duration of the transition between expand/collpase in seconds.
 *  @prop function onOverflow - Callback when the container toggles between having overflow or not.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./collapsable.scss";
import {CSSPlugin, TweenLite} from "gsap";
import {Power1} from "gsap/all";

class Collapsable extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Mounted = false;
        this.state =
        {
            finished: false
        };
    }

    /**
     * Expand/collapse on mount.
     * 
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        if (CSSPlugin) {}
        const {collapsed} = this.props;
        this.CheckOverflow();
        this.Toggle(collapsed, true);
        window.addEventListener("resize", this.CheckOverflow);
    }

    /**
     * Expand/collapse when the prop changes.
     * 
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {collapsed: c1} = this.props;
        const {collapsed: c2} = prevProps;
        if (c1 !== c2)
        {
            this.Toggle(c1);
        }
    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        window.removeEventListener("resize", this.CheckOverflow);
    }

    /**
     * Check whether the container has overflow, ie. if the collapseHeight is
     * shorter than the contents.
     * 
     * @return void
     */

    CheckOverflow = () =>
    {
        const {collapseHeight, onOverflow} = this.props;
        onOverflow(this.Content ? this.Content.offsetHeight > collapseHeight : false);
    }

    /**
     * Expand/collapse the view.
     * 
     * @param boolean collapsed - Whether the view should collapse.
     * @param boolean initial - Set duration to '0' for the initial setup.
     * @return void
     */

    Toggle = (collapsed, initial) =>
    {
        const {collapseHeight, duration, id, onComplete} = this.props;
        if (!this.Container || !this.Content)
        {
            return;
        }
        const Height = collapsed ? collapseHeight : this.Content.offsetHeight;
        const Duration = initial ? 0 : duration;
        TweenLite.to(this.Container, Duration, {
            ease: Power1.easeInOut,
            height: Height,
            onComplete: () =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                if (!collapsed)
                {
                    this.Container.style.height = "auto";
                }
                this.setState({finished: !collapsed});
                onComplete(collapsed, id);
            }
        });
    }

    render()
    {
        const {children, className, collapsed, collapseHeight} = this.props;
        const {finished} = this.state;
        const CA = ["Collapsable"];
        if (collapsed && collapseHeight)
        {
            CA.push("SemiCollapsed");
        }
        else if (collapsed)
        {
            CA.push("Collapsed");
        }
        else
        {
            CA.push("Expanded");
        }
        if (finished)
        {
            CA.push("Finished");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")} ref={container => this.Container = container}>
                <div className="CollapsableContent" ref={content => this.Content = content}>
                    {children}
                </div>
            </div>
        );
    }
}

Collapsable.propTypes =
{
    className: PropTypes.string,
    collapsed: PropTypes.bool,
    collapseHeight: PropTypes.number,
    duration: PropTypes.number,
    id: PropTypes.string,
    onComplete: PropTypes.func,
    onOverflow: PropTypes.func
};

Collapsable.defaultProps =
{
    className: "",
    collapsed: true,
    collapseHeight: 0,
    duration: .5,
    id: "",
    onComplete: () => {},
    onOverflow: () => {}
}

export default Collapsable;