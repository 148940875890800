/**!
 *  Event list widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./events.scss";
import API from "Class/API";
import Auth from "Class/Auth";
import Fuse from "Class/Fuse";
import {ArrayClone, DateStamp, TimeStamp, UcFirst} from "Functions";
import FilterField from "Components/UI/Field/FilterField";
import Link from "Components/UI/Link";
import Preview from "Components/Layout/Preview";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetEvents extends Widget
{
    constructor(props)
    {
        super(props);
        this.DefaultFilter = "upcoming";
        this.Filters =
        {
            attending: "Attending",
            past: "Past",
            pending: "Pending",
            upcoming: "Upcoming"
        };
        this.Fields = this.SetFields({
            title:
            {
                default: "Events",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            showFilter:
            {
                default: true,
                label: "Show filter options",
                type: "checkbox"
            },
            initialFilter:
            {
                default: this.DefaultFilter,
                label: "Initial filter",
                options: this.Filters,
                type: "select"
            },
            initialCommunities:
            {
                default: true,
                label: "Only users community events initially",
                type: "checkbox"
            },
            externalLink:
            {
                label: "Open events in a new tab",
                type: "checkbox"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Name = "Events";
        this.state =
        {
            communities: false,
            done: false,
            error: false,
            events: {},
            filter: this.DefaultFilter,
            loading: false,
            occurrences: []
        };
    }

    /**
     * Load events and set filter on mount.
     * @return void
     */

    componentDidMount()
    {
        const {content} = this.props;
        const {initialCommunities, initialFilter} = content;
        this.setState({communities: initialCommunities});
        this.Load(initialFilter, true);
    }

    /**
     * Update filter when the widget props changes.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {communities, filter} = this.state;
        const {initialCommunities: ic1, initialFilter: if1} = c1;
        const {initialCommunities: ic2, initialFilter: if2} = c2;
        if (ic1 !== ic2 && ic1 !== communities)
        {
            this.setState({communities: ic1});
        }
        if (if1 != if2 && if1 !== filter)
        {
            this.Load(if1, true);
        }
    }

    /**
     * Output event occurrence item.
     * @param object occurrence - Occurrence object.
     * @return JSX - The event occurrence item.
     */

    Item = (occurrence) =>
    {
        const {externalLink} = this.Content();
        const {id, event, name, starts} = occurrence;
        const Starts = new Date();
        const Url = Fuse.Url(`events/${event}/occurrences/${id}`, false);
        Starts.setTime(starts * 1000);
        return (
            <Link
                className="WidgetEventsItem"
                key={id}
                href={Url}
                target={externalLink ? "_blank" : "_top"}
            >
                <Preview
                    className="ItemPreview"
                    content="community"
                />
                <div className="ItemContent">
                    <div className="ItemName">{name}</div>
                    <div className="ItemInfo">
                        {DateStamp(Starts) + " " + TimeStamp(false, Starts)}
                    </div>
                </div>
            </Link>
        );
    }

    /**
     * Load events from the Fuse API.
     * @param string filter - past, pending, attending or upcoming.
     * @param boolean clear - Whether to clear the widget before loading new events.
     * @return void
     */

    Load = (filter, clear) =>
    {
        const {events, occurrences} = this.state;
        const Events = ArrayClone(events);
        const Occurrences = clear ? [] : ArrayClone(occurrences);
        const State =
        {
            error: false,
            filter,
            loading: true
        };
        if (clear)
        {
            State.done = false;
            State.occurrences = [];
        }
        this.setState(State);
        Fuse.Request("event_occurrences", {filter}, response =>
        {
            const {event_occurrences, last_page} = response || {};
            if (!event_occurrences)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                const Ids = [];
                event_occurrences.forEach(occurrence =>
                {
                    const {event_id} = occurrence;
                    if (Occurrences.indexOf(event_id) < 0 && Ids.indexOf(event_id) < 0)
                    {
                        Ids.push(event_id);
                    }
                });
                /**
                 * Lookup events in the backend DB to receive event community etc.
                 */
                API.Request("content/events", {ids: Ids}, response =>
                {
                    const {error, events} = response;
                    if (error)
                    {
                        this.setState({error: true, loading: false});
                    }
                    else
                    {
                        for (let id in events)
                        {
                            Events[id] = events[id];
                        }
                        event_occurrences.forEach(occurrence =>
                        {
                            const {
                                description,
                                id: occurrence_id,
                                ends_at_integer,
                                event_id,
                                event_name,
                                starts_at_integer
                            } = occurrence;
                            const Event = Events[event_id];
                            const {community} = Event;
                            Occurrences.push( {
                                community: parseInt( community, 10 ),
                                description,
                                ends: ends_at_integer,
                                event: event_id,
                                id: occurrence_id,
                                name: event_name,
                                starts: starts_at_integer
                            });
                        });
                        this.setState({
                            done: last_page,
                            events: Events,
                            loading: false,
                            occurrences: Occurrences
                        });
                    }
                });
            }
        });
    }

    /**
     * Adjust field items or load new events when the filters change.
     * @param object e - The event object.
     * @param object filters - New filters.
     * @return void
     */

    SetFilter = (e, filters) =>
    {
        const {communities: c1, filter: f1} = filters;
        const {communities: c2, filter: f2} = this.state;
        if (c1 !== c2)
        {
            this.setState({communities: c1});
        }
        if (f1 !== f2)
        {
            this.Load(f1, true);
        }
    }

    /**
     * Set the events filter label.
     * @return string - The label
     */

    SetFilterLabel = () =>
    {
        const {communities, filter} = this.state;
        const Filter = UcFirst(filter || this.DefaultFilter);
        return communities ? `${Filter} in my communities` : `${Filter} in all communities`;
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props;
        const {communities, filter, loading, occurrences} = this.state;
        const {title, showFilter} = WidgetContent;
        const CA = ["Widget", "WidgetEvents", "White", "BorderRadius"];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (loading)
        {
            CA.push( "Loading" );
        }
        const Content = [];
        const Occurrences = [];
        occurrences.forEach(occurrence =>
        {
            const {community} = occurrence;
            if (!communities || Auth.Member(community))
            {
                Occurrences.push(occurrence);
            }
        });
        if (!Occurrences.length && loading)
        {
            Content.push( <Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (!Occurrences.length)
        {
            Content.push( <div className="WidgetEmpty" key="empty">No events</div>);
        }
        else
        {
            const Items = [];
            Occurrences.forEach(occurrence =>
            {
                Items.push(this.Item(occurrence));
            });
            Content.push(<div className="WidgetEventsItems" key="items">{Items}</div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetEventsWrapper"
                    title={title}
                    toolbar={showFilter ? <FilterField
                        className="WidgetEventsFilter"
                        fields={{
                            filter:
                            {
                                options: this.Filters,
                                type: "choice"
                            },
                            communities:
                            {
                                type: "checkbox",
                                text: "My communities"
                            }
                        }}
                        onChange={this.SetFilter}
                        selectedLabel={this.SetFilterLabel}
                        values={{filter, communities}}
                    /> : ""} 
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetEvents;