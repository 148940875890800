import React from "react";
import SelectField from "Components/UI/Field/SelectField";

class ToolbarBlockTypes extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Options = ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"];
    }

    OnChange = (e, index) =>
    {
        if (this.Options[index] === undefined)
        {
            return;
        }
        const {onChange} = this.props;
        onChange(this.Options[index]);
    }

    render()
    {
        const {config = {}, currentState} = this.props;
        const {className} = config;
        const SelectedIndex = this.Options.indexOf(currentState.blockType);
        const Selected = SelectedIndex < 0 ? "0" : SelectedIndex.toString();
        return (
            <SelectField
                className="WywiwygToolbarSelectField"
                flip={className === "flip"}
                onChange={this.OnChange}
                options={this.Options}
                value={Selected}
            />
        );
    }
}

export default ToolbarBlockTypes;