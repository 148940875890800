import React from "react";
import ToolbarButtons from "./buttons";

class ToolbarInlines extends React.Component
{
    render()
    {
        return (
            <ToolbarButtons
                {...this.props}
                buttons={{
                    bold: ["Bold", "Bold"],
                    italic: ["Italic", "Italic"],
                    underline: ["Underline", "Underline"],     
                }}
            />
        );
    }
}

export default ToolbarInlines;