/**!
 *  Ask question widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./ask.scss";
import Fuse from "Class/Fuse";
import {NoOrphans} from "Functions";
import Button from "Components/UI/Button";
import Center from "Components/Layout/Center";
import TextareaField from "Components/UI/Field/TextareaField";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetAsk extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Ask a question",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            text:
            {
                insert: true,
                label: "Text",
                type: "textarea"
            },
            community:
            {
                label: "Community",
                placeholder: "Search for communities...",
                type: "content",
                types: ["community"]
            },
            contentNotice:
            {
                displayIf: ["community", "==", 0],
                label: "If no community is specified, the question will be posted to the current community.",
                type: "notice"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Mounted = false;
        this.Name = "Ask Question";
        this.state =
        {
            index: 0,
            question: "",
            sending: false,
            sent: false
        };
    }

    /**
     * Register mount.
     * @return void
     */

    componentDidMount()
    {    
        this.Mounted = true;   
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Close the send confirmation pop-up.
     * @param object e - Event object.
     * @return void
     */

    Close = (e) =>
    {
        this.setState({question: "", sent: false});
    }

    /**
     * Get the ID of the specified or current community.
     * @return integer|boolean - Community ID or 'false'.
     */

    Community = () =>
    {
        const {community} = this.Content();
        const {context, contextId} = this.props;
        if (community && typeof community === "object" && typeof community[0] === "object")
        {
            return community[0][0];
        }
        return context === "community" ? contextId : false;
    }

    /**
     * Callback when the question field is edited.
     * @param object e - Event object.
     * @param string question - The edited question.
     * @return void
     */

    OnQuestion = (e, question) =>
    {
        this.setState({question});
    }

    /**
     * Post the question.
     * @return void
     */

    Send = () =>
    {
        const {question, sending} = this.state;
        const Community = this.Community();
        if (sending || !question || !Community)
        {
            return;
        }
        const Words = question.split(" ");
        const Maxlength = 40;
        let Name = "";
        for (let i = 0; i < Words.length; i++)
        {
            if (i && Name.length + Words[i].length + 1 > Maxlength)
            {
                Name += "...";
                break;
            }
            else
            {
                Name += " " + Words[i];
            }
        }
        this.setState({sending: true});
        Fuse.Request("contents/question",
        {
            name: Name,
            description: question,
            community_ids: Community
        }, response =>
        {
            if (!this.Mounted)
            {
                return;
            }
            this.setState({sending: false, sent: true});
        }, "POST");
    }

    render()
    {
        const {active, hover} = this.props;
        const {question, sending, sent} = this.state;
        const {text, title} = this.Content();
        const CA = this.ClassNames(["WidgetAsk", "BorderRadius"]);
        if (active)
        {
            CA.push("Active");   
        }
        if (hover)
        {
            CA.push("Hover");   
        }
        const Text = typeof text === "string" ? text.split(/\r?\n/g) : [];
        const Rows = [];
        Text.forEach((row, index) =>
        {
            Rows.push(<p key={index}>{row}</p>);
        });
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <WidgetWrapper
                    className="WidgetAskWrapper"
                    title={title}
                >
                    <Center>
                        {Rows.length ? <div className="WidgetAskText">{Rows}</div> : ""}
                        <TextareaField
                            maxRows={3}
                            onChange={this.OnQuestion}
                            onInput={this.OnQuestion}
                            placeholder="Your question..."
                            value={question}
                        />
                        <Button
                            disabled={!question || !this.Community()}
                            done={sent}
                            onClick={this.Send}
                            label="Ask question"
                            loading={sending}
                        />
                    </Center>
                    {sent ? <div className="WidgetAskConfirm">
                        <span>{NoOrphans(`Your question has been sent!`)}</span>
                        <Button
                            onClick={this.Close}
                            label="Close"
                        />
                    </div> : ""}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetAsk;