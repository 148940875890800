/**!
 *  Fuse video widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

 import React from "react";
 import Widget from "../../widget.js";
 import "./video.scss";
 import VideoPlayer from "Components/UI/VideoPlayer";
 
 class WidgetVideo extends Widget
 {
    constructor(props)
    {
        super(props);
        this.Fields = {
            video:
            {
                label: "Video Content",
                placeholder: "Search for videos...",
                type: "content",
                types: ["video"]
            },
            preview:
            {
                label: "Show Preview",
                type: "checkbox",
                default: true
            },
            backgroundImage:
            {
                displayIf: ["preview", "!==", true],
                label: "Background Image",
                type: "image"
            },
            backgroundOverlay:
            {
                displayIf: ["and", ["preview", "!==", true], ["backgroundImage", "!==", "empty"]],
                label: "Background Overlay",
                type: "checkbox",
                default: true
            },
            backgroundColor:
            {
                displayIf: ["and", ["preview", "!==", true], ["backgroundImage", "===", "empty"]],
                gradient: true,
                label: "Background Color",
                type: "color",
                default: "gradient"
            },
            textColor:
            {
                label: "Foreground Color",
                type: "color",
                default: "white"
            },
            grayscale:
            {
                displayIf: ["and", ["preview", "!==", true], ["backgroundImage", "!==", "empty"]],
                label: "Grayscale",
                type: "checkbox",
                default: false
            }
        };
    }
 
    render()
    {
        const {
            preview,
            textColor,
            video
        } = this.Content();
        const CA = preview ? ["Widget", "WidgetVideo", "White", "BorderRadius"] : this.ClassNames(["WidgetVideo", "BorderRadius"]);
        if (preview)
        {
            CA.push("ShowPreview");
        }
        else
        {
            CA.push("NoPreview");
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={preview ? {color: textColor || "#ffffff"} : this.Style()}>
                {this.Toolbar()}
                {preview ? "" : this.BackgroundImage()}
                <VideoPlayer
                    className="WidgetVideoPlayer"
                    contentId={video ? video[0] : 0}
                    showPreview={preview}
                />
            </div>
        );
    }
 }
 
 export default WidgetVideo;