/**!
 *  Interactive items slider.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./slider.scss";
import {TweenLite, Power3, CSSPlugin} from "gsap/all";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import IconButton from "Components/UI/IconButton";
const Plugins = [ScrollToPlugin, CSSPlugin];

class Slider extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Plugins = Plugins;
        this.UseGsap = false;
        this.Wrapper = false;
        this.state = {
            scrollLeft: false,
            scrollRight: false
        };
    }

    /**
     * Show/hide scroll buttons on mount.
     * @return void
     */

    componentDidMount()
    {
        this.OnScroll();
        window.addEventListener("resize", this.OnScroll);
    }

    /**
     * Show/hide scroll buttons on update.
     * @return void
     */

    componentDidUpdate()
    {
        this.OnScroll();
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.OnScroll);
    }

    /**
     * Show/hide scroll buttons when the user scrolls the container.
     * @return void
     */

    OnScroll = () =>
    {
        const {scrollLeft, scrollRight} = this.state;
        if (!this.Wrapper)
        {
            return;
        }
        const Limit = this.Wrapper.scrollWidth - this.Wrapper.offsetWidth;
        const Scrolled = this.Wrapper.scrollLeft;
        const ScrollLeft = Scrolled > 0;
        const ScrollRight = Scrolled < Limit;
        if (scrollLeft !== ScrollLeft || scrollRight !== ScrollRight)
        {
            this.setState({
                scrollLeft: ScrollLeft,
                scrollRight: ScrollRight
            });
        }
    }

    /**
     * Callback when the left scroll button is clicked.
     * @return void
     */

    ScrollLeft = () =>
    {
        if (!this.Wrapper)
        {
            return;
        }
        const Items = this.Wrapper.childNodes;
        const Scrolled = this.Wrapper.scrollLeft;
        const Width = this.Wrapper.offsetWidth;
        // Find the left most item that lies within one container length of
        // the left edge and then scroll to it.
        let X = -1;
        Items.forEach(item =>
        {
            const Offset = item.offsetLeft - 10;
            if (X >= 0 || Offset - Scrolled < -Width)
            {
                return;
            }
            X = Offset;
        });
        X = Math.max(X, 0);
        if (X < Width / 2)
        {
            X = 0;
        }
        if (this.UseGsap)
        {
            TweenLite.to(this.Wrapper, 1, {
                scrollTo: {x: X, y: 0},
                ease: Power3.easeInOut
            });
        }
        else
        {
            this.Wrapper.scrollLeft = X;
        }
    }

    /**
     * Callback when the right scroll button is clicked.
     * @return void
     */

    ScrollRight = () =>
    {
        if (!this.Wrapper)
        {
            return;
        }
        const Items = this.Wrapper.childNodes;
        const Scrolled = this.Wrapper.scrollLeft;
        const Width = this.Wrapper.offsetWidth;
        const Limit = this.Wrapper.scrollWidth - Width;
        // Find the right most item that lies within the visible part of the
        // container and scroll to it.
        let X;
        Items.forEach(item =>
        {
            const Offset = item.offsetLeft;
            if (Offset - Scrolled > Width)
            {
                return;
            }
            X = Offset - 20;
        });
        X = Math.min(Math.max(X, 350), Limit);
        if (Limit - X < 150)
        {
            X = Limit;
        }
        if (this.UseGsap)
        {
            TweenLite.to(this.Wrapper, 1, {
                scrollTo: { x: X, y: 0 },
                ease: Power3.easeInOut
            });
        }
        else
        {
            this.Wrapper.scrollLeft = X;
        }
    }

    render()
    {
        const {children, className} = this.props;
        const {scrollLeft, scrollRight} = this.state;
        const CA = ["Slider"];
        if (className)
        {
            CA.push(className);
        }
        if (scrollLeft)
        {
            CA.push("ScrollLeft");
        }
        if (scrollRight)
        {
            CA.push("ScrollRight");
        }
        if (!this.UseGsap)
        {
            CA.push("NoGsap");
        }
        return (
            <div {...this.props} className={CA.join(" ")}>
                <div className="SliderContent">
                    <div
                        className="SliderWrapper"
                        onScroll={this.OnScroll}
                        ref={wrapper => this.Wrapper = wrapper}
                    >
                        {children}
                    </div>
                    <div className="SliderShadow Left"></div>
                    <div className="SliderShadow Right"></div>
                    <div className="SliderButtonContainer Left">
                        <IconButton
                            className="SliderButton ItemBackground"
                            feather="ArrowLeft"
                            onClick={this.ScrollLeft}
                            size={36}
                        />
                    </div>
                    <div className="SliderButtonContainer Right">
                        <IconButton
                            className="SliderButton ItemBackground"
                            feather="ArrowRight"
                            onClick={this.ScrollRight}
                            size={36}
                        />
                    </div>
                </div>
            </div>
       );
    }
}

Slider.propTypes =
{
    className: PropTypes.string
};

Slider.defaultProps =
{
    className: ""
};

export default Slider;