import React from "react";
import API from "Class/API";
import Globals from "Class/Globals";
import {renderToString} from "react-dom/server";
import {ArrayClone, ArrayMove, RandomToken, TimeSince} from "Functions";
import Button from "Components/UI/Button";
import ArticleBlocks from "Components/View/Articles/Blocks/blocks";
import ContentField from "Components/UI/Field/ContentField";
import IconItem from "Components/UI/IconItem";
import ImageField from "Components/UI/Field/ImageField";
import Form from "Components/UI/Form";
import Group from "Components/UI/Group";
import ScrollView from "Components/UI/ScrollView";
import SelectField from "Components/UI/Field/SelectField";
import TextareaField from "Components/UI/Field/TextareaField";
import TextField from "Components/UI/Field/TextField";
import ViewArticlesPreview from "../Preview";
import Palette from "Components/View/Articles/palette.js";
import "Components/View/Articles/Blocks/blocks.scss";

class ViewArticlesEditor extends React.Component
{
    constructor(props)
    {
        super(props);
        this.AccessLookup = {};
        this.Blocks = {};
        this.Mounted = false;
        this.state =
        {
            access: {},
            access_disable: {1: [], 2: []},
            appearance: {},
            blocks: [],
            description: "",
            error: false,
            image: false,
            images: {},
            publish: 0,
            saving: false,
            selected: -1,
            title: "",
            type: "article"
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
        const {
            access,
            appearance,
            blocks,
            description,
            image,
            images,
            title,
            type
        } = this.props;
        this.Reset(type, appearance, blocks, images, title, description, access, image);
    }

    componentWillUnmount()
    {
        this.Mounted = false;
        this.OnPreviewClose();
    }

    DuplicateBlock = (id, index) =>
    {
        const {blocks} = this.state;
        const Block = blocks[index];
        if (!Block)
        {
            return;
        }
        const {
            appearance,
            content,
            template
        } = Block;
        const Id = RandomToken(10);
        const Duplicate = {
            appearance: ArrayClone(appearance),
            content: ArrayClone(content),
            id: Id,
            template
        };
        this.Blocks[Id] = Duplicate;
        blocks.splice(index + 1, 0, Duplicate);
        this.setState({blocks, selected: blocks.indexOf(Duplicate)}, () => this.OnEdit());
    }

    GetAccess = (permission) =>
    {
        const {access} = this.state;
        const List = [];
        for (let id in access)
        {
            if (access[id] == permission)
            {
                List.push([id, "user"]);
            }
        }
        return List;
    }

    GetActiveBlock = () =>
    {
        const {blocks, selected} = this.state;
        return blocks[selected];
    }

    GetAppearanceFields = (local) =>
    {
        const Fields = {};
        if (local)
        {
            Fields.inheritAppearance =
            {
                clear: false,
                default: true,
                label: "Inherit Appearance",
                type: "checkbox"
            };
        }
        Fields.fontFaceHeading =
        {
            default: "lato",
            label: "Heading Font Face",
            options:
            {
                lato: ["Lato", <i key="lato"> (default)</i>],
                calibri: ["Calibri", <i key="calibri"> (default)</i>],
                helvetica: "Helvetica",
                merriweather: "Merriweather",
                montserrat: "Montserrat",
                openSans: "Open Sans",
                playfairDisplay: "Playfair Display",
                roboto: "Roboto",
                ubuntu: "Ubuntu"
            },
            type: "select"
        };
        Fields.fontFaceText =
        {
            default: "lato",
            label: "Text Font Face",
            options:
            {
                lato: ["Lato", <i key="lato"> (default)</i>],
                calibri: ["Calibri", <i key="calibri"> (default)</i>],
                helvetica: "Helvetica",
                merriweather: "Merriweather",
                montserrat: "Montserrat",
                openSans: "Open Sans",
                playfairDisplay: "Playfair Display",
                roboto: "Roboto",
                ubuntu: "Ubuntu"
            },
            type: "select"
        };
        Fields.colorText =
        {
            default: "#000000",
            label: "Text Color",
            type: "color"
        };
        Fields.colorBackground =
        {
            default: "#ffffff",
            gradient: true,
            label: "Background Color",
            type: "color"
        };
        Fields.colorItemForeground =
        {
            default: "#ffffff",
            label: "Item Foreground Color",
            type: "color"
        };
        Fields.colorItemBackground =
        {
            default: "#78153a",
            gradient: true,
            label: "Item Background Color",
            type: "color"
        };
        if (local)
        {
            for (let key in Fields)
            {
                if (key === "inheritAppearance")
                {
                    continue;
                }
                Fields[key].displayIf = ["inheritAppearance", "!=", true];
            }
        }
        return Fields;
    }

    GetBlockAppearance = (defaults) =>
    {
        const Appearance = ArrayClone(defaults);
        const Block = this.GetActiveBlock();
        if (!Block)
        {
            return Appearance;
        }
        for (let key in Block.appearance)
        {
            Appearance[key] = Block.appearance[key];
        }
        return Appearance;
    }

    GetBlockById = (id) =>
    {
        return this.Blocks[id];
    }

    GetBlockFields = () =>
    {
        const Block = this.GetActiveBlock();
        if (!Block?.instance)
        {
            return {};
        }
        return Block.instance.GetContentFields();
    }

    GetBlockValues = () =>
    {
        const Block = this.GetActiveBlock();
        return Block?.content || {};
    }

    GetContent = () =>
    {
        const {id} = this.props;
        const {appearance, blocks, images, publish} = this.state;
        let content = renderToString(
            <ArticleBlocks
                appearance={appearance}
                className="cbs"
                blocks={blocks}
                editable={false}
                getBlockById={this.GetBlockById}
                images={images}
                imports={true}
                publish={id + publish}
            />
        );
        // Strip newlines.
        content = content.replace(/(\r\n|\n|\r) */gm, "");
        // Remove unwanted attributes.
        content = content.replace(/ data\-reactroot\=\"\"| class\=\"ViewArticlesContentBlocks\"/g, "");
        // Decode single quotes.
        content = content.replace(/&quot;|&#x27;/g, "'");
        // Decode amp
        content = content.replace(/&amp;/g, "&");
        // Replace br with custom tag to match WYSIWYG.
        content = content.replace(/<br\/>/g, '<div class="cb-br"/>');
        return content;
    }

    GetState = () =>
    {
        const {id, permission} = this.props;
        const {
            access,
            appearance,
            blocks,
            description,
            image,
            images,
            title,
            type
        } = this.state;
        const EditorState =
        {
            access,
            appearance,
            blocks: [],
            description,
            id,
            image: Array.from(image),
            images,
            permission,
            title,
            type
        };
        blocks.forEach(({appearance, content, id, template}) =>
        {
            EditorState.blocks.push({appearance, content, id, template});
        });
        return EditorState;
    }

    InsertBlock = (index, template, id, content = {}, appearance = {}) =>
    {
        const {blocks} = this.state;
        const Block = {appearance, content, id, template};
        this.Blocks[id] = Block;
        blocks.splice(index, 0, Block);
        this.setState({blocks, selected: blocks.indexOf(Block)}, () => this.OnEdit());
    }

    MoveBlock = (fromIndex, toIndex) =>
    {
        const {blocks} = this.state;
        ArrayMove(blocks, fromIndex, toIndex, true);
        this.setState({blocks}, () => this.OnEdit());
    }

    OnAccessResults = (results, id) =>
    {
        const {access_disable} = this.state;
        const Ids = results.map(item => item.id);
        const Token = this.AccessLookup[id] = RandomToken();
        access_disable[id] = Ids;
        this.setState({access_disable});
        API.Request("articles/lookup", {users: Ids}, response =>
        {
            if (!this.Mounted || this.AccessLookup[id] !== Token)
            {
                return;
            }
            const {access_disable} = this.state;
            const {not_managers} = response;
            access_disable[id] = not_managers || Ids;
            this.setState({access_disable});
        });
    }

    OnBlockAppearanceClear = (e, key, blockId) =>
    {
        const Block = blockId ? this.GetBlockById(blockId) : this.GetActiveBlock();
        if (!Block)
        {
            return;
        }
        delete Block.appearance[key];
        const {blocks} = this.state;
        this.setState({blocks}, () => this.OnEdit());
    }

    OnBlockAppearanceEdit = (e, key, value, blockId) =>
    {
        const Block = blockId ? this.GetBlockById(blockId) : this.GetActiveBlock();
        if (!Block?.appearance)
        {
            
            const {appearance} = this.state;
            appearance[key] = value
            this.setState({appearance}, () => this.OnEdit());
        }
        else
        {
            if (key === "inheritAppearance" && value === true)
            {
                Block.appearance = {};
            }
            Block.appearance[key] = value;
            const {blocks} = this.state;
            this.setState({blocks}, () => this.OnEdit());
        }
    }

    OnBlockContentEdit = (e, key, value, blockId) =>
    {
        const Block = blockId ? this.GetBlockById(blockId) : this.GetActiveBlock();
        if (!Block?.content)
        {
            return;
        }
        if (Array.isArray(key))
        {
            const Path = Array.from(key);
            const Last = Path.pop();
            let Target = Block.content;
            for (let i in Path)
            {
                if (Target[Path[i]] === undefined || typeof Target[Path[i]] !== "object")
                {
                    console.error("Invalid field path.", key);
                    return;
                }
                Target = Target[Path[i]]
            }
            Target[Last] = value;
        }
        else
        {
            Block.content[key] = value;
        }
        const {blocks} = this.state;
        this.setState({blocks}, () => this.OnEdit());
    }

    OnBlockMount = (instance, id) =>
    {
        const Block = this.GetBlockById(id);
        if (!Block)
        {
            return;
        }
        Block.instance = instance;
        const Appearance = this.GetAppearanceFields();
        const Fields = instance.GetContentFields();
        /*for (let key in Appearance)
        {
            if (Block.appearance[key] === undefined && Appearance[key].default !== undefined)
            {
                Block.appearance[key] = Appearance[key].default;
            }
        }*/
        for (let key in Fields)
        {
            if (Block.content[key] === undefined && Fields[key].default !== undefined)
            {
                Block.content[key] = Fields[key].default;
            }
        }
        const {blocks} = this.state;
        this.setState({blocks}, () => this.OnEdit());
    }

    OnEdit = () =>
    {
        // Prevent hammering.
        clearTimeout(this.EditTimeout);
        this.EditTimeout = setTimeout(() =>
        {
            const {id, onEdit} = this.props;
            const EditorState = this.GetState();
            onEdit(EditorState, id);
        }, 100);
    }

    OnEditAccess = (e, value, permission) =>
    {
        const {access} = this.state;
        const Users = value.map(user => user[0]);
        const Remove = [];
        for (let id in access)
        {
            if (access[id] === permission && Users.indexOf(id) < 0)
            {
                Remove.push(id);
            }
        }
        Remove.forEach(id => delete access[id]);
        Users.forEach(id => access[id] = permission);
        this.setState({access}, () => this.OnEdit());
    }

    OnMetaField = (e, value, key) =>
    {
        const State = {};
        State[key] = value;
        this.setState(State, () => this.OnEdit());
    }

    OnPreview = () =>
    {
        const {id} = this.props;
        const {publish} = this.state;
        const Content = this.GetContent();
        this.setState({publish: publish + 1}, () =>
        {
            this.PreviewDialog = Globals.DialogCreate({
                title: "Preview Article",
                width: 1200,
                content: (
                    <ViewArticlesPreview content={Content} onClose={this.OnPreviewClose}/>
                )
            }, `preview-${id}`);
        });
    }

    OnPreviewClose = () =>
    {
        Globals.DialogDestroy(this.PreviewDialog);
    }

    OnPublish = () =>
    {
        const {id} = this.props;
        const {publish} = this.state;
        const Content = this.GetContent();
        this.setState({publish: publish + 1}, () =>
        {
            this.PreviewDialog = Globals.DialogCreate({
                title: "Publish Article",
                width: 1200,
                content: (
                    <ViewArticlesPreview content={Content} onClose={this.OnPreviewClose} publish={true}/>
                )
            }, `preview-${id}`);
        });
    }

    OnSave = () =>
    {
        const {id, onSave} = this.props;
        const EditorState = this.GetState();
        this.setState({error: false, saving: 1});
        API.Request("articles/save", {id, article: JSON.stringify(EditorState)}, response =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {error, saved} = response || {};
            if (!saved || error)
            {
                this.setState({error: true, saving: false});
            }
            else
            {
                this.setState({saving: false}, () => onSave(id));
            }
        });
    }

    RegisterImageUrl = (id, url) =>
    {
        const {images} = this.state;
        images[id] = url;
        this.setState({images}, () => this.OnEdit());
    }

    RemoveBlock = (index) =>
    {
        const {blocks} = this.state;
        const Block = blocks[index];
        if (!Block)
        {
            return;
        }
        delete this.Blocks[Block.id];
        blocks.splice(index, 1);
        this.setState({blocks}, () => this.OnEdit());
    }

    Reset = (type = "article", appearance, blocks, images, title, description, access, image) =>
    {
        this.Blocks = {};
        const Access = {};
        const Appearance = {};
        const AppearanceFields = this.GetAppearanceFields();
        const Blocks = [];
        const Images = {};
        for (let id in access)
        {
            Access[id] = access[id];
        }
        for (let key in AppearanceFields)
        {
            Appearance[key] = appearance[key] === undefined ? AppearanceFields[key].default : appearance[key];
        }
        blocks.forEach(({appearance, content, id, template}) =>
        {
            const Block = this.Blocks[id] = {appearance, content, id, template};
            Blocks.push(Block);
        });
        for (let id in images)
        {
            Images[id] = images[id];
        }
        this.setState({
            access: Access,
            appearance: Appearance,
            blocks: Blocks,
            description,
            image: Array.isArray(image) ? Array.from(image) : [],
            images: Images,
            title,
            type
        });
    }

    SelectBlock = (index) =>
    {
        this.setState({selected: index});
    }

    Status = () =>
    {
        const {changed, hasOriginal, local, updated} = this.props;
        const {error, saving} = this.state;
        if (saving)
        {
            return "Saving...";
        }
        if (error)
        {
            return "Error.";
        }
        const Updated = updated ? ` updated ${TimeSince(updated / 1000, !local)}.` : ".";
        if (local && (!hasOriginal || changed))
        {
            return `Unsaved draft version${Updated}`;
        }
        return `Public version${Updated}`;
    }

    render()
    {
        const {
            changed,
            hasOriginal,
            onBack,
            onClearDraft,
            onClearNew,
            permission,
            publish
        } = this.props;
        const {
            access_disable,
            appearance,
            blocks,
            description,
            image,
            images,
            local,
            saving,
            selected,
            title,
            type
        } = this.state;
        const BlockAppearance = selected < 0 ? appearance : this.GetBlockAppearance(appearance);
        const BlockFields = this.GetBlockFields();
        const BlockValues = this.GetBlockValues();
        const CanEdit = permission > 1;
        const Disabled = !!saving;
        const SelectedBlock = this.GetActiveBlock();
        return (
            <div className="ViewArticlesEditorContainer" key={publish}>
                <div className="ViewArticlesEditorWrapper">
                    <ArticleBlocks
                        appearance={appearance}
                        blocks={blocks}
                        disabled={Disabled}
                        className="ViewArticlesBlocks"
                        editable={CanEdit}
                        editorClassName="ViewArticlesEditor"
                        getBlockById={this.GetBlockById}
                        images={images}
                        onBlockDuplicate={this.DuplicateBlock}
                        onBlockEdit={this.OnBlockContentEdit}
                        onBlockInsert={this.InsertBlock}
                        onBlockMount={this.OnBlockMount}
                        onBlockMove={this.MoveBlock}
                        onBlockRemove={this.RemoveBlock}
                        onBlockSelect={this.SelectBlock}
                        onImageUrl={this.RegisterImageUrl}
                        selected={selected}
                    />
                    <div className="ViewArticlesSidebar">
                        <ScrollView className="ViewArticlesSidebarScroll">
                            <div className="ViewArticlesSidebarContent">
                                {CanEdit ? "" : (
                                    <div className="ViewArticlesEditorNotice">You don't have permission to edit this article.</div>
                                )}
                                <Group title="Article" name="article">
                                    <TextField
                                        disabled={Disabled || !CanEdit}
                                        id="title"
                                        label="Title"
                                        onInput={this.OnMetaField}
                                        value={title}
                                    />
                                    <SelectField
                                        disabled={Disabled || !CanEdit}
                                        id="type"
                                        label="Type"
                                        options={{article: "Article", template: "Template"}}
                                        onChange={this.OnMetaField}
                                        value={type}
                                    />
                                    {(CanEdit && type === "template") ? (
                                        <div className="ViewArticlesEditorNotice">Once an article is saved as a template it will no longer appear among your previous articles.</div>
                                    ) : ""}
                                    {0 ? <TextareaField
                                        disabled={Disabled || !CanEdit}
                                        id="description"
                                        label="Description"
                                        onInput={this.OnMetaField}
                                        value={description}
                                    /> : ""}
                                    {0 ? <ImageField
                                        disabled={Disabled || !CanEdit}
                                        id="image"
                                        label="Thumbnail"
                                        onChange={this.OnMetaField}
                                        value={image}
                                    /> : ""}
                                </Group>
                                <Group title="Content" name="content">
                                    {selected < 0 ? (
                                        <div className="ViewArticlesUnselected">
                                            No block is selected
                                        </div>
                                    ) : (
                                        !BlockFields || Object.keys(BlockFields).length ? (
                                            <Form
                                                content={BlockValues}
                                                disabled={Disabled || !CanEdit}
                                                fields={BlockFields}
                                                id={SelectedBlock?.id}
                                                onEdit={this.OnBlockContentEdit}
                                                palette={Palette}
                                            />
                                        ) : (
                                            <div className="ViewArticlesUnselected">
                                                This block has no editable attributes.
                                            </div>
                                        )
                                    )}
                                </Group>
                                <Group title={selected >= 0 ? "Block Appearance" : "General Appearance"} name="appearance">
                                    <Form
                                        clear={selected >= 0}
                                        content={BlockAppearance}
                                        disabled={Disabled || !CanEdit}
                                        fields={this.GetAppearanceFields(selected >= 0)}
                                        id={SelectedBlock?.id}
                                        onClear={this.OnBlockAppearanceClear}
                                        onEdit={this.OnBlockAppearanceEdit}
                                        palette={Palette}
                                    />
                                </Group>
                                <Group title="Share" name="share">
                                    {type !== "template" ? (
                                        <ContentField
                                            disabled={Disabled || !CanEdit || access_disable[1]}
                                            disabledTitle="Only managers can access the article editor."
                                            id={1}
                                            flip={true}
                                            label="Only view"
                                            multiple={true}
                                            onChange={this.OnEditAccess}
                                            onResults={this.OnAccessResults}
                                            placeholder="Search for users..."
                                            types={["user"]}
                                            value={this.GetAccess(1)}
                                        />
                                    ) : (
                                        <>
                                            <label className="ViewArticlesLabel">Only view</label>
                                            <div className="ViewArticlesAccessNotice">Templates can be viewed by anyone.</div>
                                        </>
                                    )}
                                    <ContentField
                                        disabled={Disabled || !CanEdit || access_disable[2]}
                                        disabledTitle="Only managers can access the article editor."
                                        id={2}
                                        flip={true}
                                        label="View and edit"
                                        multiple={true}
                                        onChange={this.OnEditAccess}
                                        onResults={this.OnAccessResults}
                                        placeholder="Search for users..."
                                        types={["user"]}
                                        value={this.GetAccess(2)}
                                    />
                                </Group>
                            </div>
                        </ScrollView>
                    </div>
                </div>
                <div className="ViewArticlesTray">
                    <div className="ViewArticlesTrayButtons">
                        <Button
                            disabled={Disabled || !CanEdit || !changed || !blocks.length}
                            label="Save"
                            loading={saving === 1}
                            onClick={this.OnSave}
                            title="Save to server"
                        />
                        {type === "article" ? <Button
                            disabled={!blocks.length}
                            label="Publish"
                            onClick={this.OnPublish}
                            title="Show publish options"
                        /> : ""}
                        {type === "template" ? <Button
                            hollow={true}
                            label="Preview"
                            onClick={this.OnPreview}
                        /> : ""}
                        <Button
                            hollow={true}
                            label="Back"
                            onClick={onBack}
                        />
                        <div className="ViewArticlesTrayStatus">
                            {this.Status()}
                        </div>
                    </div>
                    <div className="ViewArticlesTrayItems">
                        {hasOriginal ? (
                            <IconItem
                                disabled={!CanEdit || !local || Disabled || !hasOriginal || !changed}
                                feather="Trash"
                                label="Clear draft"
                                onClick={onClearDraft}
                            />
                        ) : (
                            <IconItem
                                disabled={!CanEdit || !local || Disabled}
                                feather="Trash"
                                label="Clear draft"
                                onClick={onClearNew}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

ViewArticlesEditor.defaultProps =
{
    appearance: {},
    access: {},
    blocks: [],
    description: "",
    hasOriginal: false,
    id: "",
    image: [],
    images: {},
    onBack: () => {},
    onClearDraft: () => {},
    onClearNew: () => {},
    onEdit: () => {},
    onSave: () => {},
    permission: 1,
    title: ""
};

export default ViewArticlesEditor;