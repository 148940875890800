
/**!
 *  Widget tabs field.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

 import React from "react";
 import PropTypes from "prop-types";
 import "./widgettabsfield.scss";
 
 import Globals from "Class/Globals";
 import RepeaterField from "Components/UI/Field/RepeaterField";
 
 class WidgetTabsField extends React.Component
 {
     constructor(props)
     {
         super(props);
         this.Fields =
         {
             label:
             {
                 label: "Label"
             }
         };
     }
 
     OnEditWidget = (e, index) =>
     {
         const {value} = this.props;
         const Widget = value[index];
         const {id} = Widget || {};
         if (!id)
         {
             return;
         }
         Globals.ViewActive(id);
     }
 
     render()
     {
         return (
             <RepeaterField
                 {...this.props}
                 additionalButtons={[["Edit", this.OnEditWidget, "Edit Widget"]]}
                 addLabel="Add Widget"
                 fields={this.Fields}
             />
         );
     }
 }
 
WidgetTabsField.propTypes =
 {
     className: PropTypes.string,
     disabled: PropTypes.bool,
     error: PropTypes.bool,
     id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
     label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
     onChange: PropTypes.func,
     value: PropTypes.array
 };
 
 WidgetTabsField.defaultProps =
 {
     className: "",
     disabled: false,
     error: false,
     id: "",
     label: "",
     onChange: () => {},
     value: []
 };
 
 export default WidgetTabsField;