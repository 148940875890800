
/*!
 *  Textarea form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value. 
 *  @prop string id - Field ID.
 *  @prop boolean insert - Whether to display injectable vars.
 *  @prop string label - Field label.
 *  @prop integer maxRows - Optional maximum number of visible rows.
 *  @prop function onAdjust - Callback for when the field height changes.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop boolean readOnly - Whether the field should be read-only.
 *  @prop string value - Field value.
 *  @prop integer visibleRows - The minimum amount of rows.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./textareafield.scss";
import {Ln2Br, RandomToken} from "Functions";
import Insert from "Components/UI/Insert";

class TextareaField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.DefaultLineHeight = 20;
        this.FocusValue = false;
        this.Input = false;
        this.Token = RandomToken();
        this.state =
        {
            focus: false,
            value: ""
        };
    }

    /**
     * Set initial value and add listeners on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        this.setState({value});
        setTimeout(this.Adjust, 0);
        window.addEventListener("resize", this.Adjust);
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = this.state;
        const {value: v3} = prevProps;
        if (v1 !== v2 && v1 !== v3)
        {   
            this.setState({value: v1});
            setTimeout(this.Adjust, 0);
        }
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.Adjust);
    }

    /**
     * Adjust the height of the field to fit its entire contents.
     * @return void
     */

    Adjust = () =>
    {
        const {id, maxRows, onAdjust, visibleRows} = this.props;
        if (!this.Input)
        {
            return;
        }
        const Styles = window.getComputedStyle(this.Input);
        let LineHeight = parseInt(Styles.lineHeight, 10);
        if (isNaN(LineHeight))
        {
            LineHeight = this.DefaultLineHeight;
        }
        this.Input.style.height = LineHeight + "px";
        const Height = this.Input.offsetHeight;
        const Delta = Height - LineHeight;
        const NewHeight = this.Input.scrollHeight - Delta;
        const MinHeight = Math.max(NewHeight, LineHeight * visibleRows);
        const MaxHeight = maxRows ? Math.min(MinHeight, LineHeight * maxRows) : MinHeight;
        this.Input.style.height = MaxHeight + "px";
        onAdjust(id);
    }

    /**
     * Set focus on this field.
     * @return void
     */

    Focus = () =>
    {
        if (this.Input)
        {
            this.Input.focus();
        }
    }

    /**
     * Callback for when the field loses focus.
     * @param object e - The event object.
     * @return void
     */

    OnBlur = (e) =>
    {
        const {id, onBlur, onChange} = this.props;
        const Value = e.currentTarget.value;
        if (this.FocusValue !== Value)
        {
            onChange(e, Value, id);
        }
        onBlur(e, id, this.Input);
        this.setState({focus: false});
    }

    /**
     * Callback for when the fields value changes.
     * @param object e - The event object.
     * @return void
     */

    OnChange = (e) =>
    {
        const {id, onChange} = this.props;
        const Value = e.currentTarget.value;
        onChange(e, Value, id);
        this.setState({value: Value});
    }

    /**
     * Callback for when the field gains focus.
     * @param object e - The event object.
     * @return void
     */

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        this.FocusValue = e.currentTarget.value;
        onFocus(e, id, this.Input);
        this.setState({focus: true});
    }

    /**
     * Callback for when the users inputs a new value into the field.
     * @param object e - The event object.
     * @return void
     */

    OnInput = (e) =>
    {
        const {disabled, id, onInput} = this.props;
        if (!this.Input || disabled)
        {
            return;
        }
        this.Adjust();
        const Value = this.Input.value;
        onInput(e, Value, id);
        this.setState({value: Value});
    }

    /**
     * Insert a variable key into the field.
     * @param object e - The event object.
     * @param string key - The variable key.
     * @return void
     */

    OnInsert = (e, key) =>
    {

        const {id, onChange} = this.props;
        if (!this.Input)
        {
            return;
        }
        const Notation = `@{${key}}`;
        const Value = this.Input.value;
        // IE
        if (document.selection)
        {
            this.Input.focus();
            const Selection = document.selection.createRange();
            Selection.text = Notation;
        }
        // Others
        else if (this.Input.selectionStart || this.Input.selectionStart === "0")
        {
            const S = this.Input.selectionStart;
            const E = this.Input.selectionEnd;
            this.Input.value = Value.substring(0, S) + Notation + Value.substring(E, Value.length);
            this.Input.selectionStart = this.Input.selectionEnd = S + Notation.length;
            this.Input.focus();
        }
        const Set = this.Input.value;
        onChange(e, Set, id);
        this.setState({value: Set});
    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * @param object e - The event object.
     * @return void
     */

    OnKeyDown = (e) =>
    {
        e.stopPropagation();
    }

    /**
     * Reset to inital state.
     * @return void
     */

    Reset = () =>
    {
        const {id, onChange, value} = this.props;
        this.setState({value});
        onChange(null, value, id);
    }

    /**
     * Get the field value.
     * @return string - The field value.
     */

    Value = () =>
    {
        return this.state.value;
    }

    render()
    {
        const {
            className,
            disabled,
            error,
            insert,
            label,
            placeholder,
            readOnly
        } = this.props;
        const {focus, value} = this.state;
        const CA = ["Field", "TextareaField"];
        if (className) CA.push(className);
        if (disabled) CA.push("Disabled");
        if (error) CA.push("Error");
        if (focus) CA.push("Focus");
        if (insert) CA.push("HasInsert");
        if (readOnly) CA.push("ReadOnly");
        if (value) CA.push("HasValue");

        return (
            <div className={CA.join(" ")}>
                {label ? <label htmlFor={this.Token}>{label}</label> : ""}
                <div className="InputWrapper">
                    {readOnly ? <div className="Input">
                        {Ln2Br(value)}
                    </div> : <textarea
                        className="Input"
                        disabled={disabled}
                        id={this.Token}
                        onBlur={this.OnBlur}
                        onChange={() => {}}
                        onFocus={this.OnFocus}
                        onInput={this.OnInput}
                        onKeyDown={this.OnKeyDown}
                        placeholder={placeholder}
                        ref={input => this.Input = input}
                        value={value}
                    />}
                    {insert ? <Insert
                        className="TextareaFieldInsert"
                        onInsert={this.OnInsert}
                    /> : ""}
                </div>
            </div>
       );
    }
}

TextareaField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    insert: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxRows: PropTypes.number,
    onAdjust: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    visibleRows: PropTypes.number
};

TextareaField.defaultProps =
{
    className: "",
    disabled: false,
    error: false,
    id: "",
    insert: false,
    label: "",
    maxRows: 0,
    onAdjust: () => {},
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    readOnly: false,
    value: "",
    visibleRows: 2
};

export default TextareaField;