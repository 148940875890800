/*!
 *  Fuse content item
 *  @prop string className - Append a class name.
 *  @prop object content - Content object.
 *  @prop number height - Item height.
 *  @prop number width - Item width.
 *  @prop string|object text - Item text visible on hover.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./contentitem.scss";
import API from "Class/API";
import Fuse from "Class/Fuse";
import {ObjectCompare} from "Functions";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";

class ContentItem extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Mounted = false;
        this.state =
        {
            content: false,
            contentObject: false,
            error: false,
            loading: false
        };
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {content, contentObject} = this.props;
        const {content: fuseContent} = content;
        if (contentObject)
        {
            this.setState({
                content: contentObject.id,
                contentObject
            });
        }
        else
        {
            this.Load(fuseContent);
        }
    }

    /**
     * Update content when a new ID is received.
     * @return void
     */

    componentDidUpdate()
    {
        const {content: c1, contentObject: o1} = this.props;
        const {content: c2, contentObject: o2} = this.state;
        const {content} = c1;
        const NewObject = !ObjectCompare(o1, o2);
        let Content = content;
        while (Content && typeof Content === "object")
        {
            Content = Content[0];
        }
        if (o1 && NewObject)
        {
            this.setState({
                content: o1.id,
                contentObject: o1
            });
        }
        else if (!o1 && !ObjectCompare(Content, c2))
        {
            this.Load(content);
        }
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Load content from the Fuse API.
     * @param array|integer item - [ contentId, type ] or just contentId.
     * @return void
     */

    Load = (item) =>
    {
        if (!item)
        {
            this.setState({
                content: 0,
                error: false,
                loading: false
            });
        }
        else if (typeof item === "object" && typeof item[0] === "object")
        {
            this.Load(item[0]);
        }
        else if (typeof item === "object")
        {
            this.setState({
                content: item[0],
                error: false,
                loading: true
            });
            this.LoadContent(item[0], item[1]);
        }
        else
        {
            this.setState({
                content: item,
                error: false,
                loading: true
            });
            /**
             * Check content type in the backend DB before fetching.
             */
            API.Request("content/cache-read", {id: item}, response =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                const {cache, error} = response;
                if (error)
                {
                    this.setState({
                        error: true,
                        loading: false
                    });
                }
                else
                {
                    this.LoadContent(item, cache.type);
                }
            });
        }
    }

    /**
     * Load content data from fuse.
     * 
     * @param integer id - The content id.
     * @param string type - The content type.
     * 
     * @return void
     */

    LoadContent = (id, type) =>
    {
        Fuse.Content(id, type, content =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {onLoad} = this.props;
            if (!content)
            {
                onLoad(false, id);
                this.setState({
                    error: true,
                    loading: false
                });
            }
            else
            {
                onLoad(content, id);
                this.setState({
                    loading: false,
                    contentObject: content
                });
            }
        });
    }

    /**
     * Scroll to the top of the text when the item is hovered.
     * @return void
     */

    ResetScroll = () =>
    {
        if (!this.RefText)
        {
            return;
        }
        this.RefText.scrollTop = 0;
    }

    render()
    {
        const {
            bgColor,
            borderRadius,
            children,
            className,
            content,
            fgColor,
            width,
            height,
            imageKey,
            labelBelow,
            overlay,
            shadow,
            showDescription,
            sizeMultiplier,
            tag,
            target,
            text
        } = this.props;
        const {
            content: contentId,
            contentObject,
            error,
            loading
        } = this.state;
        const {
            description,
            externalLink,
            grayscale,
            image,
            label,
            url,
            urlBlank,
            urlBlank2
        } = content;
        const CA = ["ContentItem"];
        if (error && (!externalLink || !url))
        {
            CA.push("ErrorItem");
        }
        if (grayscale)
        {
            CA.push("Grayscale");
        }
        if (labelBelow)
        {
            CA.push("LabelBelow");
        }
        else
        {
            CA.push("LabelOnTop");
        }
        if (shadow)
        {
            CA.push("HasShadow");
        }
        if (text)
        {
            CA.push("HasText");
        }
        if (showDescription)
        {
            CA.push("ShowDescription");
        }
        if (className)
        {
            CA.push(className);
        }
        const Content = [];
        const Children = [];
        let Background, Label, Url;
        let Target = target || (urlBlank2 ? "_blank" : "_top");
        if (loading)
        {
            Background = image ? parseInt(image[0], 10) : 0;
            Label = label;
            Content.push(<Spinner
                className="ContentItemSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (contentObject)
        {
            const {id, name, preview, type, url: altUrl} = contentObject;
            Background = (typeof image === "object" && image.length) ? parseInt(image[0], 10) : preview;
            Label = label || name;
            Url = altUrl || Fuse.ContentUrl(id, type);
        }
        else if (!contentId)
        {
            Background = image ? parseInt(image[0], 10) : 0;
            Label = label;
            Target = target || ((externalLink && urlBlank) ? "_blank" : "_top");
            Url = url;
        }
        if (!loading && text)
        {
            const {description: contentDescription} = contentObject;
            Content.push(
                <div
                    className="ContentItemText"
                    key="text"
                    ref={text => this.RefText = text}
                >
                    <div className="ContentItemTextWrapper">{text === true ? contentDescription : text}</div>
                </div>
            );
        }
        Children.push(<div
            className="ContentItemLabel Heading"
            key="label"
        >{Label}</div>);
        let BackgroundImage = "";
        if (Background && typeof Background === "string")
        {
            BackgroundImage = <LoadImage
                className={overlay ? "ContentItemBackground" : "ContentItemBackground NoOverlay"}
                src={Background}
            />;
        }
        else if (Background && typeof Background === "number")
        {
            BackgroundImage = <LoadImage
                className={overlay ? "ContentItemBackground" : "ContentItemBackground NoOverlay"}
                imageKey={imageKey}
                srcId={Background}
            />;
        }
        if (children)
        {
            Children.push(
                <div
                    className="ContentItemChildrenContent"
                    key="content"
                >{children}</div>
            );
        }
        Content.push(
            <div
                className="ContentItemChildren"
                key="children"
            >{Children}</div>
        );
        if (tag)
        {
            Content.push(
                <div
                    className="ContentItemTag ItemBackground"
                    key="tag"
                >{tag}</div>
            );
        }
        return (
            <Link

                className={CA.join(" ")}
                href={Url}
                onMouseEnter={this.ResetScroll}
                style={{
                    backgroundColor: bgColor,
                    borderRadius: borderRadius + "px",
                    color: fgColor,
                    width,
                    height: height * sizeMultiplier
                }}
                target={Target}
            >
                {BackgroundImage}
                {Content}
                {(showDescription && description) ? (
                    <div className="ContentItemDescriptionWrapper">
                        <div className="ContentItemDescription">{description}</div>
                    </div>
                ) : ""}
            </Link>
        );
    }
}

ContentItem.propTypes =
{
    bgColor: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.object,
    contentObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    fgColor: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    imageKey: PropTypes.string,
    onLoad: PropTypes.func,
    showDescription: PropTypes.bool,
    sizeMultiplier: PropTypes.number,
    target: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ContentItem.defaultProps =
{
    bgColor: "#000000",
    borderRadius: 0,
    className: "",
    content: {},
    contentObject: false,
    fgColor: "#ffffff",
    height: 200,
    width: 314,
    imageKey: "",
    labelBelow: false,
    onLoad: () => {},
    overlay: true,
    showDescription: false,
    shadow: true,
    sizeMultiplier: 1,
    tag: "",
    target: "",
    text: ""
};

export default ContentItem;