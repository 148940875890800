/**!
 *  Functional button widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./button.scss";
import API from "Class/API";
import Globals from "Class/Globals";
import Parser from "Class/Parser";
import Button from "Components/UI/Button";

class WidgetButton extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            text:
            {
                default: "",
                insert: true,
                label: "Text",
                type: "textarea"
            },
            label:
            {
                default: "",
                insert: true,
                label: "Label",
                type: "text"
            },
            action:
            {
                default: "link",
                type: "select",
                label: "Action",
                options:
                {
                    link: "Link",
                    email: "E-mail",
                    calendar: "Calendar invitation",
                    bookmark: "Bookmark"
                }
            },
            calendarTitle:
            {
                displayIf: ["action", "==", "calendar"],
                label: "Title",
                placeholder: "Event title",
                type: "text"
            },
            calendarDescription:
            {
                displayIf: ["action", "==", "calendar"],
                label: "Description",
                placeholder: "Event description",
                type: "textarea"
            },
            calendarLocation:
            {
                displayIf: ["action", "==", "calendar"],
                label: "Location",
                placeholder: "Event location",
                type: "text"
            },
            calendarUrl:
            {
                displayIf: ["action", "==", "calendar"],
                label: "URL",
                placeholder: "https://",
                type: "text"
            },
            calendarAllDay:
            {
                default: false,
                displayIf: ["action", "==", "calendar"],
                label: "All-day",
                type: "checkbox"
            },
            calendarStartDateTime:
            {
                displayIf: ["and", ["action", "==", "calendar"], ["calendarAllDay", "!=", true]],
                label: "Starts",
                type: "datetime"
            },
            calendarStartDate:
            {
                displayIf: ["and", ["action", "==", "calendar"], ["calendarAllDay", "==", true]],
                label: "Starts",
                type: "date"
            },
            calendarEndDateTime:
            {
                displayIf: ["and", ["action", "==", "calendar"], ["calendarAllDay", "!=", true]],
                label: "Ends",
                type: "datetime"
            },
            calendarEndDate:
            {
                displayIf: ["and", ["action", "==", "calendar"], ["calendarAllDay", "==", true]],
                label: "Ends",
                type: "date"
            },
            linkHref:
            {
                default: "",
                displayIf: ["action", "==", "link"],
                label: "URL",
                placeholder: "https://",
                type: "text"
            },
            linkBlank:
            {
                default: true,
                displayIf: ["action", "==", "link"],
                label: "Open in a new tab",
                type: "checkbox"
            },
            emailAddress:
            {
                default: "",
                displayIf: ["action", "==", "email"],
                label: "E-mail address",
                placeholder: "someone@example.com",
                type: "text"
            },
            emailSubject:
            {
                default: "",
                displayIf: ["action", "==", "email"],
                label: "Pre-filled e-mail subject line",
                type: "text"
            },
            bookmarkMessage:
            {
                default: "@{instruction} to bookmark this page so you don't forget the link. You're our favourite. We hope we can be yours too.",
                displayIf: ["action", "==", "bookmark"],
                label: "Bookmark message",
                type: "textarea"
            },
            alignH:
            {
                label: "Horisontal Position",
                type: "select",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                default: "center"
            },
            alignV:
            {
                label: "Vertical Position",
                type: "select",
                options: {
                    top: "Top",
                    center: "Center",
                    bottom: "Bottom"
                },
                default: "center"
            }
        });
        this.Name = "Button";
        this.state = {loading: false};
    }

    componentDidMount()
    {
        Globals.Listen("broadcast-dialog-close", this.OnClose);
    }

    componentWillUnmount()
    {
        Globals.Remove("broadcast-dialog-close", this.OnClose);
    }

    OnBookmark = () =>
    {
        let Instruction;
        switch (window.navigator.platform)
        {
            case "Android":
            case "iPad":
            case "iPhone":
            case "iPod":
                Instruction = "Use the browser toolbar on the bottom of the screen";
                break;
            case "Mac68K":
            case "MacPPC":
            case "MacIntel":
            case "Macintosh":
                Instruction = "Press <b>CMD + D</b>";
                break;
            default:
                Instruction = "Press <b>CTRL + D</b>";
        }
        const {bookmarkMessage} = this.Content();
        const Message = bookmarkMessage.replace("@{instruction}", Instruction);
        this.setState({loading: true}, () => Globals.OpenMessageDialog("Add Bookmark", Message));
    }

    OnClose = ({id}) =>
    {
        if (id !== "alert")
        {
            return;
        }
        this.setState({loading: false});
    }

    render()
    {
        const {contentId} = this.props;
        const {loading} = this.state;
        const {
            action,
            emailAddress,
            emailSubject,
            label,
            linkBlank,
            linkHref,
            text
        } = this.Content();
        const CA = ["WidgetButton", "BorderRadius"];
        const CS = this.ClassNames(CA, true);
        const ButtonProps = {
            big: true,
            label
        };
        switch (action)
        {
            case "bookmark":
                ButtonProps.onClick = this.OnBookmark;
                break;
            case "calendar":
                ButtonProps.href = API.Url(`widget/ics/${contentId}/event.ics`);
                break;
            case "email":
                ButtonProps.href = `mailto:${emailAddress}`;
                if (emailSubject)
                {
                    ButtonProps.href += "?subject=" + encodeURIComponent(emailSubject);
                }
                break;
            case "link":
                ButtonProps.href = (linkHref.substr(0, 4).toLowerCase() !== "http" ? "http://" : "") + linkHref;
                ButtonProps.target = linkBlank ? "_blank" : "_top";
                break;
            default:
        }
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <div className="WidgetButtonContent WidgetDynamicAlign">
                    <div className="WidgetButtonTextContainer">
                        <div className="WidgetButtonText Heading">
                            {text ? Parser.ParseParagraphs(text) : ""}
                        </div>
                    </div>
                    {label ? <Button loading={loading} {...ButtonProps}/> : ""}
                </div>
            </div>
       );
    }
}

export default WidgetButton;