import React from "react";
import ArticleBackground from "../../background.js";
import ArticleBlock from "../../block.js";
import Icon from "Components/Layout/Icon";
import VideoPlayer from "Components/UI/VideoPlayer";
import YoutubePlayer from "Components/UI/YoutubePlayer";

class ArticleBlockVideo extends ArticleBlock
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            title:
            {
                default: "Title",
                label: "Title",
                type: "textarea"   
            },
            platform:
            {
                default: "fuse",
                label: "Platform",
                options:
                {
                    fuse: "Fuse",
                    youtube: "Youtube"
                },
                type: "select"
            },
            src:
            {
                displayIf: ["platform", "===", "youtube"],
                default: "",
                label: "Youtube URL",
                placeholder: "https://www.youtube.com/watch?v=..."
            },
            srcFuse:
            {
                displayIf: ["platform", "===", "fuse"],
                label: "Video content",
                placeholder: "Search for videos...",
                type: "content",
                types: ["video"]
            },
            /*posterImage:
            {
                displayIf: ["platform", "===", "fuse"],
                label: "Preview image",
                type: "image"
            },*/
            alignTitle:
            {
                default: "center",
                label: "Title alignment",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                type: "select"
            },
            backgroundImage:
            {
                label: "Background image",
                type: "image"
            },
            backgroundOverlay:
            {
                default: false,
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background overlay",
                type: "checkbox"
            }
        };
    }

    OnFuseSrc = (src, srcId) =>
    {
        const {onImageUrl} = this.props;
        const Id = "v" + (Array.isArray(srcId) ? srcId[0] : srcId);
        onImageUrl(Id, src);
    }

    OnPoster = (src, id) =>
    {
        const {onImageUrl} = this.props;
        const Id = "p" + id;
        onImageUrl(Id, src);
    }

    render()
    {
        const {content, editable, id, images, onImageUrl} = this.props;
        const {
            alignTitle = "center",
            backgroundImage,
            backgroundOverlay,
            platform = "fuse",
            src,
            srcFuse,
            title
        } = content || {};
        const CA = ["cb", `cb-${id}`, "vd"];
        const Src = platform === "fuse" ? (srcFuse ? srcFuse[0] : 0) : src;
        if (editable)
        {
            CA.push("_ce");
        }
        switch (alignTitle)
        {
            case "left":
                CA.push("_al");
                break;
            case "right":
                CA.push("_ar");
                break;
            default:
                CA.push("_ac");
        }
        let Content = "";
        let Resolved = "";
        let Id = "";
        switch (platform)
        {
            case "youtube":
                Content = editable && Src ? <YoutubePlayer className="vd-pl-e" url={Src}/> : "";
                break;
            default:
                Id = Array.isArray(Src) ? Src[0] : Src;
                const Id1 = "v" + Id;
                const Id2 = "p" + id;
                Resolved = images[Id1];
                Content = editable && Src ? (
                    <VideoPlayer
                        className="vd-pl-e"
                        contentId={Src}
                        id={id}
                        interactions={false}
                        onLoad={this.OnFuseSrc}
                        onPoster={this.OnPoster}
                    />
                ) : (
                    <ArticleBackground
                        className="vd-pl-p"
                        editable={false}
                        imageId={images[Id2] === undefined ? 0 : Id2}
                        srcref={images}
                    >
                        <div className="vd-pl-pc">
                            <Icon className="vd-pl-pp" feather="Play"/>
                        </div>
                    </ArticleBackground>
                );
        }
        return (
            <>
                {this.Appearance()}
                <ArticleBackground
                    className={CA.join(" ")}
                    editable={editable}
                    imageId={backgroundImage}
                    onUrl={onImageUrl}
                    overlay={backgroundOverlay}
                    srcref={images}
                >
                    {title ? (
                        <div className="vd-tp">
                            {this.EditableText(title, "h2", "title", "vd-t")}
                        </div>
                    ) : ""}
                    <div className="vd-pl-w">
                        <div className="vd-pl-ct">
                            <div data-id={Id} data-platform={platform} data-src={Resolved || Src} className="vd-pl">
                                {Content}
                            </div>
                        </div>
                    </div>
                </ArticleBackground>
            </>
        );
    }
}

export default ArticleBlockVideo;