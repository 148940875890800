/*!
 *  Button.
 *
 *  @prop boolean big - Whether the button should be big.
 *  @prop string className - Append a class name.
 *  @prop string color - Button color.
 *  @prop boolean done - Whether the button should have the check icon. Same behaviour as 'disabled'.
 *  @prop boolean disabled - Whether the button should be disabled.
 *  @prop boolean hollow - Whether the button should be hollow.
 *  @prop string href - Optional link href.
 *  @prop string id - Button ID.
 *  @prop string label - Button label.
 *  @prop boolean loading - Whether the label should be replaced with a spinner.
 *  @prop function onBlur - Callback for when the button loses focus.
 *  @prop function onClick - Callback for when the button is clicked.
 *  @prop function onFocus - Callback for when the button gains focus.
 *  @prop string target - Optional. Link target window when a href has been specified.
 *  @prop string title - The button title tag.
 *  @prop string to - URI path when used for internal navigation.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./button.scss";
import { TweenLite } from "gsap";
import { Power1 } from "gsap/all";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import Spinner from "Components/Feedback/Spinner";

class Button extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Button = false;
        this.Content = false;
        this.Mounted = false;
        this.Width = 0;
        this.state =
        {
            down: false  
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
        const {big, done, loading} = this.props;
        if (big || !this.Button || !done || !loading)
        {
            return;
        }
        this.Button.style.width = (big ? 18 : 0) + "px"
    }

    componentDidUpdate(prevProps)
    {
        const {big: b1, done: d1, loading: l1} = this.props;
        const {big: b2, done: d2, loading: l2} = prevProps;
        if ((b1 !== b2 && d1 !== d2 && l1 !== l2) || (b1 || !this.Button || !this.Content))
        {
            return;
        }
        const Shrink = d1 || l1;
        const Shrunk = d2 || l2;
        const Narrow = b1 ? 18 : 0;
        const Wide = this.Width || (this.Width = this.Content.offsetWidth);
        if (Shrink !== Shrunk)
        {
            const From = Shrunk ? Narrow : Wide;
            const To = Shrink ? Narrow : Wide;
            TweenLite.fromTo(this.Button, .25, {
                width: From
            },{
                ease: Power1.easeInOut,
                onComplete: () =>
                {
                    if (Shrink || !this.Button)
                    {
                        return;
                    }
                    this.Width = 0;
                    this.Button.style.width = "auto";
                },
                width: To
            });
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Callback for when the field loses focus.
     * @param object e - The event object.
     * @return void
     */

    OnBlur = (e) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        const {id, onBlur} = this.props;
        onBlur(e, id);
        this.setState({focus: false});
        window.removeEventListener("keydown", this.OnKeyDown);
        window.removeEventListener("keyup", this.OnKeyUp);
    }

    /**
     * Callback for when the button is clicked.
     * @param object e - The click event.
     * @return void 
     */

    OnClick = (e) =>
    {
        const {disabled, done, id, loading, onClick} = this.props;
        if (disabled || done || loading)
        {
            return;
        }
        onClick(e, id);
    }

    /**
     * Callback for when the field gains focus.
     * @param object e - The event object.
     * @return void
     */

    OnFocus = (e) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        const {id, onFocus} = this.props;
        onFocus(e, id);
        this.setState({focus: true});
        // Listen for enter press while focused.
        window.addEventListener("keydown", this.OnKeyDown);
        window.addEventListener("keyup", this.OnKeyUp);
    }

    /**
     * Callback for when a key is pressed while the button has focus.
     * @param object e - The event object.
     * @return void
     */

    OnKeyDown = (e) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {disabled} = this.props;
        if (disabled || e.which !== 13)
        {
            return;
        }
        this.setState({down: true});
    }

    /**
     * Callback for when a key is released while the button has focus.
     * @param object e - The event object.
     * @return void
     */

    OnKeyUp = (e) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {disabled, id, onClick} = this.props;
        this.setState({down: false});
        if (disabled || e.which !== 13)
        {
            return;
        }
        onClick(e, id);
    }

    render()
    {
        const {
            big,
            children,
            className,
            color,
            disabled,
            done,
            hollow,
            href,
            label,
            loading,
            target,
            title,
            to
        } = this.props;
        const {down} = this.state;
        const CA = ["ButtonContainer"];
        if (big)
        {
            CA.push("Big");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (done)
        {
            CA.push("Done");
        }
        if (down)
        {
            CA.push("Down");
        }
        if (hollow)
        {
            CA.push("Hollow");
        }
        else
        {
            switch (color)
            {
                case "white":
                    CA.push("White");
                    break;
                default:
                    CA.push("Red");
            }
        }
        if (loading)
        {
            CA.push("Loading");
        }
        if (className) 
        {
            CA.push(className)
        }
        const Ic = done ? <Icon
            className="ButtonIcon"
            size={24}
            feather="Check"
        /> : "";
        const Sp = loading ? <Spinner
            className="ButtonSpinner"
            size={32}
        /> : "";
        if (href || to)
        {
            return (
                <span className={CA.join(" ")}>
                    <Link
                        className="Button"
                        href={href}
                        onClick={this.OnClick}
                        target={target}
                        title={title}
                        to={to}
                    >
                        {Ic}
                        <div className="ButtonContent" ref={content => this.Content = content}>{label || children}</div>
                        {Sp}
                    </Link>
                </span>
            );
        }
        else
        {
            return (
                <span className={CA.join(" ")}>
                    <div
                        className="Button"
                        onClick={this.OnClick}
                        onBlur={this.OnBlur}
                        onFocus={this.OnFocus}
                        ref={button => this.Button = button}
                        tabIndex="0"
                        title={title}
                    >
                        {Ic}
                        <div className="ButtonContent" ref={content => this.Content = content}>{label || children}</div>
                        {Sp}
                    </div>
                </span>
            );
        }
    }
}

Button.propTypes =
{
    big: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    done: PropTypes.bool,
    hollow: PropTypes.bool,
    href: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    loading: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    target: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string
};

Button.defaultProps =
{
    big: false,
    className: "",
    color: "red",
    done: false,
    disabled: false,
    hollow: false,
    href: "",
    id: "",
    label: "",
    loading: false,
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    target: "_blank",
    title: "",
    to: ""
};

export default Button;