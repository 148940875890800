/**!
 *  WYSIWYG widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import RenderContent from "Components/Layout/RenderContent";
import "./wysiwyg.scss";

class WidgetWysiwyg extends Widget
{
    constructor(props)
    {
        super(props);
        this.Height = -1;
        this.Fields = this.SetFields({
            content:
            {
                label: "Content",
                type: "wysiwyg"
            },
            alignV:
            {
                label: "Vertical Position",
                type: "select",
                options: {
                    top: "Top",
                    center: "Center",
                    bottom: "Bottom"
                },
                default: "center"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Name = "Rich-text";
    }

    componentDidMount()
    {
        window.addEventListener("resize", this.AdjustHeight);
        this.AdjustHeight();
    }

    componentDidUpdate()
    {
        this.AdjustHeight();
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.AdjustHeight);
    }

    AdjustHeight = (e) =>
    {
        const height = Math.max(this.RefContent ? this.RefContent.offsetHeight : 0, 50);
        if (height === this.Height)
        {
            return;
        }
        this.Height = height;
        // Deactivate until dynamic row height is fixed...
        //this.OnHeight(e, height);
    }

    render()
    {
        const {alignV, backgroundImage, content, grayscale} = this.Content();
        const CA = ["WidgetWysiwyg", "White", "BorderRadius"];
        if (grayscale)
        {
            CA.push("Grayscale");
        }
        const Background = backgroundImage ? parseInt(backgroundImage[0], 10) : 0;
        const CS = this.ClassNames(CA, true, Background);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage(Background)}
                <div className="WidgetWysiwygContentAlign WidgetDynamicAlign">
                    <div className="WidgetWysiwygContentWrapper" ref={node => this.RefContent = node}>
                        <RenderContent className="WidgetWysiwygContent" content={content}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default WidgetWysiwyg;