/**!
 *  Event log interface
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./log.scss";
import API from "Class/API";
import Auth from "Class/Auth";
import Fuse from "Class/Fuse";
import Button from "Components/UI/Button";
import Error from "Components/Feedback/Error";
import Icon from "Components/Layout/Icon";
import IconItem from "Components/UI/IconItem";
import Item from "Components/UI/Item";
import Link from "Components/UI/Link";
import ScrollView from "Components/UI/ScrollView";
import Spinner from "Components/Feedback/Spinner";
import TabMenu from "Components/UI/TabMenu";
import User from "Components/UI/User";
import {RandomToken} from "Functions";

class ViewLog extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Mounted = false;
        this.RequestId = null;
        this.state =
        {
            communities: {},
            containers: {},
            contents: {},
            denied: false,
            done: false,
            error: false,
            ids: [],
            images: {},
            isAdmin: false,
            loading: false,
            log: [],
            selected: "community",
            userNames: {}
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
        const {community, container, content} = this.props;
        let Selected;
        if (container || content)
        {
            Selected = "widget";
        }
        else if (community)
        {
            Selected = "community";
        }
        else if (Auth.IsAdmin())
        {
            Selected = "global";
        }
        this.setState({
            isAdmin: Auth.IsAdmin(),
            selected: Selected
        }, () =>
        {
            this.Load(true);
        });
    }

    componentDidUpdate(prevProps)
    {
        const {selected} = this.state;
        const {community: a1, container: b1, content: c1} = this.props;
        const {community: a2, container: b2, content: c2} = prevProps;
        const ChangedCommunity = a1 !== a2;
        const ChangedWidget = (a1 !== a2 || b1 !== b2 || c1 !== c2);
        if ((selected === "widget" && ChangedWidget) || (selected === "community" && ChangedCommunity))
        {
            this.Load(true);
        }
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    Action = (data, userId) =>
    {
        const {action, fields, id} = data;
        switch (action)
        {
            case "assigned_content":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} assigned content to container.
                    </div>
                );
            case "clear_poll":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} cleared poll results.
                    </div>
                );
            case "export_poll":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} exported poll results.
                    </div>
                );
            case "save_draft":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} saved a draft version.
                    </div>
                );
            case "updated_attributes":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} updated {this.Fields(fields)}.
                    </div>
                );
            case "updated_content":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} updated {this.Fields(fields)}.
                    </div>
                );
            case "updated_scope":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} updated permissions.
                    </div>
                );
            case "uploaded_image":
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} uploaded {this.Image(id)}.
                    </div>
                );
            default:
                return (
                    <div className="LogEntryAction">
                        {this.UserName(userId)} did something.
                    </div>
                );
        }
    }
    
    Badges = (entry) =>
    {
        const {communities, containers, contents} = this.state;
        const {community, container, content} = entry;
        const Badges = [];
        if (community)
        {
            if (communities[community] === undefined)
            {
                communities[community] = `Community #${community}`;
                Fuse.Request(`communities/${community}`, response =>
                {
                    if (!this.Mounted || !response.name)
                    {
                        return;
                    }
                    communities[community] = response.name;
                    this.setState({communities});
                });
            }
            Badges.push(
                <Link className="LogBadge LogBadgeCommunity" target="_top" href={Fuse.Url(`communities/${community}`, false)} key="community">
                    <Icon feather="Users" size={12}/>
                    <span>{communities[community]}</span>
                </Link>
            );
        }
        if (container)
        {
            if (containers[container] === undefined)
            {
                containers[container] = `Container #${container}`;
                API.Request(`widget/container-name/${container}`, response =>
                {
                    if (!this.Mounted || !response.content)
                    {
                        return;
                    }
                    const Name = this.NameFromTemplate(response.template);
                    containers[container] = `${Name} (Container #${container})`;
                    this.setState({containers});
                });
            }
            Badges.push(
                <Item id={container} className="LogBadge LogBadgeContainer" key="container" onClick={this.SetContainer}>
                    <Icon feather="Square" size={12}/>
                    <span>{containers[container]}</span>
                </Item>
            );
        }
        if (content)
        {
            if (contents[content] === undefined)
            {
                contents[content] = `Content #${content}`;
                API.Request(`widget/content-name/${content}`, response =>
                {
                    if (!this.Mounted || !response.content)
                    {
                        return;
                    }
                    const Name = this.NameFromTemplate(response.template);
                    contents[content] = `${Name} (Content #${content})`;
                    this.setState({contents});
                });
            }
            Badges.push(
                <Item id={content} className="LogBadge LogBadgeContent" key="content" onClick={this.SetContent}>
                    <Icon feather="Square" size={12}/>
                    <span>{contents[content]}</span>
                </Item>
            );
        }
        return Badges;
    }

    Entry = (entry) =>
    {
        const {
            data,
            datetime,
            id,
            user
        } = entry;
        return (
            <div className="LogEntry" key={id}>
                <User
                    className="ItemPreview LogEntryAvatar"
                    onLoad={this.OnLoadUser}
                    user={user}
                />
                <div className="ItemContent LogEntryContent">
                    <div className="LogEntryInfo">
                        {this.Timestamp(datetime)}
                        {this.Badges(entry)}
                    </div>
                    {this.Action(data, user)}
                </div>
            </div>
        );
    }

    Fields = (fields) =>
    {
        const Updated = [];
        const Count = fields.length;
        fields.forEach(field =>
        {
            Updated.push(field[0].toUpperCase() + field.substr(1).replace(/[A-Z]/g, function(match)
            {
                return " " + match[0];
            }));
        });
        let Str;
        if (Count > 2)
        {
            Str = [<strong key="first">{Updated[0]}</strong>, " and ", <strong className="LogEntryMoreFields" key="second">{Count - 1} more fields</strong>];
        }
        else if (Count > 1)
        {
            Str = [<strong key="first">{Updated[0]}</strong>, " and ", <strong key="second">{Updated[1]}</strong>];
        }
        else if (Count > 0)
        {
            Str = [<strong key="first">{Updated[0]}</strong>];
        }
        else
        {
            Str = "No fields";
        }
        return (
            <span className="LogEntryFields" title={Updated.join(", ")}>
                {Str}
            </span>
        );
    }

    Image = (id) =>
    {
        const {images} = this.state;
        const Url = API.Url(`files/image/${id}/large`);
        if (images[id] === undefined)
        {
            images[id] = `Image #${id}`;
            API.Request("files/info", {images: [id]}, response =>
            {
                const {info} = response;
                if (!this.Mounted || !info || !info.length)
                {
                    return;
                }
                images[id] = `${info[0].filename}.jpg`;
                this.setState({images});
            });
        }
        return (
            <Link className="LogEntryLink" href={Url}>{images[id]}</Link>
        );
    }

    Load = (reset = false) =>
    {
        const {community, container, content} = this.props;
        const {ids, log} = this.state;
        const {selected} = this.state;
        let Ids, Log, Offset;
        const State = {
            denied: false,
            error: false,
            loading: true
        };
        if (reset)
        {
            Ids = [];
            State.log = Log = [];
            Offset = 0;
        }
        else
        {
            Ids = ids;
            Log = log;
            Offset = log.length;
        }
        this.setState(State);
        let Endpoint, Request;
        switch (selected)
        {
            case "community":
                Endpoint = "log/community";
                Request = {community};
                break;
            case "widget":
                Endpoint = "log/widget";
                Request = {community, container, content};
                break;
            default:
                Endpoint = "log/global";
                Request = {};
        }
        Request.offset = Offset;
        const RequestId = this.RequestId = RandomToken();
        API.Request(Endpoint, Request, response =>
        {
            if (!this.Mounted || RequestId !== this.RequestId)
            {
                return;
            }
            const {entries, error, status} = response || {};
            if (status === "403 Forbidden")
            {
                this.setState({
                    denied: true,
                    loading: false
                });
            }
            else if (error || !entries)
            {
                this.setState({
                    error: true,
                    loading: false
                });
            }
            else
            {
                entries.forEach(entry =>
                {
                    if (Ids.indexOf(entry.id) >= 0)
                    {
                        return;
                    }
                    Ids.push(entry.id);
                    Log.push(entry);
                });
                this.setState({
                    done: !entries.length,
                    ids: Ids,
                    loading: false,
                    log: Log
                });
            }
        });
    }

    NameFromTemplate = (template) =>
    {
        return template;
    }

    /**
     * Catch a users name when an item is loaded.
     * @param integer id - Users' id.
     * @param object data - Users' data.
     * @return void
     */

    OnLoadUser = (id, data) =>
    {
        const {userNames} = this.state;
        userNames[id] = data.name;
        this.setState({userNames});
    }

     /**
     * Load more results when scrolled to the bottom.
     * @return void
     */

    OnScrollEnd = () =>
    {
        const {done, error, loading} = this.state;
        if (done || error || loading)
        {
            return;
        }
        this.Load();
    }

    OnTab = (e, key) =>
    {
        this.setState({selected: key}, () =>
        {
            this.Load(true);
        });
    }

    SetContainer = (e, id) =>
    {
        //console.log("Set container", id);
    }

    SetContent = (e, id) =>
    {
        //console.log("Set content", id);
    }

    Timestamp = (time) =>
    {
        return (
            <div className="LogEntryTime">
                {(new Date(time)).toLocaleString()}
            </div>
        );
    }

    /**
     * Get a user name.
     * @param integer id - Users' id.
     * @return string - Users' name or placeholder.
     */

    UserName = (id) =>
    {
        const {userNames} = this.state;
        const Name = userNames[id] || "[Loading...]";
        const Url = Fuse.Url(`users/${id}`, false);
        return <Link className="LogEntryLink" href={Url}>{Name}</Link>
    }

    render()
    {
        const {
            className,
            community,
            container,
            content,
            onClose
        } = this.props;
        const {
            denied,
            done,
            error,
            isAdmin,
            loading,
            log,
            selected
        } = this.state;
        const CA = ["Log"];
        const Content = [];
        const Disabled = loading ? true : {};
        const Title = {};
        const Host = btoa(API.Host);
        const Key = btoa(Auth.Key);
        let ExportUrl = API.Url("log/export/");
        switch (selected)
        {
            case "community":
                ExportUrl += `community/${Host}/${Key}/${community}`;
                break;
            case "widget":
                ExportUrl += `widget/${Host}/${Key}/${community}/${container || 0}/${content || 0}`;
                break;
            default:
                ExportUrl += `global/${Host}/${Key}`;
        }
        if (!loading && !isAdmin)
        {
            Disabled.global = true;
            Title.global = "The global log is only available for admins.";
        }
        if (!log.length && loading)
        {
            Content.push(<Spinner className="LogSpinner" overlay={true} key="spinner"/>);
        }
        else if (error)
        {
            Content.push(<Error
                className="LogError"
                button="Try Again"
                key="error"
                label="Unable to load log"
                onClick={() => this.Load()}
            />);
        }
        else if (denied)
        {
            Content.push(
                <div className="LogDenied WidgetEmpty" key="denied">
                    Access denied
                </div>
            );
        }
        else
        {
            const Log = [];
            log.forEach(entry =>
            {
                Log.push(this.Entry(entry));
            });
            Content.push(
                <ScrollView
                    key="content"
                    className="LogContent"
                    onScrollEnd={this.OnScrollEnd}
                >
                    {Log.length ? (
                        <span>
                            <div className="LogEntries" key="log">{Log}</div>
                            <IconItem
                                className="LogMoreButton"
                                disabled={done}
                                feather={done ? "StopCircle" : "PlusCircle"}
                                key="fetch"
                                label={done ? "End of log" : "Show more"}
                                loading={loading}
                                onClick={() => this.Load()}
                            />
                        </span>
                    ) : (
                        <div className="LogEmpty WidgetEmpty">Log is empty</div>
                    )}
                </ScrollView>
            );
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                <TabMenu
                    className="LogTabs"
                    disabled={Disabled}
                    items={{
                        global: "Global",
                        community: "Community",
                        widget: "Widget"
                    }}
                    onClick={this.OnTab}
                    selected={selected || "community"}
                    title={Title}
                />
                <div className="LogContentWrapper">
                    {Content}
                </div>
                <div className="LogTray">
                    {onClose ? <Button label="Close" onClick={onClose}/> : ""}
                    <Button disabled={error || loading} hollow={!!onClose} label="Export" href={ExportUrl}/>
                </div>
            </div>
        );
    }
}

ViewLog.defaultProps =
{
    className: "",
    community: 0,
    container: 0,
    content: 0,
    onClose: false
}

export default ViewLog;