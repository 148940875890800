/*!
 *  Text form field.
 *  @prop boolean big - Whether the field should be big.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string feather - Optional. Feather icon src.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop integer maxLength - Maximum number of characters. 0 = unlimited.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onEnter - Callback for when the Enter key is pressed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string return - Which variable type should be returned: float, integer, text (default).
 *  @prop string token - Set a custom token identifier for this field.
 *  @prop string type - Input field type: text, email etc...
 *  @prop string|number value - Field value.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./textfield.scss";
import {RandomToken} from "Functions";
import Icon from "Components/Layout/Icon";
import Insert from "Components/UI/Insert";
import Spinner from "Components/Feedback/Spinner";

class TextField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.FocusValue = false;
        this.Input = false;
        this.Token = RandomToken();
        this.state = {
            focus: false,
            value: ""
        };
    }

    /**
     * Set initial value.
     * @return void
     */

    componentDidMount()
    {
        const {token, value} = this.props;
        const Value = this.ParseValue(value);
        if (token)
        {
            this.Token = token;
        }
        this.setState({value: Value});
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1, token} = this.props;
        const {value: v2} = prevProps;
        if (token && token !== this.Token)
        {
            this.Token = token;
            this.forceUpdate();
        }
        if (v1 !== v2)
        {
            this.setState({value: this.ParseValue(v1)});
        }
    }

    /**
     * Set focus on this field.
     * @return void
     */

    Focus = () =>
    {
        if (this.Input)
        {
            this.Input.focus();
        }
    }

    /**
     * Callback for when the field loses focus.
     * @param object e - The event object.
     * @return void
     */

    OnBlur = (e) =>
    {
        const {id, onBlur, onChange} = this.props;
        const Value = this.ParseValue(e.currentTarget.value);
        if (this.FocusValue !== Value)
        {
            onChange(e, Value, id);
        }
        onBlur(e, Value, id);
        this.setState({focus: false});
    }

    /**
     * Callback for when the field gains focus.
     * @param object e - The event object.
     * @return void
     */

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const Value = this.ParseValue(e.currentTarget.value);
        this.FocusValue = Value;
        onFocus(e, Value, id);
        this.setState({focus: true});
    }

    /**
     * Callback for when the user inputs a new value into the field.
     * @param object e - The event object.
     * @return void
     */

    OnInput = (e) =>
    {
        const {disabled, id, onInput} = this.props;
        if (disabled)
        {
            return;
        }
        const Value = this.ParseValue(e.currentTarget.value);
        onInput(e, Value, id);
        this.setState({value: Value});
    }

    /**
     * Insert a variable key into the field.
     * @param object e - The event object.
     * @param string key - The variable key.
     * @return void
     */

    OnInsert = (e, key) =>
    {
        if (!this.Input)
        {
            return;
        }
        const {id, onChange} = this.props;
        const Notation = `@{${key}}`;
        const Value = this.Input.value;
        if (!Value)
        {
            this.Input.value = Notation;
        }
        // IE
        else if (document.selection)
        {
            this.Input.focus();
            const Selection = document.selection.createRange();
            Selection.text = Notation;
        }
        // Others
        else if (this.Input.selectionStart || this.Input.selectionStart === "0")
        {
            const S = this.Input.selectionStart;
            const E = this.Input.selectionEnd;
            this.Input.value = Value.substring(0, S) + Notation + Value.substring(E, Value.length);
            this.Input.selectionStart = this.Input.selectionEnd = S + Notation.length;
            this.Input.focus();
        }
        const Set = this.Input.value;
        onChange(e, Set, id);
        this.setState({ value: Set });
    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * @param object e - The event object.
     * @return void
     */

    OnKeyDown = (e) =>
    {
        e.stopPropagation();
    }

    /**
     * Listen for when the Enter key is pressed.
     * @param object e - The event object.
     * @return void
     */

    OnKeyUp = (e) =>
    {
        const {disabled, id, onEnter} = this.props;
        if (e.which === 13 && !disabled)
        {
            onEnter(e, this.ParseValue(e.currentTarget.value), id);
        }
    }

    /**
     * Parse the field value into desired return type.
     * @param string value - The raw value.
     * @return mixed - The parsed value.
     */

    ParseValue = (value) =>
    {
        const {maxLength} = this.props;
        let Return, Value = String(value);
        if (maxLength)
        {
            Value = Value.substr(0, maxLength);
        }
        switch (this.props.return)
        {
            case "float":
                Value = Value.replace(/[^\d.]/g, "");
                Return = parseFloat(Value);
                return isNaN(Return) ? 0 : Return;
            case "int":
            case "integer":
                Value = Value.replace(/[^\d]/g, "");
                Return = parseInt(Value, 10);
                return isNaN(Return) ? 0 : Return;
            default:
                return Value;
        }
    }

    /**
     * Reset to inital state.
     * @return void
     */

    Reset = () =>
    {
        this.setState({value: this.ParseValue(this.props.value)});
    }

    /**
     * Get the field value.
     * @return string - The field value.
     */

    Value = () =>
    {
        return this.ParseValue(this.state.value);
    }

    render()
    {
        const {
            big,
            children,
            className,
            disabled,
            error,
            feather,
            insert,
            label,
            loading,
            placeholder,
            type
        } = this.props;
        const {focus, value} = this.state;
        const CA = ["Field", "TextField"];
        if (big)
        {
            CA.push("Big");
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (insert)
        {
            CA.push("HasInsert");
        }
        if (value)
        {
            CA.push("HasValue");
        }
        if (className)
        {
            CA.push(className);
        }
        let RightSide = "";
        if (loading)
        {
            RightSide = <Spinner className="TextFieldSpinner" size={18}/>;
        }
        else if (insert)
        {
            RightSide = <Insert className="TextFieldInsert" onInsert={this.OnInsert}/>;
        }
        else if (feather)
        {
            RightSide = <Icon className="FieldIcon" feather={feather}/>;
        }
        return (
            <div className={CA.join(" ")}>
                {label ?  <label htmlFor={this.Token}>{label}</label> : ""}
                <div className="InputWrapper">
                    <input
                        className="Input"
                        disabled={disabled}
                        id={this.Token}
                        onBlur={this.OnBlur}
                        onChange={() => {}}
                        onFocus={this.OnFocus}
                        onInput={this.OnInput}
                        onKeyDown={this.OnKeyDown}
                        onKeyUp={this.OnKeyUp}
                        onMouseDown={e => e.stopPropagation()}
                        placeholder={placeholder}
                        ref={input => this.Input = input}
                        type={type}
                        value={value}
                    />
                    {RightSide}
                </div>
                {children}
            </div>
       );
    }
}

TextField.propTypes =
{
    big: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    feather: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    insert: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    loading: PropTypes.bool,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    placeholder: PropTypes.string,
    return: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TextField.defaultProps =
{
    big: false,
    className: "",
    disabled: false,
    error: false,
    feather: "",
    id: "",
    insert: false,
    label: "",
    loading: false,
    maxLength: 0,
    onAdjust: () => {},
    onBlur: () => {},
    onChange: () => {},
    onEnter: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    return: "text",
    token: "",
    type: "text",
    value: ""
};

export default TextField;