/*!
 *  Load and display user info.
 *  @prop string className - Append a class name.
 *  @prop function onLoad - OnLoad callback. (userId/object, userData).
 *  @prop function onError - OnError callback. (src).
 *  @prop object style - Image inline styles.
 *  @prop integer size - User avatar size.
 *  @prop object|integer|string user - User data or external user id.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./user.scss";
import Fuse from "Class/Fuse";
import {RandomToken} from "Functions";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";

class User extends React.Component
{
    constructor(props)
    {
        super(props);
        this.LoadToken = false;
        this.Mounted = false;
        this.state =
        {
            error: false,
            errorAvatar: false,
            loading: false,
            user: false

        };
    }

    /**
     * Load user info on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {user} = this.props;
        this.Load(user);
    }

    /**
     * Reload user when the id changes.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {user: u1} = this.props;
        const {user: u2} = prevProps;
        if (u1 !== u2)
        {
            this.Load(u1);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     *   Check the user avatar URL and return a blank string if it's the
     *   default Fuse avatar.
     *   @return string - Parsed avatar URL.
     */

    Avatar = (raw) =>
    {
        const {errorAvatar} = this.state;
        if (!errorAvatar && raw && !raw.match(/unknown-user-icon/))
        {
            return raw;
        }
        return "";
    }

    /**
     *   Load user info from the Fuse API.
     *   @param object|string|integer user - User object or external user id.
     *   @return void
     */

    Load = (user) =>
    {
        const {onError, onLoad} = this.props;
        if (!user)
        {
            this.setState({
                error: false,
                errorAvatar: false,
                loading: false,
                user: false
            });
        }
        else if (typeof user === "object")
        {
            this.LoadToken = false;
            this.setState( {
                error: false,
                loading: false,
                user
            });
        }
        else
        {
            // Assign a token to this API call in order to be able to identify
            // the latest call when the user id changes quickly.
            const Token = this.LoadToken = RandomToken();
            this.setState({
                error: false,
                loading: true,
                user: false
            });
            Fuse.Content(user, "user", content =>
            {
                if (!this.Mounted || Token !== this.LoadToken)
                {
                    return;
                }
                if (!content)
                {
                    this.setState({error: true, loading: false});
                    onError(user);
                }
                else
                {
                    this.setState({loading: false, user: content.raw});
                    onLoad(user, content.raw);
                }
            });
        }
    }

    OnAvatarError = () =>
    {
        this.setState({errorAvatar: true});
    }

    render()
    {
        const {children, className, size, style, target, user: raw} = this.props;
        const {error, loading, user} = this.state; 
        const {big_avatar_url, name} = user || {};
        const CA = ["User"];
        const Avatar = this.Avatar(big_avatar_url);
        if (children)
        {
            CA.push("HasContent");
        }
        if (error)
        {
            CA.push("Error");
        }
        else if (!loading && user)
        {
            CA.push("Loaded");
        }
        if (Avatar)
        {
            CA.push( "HasAvatar" );
        }
        if (className)
        {
            CA.push(className);
        }
        const Style = Object.assign({}, style);
        const UserId = typeof raw === "object" ? raw.id : raw;
        const Url = Fuse.Url(`users/${UserId}`, false);
        return (
            <Link className={CA.join(" ")} href={Url} style={Style} target={target} title={name}>
                <div
                    className="UserAvatar ItemBackground"
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size
                    }}
                >
                    {loading ? <Spinner
                        className="UserAvatarSpinner"
                        overlay={true}
                        size={size * .7}
                    /> : (Avatar ? <LoadImage
                        className="UserAvatarImage"
                        onError={this.OnAvatarError}
                        src={Avatar}
                    /> : <Icon
                        className="UserAvatarIcon"
                        feather="User"
                    />)}
                </div>
                {children ? <div className="UserContent">{children}</div> : ""}
            </Link>
        );
    }
}

User.propTypes =
{
    className: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    style: PropTypes.object,
    size: PropTypes.number,
    target: PropTypes.string,
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string])
};

User.defaultProps =
{
    className: "",
    onLoad: () => {},
    onError: () => {},
    style: {},
    size: 60,
    target: "_top",
    user: 0
};

export default User;