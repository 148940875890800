/**!
 *  Leaderboard widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./leaderboard.scss";
import API from "Class/API";
import Globals from "Class/Globals";
import Error from "Components/Feedback/Error";
import Spinner from "Components/Feedback/Spinner";
import User from "Components/UI/User"
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetLeaderboard extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Leaderboard",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            community: {

                label: "Community",
                placeholder: "Search for community...",
                type: "content",
                types: ["community"]
            },
            communityNotice:
            {
                displayIf: ["community", "==", 0],
                label: "If no community is specified, the leaderboard for the current community will be loaded.",
                type: "notice"
            },
            listLength:
            {
                default: 20,
                label: "Length",
                type: "number",
                minValue: 1,
                maxValue: 1000
            },
            pointsPerView:
            {
                default: 1,
                label: "Points per view",
                type: "number",
                minValue: 0
            },
            pointsPerPost:
            {
                default: 0,
                label: "Points per post",
                type: "number",
                minValue: 0
            },
            pointsPerLike:
            {
                default: 0,
                label: "Points per like",
                type: "number",
                minValue: 0
            },
            pointsPerShare:
            {
                default: 0,
                label: "Points per share",
                type: "number",
                minValue: 0
            },
            export:
            {
                callback: "exportLeaderboard",
                label: "Export Leaderboard",
                type: "button",
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Mounted = false;
        this.Name = "Leaderboard";
        this.state =
        {
            done: false,
            error: false,
            leaderboard: {},
            loading: false,
            userNames: {}
        };
    }

    /**
     * Load leaderboard on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.Reload();
    }

    /**
     * Reload leaderboard if the community is changed.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: ct1} = this.props;
        const {content: ct2} = prevProps;
        const {community: cm1} = ct1 || {};
        const {community: cm2} = ct2 || {};
        const C1 = (cm1 && cm1.length) ? cm1[0][0] : 0;
        const C2 = (cm2 && cm2.length) ? cm2[0][0] : 0;
        if (C1 !== C2)
        {
            this.Load(C1);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Compile leaderboard.
     * @return array - Compiled leaderboard.
     */

    Compile = () =>
    {
        const {content} = this.props;
        const {leaderboard} = this.state;
        const {listLength, pointsPerLike, pointsPerPost, pointsPerShare, pointsPerView} = content || {};
        //const {listLength} = content || {};
        const Leaderboard = [];
        if (!leaderboard)
        {
            return Leaderboard;
        }
        for (let user in leaderboard)
        {
            if (!user)
            {
                continue;
            }
            const {likes, posts, shares, views} = leaderboard[user];
            Leaderboard.push({
                id: parseInt(user, 10),
                user,
                points: views * pointsPerView + likes * pointsPerLike + posts * pointsPerPost + shares * pointsPerShare
            });
        }
        Leaderboard.sort((a, b) =>
        {
            if (a.points > b.points) return -1;
            if (a.points < b.points) return 1;
            if (a.id > b.id) return -1;
            if (a.id < b.id) return 1;
            return 0;
        });
        return Leaderboard.slice(0, listLength);
    }

    /**
     * Output a leaderboard item.
     * @param mixed user - User ID.
     * @param integer points - Number of points.
     * @param integer index - List index.
     * @return JSX - The item.
     */

    Item = (user, points, index) =>
    {
        return (
            <div className="WidgetLeaderboardItem" key={index}>
                <User
                    className="ItemPreview WidgetLeaderboardItemAvatar"
                    onLoad={this.OnLoadUser}
                    user={user}
                />
                <div className="ItemContent WidgetLeaderboardItemContent">
                    <div className="ItemTitle WidgetLeaderboardItemLabel">{index + 1}. {this.UserName(user)}</div>
                    <div className="ItemInfo WidgetLeaderboardItemPoints">{points} points</div>
                </div>
            </div>
        );
    }

    /**
     * Load the leaderboard.
     * @param integer community - Community id.
     * @return void
     */

    Load = (community = 0) =>
    {
        const {context, contextId} = this.props;
        const Current = context === "community" ? contextId : 0;
        const Community = community || Current;
        this.setState({
            error: false,
            leaderboard: {},
            loading: true
        });
        API.Request("leaderboard", {community: Community}, response =>
        {
            const {error, leaderboard} = response;
            if (!leaderboard || error)
            {
                this.setState( {
                    error: true,
                    loading: false
                });
            }
            else
            {
                this.setState({
                    leaderboard,
                    loading: false
                });
            }
        });
    }

    /**
     * Catch a users name when an item is loaded.
     * @param integer id - Users' id.
     * @param object data - Users' data.
     * @return void
     */

    OnLoadUser = (id, data) =>
    {
        const {userNames} = this.state;
        userNames[id] = data.name;
        this.setState({userNames});
    }

    OnRequest = (request) =>
    {
        console.log("Request", request);
    }

    /**
     * Reoad the leaderboard.
     * @param integer community - Community id.
     * @return void
     */

    Reload = () =>
    {
        const {content} = this.props;
        const {community: c} = content || {};
        const Community = (c && c.length) ? c[0][0] : 0;
        this.Load(Community);
    }

    /**
     * Get a user name.
     * @param integer id - Users' id.
     * @return string - Users' name or placeholder.
     */

    UserName = (id) =>
    {
        const {userNames} = this.state;
        return userNames[id] || "Loading...";
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props;
        const {error, loading} = this.state;
        const {title} = WidgetContent;
        const CA = this.ClassNames(["WidgetLeaderboard"]);
        const Content = [];
        if (active)
        {
            CA.push("Active");  
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (loading)
        {
            CA.push("Loading");
            Content.push(<Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (error)
        {
            CA.push("Error");
            Content.push(<Error
                button="Try again"
                className="WidgetError"
                key="error"
                label="Unable to load the leaderboard"
                onClick={this.Reload}
            />);
        }
        else
        {
            const Leaderboard = this.Compile();
            const Items = [];
            Leaderboard.forEach(({user, points}, index) =>
            {
                Items.push(this.Item(user, points, index));
            });
            Content.push(<div className="WidgetLeaderboardItems" key="items">{Items}</div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetLeaderboardWrapper"
                    title={title}
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetLeaderboard;