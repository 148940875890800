const Palette =
{
    "Default colors":
    {
        black: "#000000",
        white: "#ffffff"
    },
    "Primary colors":
    {
        darkBlue: "#003da5",
        darkPurple: "#3c1053",
        darkGreen: "#25282a",
        darkGray: "#25282a"
    },
    "Complementary colors":
    {
        mediumBlue: "#009cde",
        mediumPurple: "#a51890",
        mediumGreen: "#40c1ac",
        mediumGray: "#9ea2a2",
        lightBlue: "#71c5e8",
        lightPurple: "#ccaed0",
        lightGreen: "#a0d1ca",
        lightGray: "#c7c9c7"
    }
};

export default Palette;