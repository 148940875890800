/**!
 *  Load widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./widget.scss";
import Auth from "Class/Auth";
import API from "Class/API";
import Broadcast from "Class/Broadcast";
import Globals from "Class/Globals";
import {ArrayClone, DefaultValue, ParsedValue, RandomToken} from "Functions";
import Spinner from "Components/Feedback/Spinner";
import * as Widgets from "./widgets.js";

class ViewWidget extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Content = false;
        this.Id = false;
        this.Iteration = 0;
        this.Mounted = false;
        this.Registered = false;
        this.Rows = -1;
        this.Style = false;
        this.Token = RandomToken();
        this.WidgetComponent = false;
        this.state =
        {
            active: false,
            attributes: false,
            blocked: false,
            content: false,
            drafts: [],
            editContainer: false,
            editContent: false,
            error: false,
            hover: false,
            key: "",
            loading: true,
            restore: false,
            scope: false
        };
    }

    /**
     * Load widget on mount.
     * @return void
     */

    componentDidMount()
    {
        const {name} = this.props;
        this.Id = name || RandomToken();
        this.Mounted = true;
        Globals.Listen("active-view", this.OnActive);
        Globals.Listen(`attributes-${this.Id}`, this.OnAttributes);
        Globals.Listen(`clear-${this.Id}`, this.OnClear);
        Globals.Listen(`clear-drafts-${this.Id}`, this.OnClearDrafts);
        Globals.Listen(`draft-${this.Id}`, this.OnDraft);
        Globals.Listen(`hover-${this.Id}`, this.OnHover);
        Globals.Listen(`restore-${this.Id}`, this.OnRestore);
        Globals.Listen(`save-${this.Id}`, this.OnSave);
        // Load right away or add listener depending on auth ready state.
        if (Auth.Ready())
        {
            this.OnReady();
        }
        else
        {
            Globals.Listen("auth", this.OnReady);
        }
    }

    /**
     * Broadcast the widget position when it changes.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {x: x1, y: y1} = this.props;
        const {x: x2, y: y2} = prevProps;
        if (x1 !== x2 || y1 !== y2)
        {
            Globals.Trigger("position-view", this.Id, [x1, y1]);
        }
    }

    /**
     * Remove listener on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        this.Unregister();
        Globals.Remove("active-view", this.OnActive);
        Globals.Remove("auth", this.OnReady);
        Globals.Remove(`attributes-${this.Id}`, this.OnAttributes);
        Globals.Remove(`clear-${this.Id}`, this.OnClear);
        Globals.Remove(`clear-drafts-${this.Id}`, this.OnClearDrafts);
        Globals.Remove(`draft-${this.Id}`, this.OnDraft);
        Globals.Remove(`hover-${this.Id}`, this.OnHover);
        Globals.Remove(`restore-${this.Id}`, this.OnRestore);
        Globals.Remove(`save-${this.Id}`, this.OnSave);
    }

    /**
     * Trigger the widget component to adjust its' contents.
     * @return void
     */

    Adjust = () =>
    {
        if (!this.WidgetComponent || typeof this.WidgetComponent.Adjust !== "function")
        {
            return;
        }
        this.WidgetComponent.Adjust();
    }

    /**
     * Get widget appearance.
     * @return object - Appearance.
     */

    Appearance = () =>
    {
        const {appearance} = this.props;
        const {content} = this.state;
        const Content = content ? content.content : {};
        const {appearanceInherit} = Content || {};
        return (appearanceInherit === undefined || appearanceInherit) ? appearance : Content;
    }

    /**
     * Load widget data from the backend server.
     * @return void
     */

    Load = (name) =>
    {
        const {ancestors, context, contextId, universal} = this.props;
        this.setState({
            blocked: false,
            error: false,
            loading: true
        });
        API.Request(`widget/load/${name}`,
        {
            context,
            context_id: contextId,
            universal
        }, response =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {error, message, user, widget} = response;
            if (error || !widget)
            {
                this.setState({
                    content: false,
                    editContainer: false,
                    editContent: false,
                    error: message || "No widget.",
                    loading: false,
                    scope: false
                });
            }
            else
            {
                Auth.SetUser(user);
                const {attributes, content: c1, drafts, edit: editContainer, key, scope: s1} = widget;
                const {content: c2, edit: editContent, scope: s2} = c1 || {edit: Auth.CanManage(context, contextId)};
                const Id = c2 ? c2.id || this.Id : this.Id;
                const Template = c2 ? c2.template : "Blank";
                // Register template in global var.
                Globals.Var(`template-${this.Id}`, Template);
                // Create a snapshot of the widget container and content in
                // order to be able to restore to its' last saved state later on.
                const Restore =
                {
                    container:
                    {
                        attributes: ArrayClone(attributes),
                        edit: editContainer,
                        id: this.Id,
                        scope: ArrayClone(s1)
                    },
                    content:
                    {
                        content: ArrayClone(c2),
                        edit: editContent,
                        id: c2 ? c2.id : false,
                        scope: ArrayClone(s2)
                    }
                };
                // Avoid endless recursion by checking if this content is an
                // ancestor of itself and if so, block it.
                this.setState({
                    attributes,
                    blocked: ancestors.indexOf(Id) >= 0,
                    content: c1,
                    drafts,
                    editContainer,
                    editContent,
                    key,
                    loading: false,
                    restore: Restore,
                    scope: s1
                }, this.LoadFont);
            }
        });
    }

    /**
     * Check appearance and load the selected font (if unloaded).
     * @return void
     */

    LoadFont = () =>
    {
        const {appearanceFontFaceHeading, appearanceFontFaceText} = this.Appearance();
        Globals.LoadFont(appearanceFontFaceHeading);
        Globals.LoadFont(appearanceFontFaceText);
    }

    /**
     * Callback when global focus changes.
     * @return void
     */

    OnActive = (id) =>
    {
        const {editContainer, editContent} = this.state;
        if (!editContainer && !editContent)
        {
            return;
        }
        this.setState({active: id === this.Id, hover: false});
    }

    /**
     * Receive updated attributes from the widget editor.
     * @param object attributes - Updated attributes.
     * @return void
     */

    OnAttributes = (attributes) =>
    {
        const {onAutoAdjust, onFill} = this.props;
        const {autoAdjust: a1, fill: f1} = attributes;
        const {autoAdjust: a2, fill: f2} = this.state.attributes;
        this.setState({attributes});
        if (a1 !== a2)
        {
            onAutoAdjust(null, a1, this.Id);
        }
        if (f1 !== f2)
        {
            onFill(null, f1, this.Id);
        }
    }

    /**
     * Clear content.
     * @param object content - New content after clear.
     * @param object scope - New content scope after clear.
     * @param boolean edit - Whether the current user can edit the new content.
     * @return void
     */

    OnClear = (content, scope, edit) =>
    {
        this.Iteration++;
        this.OnContent(content, scope, edit);
    }

    /**
     * Clear draft versions and restore.
     * @return void
     */

    OnClearDrafts = () =>
    {
        this.OnRestore(true);
    }

    /**
     * Prepend a new draft version to the drafts array.
     * @param object draft - New draft object,
     * @return void
     */

    OnDraft = (draft) =>
    {
        const {drafts} = this.state;
        let Update = -1;
        drafts.forEach((publicDraft, index) =>
        {
            if (publicDraft.id === draft.id)
            {
                Update = index;
            }
        });
        if (Update < 0)
        {
            drafts.unshift(draft);
        }
        else
        {
            drafts[Update] = draft;
        }
        this.setState({drafts});
    }

    /**
     * Receive updated content.
     * @param object content - Updated content.
     * @param object scope - Updated content scope.
     * @param boolean edit - Whether the current user can edit the updated content.
     * @return void
     */

    OnContent = (content, scope, edit) =>
    {
        const {content: currentContent} = this.state;
        const {edit: currentEdit, scope: currentScope} = currentContent;
        const {id} = content;
        const Id = id || this.Id;
        if (Id !== this.Content)
        {
            Globals.Remove(`content-${this.Content}`, this.OnContent);
            Globals.Listen(`content-${Id}`, this.OnContent);
            this.Content = Id;
        }
        this.setState({   
            content:
            {
                content,
                edit: edit === undefined ? currentEdit : edit,
                scope: scope === undefined ? currentScope : scope
            }
        }, this.LoadFont);
    }

    /**
     * Callback when the widget content requests a new height. If dynamic height is
     * enabled -- this request will be propagated to its' parent grid.
     * @param object e - The event object.
     * @param integer height - The requested height.
     * @param boolean pixels - Whether the height is in pixels or number of rows.
     * @return void
     */

    OnHeight = (e, height, pixels = true) =>
    {
        const {onHeight} = this.props;
        const Rows = pixels ? Math.ceil(height / (Globals.RowHeight + Globals.RowMargin)) : height;
        if (Rows === this.Rows)
        {
            return;
        }
        this.Rows = Rows;
        if (this.Registered)
        {
            Broadcast.SendMessage({
                id: this.Id,
                type: "rows",
                rows: Rows,
                height
            });
        }
        onHeight(e, height, this.Id);
    }

    /**
     * Callback when the widget or a widget element is hovered.
     * @param boolean hover - Whether the widget is hovered.
     * @return void
     */

    OnHover = (hover) =>
    {
        this.setState({hover});
    }

    /**
     * Callback when auth is ready.
     * @return void
     */

    OnReady = () =>
    {
        const {name} = this.props;
        this.Load(name);
    }

    /**
     * Callback when the widget should be restored to its' last saved state.
     * @param bool clearDrafts - Whether to remove all drafts versions.
     * @return void
     */

    OnRestore = (clearDrafts) =>
    {
        const {restore} = this.state;
        if (!restore)
        {
            return;
        }
        this.Iteration++;
        const {container, content} = restore;
        const {attributes, edit: editContainer, scope: s1} = container;
        const {content: restoreContent, edit: editContent, scope: s2} = content;
        const Restored =
        {
            attributes,
            content:
            {
                content: restoreContent,
                edit: editContent,
                scope: s2
            },
            editContainer,
            editContent,
            scope: s1
        };
        if (clearDrafts)
        {
            Restored.drafts = [];
        }
        this.setState(Restored);
    }

    /**
     * Callback when the widget has been saved to the backend API, and should
     * update its' saved state.
     * @param object savedDraft - Draft version which was saved.
     * @param integer updatedContentId - New content id, if it has been updated.
     * @return void
     */

    OnSave = (savedDraft, updatedContentId) =>
    {
        const {container, content} = savedDraft;
        const {editContainer, editContent} = this.state;
        const {attributes, scope: s1} = container;
        const {content: c, scope: s2} = content;
        const ContentId = c ? updatedContentId || c.id : updatedContentId;
        if (ContentId)
        {
            c.id = ContentId;
        }
        const Restore =
        {
            container:
            {
                attributes: ArrayClone(attributes),
                edit: editContainer,
                id: this.Id,
                scope: ArrayClone(s1)
            },
            content:
            {
                content: ArrayClone(c),
                edit: editContent,
                id: ContentId,
                scope: ArrayClone(s2)
            }
        };
        this.setState({  
            attributes,
            content:
            {
                content: c,
                edit: editContent,
                scope: s2
            },
            drafts: [],
            restore: Restore,
            scope: s1
        });
    }

    /**
     * Catch widget template component and register it in the parent hub.
     * @return void
     */

    OnWidget = (widget) =>
    {
        const {content} = this.state;
        const Content = content ? ArrayClone(content) : false;

        if (Content)
        {
            const C = Content.content;
            const F = widget ? widget.Fields || {} : {};
            // Parse widget fields into content object.
            for (let key in F)
            {
                if (C[key] !== undefined)
                {
                    C[key] = ParsedValue(C[key], F[key].type);
                }
                else
                {
                    C[key] = F[key].default || DefaultValue(F[key].type);
                }
            }
        }
        this.Register(this.WidgetComponent = widget);
        this.setState({content: Content});
    }

    /**
     * Register this widget in the parent hub. Triggered when the widget is
     * fully loaded and rendered.
     * @return void
     */

    Register = (widget, setContent) =>
    {
        if (!widget)
        {
            return;
        }
        this.Registered = true;
        const {Fields, Name} = widget;
        const {context, contextId, name, onAutoAdjust, onFill, parent, x, y} = this.props;
        const {attributes, content, drafts, editContainer, editContent, restore, scope} = this.state;
        const {autoAdjust, fill} = attributes || {};
        const Content = setContent || content;
        const EditContent = (Content && Content.edit !== undefined) ? Content.edit : Auth.CanManage(context, contextId);
        const RestoreContent = restore ? restore.content.content : false;
        // Parse restore content.
        if (RestoreContent)
        {
            for (let key in Fields)
            {
                if (RestoreContent[key] !== undefined)
                {
                    RestoreContent[key] = ParsedValue(RestoreContent[key], Fields[key].type);
                }
                else
                {
                    RestoreContent[key] = Fields[key].default || DefaultValue(Fields[key].type);
                }
            }
        }
        // Remove any existing content listener.
        if (this.Content)
        {
            Globals.Remove(`content-${this.Content}`, this.OnContent);
        }
        // Listen for content updates.
        this.Content = content ? content.content.id || this.Id : this.Id;
        Globals.Listen(`content-${this.Content}`, this.OnContent);
        Broadcast.Widget({
            attributes,
            drafts,
            edit:
            {
                content: Content,
                fields: Fields
            },
            editContainer: editContainer,
            editContent: EditContent,
            id: this.Id,
            label: Name,
            name,
            parent,
            restore,
            rows: this.Rows,
            scope,
            x,
            y
        });
        // Set attributes,
        onAutoAdjust(null, autoAdjust, this.Id);
        onFill(null, fill, this.Id);
        if (editContent !== EditContent)
        {
            this.setState({editContent: EditContent});
        }
    }

    /**
     * Unregister this widget in the parent hub.
     * @return void
     */

    Unregister = () =>
    {
        this.Registered = false;
        if (this.Content)
        {
            Globals.Remove(`content-${this.Content}`, this.OnContent);
        }
        if (!this.WidgetComponent)
        {
            return;
        }
        Broadcast.Widget({id: this.Id});
    }

    /**
     * Create a widget component.
     * @param string key - The widget component key.
     * @return JSX - The widget component.
     */

    Widget = (key) =>
    {
        const
        {    
            ancestors,
            autoAdjust,
            context,
            contextId,
            name,
            onMount,
            rowHeight,
            toolbarOffset  
        } = this.props;
        const
        {    
            active,
            attributes,
            blocked,
            content,
            editContainer,
            editContent,
            key: imageKey,
            hover    
        } = this.state;
        const Key = Widgets[key] === undefined ? "Blank" : key;
        const Appearance = this.Appearance();
        if (blocked)
        {
            return React.createElement(Widgets.Blank,
            {
                active,
                appearance: Appearance,
                autoAdjust,
                contentId: this.Content || this.Id,
                editContainer: false,
                editContent: false,
                id: this.Id,
                imageKey,
                key: `widget-${Key}-${this.Iteration}`,
                name,
                onMount,
                ref: this.OnWidget,
                rowHeight
            });
        }
        else
        {
            return React.createElement(Widgets[Key],
            {
                active,
                ancestors,
                appearance: Appearance,
                attributes,
                autoAdjust,
                content: content ? content.content : {},
                contentId: this.Content || this.Id,
                context,
                contextId,
                editContainer,
                editContent,
                hover,
                id: this.Id,
                imageKey,
                key: `widget-${Key}-${this.Iteration}`,
                name,
                onHeight: this.OnHeight,
                onMount,
                ref: this.OnWidget,
                rowHeight,
                toolbarOffset
            });
        }
    }

    render()
    {
        const {content, loading} = this.state;
        const CA = ["ViewWidget", `Widget${this.Token}`];
        const Content = [];
        const Colors = Globals.Setting("Colors", {});
        if (loading)
        {
            CA.push("Loading");
            Content.push(<Spinner
                className="ViewWidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else
        {
            const {template} = content ? content.content : {};
            Content.push(this.Widget(template));
        }
        const {
            appearanceBorderRadius = 0,
            appearanceFontFaceHeading = "ubuntu",
            appearanceFontFaceText = "lato",
            appearanceColorHighlight = Colors.pink,
            appearanceColorItem = Colors.purple,
            appearanceColorItemFg = Colors.white,
            appearanceFontBold,
            appearanceFontItalic,
            appearanceFontUnderline,
            appearanceFontSize = "1em"
        } = this.Appearance();
        return (
            <div className={CA.join(" ")}>
                <style>
                    {`
                    .Widget${this.Token} h1,
                    .Widget${this.Token} h2,
                    .Widget${this.Token} h3,
                    .Widget${this.Token} h4,
                    .Widget${this.Token} h5,
                    .Widget${this.Token} h6,
                    .Widget${this.Token} .Heading,
                    .Widget${this.Token} .WidgetEmpty
                    {
                        font-family: ${Globals.FontStr[appearanceFontFaceHeading]}
                    }

                    .Widget${this.Token} .BorderRadius,
                    .Widget${this.Token} .BorderRadius:after
                    {
                        border-radius: ${appearanceBorderRadius}px;
                    }

                    .Widget${this.Token} .ItemActive
                    {
                        color: ${appearanceColorHighlight};
                    }

                    .Widget${this.Token} h3:after,
                    .Widget${this.Token} .HighlightAfter:after,
                    .Widget${this.Token} .HighlightBackground
                    {
                        background-color: ${appearanceColorHighlight};
                    }

                    .Widget${this.Token} .ItemBackground
                    {
                        color: ${appearanceColorItemFg};
                        background-color: ${appearanceColorItem};
                    }

                    .Widget${this.Token} .Button
                    {
                        color: ${appearanceColorItemFg} !important;
                        border-color: ${appearanceColorItem} !important;
                        background-color: ${appearanceColorItem} !important;
                    }

                    .Widget${this.Token}
                    {
                        font-size: ${appearanceFontSize};
                    }

                    .Widget${this.Token},
                    .Widget${this.Token} *
                    {
                        font-family: ${Globals.FontStr[appearanceFontFaceText]};
                        font-weight: ${appearanceFontBold ? "600 !important" : "inherit"};
                        font-style: ${appearanceFontItalic ? "italic !important" : "inherit"};
                        text-decoration: ${appearanceFontUnderline ? "underline !important" : "inherit"};
                    }
                    `}
                </style>
                {Content}
            </div>
        );
    }
}

ViewWidget.propTypes =
{
    ancestors: PropTypes.array,
    appearance: PropTypes.object,
    autoAdjust: PropTypes.bool,
    context: PropTypes.string,
    contextId: PropTypes.string,
    name: PropTypes.string,
    onAutoAdjust: PropTypes.func,
    onFill: PropTypes.func,
    onHeight: PropTypes.func,
    onMount: PropTypes.func,
    parent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    toolbarOffset: PropTypes.number,
    universal: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number
};

ViewWidget.defaultProps =
{
    ancestors: [],
    appearance: {},
    autoAdjust: true,
    context: "",
    contextId: "",
    name: "",
    onAutoAdjust: () => {},
    onFill: () => {},
    onHeight: () => {},
    onMount: () => {},
    parent: 0,
    rowHeight: 1,
    toolbarOffset: 0,
    universal: false,
    x: -1,
    y: -1
};

export default ViewWidget;