
/*!
 *  Date and time selector form field.
 *
 *  @prop string className - Append a class name.
 *  @param boolean disabled - Whether the field should be disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop boolean seconds - Whether to include seconds.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./datetimefield.scss";

import {DateParse, TimeParse} from "Functions";
import DateField from "Components/UI/Field/DateField";
import TextField from "Components/UI/Field/TextField";

class DateTimeField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            date: "",
            formatted: "",
            time: ""
        };
    }

    /**
     * Parse the initial value on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        this.SetValue(value);
    }

    /**
     * Parse the updated value when one is received.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        const {formatted} = this.state;
        if (v1 !== v2 && v1 !== formatted)
        {
            this.SetValue(v1);
        }
    }

    /**
     * Update the field value when the date field changes.
     * 
     * @param object e - The event object.
     * @param string date - The new date.
     * @return void
     */

    SetDate = (e, date) =>
    {
        const {id, onChange} = this.props;
        const {time} = this.state;
        const Formatted = date + " " + time;
        onChange(e, Formatted, id);
        this.setState({
            date,
            formatted: Formatted
        });
    }

    /**
     * Update the field value when the time field changes.
     * 
     * @param object e - The event object.
     * @param string time - The new time. 
     * @return void
     */

    SetTime = (e, time) =>
    {
        const {id, onChange, seconds} = this.props;
        const {date} = this.state;
        const Time = TimeParse(time, seconds);
        const Formatted = date + " " + Time;
        onChange(e, Formatted, id);
        this.setState({
            formatted: Formatted,
            time
        });
    }

    /**
     * Parse and set the value of the field-
     * 
     * @param string value - The unparsed value.
     * @return void
     */

    SetValue = (value) =>
    {
        const {seconds} = this.props;
        let [D, T] = value.split(" ");
        let Tmp = D;
        D = DateParse(D, true);
        T = TimeParse(T, seconds);
        this.setState({
            date: D,
            formatted: D + " " + T,
            time: T
        });
    }

    /**
     * Get the formatted value of the field.
     * @return string - The formatted value.
     */

    Value = () =>
    {
        const {formatted} = this.state;
        return formatted;
    }

    render()
    {
        const {className, disabled, label, seconds} = this.props;
        const {date, time} = this.state;
        const CA = ["DateTimeField"];
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                {label ?  <label>{label}</label> : ""}
                <DateField
                    { ...this.props }
                    className="DateTimeFieldDateField"
                    disabled={disabled}
                    label=""
                    onChange={this.SetDate}
                    value={date}
                />
                <TextField
                    className="DateTimeFieldTimeField"
                    disabled={disabled}
                    maxLength={seconds ? 8 : 5}
                    onBlur={this.SetTime}
                    placeholder={seconds ? "00:00:00" : "00:00"}
                    value={time}
                />
            </div>
        );
    }
}

DateTimeField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    seconds: PropTypes.bool,
    value: PropTypes.string
};

DateTimeField.defaultProps =
{
    className: "",
    disabled: false,
    id: "",
    label: "",
    onChange: () => {},
    seconds: false,
    value: ""
};

export default DateTimeField;