import React from "react";

export default function RenderContent({className = "", content = "", node = "div", nl2br = false})
{
    let html = content;
    if (nl2br)
    {
        html = html.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    }
    return React.createElement(node, {className, dangerouslySetInnerHTML: {__html: html}});
}