/**!
 *  Collection of content links.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./content.scss";
import {RandomToken} from "Functions";
import ContentItem from "Components/UI/ContentItem";
import ContentListItem from "Components/UI/ContentListItem";
import Slider from "Components/UI/Slider";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetContent extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Explore",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            links:
            {
                addLabel: "Add link",
                onLabel: ["content", "content", 0],
                label: "Links",
                type: "repeater",
                nameKey: "label",
                fields:
                {
                    externalLink:
                    {
                        label: "External link",
                        type: "checkbox"
                    },
                    content:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Content",
                        type: "content",
                        //types: ["content::article", "community", "externalLink", "event", "question", "scormcourse", "uploadedfile", "video"]
                    },
                    contentNotice:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Label and Image will replace the contents preview and name if specified.",
                        type: "notice"
                    },
                    url:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "URL",
                        type: "url",
                        default: "https://"

                    },
                    urlBlank:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: true
                    },
                    urlBlank2:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: false
                    },
                    label:
                    {
                        label: "Label"
                    },
                    description:
                    {
                        label: "Description",
                        type: "textarea"
                    },
                    descriptionNotice:
                    {
                        label: "The description text will appear when the cursor moves over the content item.",
                        type: "notice"
                    },
                    image:
                    {
                        label: "Image",
                        type: "image"
                    },
                    grayscale:
                    {
                        label: "Grayscale",
                        type: "checkbox",
                        default: true
                    }
               }
            },
            listView:
            {
                default: true,
                label: "Display as list",
                type: "checkbox"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Name = "Content links";
   }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover, rowHeight} = this.props;
        const {links, listView, title} = WidgetContent;
        const CA = ["Widget", "WidgetContent", "White", "BorderRadius"];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {

            CA.push("Hover");
        }
        if (listView)
        {
            CA.push("ListView");
        }
        const Items = [];
        (links || []).forEach(link =>
        {
            // Assign a unique id/key to each link to avoid re-render.            
            if (!link.id)
            {
                link.id = RandomToken();
            }
            if (listView)
            {
                Items.push(
                    <ContentListItem       
                        content={link}
                        key={link.id}
                    />
                );
            }
            else
            {
                Items.push(
                    <div className="WidgetContentItem" key={link.id}>
                        <ContentItem
                            content={link}
                            width={200}
                            height={250}
                            sizeMultiplier={Math.min(1, rowHeight)}
                            text={link.description}
                        />
                    </div>   
                ); 
            }
        });
        return (

            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetContentWrapper"
                    title={title}
                >
                    {listView ? (
                        <div className="WidgetContentList">
                            {Items}
                        </div>
                    ) : (
                        <div className="WidgetContentAlign">
                            <Slider className="WidgetContentItems" style={{height: 280 * rowHeight}}>
                                {Items}
                            </Slider>
                        </div>
                    )}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetContent;