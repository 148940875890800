/*!
 *  Content type select form field.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import ChoiceField from "Components/UI/Field/ChoiceField";
import SelectField from "Components/UI/Field/SelectField";

class ContentTypeField extends React.Component
{
    constructor(props)
    {
        super(props);

        this.Types = {
            v1: {
                "content::article": "Article",
                //"capturedvideo": "Captured Video",
                "community": "Community",
                "externalLink": "External Link",
                "event": "Event",
                //"learningplan": "Learning Plan",
                "question": "Question",
                "scormcourse": "Scorm Course",
                //"topic": "Topic",
                "uploadedfile": "Uploaded File",
                "user": "User",
                "video": "Video"
            },
            v2: {
                "Topic": "Topics",
                "UploadedFile": "Files",
                "Content::Article": "Articles",
                "ExternalLink": "Links",
                "ScormCourse": "Courses",
                "LearningPlan": "Learning Plans",
                "Question": "Questions",
                "Person": "People",
                "Event": "Events",
                "Video": "Videos",
                "Community": "Communities"
            }
        };
    }

    render()
    {
        const {className, version, ...inheritProps} = this.props;
        const CA = ["ContentTypeField"];
        if (className)
        {
            CA.push(className);
        }
        return <ChoiceField
            {...inheritProps}
            className={CA.join(" ")}
            options={this.Types[version]}
        />;
    }
}

ContentTypeField.propTypes =
{
    className: PropTypes.string,
    version: PropTypes.string
};

ContentTypeField.defaultProps =
{
    className: "",
    version: "v1"
};

export default ContentTypeField;