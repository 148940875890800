/**!
 *  Handles global variables and methods.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import Conditions from "Import/JSON/conditions.json";
import Settings from "Import/JSON/settings.json";
import {BrowserLanguage, CanForEach, CompareValues, DateStamp, DateTimeStamp, RandomToken, TimeStamp} from "Functions";
import Auth from "../Auth";
import Broadcast from "../Broadcast";

class Globals
{
    constructor()
    {
        this.DialogList = {};
        this.Fonts = {lato: 1, ubuntu: 1};
        this.FontSrc =
        {
            // https://fonts.google.com/specimen/Ubuntu
            ubuntu: {provider: "Google", src: "Ubuntu:300,300i,400,400i,700"},
            // https://fonts.google.com/specimen/Open+Sans
            openSans: {provider: "Google", src: "Open Sans:300,300i,400,400i,700"},
            // https://fonts.google.com/specimen/Montserrat
            montserrat: {provider: "Google", src: "Montserrat:300,300i,400,400i,700"},
            // https://fonts.google.com/specimen/Roboto
            roboto: {provider: "Google", src: "Roboto:300,300i,400,400i,700"},
            // https://fonts.google.com/specimen/Playfair+Display
            playfairDisplay: {provider: "Google", src: "Playfair Display:300,300i,400,400i,700"},
            // https://fonts.google.com/specimen/Lato
            lato: {provider: "Google", src: "Lato:300,300i,400,400i,700"},
            // https://fonts.google.com/specimen/Merriweather
            merriweather: {provider: "Google", src: "Merriweather:300,300i,400,400i,700"}
        };
        this.FontStr =
        {
            calibri: "Calibri, Arial, sans-serif",
            helvetica: "Helvetica, Arial, sans-serif",
            lato: '"Lato", sans-serif',
            merriweather: '"Merriweather", serif',
            montserrat: '"Montserrat", sans-serif',
            openSans: '"Open Sans", sans-serif',
            playfairDisplay: '"Playfair Display", serif',
            roboto: '"Roboto", sans-serif',
            ubuntu: '"Ubuntu", sans-serif'
        };
        this.FontLibraryAdded = false;
        this.FontLibraryCallbacks = [];
        this.FontLibrarySrc = "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js";
        this.FontLoad = [];
        this.FontTimer = false;
        this.Listeners = {};
        this.RowHeight = 400;
        this.RowMargin = 10;
        this.Scripts = {};
        this.Settings = Settings || {};
        this.StorageIsEnabled = -1;
        this.StorageWarningSent = false;
        this.Vars = {};
        this.Views = {};
        this._ViewActive = false;
        this._ViewAction = false;
        // Trigger an event when fonts load to update section masks etc.
        if (document.fonts)
        {
            document.fonts.onloadingdone = this.OnLoadFont;
        }
    }

    AddScript = (name, src) =>
    {
        if (this.Scripts[name] !== undefined)
        {
            return;
        }
        const Script = this.Scripts[name] = document.createElement("script");
        Script.name = name;
        Script.src = src;
        Script.type = "text/javascript";
        document.body.appendChild(Script);
    }

    CheckConditions = (conditions) =>
    {
        if (!CanForEach(conditions) || !conditions.length)
        {
            return true;
        }
        let MatchOr = false;
        conditions.forEach(({children}) =>
        {
            if (MatchOr)
            {
                return;
            }
            let MatchAnd = true;
            children.forEach(({operator, type, value}) =>
            {
                if (!MatchAnd || Conditions[type] === undefined)
                {
                    return;
                }
                const {defaultOperator, operators} = Conditions[type];
                const O = operators[operator] || operators[defaultOperator];
                switch (type)
                {
                    case "communityManager":
                        const Manager = (typeof value === "object" && value.length) ? Auth.IsManager(value[0][0]) : false;
                        if ((O === "of" && !Manager) || (O === "not of" && Manager))
                        {
                            MatchAnd = false;
                        }
                        break;
                    case "communityMember":
                        const Member = (typeof value === "object" && value.length) ? Auth.Member(value[0][0]) : false;
                        if ((O === "of" && !Member) || (O === "not of" && Member))
                        {
                            MatchAnd = false;
                        }
                        break;
                    case "date":
                        if (!CompareValues(DateStamp(), value || DateStamp(), O))
                        {
                            MatchAnd = false;
                        }
                        break;
                    case "dateTime":
                        if (!CompareValues(DateTimeStamp(), value || DateTimeStamp(), O))
                        {
                            MatchAnd = false;
                        }
                        break;
                    case "role":
                        const R = ["admin", "manager", "user"];
                        const R1 = 2 - (Auth.IsAdmin() ? 0 : (Auth.IsManager() ? 1 : 2));
                        const R2 = 2 - (value ? R.indexOf(value) : 0);
                        if (!CompareValues(R1, R2, O))
                        {
                            MatchAnd = false;
                        }
                        break;
                    case "time":
                        if (!CompareValues(TimeStamp(), value || TimeStamp(), O))
                        {
                            MatchAnd = false;
                        }
                        break;
                    default:
                        console.log(type, O, value);
                }
            });
            if (MatchAnd)
            {
                MatchOr = true;
            }
        });
        return MatchOr;
    }

    /**
     * Close the artice editor dialog.
     * @return void
     */

    CloseArticleEditor = () =>
    {
        Broadcast.SendMessage({
            type: "dialog-close",
            uri: "articles"
        });
    }

    /**
     * Save or Load a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * @return mixed - The setting value.
     */

    Client = (key, value = null) =>
    {
        if (this.StorageEnabled())
        {
            return this.Session(key, value) || this.Storage(key, value);
        }
        else
        {
            if (!this.StorageWarningSent)
            {
                console.warn("Storage is not available in this client. Falling back on global variables.");
                this.StorageWarningSent = true;
            }
            return this.Var(key, value);
        }
    }

    /**
     * Remove a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     * @param string key - The value key.
     * @return void
     */

    ClientRemove = (key) =>
    {
        if (this.StorageEnabled())
        {
            this.SessionRemove(key);
            this.StorageRemove(key);
        }
    }

    /**
     * Receive updated content.
     * @param string|integer id - Content id.
     * @param object content - Updated content.
     * @return void
     */

    ContentUpdate = (id, content) =>
    {
        this.Trigger(`content-${id}`, content);
    }

    /**
     * Create and display an overlay dialog.
     * @param object dialog - Dialog object.
     * @return string - The unique ID of the dialog.
     */

    DialogCreate = (dialog, id) =>
    {
        const Id = id || RandomToken();
        this.Trigger("dialog-create", Id, this.DialogList[Id] = dialog);
        return Id;
    }

    /**
     * Destroy a dialog.
     * @param string id - The dialog id.
     * @return void
     */

    DialogDestroy = (id, skipClose) =>
    {
        const Dialog = this.DialogList[id];
        if (!Dialog)
        {
            return;
        }
        if (!skipClose && Dialog.props)
        {
            const {onClose} = Dialog.props;
            if (typeof onClose === "function" && onClose(id) === false)
            {
                return;
            }
        }
        delete this.DialogList[id];
        this.Trigger("dialog-destroy", id);
    }

    /**
     * Update a dialog.
     * @param string id - The dialog id.
     * @param object dialog - Updated dialog object.
     * @return void
     */

    DialogUpdate = (id, dialog) =>
    {
        this.Trigger("dialog-update", id, this.DialogList[id] = dialog);
    }

    /**
     * Get the list of dialogs.
     * @return object - The dialogs list as {id: dialog}
     */

    Dialogs = () =>
    {
        return this.DialogList;
    }

    /**
     * Get all event listeners for an event.
     * @param string event - The event name
     * @return array - An array of listeners.
     */

    Get = (event) =>
    {
        if (this.Listeners[event] === undefined)
        {
            this.Listeners[event] = [];
        }
        return this.Listeners[event];
    }

    /**
     * Increase and return the value of a global variable.
     * @param string key - The value key.
     * @return mixed - The value.
     */

    Increase = (key) =>
    {
        let Value = this.Vars[key];
        if (Value === undefined)
        {
            return this.Vars[key] = 0;
        }
        if (typeof Value !== "number")
        {
            return Value;
        }
        return this.Vars[key] = Value + 1;
    }

    /**
     * Add an event listener
     * @param string event - The event name
     * @param function callback - The callback function
     * @return void
     */

    Listen = (event, callback) =>
    {
        if (typeof callback !== "function")
        {
            return;
        }
        const Listeners = this.Get(event);
        const Index = Listeners.indexOf(callback);
        if (Index < 0)
        {
            Listeners.push(callback);
        }
    }

    LoadFont = (name) =>
    {
        if (this.Fonts[name] !== undefined || this.FontSrc[name] === undefined)
        {
            return;
        }
        const {provider, src} = this.FontSrc[name];
        this.Fonts[name] = 0;
        switch (provider)
        {
            case "Google":
                this.LoadFontLibrary((webfont) =>
                {
                    clearTimeout(this.FontTimer);
                    this.FontLoad.push(this.FontSrc[name]);
                    this.FontTimer = setTimeout(() =>
                    {
                        webfont.load({
                            google:
                            {
                                families: this.FontLoad
                            }
                        });
                        this.FontLoad = [];
                    }, 100);
                });
                break;
            default:
                this.Fonts[name] = 1;
        }
    }

    LoadFontLibrary = (callback) =>
    {
        if (this.FontLibraryAdded && window.WebFont)
        {
            callback(window.WebFont);
        }
        else if (this.FontLibraryAdded)
        {
            this.FontLibraryCallbacks.push(callback);
        }
        else
        {
            this.FontLibraryAdded = true;
            this.FontLibraryCallbacks.push(callback);
            const Script = document.createElement("script");
            Script.src = this.FontLibrarySrc;
            Script.onerror = () =>
            {
                console.error("Unable to load WebFont.");
            };
            Script.onload = () =>
            {
                this.FontLibraryCallbacks.forEach(callback =>
                {
                    callback(window.WebFont);
                });
            };
            document.body.appendChild(Script);
        }
    }

    /**
     * Get the client locale.
     * @return string - The locale
     */

    LocaleGet = () =>
    {
        let Locale = this.Var("locale");
        if (Locale)
        {
            return Locale;
        }
        Locale = this.Storage("locale");
        if (typeof Locale === "string" && Locale.match(/^[a-z]{2}_[A-Z]{2}$/))
        {
            return this.Var("locale", Locale);
        }
        return this.Var("locale", BrowserLanguage() || this.Setting("Locale", "en_US"));
    }

    /**
     * Set the client locale.
     * @param string locale - The new locale.
     * @return void
     */

    LocaleSet = (locale) =>
    {
        this.Storage("locale", locale);
        this.Var("locale", locale);
    }

    /**
     * Callback when a font loads.
     * @return void
     */

    OnLoadFont = (e) =>
    {
        this.Trigger("font");
    }

    /**
     * Request the artice editor dialog.
     * @return void
     */

    OpenArticleEditor = () =>
    {
        Broadcast.SendMessage({
            type: "dialog",
            uri: "articles"
        });
    }

    OpenMessageDialog = (title = "", message = "") =>
    {
        Broadcast.SendMessage({
            params: {
                t: btoa(title),
                m: btoa(message)
            },
            type: "dialog",
            uri: "alert"
        });
    }

    /**
     * Remove an event listener
     * @param string event - The event name
     * @param function callback - The callback function
     * @return void
     */

    Remove = (event, callback) =>
    {
        const Listeners = this.Get(event);
        const Index = Listeners.indexOf(callback);
        if (Index < 0)
        {
            return;
        }
        Listeners.splice(Index, 1);
    }

    /**
     * Save or Load a value from the clients session storage.
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * @return mixed - The setting value.
     */
    
    Session = (key, value = null) =>
    {
        if (!this.StorageEnabled())
        {
            if (!this.StorageWarningSent)
            {
                console.warn("Storage is not available in this client. Falling back on global variables.");
                this.StorageWarningSent = true;
            }
            return this.Var(key, value);
        }
        if (value !== null)
        {
            sessionStorage.setItem(key, value);
        }
        else
        {
            value = sessionStorage.getItem(key);
        }
        switch (value)
        {
            case "false": return false;
            case "true": return true;
            case null: return undefined;
            default: return value;
        }
    }

    /**
     * Remove a value from the clients session storage.
     * @param string key - The value key.
     * @return void
     */
    
    SessionRemove = (key) =>
    {
        if (!this.StorageEnabled())
        {
            return undefined;
        }
        sessionStorage.removeItem(key);
    }

    /***
     * Get a setting value.
     * @param string name - The setting name.
     * @param string defaultValue - The default value when not set. 
     * @return mixed - The setting value.
     */
    
    Setting = (name, defaultValue) =>
    {
        return typeof this.Settings[name] !== "undefined" ? this.Settings[name] : defaultValue;
    }
    
    /**
     * Set backend server environent vars as global vars.
     * @param object vars - Environment vars.
     * @return void
     */

    SetVars = (vars) =>
    {
        if (!vars)
        {
            return;
        }
        for (let key in vars)
        {
            this.Var(key, vars[key]);
        }
    }
    
    /**
     * Save or Load a value from the clients local storage.
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * @return mixed - The setting value.
     */
    
    Storage = (key, value = null) =>
    {
        if (!this.StorageEnabled())
        {
            if (!this.StorageWarningSent)
            {
                console.warn("Storage is not available in this client. Falling back on global variables.");
                this.StorageWarningSent = true;
            }
            return this.Var(key, value);
        }
        if (value !== null)
        {
            localStorage.setItem(key, value);
        }
        else
        {
            value = localStorage.getItem(key);
        }
        switch (value)
        {
            case "false": return false;
            case "true": return true;
            case null: return undefined;
            default: return value;
        }
    }

    /**
     * Check if local/ession storage is available.
     * @return boolean - Whether local/session storage is available.
     */

    StorageEnabled = () =>
    {
        if (this.StorageIsEnabled !== -1)
        {
            return this.StorageIsEnabled;
        }
        try
        {
            localStorage.setItem("test", "test");
            localStorage.removeItem("test");
            sessionStorage.setItem("test", "test");
            sessionStorage.removeItem("test");
            return this.StorageIsEnabled = true;
        }
        catch (e)
        {
            return this.StorageIsEnabled = false;
        }
    }
    
    /**
     * Load a value from the clients local storage and parse it as JSON.
     * @param string key - The value key.
     * @param object defaultObject - Default object when missing/failed.
     * @return object - Parsed or default object.
     */

    StorageJson = (key, defaultObject) =>
    {
        const Raw = this.Storage(key);
        const Default = defaultObject !== undefined ? defaultObject : [];
        if (!Raw || typeof Raw !== "string")
        {
            return Default;
        }
        let Decoded;
        try
        {
            Decoded = JSON.parse(Raw);
        }
        catch (e)
        {
            return Default;
        }
        return typeof Decoded === "object" ? Decoded : Default;
    }

    /**
     * Remove a value from the clients local storage.
     * @param string key - The value key.
     * @return void
     */
    
    StorageRemove = (key) =>
    {
        if (!this.StorageEnabled())
        {
            return undefined;
        }
        localStorage.removeItem(key);
    }

    /**
     * Toggle a global variable between true/false.
     * @param string key - The value key.
     * @return mixed - The value.
     */

    Toggle = (key) =>
    {
        const Value = !this.Vars[key];
        return this.Var(key, Value);
    }

    /**
     * Trigger an event
     * @param string event - The event name
     * @param mixed data1 - Optional. Data to send to the event listeners.
     * @param mixed data2 - Optional. Data to send to the event listeners.
     * @param mixed data3 - Optional. Data to send to the event listeners.
     * @return void
     */

    Trigger = (event, data1, data2, data3) =>
    {
        const Listeners = this.Get(event);
        Listeners.forEach(callback =>
        {
            callback(data1, data2, data3);
        });
    }

    /**
     * Set or get the value of a global variable.
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * @param bool forceTrigger - Whether to force a event trigger.
     * @return mixed - The value.
     */

    Var = (key, value, forceTrigger = false) =>
    {
        if (!forceTrigger && (value === undefined || value === this.Vars[key]))
        {
            return this.Vars[key];
        }
        this.Trigger("var-" + key, value, this.Vars[key]);
        return this.Vars[key] = value;
    }

    /**
     * Broadcast active view.
     * @return void
     */

    ViewActive = (id, broadcast = true) =>
    {
        if (id !== this._ViewActive)
        {
            this.Trigger("active-view", this._ViewActive = id);
        }
        if (broadcast)
        {
            Broadcast.SendMessage({
                id: id,
                type: "active"
            });
        }
    }

    /**
     * Broadcast widget interaction to the editor.
     * @param string id - Unique id of the view.
     * @param string action - The expected action.
     * @return void
     */

    ViewAction = (id, action) =>
    {
        if (id !== this._ViewActive)
        {
            this.Trigger("active-view", this._ViewActive = id);
        }
        this.Trigger("action-view", this._ViewAction = action);
        Broadcast.SendMessage({
            id: id,
            type: "active"
        });
    }

    /**
     * Receive updates attributes for a view.
     * @param string id - Unique id of the view.
     * @param object attributes - Updated attributes.
     * @return void
     */

    ViewAttributes = (id, attributes) =>
    {
        this.Trigger(`attributes-${id}`, attributes);
    }

    /**
     * Replace content in a view.
     * @param string id - Unique id of the view.
     * @param object content - Optional new content.
     * @param object scope - Optional scope object.
     * @param boolean edit - Whether the current user can edit the new content.
     * @return void
     */

    ViewClear = (id, content, scope, edit = true) =>
    {
        this.Trigger(`clear-${id}`, content || {}, scope, edit);
    }

    /**
     * Clear all draft version of a view
     * @param string id - Unique id of the view.
     * @return void
     */

    ViewClearDrafts = (id) =>
    {
        this.Trigger(`clear-drafts-${id}`);
    }

    /**
     * Append a new draft version to a view.
     * @param string id - Unique id of the view.
     * @param object draft - Draft object.
     * @return void
     */

    ViewDraft = (id, draft) =>
    {
        this.Trigger(`draft-${id}`, draft);
    }

    /**
     * Callback when a view should be focused/blurred during hover.
     * @param string id - Unique id of the view.
     * @param boolean hover - Whether a view element is hovered.
     * @param boolean broadcast - Whether the event should be sent to other views.
     * @return void
     */

    ViewHover = (id, hover, broadcast = true) =>
    {
        this.Trigger(`hover-${id}`, hover);
        if (broadcast)
        {
            Broadcast.SendMessage({
                id: id,
                type: "hover",
                hover
            });
        }
    }

    /**
     * Receive initial (default) widgets from the current landing page.
     * @param string id - Unique id of the view.
     * @param array widgets - List of widhets as [[type, html] ...]
     * @return void
     */

    ViewInitial = (id, widgets) =>
    {
        this.Trigger(`initial-${id}`, widgets);
    }

    /**
     * Receive widget data from /manager-dashboard/.
     * @param string id - Unique id of the view.
     * @param object widgetData - gon.widgets from Fuse DOM.
     * @return void
     */

     
    ViewManagerWidgetsData = (id, widgetData) =>
    {
        this.Trigger(`manager-widgets-${id}`, widgetData);
    }

    /**
     * Callback when a view position is changed.
     * @param string id - Unique id of the view.
     * @param integer x - The X position.
     * @param integer y - The Y position.
     * @return void
     */

    ViewPosition = (id, x, y) =>
    {
        this.Trigger("position-view", id, [x, y]);
    }

    /**
     * Register a editable view (widget).
     * @param string id - Unique id of the view.
     * @param object view - View object.
     * @return void
     */

    ViewRegister = (id, view) =>
    {
        this.Views[id] = view;
        this.Trigger("register-view", id, view);
    }

    /**
     * Send a custom request to a widget.
     * @param string id - Unique id of the view.
     * @param object request - Request object.
     * @return void
     */

    ViewRequest = (id, request) =>
    {
        this.Trigger(`request-${id}`, request);
    }

    /**
     * Request a widget to be restored to its' last saved state.
     * @param string id - Unique id of the view.
     * @return void
     */

    ViewRestore = (id) =>
    {
        this.Trigger(`restore-${id}`);
    }

    /**
     * Request a widget to update its' saved state.
     * @param string id - Unique id of the view.
     * @param object draft - Draft version which was saved.
     * @param string contentId - New content ID if it's been updated.
     * @return void
     */

    ViewSave = (id, draft, contentId) =>
    {
        this.Trigger(`save-${id}`, draft, contentId);
    }

    /**
     * Unregister a editable view (widget).
     * @param string id - Unique id of the view.
     * @return void
     */

    ViewUnregister = (id) =>
    {
        if (this.Views[id] === undefined)
        {
            return;
        }
        delete this.Views[id];
        this.Trigger("unregister-view", id);
    }
}

export default new Globals();