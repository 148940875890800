/**!
 *  Alert dialog.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */
import React from "react";
import "./alert.scss";
import Broadcast from "Class/Broadcast";
import Globals from "Class/Globals";
import Button from "Components/UI/Button";
import Center from "Components/Layout/Center";
import IconButton from "Components/UI/IconButton";
import RenderContent from "Components/Layout/RenderContent";

class ViewAlert extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            message: "",
            title: ""
        };
    }

    componentDidMount()
    {
        Globals.Listen("var-params", this.UpdateParams);
        this.UpdateParams();
    }

    componentWillUnmount()
    {
        Globals.Remove("var-params", this.UpdateParams);
    }

    OnClose = () =>
    {
        Broadcast.SendMessage({
            type: "dialog-close",
            uri: "alert"
        });
    }

    UpdateParams = (newParams) =>
    {
        const {t: title = "", m: message = ""} = newParams || Globals.Var("params") || {};
        // window.top.focus to enable browser key commands...
        this.setState({message: atob(message), title: atob(title)}, () => window.top.focus());
    }

    render()
    {
        const {title, message} = this.state;
        return (
            <div className="ViewAlert">
                <Center
                    className="ViewAlertAlign"
                    padding={20}
                    width={400}
                >
                    <div className="ViewAlertContent">
                        <h2>{title}</h2>
                        <RenderContent className="ViewAlertContentText" content={message}/>
                        <IconButton
                            className="ViewAlertClose"
                            feather="X"
                            onClick={this.OnClose}
                        />
                        <Button
                            label="Close"
                            onClick={this.OnClose}
                        />
                    </div>
                </Center>
            </div>
        );
    }
}

export default ViewAlert;