/*!
 *  File upload interface.

 *  @prop array accept - Allowed mime types.
 *  @prop boolean disabled - Whether the interface should be disabled.
 *  @prop string endpoint - Endpoint (URI) when uploading to the backend server.
 *  @prop boolean multiple - Whether to allow multiple (simultaneous) uploads.
 *  @prop function onChange - Callback for when the uploaders state changes.
 *  @prop function onComplete - Callback for when a file upload is finished. Called regardless of success.
 *  @prop function onUpload - Callback for when a upload is initiated.
 *  @prop boolean show - Whether the interface should be visible.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./fileupload.scss";
import API from "Class/API";
import Globals from "Class/Globals";
import {RandomToken, Time} from "Functions";
import Button from "Components/UI/Button";
import Error from "Components/Feedback/Error";
import Icon from "Components/Layout/Icon";
import Progress from "Components/Feedback/Progress";

class FileUpload extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Completed = [];
        this.DevMode = Globals.Setting("DevMode");
        this.Field = false;
        this.Mounted = false;
        this.Uploads = {};
        this.state = {
            done: false,
            error: "",
            over: false,
            uploading: false
        };
    }

    /**
     *  Keep track on whether the component is mounted.
     *  @return void.
     */

    componentDidMount()
    {
        this.Mounted = true;
    }

    /**
     *  Register unmount.
     *  @return void.
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     *  Cancel all active upload processes.
     *  @return void.
     */

    OnAbort = () =>
    {
        for (let id in this.Uploads)
        {
            let Upload = this.Uploads[id];
            if (Upload.done)
            {
                continue;
            }
            Upload.xhr.abort();
        }
        if (!this.Mounted)
        {
            return;
        }
        this.Completed = [];
        this.Uploads = {};
        this.setState({
            done: false,
            error: "",
            uploading: false
        });
    }

    /**
     *  Callback when the file field value changes.
     *  @return void.
     */

    OnChange = (e) =>
    {
        if (!this.Mounted || this.props.disabled)
        {
            return;
        }
        const Files = e.currentTarget.files;
        this.Upload(Files);
    }

    /**
     *  Delegate clicks to the file field.
     *  @return void.
     */

    OnClick = () =>
    {
        if (!this.Field || this.props.disabled)
        {
            return;
        }
        this.Field.click();
    };

    /**
     *  Callback when files are dropped onto the uploader.
     *  @param object e - The drop event.
     *  @return void.
     */

    OnDrop = (e) =>
    {
        if (!this.Mounted || this.props.disabled)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        this.setState({over: false});
        this.Upload(e.dataTransfer.files);
        return false;
    }

    /**
     *  Callback when files are dragged over the uploader.
     *  @param object e - The drag event.
     *  @return void.
     */

    OnOver = (e) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const {disabled} = this.props;
        const {over, uploading} = this.state; 
        if (disabled || over || uploading)
        {
            return false;
        }
        this.setState({over: true});
        return false;
    }

    /**
     *  Callback when files are dragged outside of the uploader.
     *  @param object e - The drag event.
     *  @return void.
     */

    OnOut = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        if (!this.Mounted || e.target !== e.currentTarget)
        {
            return false;
        }
        this.setState({over: false});
        return false;
    }

    /**
     *  Read data from the file field.
     *  @param object file - File info.
     *  @param function callback - Callback when finished.
     *  @return void.
     */

    ReadFile = (file, callback) =>
    {
        const Reader = new FileReader();
        Reader.onload = (e) =>
        {
            callback(e.target.result);
        }
        Reader.onerror = () =>
        {
            if (!this.Mounted)
            {
                return;
            }
            this.setState({error: "Couldn't read file."});
            callback(false);
        }
        Reader.readAsDataURL(file);
    };

    /**
     *  Upload files!
     *  @param object files - File data object.
     *  @return void.
     */

    Upload = (files) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        const {
            accept,
            appendData,
            endpoint,
            maxSize,
            multiple,
            onChange,
            onComplete,
            onDone,
            onError,
            onUpload
        } = this.props;
        const {uploading} = this.state;
        const [Context, ContextId] = Globals.Var("context") || [];
        const Community = Context === "community" ? ContextId : 0;
        // One upload at a time.
        if (uploading)
        {
            return;
        }
        this.setState({error: ""});
        this.Completed = [];
        const Upload = [];
        const MaxSize = Globals.Var("max_upload_size");
        const MaxSizeStr = Globals.Var("max_upload_size_str");
        for (var i = 0; i < files.length; i++)
        {
            let File = files.item(i);
            // Check for unallowed file extension.
            if (accept.indexOf(File.type) < 0)
            {
                this.setState({error: `Unallowed file type: ${File.type}.`});
                continue;
            }
            // Check for unallowed file size.
            if (maxSize && File.size > MaxSize)
            {
                this.setState({error: `The selected file is larger than ${MaxSizeStr}.`});
                continue;
            }
            Upload.push(files[i]);
            // Only allow one upload when multiple is 'false'.
            if (!multiple)
            {
                break;
            }  
        }
        if (!Upload.length)
        {
            return;
        }
        onError(false);
        this.setState({
            uploading: true,
            done: false
        });
        let NumUploaded = 0;
        // Read and upload each file as separate XHR requests.
        Upload.forEach((file, index) =>
        {
            this.ReadFile(file, (base64) =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                const Id = RandomToken();
                const Now = Time();
                const FileName = file.name.replace(/\.[a-z]*$/i, "");
                this.Uploads[Id] = {
                    id: Id,
                    file: file,
                    filename: FileName,
                    modified: Now,
                    loaded: 0,
                    total: 0,
                    done: false,
                    complete: false,
                    error: false,
                    upload: false
                };
                const Data = {
                    community: Community,
                    filename: file.name,
                    filetoken: Id,
                    type: file.type,
                    data: base64
                };
                for (let key in appendData)
                {
                    Data[key] = appendData[key];
                }
                Globals.Trigger("upload-start", Id, {
                    file: file,
                    filename: FileName,
                    index,
                    modified: Now,
                    preview: base64
                });
                this.Uploads[Id].xhr = API.Request(endpoint, Data,
                    // Callback when a file has finished uploading.
                    (response) =>
                    {
                        if (!this.Mounted)
                        {
                            return;
                        }
                        const {file, error} = response;
                        NumUploaded++;
                        this.Uploads[Id].done = true;
                        this.Uploads[Id].upload = file;
                        if (error)
                        {
                            const ErrorMessage = this.DevMode ? error : "Something went wrong.";
                            this.Uploads[Id].error = error;
                            this.setState({error: ErrorMessage});
                            Globals.Trigger("upload-error", Id, {message: ErrorMessage});
                            onChange(this.Uploads);
                            onError(ErrorMessage);
                        }
                        else
                        {
                            Globals.Trigger("upload-done", Id, {file});
                        }
                        this.Completed.push(file);
                        // Check if all files have been uploaded.
                        if (NumUploaded < Upload.length)
                        {
                            onComplete(response);
                            return;
                        }
                        // All done!
                        for (var i in this.Uploads)
                        {
                            this.Uploads[i].complete = true;
                        }
                        this.setState({uploading: false});
                        onComplete(response);
                        onChange(this.Uploads);
                        onDone(this.Completed);
                    },
                    // Track upload progress trough this callback.
                    (loaded, total) =>
                    {
                        if (!this.Mounted || typeof this.Uploads[Id] !== "object")
                        {
                            return;
                        }
                        this.Uploads[Id].loaded = loaded;
                        this.Uploads[Id].total = total;
                        this.forceUpdate();
                        Globals.Trigger("upload-progress", Id, {
                            loaded,
                            total
                        });
                        onChange(this.Uploads);
                    }
                );
                this.forceUpdate();
                onChange(this.Uploads);
            });
        });
        onUpload();
    }

    render()
    {
        const {accept, disabled, multiple, show} = this.props;
        const {done, error, over, uploading} = this.state;
        if (!show)
        {
            return "";
        }
        const CA = ["FileUploadContainer"];
        if (disabled) CA.push("Disabled");
        if (done) CA.push("Done");
        if (over) CA.push("Over");
        if (uploading) CA.push("Uploading");
        let ProgressView = "";
        let NumUploading = 0;
        for (let i in this.Uploads)
        {
            if (!this.Uploads[i].complete)
            {
                NumUploading++;
            }
        }
        if (uploading && NumUploading)
        {
            const First = this.Uploads[Object.keys(this.Uploads)[0]].filename;
            const Info = NumUploading !== 1 ? `Uploading ${NumUploading} files...` : `Uploading ${First}...`;
            let Loaded = 0;
            let Total = 0;
            for (let i in this.Uploads)
            {
                let {complete, loaded, total} = this.Uploads[i];
                if (complete)
                {
                    continue;
                }
                Loaded += loaded;
                Total += total;
            }
            const Percent = Total ? Loaded / Total : 0;
            ProgressView = (
                <div className="FileUploadProgressOverlay">
                    <div className="FileUploadProgress">
                        <div className="FileUploadProgressInfo">{Info}</div>
                        <Progress progress={Percent} />
                        <div className="FileUploadAbort" onClick={this.OnAbort}>Cancel</div>
                    </div>
                </div>
            );
        }
        const Accept = accept.join(", ");
        const Instruction = multiple ? "Drop a files here to upload" : "Drop file here to upload";
        const Label = multiple ? "Select Files" : "Select File";
        const MaxSize = Globals.Var("max_upload_size_str");
        return (
            <div
                className={CA.join(" ")}
                onDragOver={this.OnOver}
                onDragLeave={this.OnOut}
                onDrop={this.OnDrop}
            >
                {ProgressView}
                <div className="FileUploadOverlay">
                    <Icon size={64} feather="UploadCloud" />
                </div>
                <div className="FileUpload">
                    <input
                        type="file"
                        ref={field => this.Field = field}
                        accept={Accept}
                        onChange={this.OnChange}
                        multiple={multiple}   
                    />
                    <div className="FileUploadInstruction">
                        {Instruction}
                    </div>
                    <div className="FileUploadOr">or</div>
                    <Button className="FileUploadButton" onClick={this.OnClick}>{Label}</Button>
                    <div className="FileUploadDescription">
                        {`Maximum upload file size is ${MaxSize}`}
                    </div>
                    <Error label={error} />
                </div>
            </div>
        );
    }
}

FileUpload.propTypes =
{
    accept: PropTypes.array,
    appendData: PropTypes.object,
    disabled: PropTypes.bool,
    endpoint: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onComplete: PropTypes.func,
    onDone: PropTypes.func,
    onError: PropTypes.func,
    onUpload: PropTypes.func,
    show: PropTypes.bool,
};

FileUpload.defaultProps =
{
    accept: ["image/png", "image/jpeg", "image/gif"],
    appendData: {},
    disabled: false,
    endpoint: "files/upload",
    multiple: true,
    onChange: () => {},
    onClose: () => {},
    onComplete: () => {},
    onDone: () => {},
    onError: () => {},
    onUpload: () => {},
    show: true
}

export default FileUpload;