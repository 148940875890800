/*!
 *  Lists manager widgets present in /manager-dashboard/.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string|number value - Field value.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import Broadcast from "Class/Broadcast";
import {ObjectCompare} from "Functions";
import Globals from "Class/Globals";
import SelectField from "Components/UI/Field/SelectField";  

class ManagerWidgetsField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            grid: 0,
            value: 0,
            widgets: {0: "Select widget"}
        };
    }

    /**
     * Add listeners and request widgets on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        Globals.Listen("manager-widgets-field", this.OnWidgets);
        this.RequestWidgets();
        this.SetValue(value);
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        if (!ObjectCompare(v1, v2))
        {
            this.SetValue(v1);
        }
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        Globals.Remove("manager-widgets-field", this.OnWidgets);
    }

    /**
     * Callback for when the field value changes.
     * @param object e - The event object.
     * @param integer value - The new value.
     * @return void
     */

    OnChange = (e, value) =>
    {
        const {id, onChange} = this.props;
        this.setState({value});
        onChange(e, [this.state.grid, value], id);
    }

    /**
     * Callback when widget data is received.
     * @param object data - Widget grid data (gon.widgets)
     * @return void
     */

    OnWidgets = (data) =>
    {
        const {containers, grid_id} = data;
        if (!containers || !grid_id)
        {
            return;
        }
        const {filter} = this.props;
        const Widgets = {0: "Select widget"};
        containers.forEach(container =>
        {
            const {default_name, id, name, widget_type} = container;
            if (filter && filter.indexOf(widget_type) < 0)
            {
                return;
            }
            const Name = name || default_name;
            const Type = widget_type.replace(/(^|_)([a-z])/g, (a, b, c) =>
            {
                return (b === "_" ? " " : "") + c.toUpperCase();
            });
            Widgets[id] = <div>{Name} <i>({Type})</i></div>;
        });
        this.setState({grid: grid_id, widgets: Widgets});
    }

    /**
     * Request a scrape of /manager-widgets/.
     * @return void
     */

    RequestWidgets = () =>
    {
        Broadcast.SendMessage({
            id: "field",
            type: "manager-widgets"
        });
    }

    SetValue = (value) =>
    {
        const [Grid, Value] = value;
        this.setState({grid: Grid, value: Value});
    }

    render()
    {
        const {className, id} = this.props;
        const {value, widgets} = this.state;
        const CA = ["ManagerWidgetsField"];
        if (className)
        {
            CA.push(className);
        }
        return (
            <SelectField
                {...this.props}
                className={CA.join(" ")}
                id={id}
                onChange={this.OnChange}
                options={widgets}
                value={value}
            />
        );
    }
}

ManagerWidgetsField.propTypes =
{
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
};

ManagerWidgetsField.defaultProps =
{
    className: "",
    filter: false,
    id: "",
    onChange: () => {},
    value: false
};

export default ManagerWidgetsField;