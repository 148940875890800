/**!
 *  Display assigned learning.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./assignedlearning.scss";
import Auth from "Class/Auth";
import Fuse from "Class/Fuse";
import {ArrayClone, RandomToken} from "Functions";
import FilterField from "Components/UI/Field/FilterField";
import IconItem from "Components/UI/IconItem";
import Link from "Components/UI/Link";
import Preview from "Components/Layout/Preview";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetAssignedLearning extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Assigned learning",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            showFilter:
            {
                default: true,
                label: "Show content filter",
                type: "checkbox"
            },
            externalLink:
            {
                label: "Open contents in a new tab",
                type: "checkbox"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.LoadToken = false;
        this.Mounted = false;
        this.Name = "Assigned Learning";
        this.Status =
        {
            all: "All",
            not_completed: "Not Completed",
            missed: "Missed",
            completed: "Completed"
        };
        this.Types =
        {
            all: "All",
            learning_plan: "Learning Plans",
            content: "Contents",
            topic: "Topics"
        };
        this.state =
        {
            content: [],
            done: false,
            error: false,
            loading: false,
            page: 0,
            status: -1,
            type: -1
        };
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.Load();
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Output a content item.
     * @param object content - Content object.
     * @param integer index - Item index in list.
     * @return JSX - The content item.
     */

    Item = (content, index) =>
    {
        const {externalLink} = this.Content();
        const {
            assigner_name,
            course_id: id,
            course_image_url: preview_path,
            course_title: title,
            course_type: type,
            status
        } = content;
        const Url = Fuse.ContentUrl(id, type);
        const PreviewUrl = preview_path ? Fuse.Url(preview_path.substr(1), false) : "";
        const Status = this.Status[status] !== undefined ? `${this.Status[status]} — ` : "";
        const StatusStr = `${Status}Assigned by ${assigner_name}`;
        return (
            <Link
                className="WidgetAssignedLearningItem"
                key={`${index}.${id}`}
                href={Url}
                target={externalLink ? "_blank" : "_top"}
                title={`${title}. ${StatusStr}`}
            >
                <Preview
                    className="ItemPreview"
                    content={type}
                    image={PreviewUrl}
                />
                <div className="ItemContent">
                    <div className="ItemName">{title}</div>
                    <div className="ItemInfo">{StatusStr}</div>
                </div>
            </Link>
        )
    }

    /**
     * Load Favourites content from the Fuse API.
     * @return void
     */

    Load = () =>
    {
        const {content, page, status, type} = this.state;
        const {UserId} = Auth;
        const Page = page || 1;
        if (!UserId)
        {
            return;
        }
        const Token = this.LoadToken = RandomToken();
        const Payload = {
            page: Page,
            swagger_request: "true"
        };
        switch (status)
        {
            case "completed":
            case "missed":
            case "not_completed":
                Payload.status = status;
                break;
            default:
        }
        switch (type)
        {
            case "content":
            case "learning_plan":
            case "topic":
                Payload.assigned_type = type;
                break;
            default:
        }
        this.setState({error: false, loading: true});
        Fuse.Request(`/learning_assignments/my`, Payload, response =>
        {
            if (!this.Mounted || Token !== this.LoadToken)
            {
                return;
            }
            const {last_page, user_learning_assignments: items} = response || {};
            if (!items)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                const Content = ArrayClone(content).concat(items);
                this.setState({
                    done: last_page,
                    content: Content,
                    loading: false,
                    page: Page + 1
                });
            }
        });
    }

    /**
     * Change content filter.
     * @param object e - The event object.
     * @param string filter - Content type.
     * @return void
     */

    SetFilter = (e, filter) =>
    {
        const {status: s1, type: t1} = filter;
        const {status: s2, type: t2} = this.state;
        if (s1 === s2 && t1 === t2)
        {
            return;
        }
        this.setState({
            content: [],
            page: 0,
            status: s1,
            type: t1
        }, this.Load);
    }

    SetFilterLabel = () =>
    {
        const {status, type} = this.state;
        let Status, Type;
        switch (status)
        {
            case "completed":
                Status = "Completed";
                break;
            case "missed":
                Status = "Missed";
                break;
            case "not_completed":
                Status = "Uncompleted";
                break;
            default:
                Status = "All"
        }
        switch (type)
        {
            case "content":
                Type = "content";
                break;
            case "learning_plan":
                Type = "learning plans";
                break;
            case "topic":
                Type = "topics";
                break;
            default:
                Type = "learning";
        }
        return `${Status} ${Type}`;
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props;
        const {content, filter, done, loading, status, type} = this.state;
        const {title, showFilter} = WidgetContent;
        const CA = ["Widget", "WidgetAssignedLearning", "BorderRadius"];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover"); 
        }
        const Content = [];
        const Items = [];
        if (content && content.length)
        {
            content.forEach((c, i) =>
            {
                Items.push(this.Item(c, i));
            });
        }
        if (!Items.length && loading)
        {
            Content.push(<Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (!Items.length)
        {
            Content.push(<div
                className="WidgetEmpty"
                key="empty"
            >No learning</div>);
        }
        else
        {
            Content.push(<div
                className="WidgetAssignedLearningItems"
                key="items"
            >{Items}</div>);
            Content.push(<IconItem
                className="WidgetAssignedLearningButton"
                disabled={done}
                feather="PlusCircle"
                key="fetch"
                label="Show more"
                loading={loading}
                onClick={this.Load}
            />);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className=" WidgetAssignedLearningWrapper"
                    title={title}
                    toolbar={showFilter ? <FilterField
                        className=" WidgetAssignedLearningFilter"
                        fields={{
                        type:
                        {
                            label: "Type",
                            options: this.Types,
                            type: "choice"
                        },
                        status: {
                            label: "Status",
                            options: this.Status,
                            type: "choice"
                        }
                        }}
                        onChange={this.SetFilter}
                        selectedLabel={this.SetFilterLabel}
                        values={{status, type}}
                    /> : ""} 
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default  WidgetAssignedLearning;