/**!
 *  Carousel widget with link slides.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

 import React from "react";
 import Widget from "../../widget.js";
 import "./carousel.scss";
 import Fuse from "Class/Fuse";
 import Globals from "Class/Globals";
 import Parser from "Class/Parser";
 import {RandomToken} from "Functions";
 import ContentListItem from "Components/UI/ContentListItem";
 import Item from "Components/UI/Item";
 import Link from "Components/UI/Link";
 import LoadImage from "Components/Layout/LoadImage";
 
 class WidgetCarousel extends Widget
 {
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            slides:
            {
                addLabel: "Add slide",
                label: "Slides",
                type: "repeater",
                nameKey: "title",
                fields:
                {
                    title:
                    { 
                        insert: true,
                        label: "Title",
                        type: "text"
                    },
                    text:
                    {
                        insert: true,
                        label: "Text",
                        type: "textarea"
                    },
                    image:
                    {
                        label: "Background Image",
                        type: "image"
                    },
                    externalLink:
                    {
                        label: "External link",
                        type: "checkbox"
                    },
                    content:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Content",
                        type: "content"
                    },
                    url:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "URL",
                        type: "url",
                        default: "https://"

                    },
                    urlBlank:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: true
                    },
                    urlBlank2:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: false
                    },
                    backgroundOverlay:
                    {
                        displayIf: ["image", "!==", "empty"],
                        label: "Background Overlay",
                        type: "checkbox",
                        default: true
                    },
                    grayscale:
                    {
                        displayIf: ["image", "!==", "empty"],
                        label: "Grayscale",
                        type: "checkbox",
                        default: false
                    }
                }
            },
            autoplay:
            {
                label: "Autoplay",
                type: "checkbox",
                default: true
            },
            interval:
            {
                displayIf: ["autoplay", "==", true],
                label: "Switch interval (seconds)",
                minValue: 0,
                type: "number",
                default: 7
            },
            links:
            {
                addLabel: "Add link",
                onLabel: ["content", "content", 0],
                label: "Links",
                type: "repeater",
                nameKey: "label",
                fields:
                {
                    externalLink:
                    {
                        label: "External link",
                        type: "checkbox"
                    },
                    content:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Content",
                        type: "content"
                    },
                    url:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "URL",
                        type: "url",
                        default: "https://"

                    },
                    urlBlank:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: true
                    },
                    urlBlank2:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: false
                    },
                    label:
                    {
                        label: "Label"
                    }
               }
            },
            makeCta:
            {
                label: "Make the first link a Call to Action",
                type: "checkbox",
                default: false
            }
        });
        // Append link color...
        this.Fields.linkColor =
        {
            label: "Link Color",
            type: "color",
            default: "black"
        };
        this.Name = "Carousel";
        this.Timeout = false;
        this.state = {
            edit: false,
            index: 0
        };
    }

    componentDidMount()
    {
        const {content, contentId} = this.props;
        const {autoplay, interval} = content;
        Globals.Listen(`request-${contentId}`, this.OnRequest);
        if (autoplay)
        {
            this.Reset(interval);
        }
    }

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {autoplay: a1, contentId: i1, interval: t1} = c1 || {};
        const {autoplay: a2, contentId: i2, interval: t2} = c2 || {};
        if (a1 && (a1 !== a2 || t1 !== t2))
        {
            this.Reset(t1);
        }
        else if (!a1 && a2)
        {
            this.Stop();
        }
        if (i1 !== i2)
        {
            Globals.Remove(`request-${i2}`, this.OnRequest);
            Globals.Listen(`request-${i1}`, this.OnRequest);
        }
    }

    componentWillUnmount()
    {
        const {contentId} = this.props;
        Globals.Remove(`request-${contentId}`, this.OnRequest);
        this.Stop();
    }

    Link = (link = {}, className, icon = "") =>
    {
        // Assign a unique id/key to each link to avoid re-render.            
        if (!link.id)
        {
            link.id = RandomToken();
        }
        return (
            <ContentListItem
                className={className}
                content={link}
                icon={icon}
                key={link.id}
                noStyle={true}
                showInfo={false}
                showPreview={false}
            />
        );
    }

    OnIndex = (e, index) =>
    {
        this.setState({index});
    }

    OnMouseEnter = () =>
    {
        this.Stop();
    }

    OnMouseLeave = () =>
    {
        const {autoplay, interval} = this.props.content;
        if (autoplay)
        {
            this.Reset(interval);
        }
    }

    OnRequest = (request) =>
    {
        const {interval} = this.props.content;
        const {id, index, open, type} = request;
        if (id !== "slides" || type !== "repeaterItem")
        {
            return;
        }
        if (open)
        {
            this.Stop();
            this.setState({edit: true, index});
        }
        else
        {
            this.setState({edit: false}, () => this.Reset(interval));
        }
    }

    Reset = (seconds) =>
    {
        if (this.state.edit || seconds < 1)
        {
            return;
        }
        this.Stop();
        const Ms = parseFloat(seconds) * 1000;
        this.Timeout = setTimeout(this.Switch, Ms);
    }

    Slide = (slide, index) =>
    {
        const {imageKey} = this.props;
        const {
            backgroundOverlay,
            content,
            externalLink,
            grayscale,
            id,
            image,
            text,
            title,
            url,
            urlBlank,
            urlBlank2
        } = slide;
        const CA = ["WidgetCarouselSlide", "BorderRadius"];
        if (image)
        {
            CA.push("HasBackground");
        }
        if (backgroundOverlay)
        {
            CA.push("HasBackgroundOverlay");
        }
        if (grayscale)
        {
            CA.push("Grayscale");
        }
        if (index === this.state.index)
        {
            CA.push("Active");
        }
        const Target = (externalLink ? urlBlank : urlBlank2) ? "_blank" : "_top";
        const Url = externalLink ? url : (content && content.length ? Fuse.ContentUrl(...content[0]) : "");
        return (
            <Link
                className={CA.join(" ")}
                href={Url}
                key={id}
                label=""
                target={Target}
            >
                {image ? <LoadImage className="WidgetCarouselSlideBackground" imageKey={imageKey} srcId={parseInt(image[0], 10)}/> : ""}
                <div className="WidgetCarouselSlideContent">
                    <h2>{title}</h2>
                    {Parser.ParseParagraphs(text)}
                </div>
            </Link>   
        );
    }

    Stop = () =>
    {
        clearTimeout(this.Timeout);
    }

    Switch = () =>
    {
        const {index} = this.state;
        const {interval, slides} = this.props.content;
        const Index = index < slides.length - 1 ? index + 1 : 0;
        this.setState({index: Index});
        this.Reset(interval);
    }
 
    render()
    {
        const {active, hover} = this.props;
        const {
            links = [],
            linkColor = "#ffffff",
            makeCta = false,
            slides = []
        } = this.Content();
        const CA = ["Widget", "WidgetCarousel", "White", "BorderRadius"];
        const HasLinks = links.length > 0;
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (HasLinks)
        {
            CA.push("HasTopBar");
        }
        const FirstLink = makeCta ? links.shift() || {} : {};
        const Links = [];
        const Navigation = [];
        const Slides = [];
        slides.forEach((slide, index)  =>
        {
            // Assign a unique id/key to each slide to avoid re-render.            
            if (!slide.id)
            {
                slide.id = RandomToken();
            }
            Navigation.push(
                <Item
                    className={index === this.state.index ? "WidgetCarouselDot Active" : "WidgetCarouselDot"}
                    id={index}
                    key={index}
                    onClick={this.OnIndex}
                />
            );
            Slides.push(this.Slide(slide, index));
        });
        links.forEach((link, index) =>
        {
            Links.push(this.Link(link, "WidgetCarouselTopBarLink Heading"));
        });
        return (
            <div
                className={CA.join(" ")}
                onMouseEnter={this.OnMouseEnter}
                onMouseLeave={this.OnMouseLeave}
                ref={widget => this.RefWidget = widget}
                style={this.Style()}
            >
                {this.Toolbar()}
                <div className="WidgetCarouselContent">
                    {HasLinks ? (
                        <div className="WidgetCarouselTopBar" style={{color: linkColor}}>
                            <div className="WidgetCarouselTopBarLinks">{Links}</div>
                            {FirstLink ? this.Link(FirstLink, "WidgetCarouselTopBarCta Heading ItemActive", FirstLink.externalLink ? "ExternalLink" : "ArrowRight") : ""}
                        </div>
                    ) : ""}
                    <div className="WidgetCarouselSlides">
                        {Slides}
                    </div>
                    <div className="WidgetCarouselNavigation">
                        {Navigation}
                    </div>
                </div>
            </div>
        );
    }
}
 
 export default WidgetCarousel;