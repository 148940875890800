/*!
 *  Fuse content button
 *  @prop string className - Append a class name.
 *  @prop object content - Content object.
 *  @prop string|object text - Item text visible on hover.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./contentbutton.scss";
import API from "Class/API";
import Fuse from "Class/Fuse";
import {ObjectCompare} from "Functions";
import Button from "Components/UI/Button";

class ContentButton extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Mounted = false;
        this.state =
        {
            content: false,
            contentObject: false,
            error: false,
            loading: false
        };
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {content, contentObject} = this.props;
        const {content: fuseContent} = content;
        if (contentObject)
        {
            this.setState({
                content: contentObject.id,
                contentObject
            });
        }
        else
        {
            this.Load(fuseContent);
        }
    }

    /**
     * Update content when a new ID is received.
     * @return void
     */

    componentDidUpdate()
    {
        const {content: c1, contentObject: o1} = this.props;
        const {content: c2, contentObject: o2} = this.state;
        const {content} = c1;
        const NewObject = !ObjectCompare(o1, o2);
        let Content = content;
        while (Content && typeof Content === "object")
        {
            Content = Content[0];
        }
        if (o1 && NewObject)
        {
            this.setState({
                content: o1.id,
                contentObject: o1
            });
        }
        else if (!o1 && !ObjectCompare(Content, c2))
        {
            this.Load(content);
        }
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Load content from the Fuse API.
     * @param array|integer item - [ contentId, type ] or just contentId.
     * @return void
     */

    Load = (item) =>
    {
        if (!item)
        {
            this.setState({
                content: 0,
                error: false,
                loading: false
            });
        }
        else if (typeof item === "object" && typeof item[0] === "object")
        {
            this.Load(item[0]);
        }
        else if (typeof item === "object")
        {
            this.setState({
                content: item[0],
                error: false,
                loading: true
            });
            this.LoadContent(item[0], item[1]);
        }
        else
        {
            this.setState({
                content: item,
                error: false,
                loading: true
            });
            /**
             * Check content type in the backend DB before fetching.
             */
            API.Request("content/cache-read", {id: item}, response =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                const {cache, error} = response;
                if (error)
                {
                    this.setState({
                        error: true,
                        loading: false
                    });
                }
                else
                {
                    this.LoadContent(item, cache.type);
                }
            });
        }
    }

    /**
     * Load content data from fuse.
     * 
     * @param integer id - The content id.
     * @param string type - The content type.
     * 
     * @return void
     */

    LoadContent = (id, type) =>
    {
        Fuse.Content(id, type, content =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {onLoad} = this.props;
            if (!content)
            {
                onLoad(false, id);
                this.setState({
                    error: true,
                    loading: false
                });
            }
            else
            {
                onLoad(content, id);
                this.setState({
                    loading: false,
                    contentObject: content
                });
            }
        });
    }

    render()
    {
        const {className, content} = this.props;
        const {content: contentId, contentObject, loading} = this.state;
        const {externalLink, label, url, urlBlank, urlBlank2} = content;
        const CA = ["ContentButton"];
        if (className)
        {
            CA.push(className);
        }
        let Label, Url;
        let Target = urlBlank2 ? "_blank" : "_top";
        if (loading)
        {
            Label = label;
        }
        else if (contentObject)
        {
            const {id, name, type, url: altUrl} = contentObject;
            Label = label || name;
            Url = altUrl || Fuse.ContentUrl(id, type);
        }
        else if (!contentId)
        {
            Label = label;
            Target = (externalLink && urlBlank) ? "_blank" : "_top";
            Url = url;
        }
        return (
            <Button
                className={CA.join(" ")}
                href={Url}
                label={Label || "Link"}
                loading={loading}
                target={Target}
            />
        );
    }
}

ContentButton.propTypes =
{
    className: PropTypes.string,
    content: PropTypes.object,
    contentObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onLoad: PropTypes.func
};

ContentButton.defaultProps =
{
    className: "",
    content: {},
    contentObject: false,
    onLoad: () => {}
};

export default ContentButton;