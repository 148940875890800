/**!
 *  User learning plans widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./learningplans.scss";
import Fuse from "Class/Fuse";
import {ArrayClone, DateMonth, RandomToken, DateParse, ObjectCompare} from "Functions";
import ContentItem from "Components/UI/ContentItem";
import FilterField from "Components/UI/Field/FilterField";
import Progress from "Components/Feedback/Progress";
//import SelectField from "Components/UI/Field/SelectField";
import Slider from "Components/UI/Slider";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetLearningPlans extends Widget
{
    constructor(props)
    {
        super(props);
        this.Adjusting = false;
        this.DefaultFilter = "upcoming";
        this.Filters =
        {
            in_progress: "In progress",
            not_started: "Not started",
            completed: "Completed",
            //with_deadline: "With deadline"
        };
        this.FiltersDeadline = {0: "With deadline", 1: "Without deadline"};
        this.Fields = this.SetFields({
            title:
            {
                default: "Learning Plans",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            manualSelect:
            {
                label: "Select Manually",
                type: "checkbox"
            },
            externalLink:
            {
                label: "Open learning plans in a new tab",
                type: "checkbox"
            },
            selected:
            {
                displayIf: ["manualSelect", "==", true],
                label: "Selected Learning Plans",
                multiple: true,
                placeholder: "Search for learning plans...",
                type: "content",
                types: ["learningplan"]
            },
            community:
            {
                displayIf: ["manualSelect", "!=", true],
                label: "Filter from Community",
                placeholder: "Search for community...",
                type: "content",
                types: ["community"]
            },
            showFilter:
            {
                displayIf: ["manualSelect", "!=", true],
                default: true,
                label: "Show filter",
                type: "checkbox"
            },
            initialFilter:
            {
                displayIf: ["manualSelect", "!=", true],
                default: this.DefaultFilter,
                label: "Initial filter",
                options: this.Filters,
                placeholder: "All Learningplans",
                type: "select"
            },
            initialFilterDeadline:
            {
                displayIf: ["manualSelect", "!=", true],
                label: "Deadline",
                options: this.FiltersDeadline,
                placeholder: "With or without deadline",
                type: "select"
            },
            orderField:
            {
                displayIf: ["manualSelect", "!=", true],
                default: "id",
                label: "Order field",
                options:
                {
                    id: "ID",
                    created_at: "Created at",
                    title: "Title"
                },
                type: "select"
            },
            orderDirection:
            {
                displayIf: ["manualSelect", "!=", true],
                default: "asc",
                label: "Order direction",
                options:
                {
                    asc: "Ascending",
                    desc: "Descending"
                },
                type: "select"
            },
            lpGrayscale:
            {
                label: "Make Learning Plans grayscale",
                type: "checkbox",
                default: false
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.LoadToken = false;
        this.Mounted = false;
        this.Name = "Learning plans";
        this.state =
        {
            community: 0,
            deadline: -1,
            done: false,
            error: false,
            filter: -1,
            learningPlans: [],
            loaded: {},
            loading: false,
            orderField: "id",
            orderDirection: "asc",
            page: 0,
            width: 324
        };
    }

    componentDidMount()
    {
        const {content} = this.props;
        const {
            community: c,
            initialFilter,
            initialFilterDeadline,
            manualSelect,
            orderField,
            orderDirection
        } = content || {};
        this.Mounted = true;
        if (!manualSelect)
        {
            const Community = (c && c.length) ? c[0][0] : 0;
            this.Load(initialFilter, initialFilterDeadline, Community, orderField, orderDirection);
        }
        this.Adjust();
        window.addEventListener("resize", this.Adjust);
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.Adjust);
        this.Mounted = false;
    }

    /**
     * Update community filter.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {deadline, filter, community} = this.state;
        const {
            community: c,
            initialFilter: ff1,
            initialFilterDeadline: fd1,
            manualSelect: m1,
            orderField: f1,
            orderDirection: d1
        } = c1 || {};
        const {
            initialFilter: ff2,
            initialFilterDeadline: fd2,
            manualSelect: m2,
            orderField: f2,
            orderDirection: d2
        } = c2 || {};
        const Community = (c && c.length) ? c[0][0] : 0;
        if (!m1 && (m1 !== m2 || Community !== community || f1 !== f2 || d1 !== d2))
        {
            this.Load(filter, deadline, Community, f1, d1);
        }
        if (ff1 !== ff2 || fd1 !== fd2)
        {
            this.setState({deadline: parseInt(fd1, 10), filter: ff1});
        }
        if (!this.Adjusting)
        {
            this.Adjust();
        }
    }

    Adjust = () =>
    {
        if (!this.RefWidget)
        {
            return;
        }
        this.Adjusting = true;
        this.setState({width: 150 + Math.round(this.RefWidget.offsetWidth / 6.8391)}, () =>
        {
            this.Adjusting = false;
        });
    }

    Load = (filter = "", deadline = -1, community = 0, orderField = "id", orderDirection = "asc") =>
    {
        const {
            community: currentCommunity,
            filter: currentFilter,
            learningPlans,
            orderField: currentField,
            orderDirection: currentDirection,
            page
        } = this.state;
        const Reset = filter !== currentFilter || community !== currentCommunity || orderField !== currentField || orderDirection !== currentDirection;
        const Page =  Reset ? 1 : page || 1;
        const LearningPlans = Reset ? [] : learningPlans;
        const Token = this.LoadToken = RandomToken();
        const Endpoint = community ? `communities/${community}/learning_plans` : "learning-plans/my";
        this.setState({
            community,
            deadline,
            error: false,
            filter,
            learningPlans: LearningPlans,
            loading: true,
            orderField,
            orderDirection
        });
        Fuse.Request(Endpoint, this.Filters[filter] !== undefined ? {
            filter,
            page: Page,
            order_field: orderField,
            order_direction: orderDirection
        } : {
            page: Page,
            order_field: orderField,
            order_direction: orderDirection
        }, response =>
        {
            if (!this.Mounted || Token !== this.LoadToken)
            {
                return;
            }
            const {last_page, learning_plans} = response || {};
            if (!learning_plans)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({
                    done: last_page,
                    learningPlans: ArrayClone(LearningPlans).concat(learning_plans),
                    loading: false,
                    page: Page + 1
                });
            }
        });
    }

    OnLoadItem = (content, id) =>
    {
        if (!content)
        {
            return;
        }
        const {loaded} = this.state;
        loaded[id] = content.raw;
        this.setState({loaded});
    }

    /**
     * Change content filter.
     * @param object e - The event object.
     * @param string filter - Content type.
     * @return void
     */

    SetFilter = (e, filters) =>
    {
        const {deadline: d1, filter: f1} = filters;
        const {community, deadline: d2, filter: f2} = this.state;
        const Deadline = d1 === undefined ? d2 : parseInt(d1, 10);
        const Filter = f1 === undefined ? f2 : f1;
        if (Filter === f2)
        {
            this.setState({deadline: Deadline});
        }
        else
        {
            this.Load(Filter, Deadline, community);
        }
    }

    /**
     * Set the learninplan filter label.
     * @return string - The label
     */

    SetFilterLabel = () =>
    {
        const {deadline, filter} = this.state;
        let Filter = this.Filters[filter] || "All learning plans";
        if (deadline === 0)
        {
            Filter += " with deadline";
        }
        else if (deadline === 1)
        {
            Filter += " without deadline";
        }
        return Filter;
    }

    render()
    {
        const WidgetContent = this.Content();
        const {
            active,
            hover,
            rowHeight
        } = this.props;
        const {
            deadline,
            filter,
            learningPlans,
            loaded,
            loading,
            width
        } = this.state;
        const {
            externalLink,
            lpGrayscale,
            manualSelect,
            selected,
            showFilter,
            title
        } = WidgetContent;
        const CA = ["Widget", "WidgetLearningPlans", "White", "BorderRadius"];
        const Content = [];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (manualSelect)
        {
            const Items = [];
            selected.forEach((content) =>
            {
                const Id = content[0];
                const {expiration_info, progress} = loaded[Id] || {};
                const {deadline_date} = expiration_info || {};
                const DeadlineDate = deadline_date ? DateMonth(deadline_date) : "";
                Items.push(
                    <div className="WidgetLearningPlansItem" key={Id}>
                        <ContentItem
                            content={{
                                content,
                                grayscale: lpGrayscale
                            }}
                            width={width}
                            height={270 * rowHeight}
                            onLoad={this.OnLoadItem}
                            tag={DeadlineDate ? `Due ${DeadlineDate}` : ""}
                            target={externalLink ? "_blank" : "_top"}
                        >
                            {progress !== undefined ? <Progress
                                className="ContentItemProgress"
                                progress={progress / 100}
                            /> : ""}
                        </ContentItem>
                    </div>   
                );
            });
            if (Items.length)
            {
                Content.push(<Slider 
                    className="WidgetLearningPlansItems"
                    key="items"
                    style={{height: 280 * rowHeight}}
                >{Items}</Slider>);
            }
        }
        else if (!learningPlans.length && loading)
        {
            CA.push("Loading");
            Content.push(<Spinner
                className="WidgetLearningPlansSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (learningPlans.length)
        {
            const Items = [];
            learningPlans.forEach(learningPlan =>
            {
                const {id, expiration_info, progress, thumbnail_3x_url, title} = learningPlan
                const {deadline_date} = expiration_info || {};
                const DeadlineDate = deadline_date ? DateMonth(deadline_date) : "";
                if ((deadline === 0 && !DeadlineDate) || (deadline === 1 && DeadlineDate))
                {
                    return;
                }
                // When filtering from a community, the endpoint doesn't take
                // notice of the filter, so we do this filtering at render.
                if (filter === "in_progress" && (progress === 0 || progress === 100))
                {
                    return;
                }
                if (filter === "not_started" && progress !== 0)
                {
                    return;
                }
                if (filter === "completed" && progress !== 100)
                {
                    return;
                }
                if (filter === "with_deadline" && !DeadlineDate)
                {
                    return;
                }
                Items.push(
                    <div className="WidgetLearningPlansItem" key={id}>
                        <ContentItem
                            content={{grayscale: lpGrayscale}}
                            contentObject={{
                                id,
                                name: title,
                                preview: thumbnail_3x_url,
                                type: "learning-plan"
                            }}
                            width={width}
                            height={270 * rowHeight}
                            tag={DeadlineDate ? `Due ${DeadlineDate}` : ""}
                        >
                            <Progress
                                className="ContentItemProgress"
                                progress={progress / 100}
                            />
                        </ContentItem>
                    </div>   
                );
            });
            if (Items.length)
            {
                Content.push(<Slider 
                    className="WidgetLearningPlansItems"
                    key="items"
                    style={{height: 280 * rowHeight}}
                >{Items}</Slider>);
            }
        }
        if (!Content.length)
        {
            Content.push(<div
                className="WidgetEmpty"
                key="empty"
            >No learning plans</div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetLearningPlansWrapper"
                    title={title}
                    /*toolbar={(showFilter && !manualSelect) ? <SelectField
                        className="WidgetLearningPlansFilter"
                        onChange={this.SetFilter}
                        options={this.Filters}
                        value={filter}
                    /> : "" }*/
                    toolbar={(showFilter && !manualSelect) ? <FilterField
                        className="WidgetLearningPlansFilter"
                        fields={{
                            filter:
                            {
                                options: this.Filters,
                                placeholder: "All learning plans",
                                type: "choice"
                            },
                            deadline:
                            {
                                options: this.FiltersDeadline,
                                placeholder: "With or without deadline",
                                type: "choice"
                            }
                        }}
                        onChange={this.SetFilter}
                        selectedLabel={this.SetFilterLabel}
                        values={{filter, deadline: deadline + ""}}
                    /> : "" }
                >
                    <div className="WidgetLearningPlansAlign">
                        {Content}
                    </div>
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetLearningPlans;