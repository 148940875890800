/*!
 *  Select form field.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop boolean flip - Whether to make the options appear above the field instead of below.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback function.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop array|object options - Field options.
 *  @prop string placeholder - Placeholder/unselected value.
 *  @prop string value - Field value.
 *  @prop function selectedLabel - Optional callback to set the selected label.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./selectfield.scss";
import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";
import Sticky from "Components/Layout/Sticky";
import {ObjectAssign, ObjectCompare} from "Functions";

class SelectField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Input = false;
        this.Options = {};
        this.state =
        {
            expand: false,
            focus: false,
            value: false,
            width: 200,
        };
    }

    /**
     * Set initial value and options and add listeners.
     * @return void
     */

    componentDidMount()
    {
        const {options, value} = this.props;
        this.SetValue(value, options, true);
        this.SetSize();
        window.addEventListener("resize", this.SetSize);
    }

    /**
     * Update value and options.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {disabled, options: o1, value: v1} = this.props;
        const {options: o2, value: v2} = prevProps;
        const {expand} = this.state;
        if (disabled && expand)
        {
            this.setState({expand: false});
        }
        if (v1 !== v2 || o1 !== o2)
        {
            this.SetValue(v1, o1, true);
        }
    }

    /**
     * Remove listeners.
     * @return void
     */

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.SetSize);
    }

    GetValue = () =>
    {
        const {multiple} = this.props;
        const {value} = this.state;
        if (!multiple || Array.isArray(value))
        {
            return value;
        }
        if (value && value !== -1)
        {
            return [value];
        }
        return [];
    }

    /**
     * Output a option.
     * @param string label - The option label.
     * @param string key - The option key.
     * @param boolean selected - Whether this option is selected.
     * @return JSX - The option.
     */

    Item = (label, key, selected) =>
    {
        const CA =["Option"];
        if (selected)
        {
            CA.push("Selected");
        }
        return (
            <div
                key={key}
                className={CA.join(" ")}
                onClick={() => this.SetValue(key)}
                title={typeof label === "string" ? label : ""}
            >
                {label}
            </div>
        );
    }

    /**
     * Callback for when the field loses focus.
     * @param object e - The event object.
     * @return void
     */

    OnBlur = (e) =>
    {
        const {id, onBlur} = this.props;
        const {value} = this.state;
        onBlur(e, value, id);
        this.setState({focus: false});
        window.removeEventListener("keydown", this.OnKeyDown);
        window.removeEventListener("keyup", this.OnKeyUp);
    }

    /**
     * Clear the field value.
     * @param object e - The event object.
     * @return void
     */

    OnClearField = (e) =>
    {
        e.preventDefault();
        e.stopPropagation();
        const {id, onClear} = this.props;
        onClear(e, id);
    }

    /**
     * Callback for when the field gains focus.
     * @param object e - The event object.
     * @return void
     */

    OnFocus = (e) =>
    {
        const {id, onFocus} = this.props;
        const {value} = this.state;
        onFocus(e, value, id);
        this.setState({focus: true});
        // Listen for key presses while focused.
        window.addEventListener("keydown", this.OnKeyDown);
        window.addEventListener("keyup", this.OnKeyUp);
    }

    /**
     * Callback for when a key is pressed while the field has focus.
     * @param object e - The event object.
     * @return void
     */

    OnKeyDown = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        const {expand} = this.state;
        if (disabled)
        {
            return;
        }
        const {value} = this.state;
        const Options = this.Options;
        const Keys = Object.keys(Options);
        const Limit = Keys.length - 1;
        const Index = Keys.indexOf(value);
        let Value;
        switch (e.which)
        {
            // Enter = Toggle expand/collpase.
            case 13:
                this.setState({expand: !expand});
                break;
            // Up/Left = Previous option.
            case 37:
            case 38:
                Value = Keys[Index <= 0 ? 0 : Index - 1];
                onChange(e, Value, id);
                this.setState({value: Value});
                break;
            // Down/Right = Next option.
            case 39:
            case 40:
                Value = Keys[Index >= Limit ? Limit : Index + 1];
                onChange(e, Value, id);
                this.setState({value: Value});
                break;
            default:
                return;
        }
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Callback for when a key is released while the field has focus.
     * @param object e - The event object.
     * @return void
     */

    OnKeyUp = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Toggle between expanded/collapsed.
     * @return void
     */

    OnToggle = (e) =>
    {
        e.stopPropagation();
        e.preventDefault();
        const {disabled} = this.props;
        const {expand} = this.state;
        if (disabled || !this.Input)
        {
            return;
        }
        if (expand)
        {
            this.Input.blur();
        }
        else
        {
            this.SetSize();
            this.Input.focus();
        }
        this.setState({expand: !expand});
    }

    /**
     * Get the selected options label.
     * @return string - The selected options label.
     */

    Selected = () =>
    {
        const {placeholder, selectedLabel} = this.props;
        const Value = this.GetValue();
        const SelectedLabel = selectedLabel(Value);
        if (SelectedLabel)
        {
            return SelectedLabel;
        }
        const Options = this.Options;
        const Keys = Object.keys(Options);
        if (!Keys.length)
        {
            return "";
        }
        if (Array.isArray(Value))
        {
            const SelectedLabels = [];
            Value.forEach(selected =>
            {
                if (!Options[selected])
                {
                    return;
                }
                SelectedLabels.push(Options[selected]);
            });
            return SelectedLabels.length ? SelectedLabels.join(", ") : placeholder || Options[Keys[0]];
        }
        return Options[Value] || placeholder || Options[Keys[0]];
    }

    /**
     * Adjust the width of the dropdown menu when the client resizes.
     * @return void
     */

    SetSize = () =>
    {
        if (!this.Input)
        {
            return;
        }
        this.setState({width: this.Input.offsetWidth});
    }

    /**
     * Update the selected value and options.
     * @param string key - The selected option key.
     * @param array|object options - Select options.
     * @param boolean noCallback - Whether to skip onChange().
     * @return string - The selected options label.
     */

    SetValue = (key, options, noCallback = false) =>
    {
        const {id, multiple, onChange, placeholder} = this.props;
        this.Options = ObjectAssign({}, options || this.Options);
        const Keys = Object.keys(this.Options);
        if (!Keys.length)
        {
            return false;
        }
        let Value = this.GetValue();
        if (multiple)
        {
            const Index = Value.indexOf(key);
            if (key === -1)
            {
                Value = [];
            }
            else if (Index < 0 && this.Options[key] !== undefined)
            {
                Value.push(key);
            }
            else if (Index >= 0)
            {
                Value.splice(Index, 1);
            }
        }
        else if (this.Options[key] === undefined)
        {
            Value = placeholder ? -1 : Keys[0];
        }
        else
        {
            Value = key;
        }
        if (!noCallback)
        {
            onChange(null, Value, id);
        }
        this.setState({expand: false, value: Value});
    }

    /**
     * Get the selected option label.
     * @return string - The selected options label.
     */

    Value = () =>
    {
        return this.Selected() || "";
    }

    render()
    {
        const {
            className,
            clear,
            disabled,
            error,
            flip,
            label,
            multiple,
            placeholder
        } = this.props;
        const {expand, focus, width} = this.state;
        const CA = ["Field", "SelectField"];
        const NumOptions = Array.isArray(this.Options) ? this.Options.length : Object.keys(this.Options).length;
        const Value = this.GetValue();
        if (disabled || !NumOptions)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (expand)
        {
            CA.push("Expand");
        }
        if (flip)
        {
            CA.push("Flip");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (multiple)
        {
            CA.push("Multiple");
        }
        if (className)
        {
            CA.push(className);
        }
        const Selected = this.Selected();
        let Menu, Index = 0;
        if (expand)
        {
            const Items = [];
            if (placeholder)
            {
                Items.push(this.Item(placeholder, -1, (Array.isArray(Value) && !Value.length) || Value === -1));
            }
            for (let key in this.Options)
            {
                let Item = this.Options[key];
                let IsSelected = (!Index && !key) || (Array.isArray(Value) && Value.indexOf(key) >= 0) || key === Value;
                Items.push(this.Item(Item, key, IsSelected));
                Index++;
            }
            Menu = (
                <Sticky
                    align="right"
                    className="SelectFieldList"
                    flip={flip}
                    onClose={this.OnToggle}
                    width={width}
                >
                    {Items}
                </Sticky>
            );
        }
        return (
            <div className={CA.join(" ")}>
                {label ? (
                    <label onClick={this.OnToggle}>
                        {label}
                        {clear ? (
                            <IconButton
                                disabled={disabled}
                                feather="XCircle"
                                onClick={this.OnClearField}
                                title="Reset this field"
                            />
                        ) : ""}
                    </label>
                ) : ""}
                <div
                    className="Input"
                    onBlur={this.OnBlur}
                    onClick={this.OnToggle}
                    onFocus={this.OnFocus}
                    ref={input => this.Input = input}
                    tabIndex="0"
                    title={Selected}
                >
                    <span>{Selected}</span>
                    <Icon feather={flip ? "ChevronUp" : "ChevronDown"}/>
                </div>
                {Menu}
            </div>            
        );
    }
}

SelectField.propTypes =
{
    className: PropTypes.string,
    clear: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    flip: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    placeholder: PropTypes.string,
    selectedLabel: PropTypes.func
};

SelectField.defaultProps =
{
    className: "",
    clear: false,
    disabled: false,
    error: false,
    flip: false,
    id: "",
    label: "",
    multiple: false,
    onBlur: () => {},
    onChange: () => {},
    onClear: () => {},
    onFocus: () => {},
    options: [],
    placeholder: "",
    selectedLabel: () => {},
    value: -1
};

export default SelectField;