import React from "react";
import ColorField from "Components/UI/Field/ColorField";

class ToolbarColor extends React.Component
{
    OnChange = (e, color) =>
    {
        clearTimeout(this.ChangeTimeout);
        this.ChangeTimeout = setTimeout(() =>
        {
            const {onChange} = this.props;
            onChange("color", color);
        }, 100);    
    }

    render()
    {
        const {currentState, onChange} = this.props;
        const {color} = currentState;
        return (
            <div className="WywiwygToolbarColorPicker" onClick={e => e.stopPropagation()}>
                <ColorField
                    minimal={true}
                    onChange={this.OnChange}
                    value={color}
                /> 
            </div>
        );  
    }
}

export default ToolbarColor;