/**!
 *  Blank/empty widget.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import Parser from "Class/Parser";
import "./blank.scss";

class WidgetBlank extends Widget
{
    constructor (props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                insert: true,
                label: "Label",
                reset: true,
                type: "textarea"   
            },
            alignH:
            {
                label: "Horisontal Position",
                type: "select",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                default: "center"
            },
            alignV:
            {
                label: "Vertical Position",
                type: "select",
                options: {
                    top: "Top",
                    center: "Center",
                    bottom: "Bottom"
                },
                default: "center"
            }
        });
        this.Name = "Blank";
    }

    render()
    {
        const {title} = this.Content();
        const CS = this.ClassNames(["WidgetBlank", "BorderRadius"], true);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <div className="WidgetBlankContent WidgetDynamicAlign">
                    <div className="WidgetBlankContentLabel Heading">{Parser.ParseParagraphs(title)}</div>
                </div>
            </div>
        );
    }
}

export default WidgetBlank;