/**!
 *  Widget that displays a collection of sub-widgets in tabs.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./tabs.scss";
import Globals from "Class/Globals";
import {ArrayClone, CanForEach} from "Functions";
import Link from "Components/UI/Link";
import ViewWidget from "Components/View/Widget";
 
class WidgetTabs extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            widgets:
            {
                label: "Widgets",
                type: "widgetTabs"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Mounted = false;
        this.Name = "Tabs";
        this.Widgets = {};
        this.state =
        {
            activeId: Globals._ViewActive
        };
    }

    /**
     * Add listeners and refresh timer on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        // Only listen for active widget when editable.
        const {content, editContainer, editContent} = this.props;
        const {widgets} = content || {};
        if (editContainer || editContent)
        {
            Globals.Listen("active-view", this.OnActive);
        }
        // Set active tab.
        if (CanForEach(widgets) && widgets[0])
        {
            this.SetWidget(widgets[0].id);
        }
    }

    /**
     * Adjust active tab when a tab is added or removed.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {widgets: w1} = c1 || {};
        const {widgets: w2} = c2 || {};
        const {activeId} = this.state;
        if (!CanForEach(w1) || !CanForEach(w2))
        {
            return;
        }
        const Id1 = [];
        const Id2 = [];
        let NewId;
        w2.forEach(({id}) => Id2.push(id));
        w1.forEach(({id}) =>
        {
            Id1.push(id);
            if (Id2.indexOf(id) < 0)
            {
                NewId = id;
            }
        });
        if (NewId)
        {
            this.setState({activeId: NewId});
        }
        else if (Id1.indexOf(activeId) < 0 && Id1[0] !== undefined)
        {
            this.setState({activeId: Id1[0]});
        }
    }

    /**
     * Remove listener and refresh timer on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        Globals.Remove("active-view", this.OnActive);
    }
 
    /**
     * Callback when global focus changes.
     * @return void
     */

    OnActive = (activeId) =>
    {
        const {content} = this.props;
        const {widgets} = content || {};
        if (!CanForEach(widgets))
        {
            return;
        }
        let Set = false;
        for (let i in widgets)
        {
            if (widgets[i].id === activeId)
            {
                Set = true;
                break;
            }
        }
        if (Set)
        {
            this.SetWidget(activeId);
        }
    }

    /**
     * Callback when a tab item is clicked.
     * @return void
     */
    OnClick = (e, id) =>
    {
        this.SetWidget(id);
    }

    SetWidget = (activeId) =>
    {
        this.setState({activeId}, () =>
        {
            if (!this.Widgets[activeId])
            {
                return;
            }
            this.Widgets[activeId].OnUpdate();
        });
    }
 
    render()
    {
        const {
            active,
            ancestors,
            appearance,
            content,
            contentId,
            context,
            contextId,
            hover,
            rowHeight,
            toolbarOffset
        } = this.props;
        const {activeId} = this.state;
        const {widgets} = content || {};
        const CA = ["Widget", "WidgetTabs"];
        const Ancestors = ArrayClone(ancestors);
        const TabLabels = [];
        const Tabs = [];
        Ancestors.push(contentId);
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (CanForEach(widgets))
        {
            widgets.forEach(({id: widgetId, label}) =>
            {
                TabLabels.push(
                    <Link
                        className={widgetId === activeId ? "WidgetTabsLabel HighlightAfter Heading Active" : "WidgetTabsLabel HighlightAfter Heading"}
                        id={widgetId}
                        key={widgetId}
                        label={label}
                        onClick={this.OnClick}
                    />
                );
                Tabs.push(
                    <div
                        className={widgetId === activeId ? "WidgetTabsTab Active" : "WidgetTabsTab"}
                        key={widgetId}
                    >
                        <ViewWidget
                            ancestors={Ancestors}
                            appearance={appearance}
                            autoAdjust={false}
                            context={context}
                            contextId={contextId}
                            name={widgetId}
                            onAutoAdjust={this.OnItemAutoAdjust}
                            onFill={this.OnItemFill}
                            onHeight={this.OnItemHeight}
                            onMount={widget => this.Widgets[widgetId] = widget}
                            parent={contentId}
                            // Subtract the tab labels from the sub-widgets heights.
                            rowHeight={rowHeight - 40 / 390}
                            universal={true}
                            x={0}
                            y={0}
                        />
                    </div>
                );
            });
        }
        const CS = this.ClassNames(CA, true);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar(true, true)}
                {this.BackgroundImage()}
                <div className="WidgetTabsLabels">
                    {TabLabels}
                </div>
                <div className="WidgetTabsTabs">
                    {Tabs}
                </div>
            </div>
        );
    }
}

export default WidgetTabs;