/**!
 *  Parses strings into JSX.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
// HTML entities index.
import Entities from "Import/JSON/entities.json";
import {Trim} from "Functions";
import Link from "Components/UI/Link";

class Parser
{
    constructor()
    {
        // Allowed node attributes.
        this.AllowedAttributes = ["align", "className", "d", "fill", "height", "href", "key", "points", "style", "target", "transform", "width", "viewBox", "xmlns"];
        this.AllowedStyles = ["text-align"];
        // Allowed node names.
        this.AllowedTags = ["a", "b", "blockquote", "br", "circle", "div", "em", "h1", "h2", "h3", "h4", "h5", "h6", "i", "li", "ol", "p", "path", "polygon", "pre", "q", "rect", "s", "span", "strike", "strong", "sub", "sup", "svg", "u", "ul"];
        // Replace nodes with components.
        this.TagComponents =
        {
            "a": Link
        };
        // These are void elements, i.e. they are not allowed to have children. We need
        // to keep track of these to avoid errors while rendering.
        this.Voids = ["area", "base", "br", "col", "hr", "img", "input", "link", "meta", "param"];
    }

    /**
     * Decode entities in a string.
     * @param string str - The undecoded string.
     * @return str - The decoded string.
     */

    DecodeEntities = (str) =>
    {
        return str.replace(/&([a-z0-9#]*);/gi, match =>
        {
            return typeof Entities[match] !== "undefined" ? Entities[match] : "";
        });
    }

    FormatCSS = (css, level = 0, minify = false) =>
    {
        return `${this.FormatTabs(level, minify)}${css}${this.FormatLn(minify)}`;
    }

    FormatHTML = (nodes, level = 0, minify = false) =>
    {
        let HTML = "";
        nodes.forEach((child, index) =>
        {
            if (typeof child === "object")
            {
                const {attributes, children, name} = child;
                const Attributes = [];
                this.FormatHTMLNode(name, child, true);
                for (let key in attributes)
                {
                    Attributes.push(`${key}="${attributes[key]}"`);
                }
                if (this.Voids.indexOf(name) >= 0)
                {
                    HTML += `${this.FormatTabs(level, minify)}<${name}${Attributes.length ? ' ' + Attributes.join(" ") : ""}/>${this.FormatLn(minify)}`;
                }
                else if (!children.length)
                {
                    HTML += `${this.FormatTabs(level, minify)}<${name}${Attributes.length ? ' ' + Attributes.join(" ") : ""}></${name}>${this.FormatLn(minify)}`;
                }
                else if (name === "style")
                {
                    HTML += `${this.FormatTabs(level, minify)}<${name}${Attributes.length ? ' ' + Attributes.join(" ") : ""}>${this.FormatLn(minify)}`;
                    HTML += this.FormatCSS(children[0], level + 1, minify);
                    HTML += `${this.FormatTabs(level, minify)}</${name}>${this.FormatLn(minify)}`;
                }
                else if (name === "script")
                {
                    HTML += `${this.FormatTabs(level, minify)}<${name}${Attributes.length ? ' ' + Attributes.join(" ") : ""}>${this.FormatLn(minify)}`;
                    HTML += this.FormatJS(children[0], level + 1, minify);
                    HTML += `${this.FormatTabs(level, minify)}</${name}>${this.FormatLn(minify)}`;
                }
                else if (children.length === 1 && typeof children[0] === "string")
                {
                    HTML += `${this.FormatTabs(level, minify)}<${name}${Attributes.length ? ' ' + Attributes.join(" ") : ""}>${children[0]}</${name}>${this.FormatLn(minify)}`;
                }
                else
                {
                    HTML += `${this.FormatTabs(level, minify)}<${name}${Attributes.length ? ' ' + Attributes.join(" ") : ""}>${this.FormatLn(minify)}`;
                    HTML += this.FormatHTML(children, level + 1, minify);
                    HTML += `${this.FormatTabs(level, minify)}</${name}>${this.FormatLn(minify)}`;
                }
                this.FormatHTMLNode(name, child, false);
            }
            else
            {
                HTML += `${this.FormatTabs(level, minify)}${child}${this.FormatLn(minify)}`;
            }
        });
        return HTML;
    }

    FormatHTMLNode = (name, node, before) =>
    {
        // Turn off formatting in preformatted tags.
        if (name === "pre")
        {
            this.MinifyNodeContent = before;
        }
    }

    FormatJS = (js, level = 0, minify = false) =>
    {
        return `${this.FormatTabs(level, minify)}${js}${this.FormatLn(minify)}`;
    }

    FormatLn = (minify) =>
    {
        return (minify || this.MinifyNodeContent) ? "" : "\n";
    }

    FormatTabs = (level = 0, minify = false) =>
    {
        return (minify || this.MinifyNodeContent) ? "" : ("\t").repeat(level);
    } 

    /**
     * Parse text into JSX.
     * @param string raw - The unparsed text.
     * @param string container - Optional. Container node name. Defaults to 'div'.
     * @param mixed injectVars - Optional. Callback for fetching vars or var object.
     * @param object attributes - Optional. The container nodes attributes.
     * @param string key - Optional. The container nodes key.
     * @return jsx - The parsed JSX.
     */

    Parse = (raw, container = "span", injectVars, attributes = {}, key = null) =>
    {
        this.InjectVars = injectVars;
        // 1. Parse the text into a DOM style tree.
        const Tree = this.ParseRaw(raw);
        // 2. Recursively validate the DOM nesting.
        this.ValidateLevel(Tree);
        // 3. Parse the tree into JSX.
        return this.ParseLevel(Tree, container, key, attributes);
    }

    /**
     * Recursively parse the "DOM tree" into JSX.
     * @param object children - The children on this level in the tree.
     * @param string|object nodeName - Node name or React component.
     * @param string key - The key attribute of the returned component.
     * @param object attributes - The node attributes.
     * @return void
     */

    ParseLevel = (children, nodeName = "div", key = "0", attributes = {}) =>
    {
        // Store all nodes/components on this level in theis object.
        const Level = [];
        // Parse each child.
        children.forEach((child, index) =>
        {
            // If this is a text node, it just needs to be appended.
            if (typeof child === "string")
            {
                Level.push(this.ParseVar(child));
            }
            // In case of some unexpected scenario.
            else if (typeof child !== "object")
            {
                return;
            }
            // Add the node and its children by calling this method recursively.
            else
            {
                Level.push(this.ParseLevel(child.children, child.name, key + "_" + index, child.attributes));
            }
        });
        // Create and return a React component containing the parsed children.
        attributes.key = key;
        const Props = {};
        for (var prop in attributes)
        {
            if (this.AllowedAttributes.indexOf(prop) < 0)
            {
                continue;
            }
            Props[prop] = this.ParseVar(attributes[prop], prop);
        }
        // If this node has a replacment component - Add the component.
        if (this.TagComponents[nodeName] !== undefined)
        {
            return React.createElement(this.TagComponents[nodeName], Props, Level);
        }
        // Don't allow void elements to have children.
        if (Level.length && this.Voids.indexOf(nodeName) < 0)
        {
            return React.createElement(nodeName, Props, Level);
        } 
        else
        {
            return React.createElement(nodeName, Props);
        }
    }

    /**
     * Parse text paragraphs of JSX.
     * @param string raw - The unparsed text.
     * @return array - Array of parsed JSX, contained in paragraphs.
     */

    ParseParagraphs = (raw) =>
    {
        if (typeof raw !== "string")
        {
            return <p>{raw}</p>;
        }
        const Paragraphs = [];
        const Segments = raw.split(/[\t\r\n\f]+/);
        Segments.forEach((segment, index) =>
        {
            const P = this.Parse(Trim(segment), "p", null, {}, index);
            Paragraphs.push(P);
        });
        return Paragraphs;
    }

    /**
     * Parse text into a "DOM style" tree.
     * @param string raw - The unparsed text.
     * @param bool validate - Whether to validate tags/code,
     * @return object
     */

    ParseRaw = (raw , validate = true) =>
    {
        // Only parses strings.
        if (typeof raw !== "string")
        {
            return [];
        }
        // Create a regexp to match all HTML tags.
        const Regexp = /<\/?[\w\s="/.':;#-/?@]+>/gi;
        // Extract tags.
        const Tags = raw.match(Regexp);
        // Extract non-tags by splitting the string at every tag.
        const Texts = raw.split(Regexp);
        const Tree = [];
        // Keep track of the current level in the three.
        let Level = Tree;
        // Keep track of which level we are at so that we can navigate up and
        // down the tree.
        const Levels = [];
        // Keep track of which types of nodes the current level descends from
        // in order to adjust the contents accordingly.
        const Nodes = [];
        // Return the text if it doesn't contain any tags.
        if (!Tags)
        {
            return [raw];
        }
        // Iterate through each tag.
        Tags.forEach((tag, index) =>
        {
            // Extract the node name (div, p, span etc.).
            const nodeName = (tag.match( /<\/?(\w*)/i))[1].toLowerCase();
            // Check if there is text to insert before this node.
            if (Texts[index])
            {
                Level.push(this.ParseText(Texts[index], Nodes, nodeName));
            }
            // Don't parse if the node is unallowed (script etc.)
            if (validate && this.AllowedTags.indexOf(nodeName) < 0)
            {
                return;
            }
            // Extract and parse the tag attributes into a object.
            const AttributesRaw = tag.match(/[a-z0-9-]*="[^"]*|[a-z0-9-]*='[^']*/gi);
            const Attributes = [];
            if (AttributesRaw)
            {
                // Format attributes, eg: font-size => fontSize
                AttributesRaw.forEach(attribute =>
                {
                    const A = attribute.split(/="|='/);
                    const K = validate ? A[0].replace(/-(\w)/gi, (match, letter) => {return letter.toUpperCase()}) : A[0];
                    Attributes[K] = A[1].match(/^\d+$/) ? parseInt(A[1], 10) : A[1];
                });
            }
            // Each node the tree is stored as an object.
            const TagObject =
            {
                name: nodeName,
                attributes: Attributes,
                children: []
            };
            // If this is a closing tag, navigate back to the previous level.
            if (tag.match(/^<\//))
            {
                Level = Levels.length ? Levels.pop() : Level;
                Nodes.pop();
            }
            // If this is a void tag, just append the object to the current level.
            else if (tag.match(/\/ ?>$/))
            {
                Level.push(TagObject);
            }
            // If this is an opening tag, navigate to the next level,
            // i.e. the children of this node.
            else
            {
                // Append to current level.
                Level.push(TagObject);
                // Set next level.
                Levels.push(Level);
                Level = TagObject.children;
                Nodes.push(nodeName);
            }
        });
        // Append remaining text nodes.
        for (let a = 0, b = Tags.length; a < Texts.length - Tags.length; a++, b++)
        {
            Tree.push(Texts[b]);
        }
        return Tree;
    }

    /**
     * Parse a style attribute.
     * @param string style - The raw style attribute.
     * @return object - Parsed styles.
     */

    ParseStyle = (style) =>
    {
        const Parsed = {};
        if (typeof style !== "string")
        {
            return Parsed;
        }
        const List = style.split(";");
        List.forEach(attribute =>
        {
            const [Key, Value] = attribute.split(":");
            const Index = this.AllowedStyles.indexOf(Trim(Key));
            if (Index < 0)
            {
                return;
            }
            const Attribute = this.AllowedStyles[Index].replace(/-(.)/, match =>
            {
                return match[1].toUpperCase();
            });
            Parsed[Attribute] = Trim(Value);
        });
        return Parsed;
    }

    /**
     * Adjust text nodes before inserting them into the tree.
     * @param string text - The unparsed text.
     * @param object parents - The node names of ancestor nodes.
     * @param string nodeName - The node name of the current node.
     * @return string
     */

    ParseText = (text, parents, nodeName) =>
    {
        // Join ancestor nodes and the current nodes into one array.
        const Nodes = Array.from(parents);
        Nodes.push( nodeName );
        // Handle entities.
        let Parsed = this.DecodeEntities(text);
        // If the text is inside a quote, remove any opening and closing quotes
        // to allow the node to be styled properly.
        if (Nodes.indexOf("blockquote") >= 0 || Nodes.indexOf("q") >= 0)
        {
            Parsed = Parsed.replace(/^["' ]*|["' ]*$/g, "");
        }
        return Parsed;
    }

    /**
     * Replace a string with a variable.
     * @param string str - The string
     * @param string param - Parameter.
     * @return mixed - Variable.
     */

    ParseVar = (str, param) =>
    {
        switch (param)
        {
            case "style":
                return this.ParseStyle(str);
            default:
        }
        if (typeof str !== "string" || str[0] !== '$')
        {
            return str;
        }
        const Key = str.substr(1);
        switch (typeof this.InjectVars)
        {
            case "function":
                return this.InjectVars(Key);
            case "object":
                return typeof this.InjectVars[Key] !== undefined ? this.InjectVars[Key] : "";
            default:
                return str;
        }
    }

    /**
     * Recursively validates the nesting of the "DOM tree."
     * @param object children - The children on this level in the tree.
     * @return object - An array containing all the names of the nodes in this "branch"
     */

    ValidateLevel = (children) =>
    {
        const NodeNames = [];
        children.forEach(child =>
        {
            // Ignore text nodes.
            if ( typeof child !== "object" )
            {
                return;
            }
            // Get the descendants node names.
            const Descendants = this.ValidateLevel(child.children);
            // Append to the return object.
            Descendants.forEach(nodeName => NodeNames.push(nodeName));
            // P cannot have DIV, IFRAME or P as a descendant.
            if (child.name === "p" && (Descendants.indexOf("p") >= 0 || Descendants.indexOf("div") >= 0 || Descendants.indexOf("iframe") >= 0))
            {
                child.name = "div";
            }
            // A cannot have A as a descendant.
            else if (child.name === "a" && Descendants.indexOf("a") >= 0)
            {
                child.name = "span";
            }
            NodeNames.push(child.name);
        });
        return NodeNames;
    }
}

export default new Parser();