/*!
 *  Load and display an image resource.
 *  @prop boolean autoAdjust - Whether the containing div should auto-adjust to fit the image.
 *  @prop string className - Append a class name.
 *  @prop function onLoad - OnLoad callback. (src, width, height).
 *  @prop function onError - OnError callback. (src).
 *  @prop string size - Image size slug, when loading from backend. Defaults to 'mid'.
 *  @prop string src - Image source URL.
 *  @prop string src2x - Double resolution image source URL.
 *  @prop integer srcId - Load a image from the backend server.
 *  @prop object style - Image inline styles.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./loadimage.scss";
import API from "Class/API";
import Auth from "Class/Auth";

class LoadImage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            height: 0,
            loaded: false,
            width: 0
        };
        this.Mounted = false;
        this.Loading = false;
        this.LoadDelay = 300;
        this.LoadTimer = false;
        this.Src = "";
    }

    /**
     * Load the image on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {size, src, src2x, srcId} = this.props;
        if (srcId)
        {
            this.LoadId(srcId, size);
        }
        else
        {
            const PR = window.devicePixelRatio;
            const Src = PR > 1 && src2x ? src2x : src;
            this.Load(Src);
        }
    }

    /**
     * Reload the image when its' source changes.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {size: size1, src: src1, src2x: src2x1, srcId: srcId1} = this.props;
        const {size: size2, src: src2, src2x: src2x2, srcId: srcId2} = prevProps;
        if (srcId1 && (srcId1 !== srcId2 || size1 !== size2))
        {
            this.LoadId(srcId1, size1);
        }
        else if (srcId1 !== srcId2 || src1 !== src2 || src2x1 !== src2x2)
        {
            const PR = window.devicePixelRatio;
            const Src = PR > 1 && src2x1 ? src2x1 : src1;
            this.Load(Src);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     *   Load an image source.
     *   @param string src - Image source URL.
     *   @return void
     */

    Load = (src) =>
    {
        if (!src || typeof src !== "string")
        {
            return;
        }
        if (this.state.loaded)
        {
            this.setState({loaded: false});
        }
        clearTimeout(this.LoadTimer);
        this.LoadTimer = setTimeout(() =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {onError, onLoad} = this.props;
            this.Loading = true;
            const Img = new Image();
            Img.setAttribute("component", this);
            Img.onload = () =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                const {width, height} = Img;
                this.setState({
                    height,
                    loaded: true,
                    width
                });
                this.Loading = false;
                onLoad(src, width, height, this);
            };
            Img.onerror = () =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                this.setState({error: true});
                this.Loading = false;
                onError(this.Src, this);
            };
            Img.src = this.Src = src;
            this.setState({
                error: false,
                loaded: false
            });
        }, this.LoadDelay);
    }

    LoadId = (id, size) =>
    {
        const {imageKey} = this.props;
        const Key = imageKey || (Auth.User ? Auth.User.image_key || '' : '');
        this.Load(API.Url(`files/image/${id}/${size}/${Key}`));
    }

    render()
    {
        const {autoAdjust, children, className, onClick, style, title} = this.props;
        const {error, loaded, width, height} = this.state;
        const CA = ["LoadImage"];
        if (error)
        {
            CA.push("Error");
        }
        else if (loaded)
        {
            CA.push("Loaded");
        }
        if (className)
        {
            CA.push(className);
        }
        const Style = Object.assign({}, style);
        Style.backgroundImage = `url(${this.Src})`;
        if (autoAdjust)
        {
            Style.paddingTop = height / width * 100 + "%"
        }
        return (
            <div
                className={CA.join(" ")}
                onClick={onClick}
                style={Style}
                title={title}
            >
                {children}
            </div>
        );
    }
}

LoadImage.propTypes =
{
    autoAdjust: PropTypes.bool,
    className: PropTypes.string,
    imageKey: PropTypes.string,
    onClick: PropTypes.func,
    onError: PropTypes.func,
    onLoad: PropTypes.func,
    size: PropTypes.string,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    src2x: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    srcId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    title: PropTypes.string
};

LoadImage.defaultProps =
{
    autoAdjust: false,
    className: "",
    imageKey: "",
    onClick: () => {},
    onError: () => {},
    onLoad: () => {},
    size: "mid",
    src: "",
    src2x: "",
    srcId: 0,
    style: {},
    title: ""
};

export default LoadImage;