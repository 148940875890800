/*!
 *  Feather icon field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop boolean flip - Whether to make the options appear above the field instead of below.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback function.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */


import React from "react";
import "./feathericonfield.scss";
import SelectField from "../SelectField";
import * as FeatherIcon from "react-feather";

export default function FeatherIconField(props)
{
    const {
        className = "",
        ...inheritProps
    } = props;
    const CA = ["FeatherIconField"];
    const Options = {};
    for (let key in FeatherIcon)
    {
        const IconElement = React.createElement(FeatherIcon[key],
        {
            display: "block",
            width: "100%",
            height: "100%",
            strokeWidth: 1
        });
        Options[key] = <div className="FeaterIconFieldOption">{IconElement} {key}</div>;
    }
    return <SelectField
        className={CA.join(" ")}
        {...inheritProps}
        options={Options}
        placeholder={<div className="FeaterIconFieldOption">None</div>}
    />;
}