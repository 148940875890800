/**!
 *  Link widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./imageandtext.scss";
import API from "Class/API";
import Fuse from "Class/Fuse";
import {ObjectCompare} from "Functions";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import RenderContent from "Components/Layout/RenderContent";
import Spinner from "Components/Feedback/Spinner";
import LoadImage from "Components/Layout/LoadImage";

class WidgetImageText extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            image:
            {
                label: "Image",
                type: "image"
            },
            title:
            {
                insert: true,
                label: "Title",
                reset: true,
                type: "textarea"   
            },
            text:
            {
                insert: true,
                label: "Text",
                type: "textarea"
            },
            icon:
            {
                default: "Play",
                label: "Icon",
                type: "feathericon"
            },
            alignH:
            {
                label: "Horisontal Position",
                type: "select",
                options: {
                    left: "Left",
                    center: "Center",
                    right: "Right"
                },
                default: "center"
            },
            externalLink:
            {
                label: "External link",
                type: "checkbox"
            },
            content:
            {
                displayIf: ["externalLink", "!=", true],
                label: "Content",
                type: "content"
            },
            contentNotice:
            {
                displayIf: ["externalLink", "!=", true],
                label: "Image, Title and Text will replace the contents preview, name and description if specified.",
                type: "notice"
            },
            url:
            {
                displayIf: ["externalLink", "==", true],
                label: "URL",
                type: "url",
                default: "https://"
            },
            urlBlank:
            {
                displayIf: ["externalLink", "==", true],
                label: "Open in a new tab",
                type: "checkbox",
                default: true
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.state =
        {
            content: false,
            contentObject: false,
            error: false,
            loading: false
        };
        this.Name = "Image and Text";
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        const {content, contentObject} = this.props;
        const {content: fuseContent, externalLink} = content;
        if (contentObject)
        {
            this.setState({content: contentObject.id, contentObject});
        }
        else if (!externalLink)
        {
            this.Load(fuseContent);
        }
    }

    /**
     * Update content when a new ID is received.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {content: c3, externalLink: e1} = c1;
        const {content: c4, externalLink: e2} = c2;
        if (!e1 && (!ObjectCompare(c3, c4) || e1 !== e2))
        {
            this.Load(c3);
        }
    }

    /**
     * Load content from the Fuse API.
     * @param array|integer item - [contentId, type] or just contentId.
     * @return void
     */

    Load = (item) =>
    {
        if (!item || (Array.isArray(item) && !item.length))
        {
            this.setState({
                content: 0,
                contentObject: false,
                error: false,
                loading: false
            });
        }
        else if (typeof item === "object" && typeof item[0] === "object")
        {
            this.Load(item[0]);
        }
        else if (typeof item === "object")
        {
            this.setState({
                content: item[0],
                error: false,
                loading: true
            });
            this.LoadContent(item[0], item[1]);
        }
        else
        {
            this.setState({
                content: item,
                error: false,
                loading: true
            });
            /**
             * Check content type in the backend DB before fetching.
             */
            API.Request("content/cache-read", {id: item}, response =>
            {
                const {cache, error} = response;
                if (error)
                {
                    this.setState({error: true, loading: false});
                }
                else
                {
                    this.LoadContent(item, cache.type);
                }
            });
        }
    }

    /**
     * Load content data from fuse.
     * @param integer id - The content id.
     * @param string type - The content type.
     * @return void
     */

    LoadContent = (id, type) =>
    {
        Fuse.Content(id, type, content =>
        {
            if (!content)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({loading: false, contentObject: content});
            }
        });
    }

    render()
    {
        const {contentObject, loading} = this.state;
        const {alignH = "center", externalLink, icon, image, text, title, url, urlBlank} = this.Content();
        const CA = ["WidgetImageAndText", "White", "BorderRadius"];
        const Content = [];
        let Image, Text, Title, Url;
        let Target = "_top";
        if (loading)
        {
            Image = image ? parseInt(image[0], 10) : 0;
            Text = text;
            Title = title;
            Content.push(<Spinner
                className="WidgetImageAndTextSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (externalLink)
        {
            Image = image ? parseInt(image[0], 10) : 0;
            Text = text;
            Title = title;
            Target = urlBlank ? "_blank" : "_top";
            Url = url;
        }
        else if (!externalLink && contentObject)
        {
            const {description, id, name, preview, type} = contentObject;
            Image = image ? parseInt(image[0], 10) : preview;
            Text = text || description;
            Title = title || name;
            Url = Fuse.ContentUrl(id, type);
        }
        else
        {
            CA.push("Disabled");
            Title = title;
        }
        return (
            <div className={this.ClassNames(CA, true)} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <Link
                    className="WidgetImageAndTextContent"
                    href={Url}
                    target={Target}
                    style={{textAlign: alignH}}
                >
                    <LoadImage className="WidgetImageAndTextImage BorderRadius" size="large" srcId={Image}>
                        {icon ? (
                        <div className="WidgetImageAndTextIconWrapper ItemBackground">
                            <Icon
                                className="WidgetImageAndTextIcon"
                                feather={icon}
                                size="100%"
                            />
                        </div>
                        ) : ""}
                    </LoadImage>
                    <RenderContent className="ItemActive" content={Title} nl2br={true} node="h2"/>
                    <RenderContent content={Text} nl2br={true} node="p"/>
                </Link>
            </div>
        );
    }
}

export default WidgetImageText;