/**!
 *  Sticky menu view.
 *
 *  @prop string align - Menu alignment: "left", "center" or "right".
 *  @prop string className - Append a class name.
 *  @prop boolean flip - Whether to make the container expand upwards instead of downwards.
 *  @prop function onClose - Callback for when the backdrop is clicked.
 *  @prop number width - The menu width.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./sticky.scss";

class Sticky extends React.Component
{
    render()
    {
        const {align, children, className, flip, onClose, width} = this.props;
        const CA1 = ["Sticky"];
        const CA2 = ["StickyContent"];
        if (flip)
        {
            CA1.push("Flip");
        }
        switch (align)
        {
            case "left":
                CA1.push("AlignLeft");
                break;
            case "right":
                CA1.push("AlignRight");
                break;
            default:
                CA1.push("AlignCenter");
        }
        if (className)
        {
            CA2.push(className);
        }
        return (
            <div className="StickyAlign">
                <div className="StickyBackdrop" onClick={onClose}/>
                <div className="StickyContainer">
                    <div className={CA1.join(" ")} style={{width}}>
                        <div className={CA2.join(" ")}>
                            <div className="StickyContentWrapper">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Sticky.propTypes =
{
    align: PropTypes.string,
    className: PropTypes.string,
    flip: PropTypes.bool,
    onClose: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Sticky.defaultProps =
{
    align: "right",
    className: "",
    flip: false,
    onClose: () => {},
    width: 200
};

export default Sticky;