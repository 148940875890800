
/*!
 *  Code form field.
 *  https://uiwjs.github.io/react-textarea-code-editor/
 *  https://github.com/uiwjs/react-textarea-code-editor/tree/main/src
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./codefield.scss";
import CodeEditor, {SelectionText} from "@uiw/react-textarea-code-editor";
import {RandomToken} from "Functions";

class CodeField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Token = RandomToken();
        this.state =
        {
            focus: false,
            value: ""
        };
    }

    /**
     * Set initial value and add listeners on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        this.setState({value});
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = this.state;
        const {value: v3} = prevProps;
        if (v1 !== v2 && v1 !== v3)
        {    
            this.setState({value: v1});
        }
    }

    OnChange = (e) =>
    {
        const {id, onInput} = this.props;
        const Value = e.target.value;
        onInput(e, Value, id);
        this.setState({value: Value});
    }

    OnKeyDown = (e)  =>
    {
        // https://github.com/uiwjs/react-textarea-code-editor/blob/main/src/shortcuts.ts
        const S = new SelectionText(e.target);
        const T = "    ";
        const L = T.length;
        switch (e.which)
        {
            // Tab
            case 9:
                e.stopPropagation();
                e.preventDefault();
                if (S.start == S.end)
                {
                    S.insertText(T).position(S.start + L, S.end + L);
                }
                else if (S.getSelectedValue().indexOf('\n') > -1 && e.shiftKey)
                {
                    S.lineStarRemove(T);
                }
                else if (S.getSelectedValue().indexOf('\n') > -1)
                {
                    S.lineStarInstert(T);
                }
                else
                {
                    S.insertText(T).position(S.start + L, S.end);
                }
                S.notifyChange();
                break;
            // Enter
            case 13:
                e.stopPropagation();
                e.preventDefault();
                const Indent = `\n${S.getIndentText()}`;
                S.insertText(Indent).position(S.start + Indent.length, S.start + Indent.length);
                S.notifyChange();
                break;
            default:
        }
    }

    render()
    {
        const {
            className,
            disabled,
            error,
            label,
            language
        } = this.props;
        const {
            focus,
            value
        } = this.state;
        const CA = ["Field", "CodeField"];
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                {label ? <label htmlFor={this.Token}>{label}</label> : ""}
                <div className="CodeFieldWrapper">
                    <CodeEditor
                        className="Input"
                        disabled={disabled}
                        id={this.Token}
                        language={language}
                        onChange={this.OnChange}
                        onKeyDown={this.OnKeyDown}
                        padding={10}
                        style={{
                            fontFamily: "monospace"
                        }}
                        value={value}
                    />
                </div>
            </div>
        );
    }
}

CodeField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])

};

CodeField.defaultProps =
{
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    language: "markup",
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onInput: () => {},
    value: ""

};

export default CodeField;