/*!
 *  Useful functions.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";

/**
 * Clone an array or object. Called recursively.
 * @param array|object arr - The array or object to be cloned.
 * @param integer level - The current level of recursion.
 * @param integer maxLevel - The maximum level of recursion. To avoid leaks.
 * @return array|object - The clone.
 */

export const ArrayClone = (arr, level = 0, maxLevel = 10) =>
{
    if (!arr || level >= maxLevel)
    {
        return arr;
    }
    const IsArray = typeof arr === "object" && typeof arr.map === "function";
    const IsObject = !IsArray && typeof arr === "object";
    // Return non-objects and JSX without cloning.
    // Cloning JSX causes all kinds of problems.
    if ((!IsArray && !IsObject) || React.isValidElement(arr))
    {
        return arr;
    }
    const Clone = IsArray ? [] : {};
    for (var key in arr)
    {
        Clone[key] = ArrayClone(arr[key], level + 1, maxLevel);
    }
    return Clone;
}

/**
 * Move an array item from one index to another.
 * @param array arr - The array.
 * @param integer from - The current index.
 * @param integer to - The new index.
 * @param boolean noClone - Whether to manipulate the array directly.
 * @return array - The new array.
 */

export const ArrayMove = (arr, from, to, noClone) =>
{
    const Arr = noClone ? arr : ArrayClone(arr);
    const Item = Arr.splice(from, 1)[0];
    const To = to > from ? to - 1 : to;
    Arr.splice(Math.min(Arr.length, To), 0, Item);
    return Arr;
}

/**
 * Shuffle an array.
 * @param array arr - The array
 * @return array - The array.
 */

export const ArrayShuffle = (arr) =>
{
    arr.forEach((value, key) =>
    {
        const move = Math.floor(Math.random() * (key + 1));
        [arr[key], arr[move]] = [arr[move], arr[key]];
    });
    return arr;
}


/**
 * Get the language of the client browser.
 * @return string|boolean - Locale on success. Boolean false when failed.
 */

export const BrowserLanguage = () =>
{
    const Lang = window.navigator.userLanguage || window.navigator.language;
    const Match = typeof Lang === "string" ? Lang.match(/^([a-z]{2}).([A-Z]{2})$/) : false;
    if (!Match.shift())
    {
        return false;
    }
    const [L1, L2] = Match;
    const Locale = `${L1}_${L2}`;
    if (Locale.match(/^ar/)) return "ar_AR";
    if (Locale.match(/^de/)) return "de_DE";
    if (Locale.match(/^en/)) return "en_US";
    if (Locale.match(/^es/)) return "es_ES";
    if (Locale.match(/^fr/)) return "fr_FR";
    if (Locale.match(/^it/)) return "it_IT";
    return Locale;
}

/**
 * Cap a number between two values.
 * @param number number - The number.
 * @param number min - The minimum. Default to '0'.
 * @param number maximum - The minimum. Default to '1'.
 * @return number - The capped number.
 */

export const CapFloat = (number, min = 0, max = 1) =>
{
    if (typeof number !== "number")
    {
        return number;
    }
    if (min !== false && number < min)
    {
        return min;
    }
    if (max !== false && number > max)
    {
        return max;
    }
    return number;
}

/**
 * Capitalize a string.
 * @param string str - The string.
 * @param boolean firstWord - Whether to only capitalize the first word.
 * @return string - The parsed string.
 */

export const Capitalize = (str, firstWord = false) =>
{
    if (typeof str !== "string")
    {
        return str;
    }
    const RegExp = firstWord ? /(^)(.)/ : /(^|[\s -])(.)/g;
    return str.replace(RegExp, (match, before, char) =>
    {
        return before + char.toUpperCase();
    });
}

export const CanForEach = (obj) =>
{
    return (typeof obj === "object" && typeof obj.forEach === "function");
}

export const ColorToString = (color) =>
{
    if (Array.isArray(color))
    {
        if (color.length === 3)
        {
            const [color1, color2, rotation] = color;
            return `linear-gradient(${rotation}deg, ${color1}, ${color2})`;
        }
        else
        {
            return color[0];
        }
    }
    return color;
}

export const CompareValues = (v1, v2, comparator) =>
{
    switch (comparator)
    {
        case "<": return v1 < v2;
        case "<=": return v1 <= v2;
        case "==":
        case "===": return v1 === v2;
        case "!=":
        case "!==": return v1 !== v2;
        case ">=": return v1 >= v2;
        case ">": return v1 > v2;
        default: return false;
    }
}

export const DateMonth = (date) =>
{
    const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const Parsed = DateParse(date);
    if (!Parsed)
    {
        return "";
    }
    const Month = Months[Parsed[1]];
    const Day = StNdTh(Parsed[0]);
    return Month + ' ' + Day;
}

/**
 * Parse a date.
 * 
 * @param mixed date - The unparsed date.
 * @param boolean format - Whether to return a formatted date.
 * 
 * @return array - [date, month, year] or formatted date.
 */

export const DateParse = (date, format) =>
{
    let D;
    if (typeof date === "number")
    {
        return DateParse(new Date(date), format);
    }
    if (typeof date === "object" && typeof date.getDate === "function")
    {
        D = [
            date.getDate(),
            date.getMonth(),
            date.getFullYear()
       ];
        return format ? DateStamp(D, true) : D;
    }
    if (typeof date === "object" && Array.isArray(date))
    {
        const Dates = [];
        date.forEach(d => Dates.push(DateParse(d, format)));
        return Dates;
    }
    if (typeof date === "string" && date.match(/^\d{4}.\d{2}.\d{2}$/))
    {
        D = [
            parseInt(date.substr(8, 2), 10),
            parseInt(date.substr(5, 2), 10) - 1,
            parseInt(date.substr(0, 4), 10)
       ];
        return format ? DateStamp(D, true) : D;
    }
    let O = (date && typeof date === "string") ? new Date(date) : false;
    if (!O || isNaN(O))
    {
        O = new Date();
    }
    D = [
        O.getDate(),
        O.getMonth(),
        O.getFullYear()
    ];
    return format ? DateStamp(D, true) : D;
}

/**
 * Parse a date into a formatted date stamp.
 * @param object date - The unparsed date.
 * @param boolean noObj - Whether this is not a JS date object.
 * @return string - The formatted date.
 */

export const DateStamp = (date, noObj) =>
{
    const Dt = date || new Date();
    const Y = noObj ? date[2] : Dt.getFullYear();
    const M = PadNumber(noObj ? date[1] + 1 : Dt.getMonth() + 1);
    const D = PadNumber(noObj ? date[0] : Dt.getDate());
    return `${Y}-${M}-${D}`;
}

export const DateTimeParse = (datetime) =>
{
    return DateTimeStamp(new Date(datetime * 1000));
}

export const DateTimeStamp = (date, seconds) =>
{
    return DateStamp(date) + " " + TimeStamp(seconds, date);
}

/**
 * Return a field types default (empty) value.
 * @param string fieldType - Field type.
 * @return mixed - Empty value depending on field type.
 */

export const DefaultValue = (fieldType) =>
{
    switch (fieldType)
    {
        case "checkbox":
            return false;
        case "conditionalWidget":
        case "conditions":
        case "content":
        case "image":
        case "repeater":
        case "widgets":
        case "widgetTabs":
            return [];
        case "number":
            return 0;
        default:
            return "";
    }
}

/**
 * Convert degrees to radians.
 * @param number degrees - Degrees.
 * @return number - Radians.
 */

export const DegreeToRad = (degrees) =>
{
    return degrees / 180 * Math.PI;
}

/**
 * Check if a value is empty.
 * @param mixed value - The value.
 * @return boolean - Whether the value is empty.
 */

export const Empty = (value) =>
{
    if (typeof value !== "object")
    {
        return !value;
    }
    if (value.length === undefined)
    {
        return !Object.keys(value).length;
    }
    return !value.length;
}

/**
 * Returns the floating point remainder (modulo) of the division of the arguments.
 * https://locutus.io/php/math/fmod/
 * @param float x - The dividend.
 * @param float y - The divisor.
 * @return float - The floating point remainder.
 */

export const Fmod = (x, y) =>
{
    const EX = x.toExponential().match(/^.\.?(.*)e(.+)$/);
    const PX = parseInt(EX[2], 10) - String(EX[1]).length;
    const EY = y.toExponential().match(/^.\.?(.*)e(.+)$/);
    const PY = parseInt(EY[2], 10) - String(EY[1]).length;
    const P = Math.max(PX, PY);
    const R = x % y;
    if (P < -100 || P > 20)
    {
        const L1 = Math.round(Math.log(R) / Math.log(10));
        const L2 = Math.pow(10, L1);
        return (R / L2).toFixed(L1 - P) * L2;
    }
    return parseFloat(R.toFixed(-P));
}

/**
 * Simplify a fraction by finding its' least common multiple.
 * @param number num - The number.
 * @return string - The formatted number.
 */

export const Fraction = (numerator, denominator) =>
{
    let A = Math.abs(numerator);
    let B = Math.abs(denominator);
    let T;
    while (B)
    {
        T = B;
        B = A % B;
        A = T;
    }
    return [numerator / A, denominator / A];
}

/**
 * Select a highlight color depending on the luminance of another color.
 * @param string|array color - Color to analyse.
 * @param string colorLight - Highlight color for low luminance.
 * @param string colorDark - Highlight color for high luminance.
 * @return string - colorLight or colorDark depending on luminance of color.
 */

export const HighlightColor = (color, colorLight, colorDark) =>
{
    const Color = StringToRgb(color) || color;
    let Sum = 0;
    for (let i = 0; i < 3; i++)
    {
        Sum += Color[i] || 0;
    }
    return Sum / 3 < 128 ? colorLight : colorDark;
}

/**
 * Check if two lines intesect.
 * @param array $p1 - Start of line 1 descibed as [X, Y].
 * @param array $p2 - End of line 1 descibed as [X, Y].
 * @param array $p3 - Start of line 2 descibed as [X, Y].
 * @param array $p4 - End of line 2 descibed as [X, Y].
 * @return boolean - Whether the lines intersect.
 */

export const LineIntersectCheck = (p1, p2, p3, p4) =>
{
    const O1 = PointOrientation(p1, p2, p3);
    const O2 = PointOrientation(p1, p2, p4);
    const O3 = PointOrientation(p3, p4, p1);
    const O4 = PointOrientation(p3, p4, p2);
    if (O1 !== O2 && O3 !== O4)
    {
        return true;
    }
    if (O1 === 0 && PointOnSegment(p1, p3, p2)) return true;
    if (O2 === 0 && PointOnSegment(p1, p4, p2)) return true;
    if (O3 === 0 && PointOnSegment(p3, p1, p4)) return true;
    if (O4 === 0 && PointOnSegment(p3, p2, p4)) return true;
    return false;
}

/**
 * Format a number.
 * @param number num - The number.
 * @return string - The formatted number.
 */

export const NiceNumber = (num) =>
{
    if (typeof num === "string" && num.match(/[^0-9.,]/))
    {
        return num;
    }
    const Rounded = Math.round(num);
    return Rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

/**
 * Convert linebreaks to br-tags.
 * @param string str - The unparsed string.
 * @return array - Array of parsed JSX.
 */

export const Ln2Br = (str) =>
{
    if (typeof str !== "string")
    {
        return str;
    }
    const Parsed = [];
    const Lines = str.split(/[\n]/);
    Lines.forEach((line, index) =>
    {
        if (index)
        {
            Parsed.push(<br key={index}/>);
        }
        Parsed.push(line);
    });
    return Parsed;
}

/**
 * Prevent orphans in a text.
 * @param string str - The text.
 * @param integer wrap - The minimum length of the last row.
 * @return string - The parsed text.
 */

export const NoOrphans = (str, wrap = 15) =>
{
    if (typeof str !== "string"  || window.innerWidth < 400)
    {
        return str;
    }
    const Words = str.split(" ");
    let Word, Last = ""
    Words.forEach(word =>
    {
        if (Last.length > wrap)
        {
            return;
        }
        Word = Words.pop();
        Last = Last ? Word + "\u00A0" + Last : Word;
    });
    Words.push(Last);
    return Words.join(" ");
}

/**
 * Copy properties from one object to another. Same as Object.assign.
 * @param array|object target - The target object.
 * @param array|object source - The source object.
 * @return object - The target object.
 */

export const ObjectAssign = (target, source) =>
{
    if (typeof target !== "object" || typeof source !== "object")
    {
        return target;
    }
    for (let key in source)
    {
        target[key] = source[key];
    }
    return target;
}

/**
 * Check if two object are identical.
 * @param array|object obj1 - The first object.
 * @param array|object obj2 - The second object.
 * @return boolean - Whether the object are identical.
 */

export const ObjectCompare = (obj1, obj2) =>
{
    if (typeof obj1 !== "object" || typeof obj2 !== "object")
    {
        return obj1 === obj2;
    }
    const Json1 = ObjectPrint(obj1);
    const Json2 = ObjectPrint(obj2);
    return Json1 === Json2;
}

/**
 * Extend a object with new properties.
 * @param object obj1 - The object.
 * @param object obj2 - New properties.
 * @param boolean newObject - Whether to create a new object.
 * @param boolean overwrite - Whether to overwrite exisiting props.
 * @return obj - The extended object.
 */

export const ObjectExtend = (obj1, obj2, newObject, overwrite) =>
{
    if (newObject)
    {
        const Obj = {};
        ObjectExtend(Obj, obj1);
        ObjectExtend(Obj, obj2);
        return Obj
    }
    for (let key in obj2)
    {
        if (obj1[key] === undefined || overwrite)
        {
            obj1[key] = obj2[key];
        }
    }
    return obj1;
}

/**
 * Stringify an object while avoiding circular reference.
 * @param object obj - The object to stringify.
 * @return string - The stringified JSON.
 */

export const ObjectPrint = (obj) =>
{
    const Cache = [];
    return JSON.stringify(obj, (key, value) =>
    {
        // Don't stringify entire React components, since it causes unreliable behaviour. ...
        if (React.isValidElement(value))
        {
            value = value.ref + ObjectPrint(value.props);
        }
        if (typeof value === "object" && value !== null)
        {
            if (Cache.indexOf(value) >= 0)
            {
                return;
            }
            Cache.push(value);
        }
        return value;
    });
}

/**
 * Add leading zeroes to a number.
 * @param number number - The number.
 * @param integer length - Add zeroes until this length is reached.
 * @return string - The padded number.
 */

export const PadNumber = (number, length = 2) =>
{
    let Padded = number.toString();
    while (Padded.length < length)
    {
        Padded = "0" + Padded;
    }
    return Padded;
}

/**
 * Parse a value for input into a field.
 * @param mixed value - Unparsed value.
 * @param string fieldType - Field type.
 * @param mixed defaultValue - Default value when undefined.
 * @return mixed - Parsed value
 */

export const ParsedValue = (value, fieldType, defaultValue) =>
{
    if (value === undefined && defaultValue !== undefined)
    {
        return defaultValue;
    }
    switch (fieldType)
    {
        case "checkbox":
            return (value && value !== "false" && value !== "0");
        case "conditionalWidget":
        case "conditions":
        case "content":
        case "image":
        case "repeater":
        case "widgetTabs":
            return typeof value === "object" ? value : [];
        case "wysiwyg":
            return typeof value === "string" ? value : "";
        case "number":
            return parseInt(value, 10);
        default:
            return value;
    }
}

/**
 * Check if a point lies inside a polygon.
 * @param array point - The point.
 * @param array polygon - The polygon as [[X, Y], ...]
 * @return boolean - Whether the point lies inside the polygon.
 */

export const PointInPolygon = (point, polygon) =>
{
    if (polygon.length < 3)
    {
        return false;
    }
    const Extreme = [999999999, point[1]];
    let Intersects = 0;
    let P1, P2;
    for (let i in polygon)
    {
        P1 = polygon[i];
        P2 = polygon[(parseInt(i, 10) + 1) % polygon.length];
        if (LineIntersectCheck(P1, P2, point, Extreme))
        {
            if (!PointOrientation(P1, point, P2))
            {
                return PointOnSegment(P1, point, P2);
            }
            Intersects++;
        }
    }
    return Intersects % 2 === 1;
}

/**
 * Check if a point lies on a segment.
 * @return boolean - Whether the point lies on the segment.
 */

export const PointOnSegment = ([px, py], [qx, qy], [rx, ry]) =>
{
    const XMx = Math.max(px, rx);
    const XMn = Math.min(px, rx);
    const YMx = Math.max(py, ry);
    const YMn = Math.min(py, ry);
    if (qx <= XMx && qx >= XMn && qy <= YMx && qy >= YMn)
    {
        return true;
    }
    return false;
}

/**
 * Calculate a point orientation.
 * @return integer - Point orientation.
 */

export const PointOrientation = ([px, py], [qx, qy], [rx, ry]) =>
{
    const Orientation = parseFloat(((qy - py) * (rx - qx) - (qx - px) * (ry - qy)).toFixed(9));
    if (Orientation > 0) return 1;
    if (Orientation < 0) return 2;
    return 0;
}

/**
 * Get the absolute position of a node in the DOM.
 * @param object node - The node.
 * @return array - [xPos, yPos]
 */

export const Position = (node) =>
{
    let X = 0;
    let Y = 0;
    if (typeof node !== "object" || node.offsetLeft === undefined)
    {
        return [X, Y];
    }
    while (node)
    {
        X += node.offsetLeft;
        Y += node.offsetTop;
        node = node.offsetParent;
    }
    return [X, Y];
}

/**
 * Format a number into power 10 notation.
 * @param number num - The number.
 * @param integer threshold - The minimum number of digits before the number is formatted.
 * @param integer precision - The decimal precision of the formatted number.
 * @param boolean JSX - Whether to format into JSX (using <sup> instead of special chars).
 * @return string|number - The parsed number.
 */

export const Power10 = (num, threshold = 6, precision = 2, jsx = true) =>
{
    let Power = String(num).length - 1;
    if (Power < threshold)
    {
        return num;
    }
    const Mult1 = Math.pow(10, Power);
    const Mult2 = Math.pow(10, precision);
    const Factor = String(Math.round(num / Mult1 * Mult2) / Mult2);
    const Super = ["\u2070", "\u00B9", "\u00B2", "\u00B3", "\u2074", "\u2075", "\u2076", "\u2077", "\u2078", "\u2079"]; 
    Power = String(Power);
    if (jsx)
    {
        return `${Factor} * 10<sup>${Power}</sup>`;
    }
    let PowerStr = "";
    for (let i = 0; i < Power.length; i++)
    {
        PowerStr += Super[parseInt(Power.substr(i, 1), 10)];
    };
    return `${Factor} * 10${PowerStr}`;
}

/**
 * Convert radians to degrees.
 * @param number radians - Radians.
 * @return number - Degrees.
 */

export const RadToDegree = (radians) =>
{
    return radians / Math.PI * 180;
}

/**
 * Generate a random token.
 * @param number length - Token length.
 * @return string - The token.
 */

export const RandomToken = (length = 16) =>
{
    const Chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvxyz012345678901234567890123456789";
    let Token = "";
    for (var i = 0; i < length; i++)
    {
        Token += Chars.charAt(Math.round(Math.random() * (Chars.length - 1)));
    }
    return Token;
}

/**
 * Convert a RGB(A) array to a HEX string.
 * @param array color - RGB(A) array.
 * @return string|boolean - HEX string. 'False' on fail.
 */

export const RgbToHex = (color) =>
{
    if (typeof color !== "object" || color.length < 3)
    {
        return false;
    }
    const RGB = color.slice(0, 3);
    RGB.forEach((c, i) =>
    {
        const H = c.toString(16);
        RGB[i] = H.length < 2 ? "0" + H : H;
    });
    return "#" + RGB.join("");
}

/**
 * Convert a RGB(A) array to a color string.
 * @param array color - RGB(A) array.
 * @return string|boolean - Color string. 'False' on fail.
 */

export const RgbToString = (color) =>
{
    if (typeof color !== "object")
    {
        return false;
    }
    const [R, G, B, A] = color;
    if (A !== undefined && A !== 1)
    {
        return `rgba(${R},${G},${B},${A})`;
    }
    return `rgb(${R},${G},${B})`;
}

/**
 * Parse a string into a slug.
 * @param string str - The string.
 * @return string - The slug.
 */

export const Slug = (str) =>
{
    let Parsed = str.toLowerCase();
    Parsed = Parsed.replace(/[ÃƒÂ¥ÃƒÂ¤ÃƒÂ¡Ãƒ ÃƒÂ¢Ãƒâ€¦Ãƒâ€žÃƒÂÃƒâ‚¬Ãƒâ€ž]/g, "a");
    Parsed = Parsed.replace(/[ÃƒÂ«ÃƒÂ©ÃƒÂ¨ÃƒÂªÃƒâ€¹Ãƒâ€°ÃƒË†ÃƒÅ]/g, "e");
    Parsed = Parsed.replace(/[ÃƒÂ¯ÃƒÂ­ÃƒÂ¬ÃƒÂ®ÃƒÂÃƒÂÃƒÅ’ÃƒÅ½]/g, "i");
    Parsed = Parsed.replace(/[ÃƒÂ¶ÃƒÂ³ÃƒÂ²ÃƒÂ´Ãƒâ€“Ãƒâ€œÃƒâ€™Ãƒâ€]/g, "o");
    Parsed = Parsed.replace(/[ÃƒÂ¼ÃƒÂºÃƒÂ¹ÃƒÂ»ÃƒÅ“ÃƒÅ¡Ãƒâ„¢Ãƒâ€º]/g, "u");
    Parsed = Parsed.replace(/[.-]{1,}/g, "-");
    Parsed = Parsed.replace(/[^a-z0-9.]/g, "");
    return Parsed;
}

/**
 * Parse a string into a slug with a maximum length.
 * @param string str - The string.
 * @param integer maxLength - The maximum length.
 * @return string - The slug.
 */

export const SlugShort = (str, maxLength = 20) =>
{
    return Slug(str).substr(0, maxLength);
}

/**
 * Inject vars into a string.
 * @param string str - The string.
 * @param object vars - The vars.
 * @return string - The parsed string.
 */

export const Sprintf = (str, vars) =>
{
    if (typeof vars !== "object")
    {
        return str;
    }
    let Inject = 0;
    return str.replace(/%([a-z])/g, (matches) =>
    {
        let Key = Inject++;
        return vars[matches[1]] || vars[Key] || "";
    });
}

export const StNdTh = (num) =>
{
    const Str = String(num);
    const Last = parseInt(Str.charAt(Str.length - 1));
    const Secl = parseInt(Str.charAt(Str.length - 2));
    if (Last === 1 && Secl !== 1)
    {
        return num + "st";
    }
    if (Last === 2 && Secl !== 1)
    {
        return num + "nd";
    }
    return num + "th";
}

/**
 * Stip tabs and excessive line breaks from a string.
 * @param string str - The string.
 * @return string - The parsed string.
 */

export const StringClean = (str) =>
{
    let Parsed = str;
    Parsed = Parsed.replace(/^\s+|\t/gi, "");
    Parsed = Parsed.replace(/\n +/gi, "\n");
    Parsed = Parsed.replace(/\n{3,}/, "\n\n");
    return Parsed;
}

/**
 * Convert a color string to a RGB(A) array.
 * @param string color - Color string.
 * @param boolean includeAlpha - Whether to include alpha.
 * @return array|boolean - RGB(A) array. 'False' on fail.
 */

export const StringToRgb = (color, includeAlpha = true) =>
{
    if (typeof color !== "string")
    {
        return false;
    }
    let RGBA;
    if (color.substr(0, 1) === "#")
    {
        if (color.length < 4)
        {
            return false;
        }
        RGBA = color.substr(1).match(color.length < 7 ? /[a-f\d]{1}/gi : /[a-f\d]{2}/gi);
        for (let i in RGBA)
        {
            RGBA[i] = parseInt(RGBA[i], 16);
        }
        RGBA[3] = RGBA[3] === undefined ? 1 : RGBA[3] / 255;
        return includeAlpha ? RGBA : RGBA.slice(0, 3);
    }
    if (color.substr(0, 3) === 'hsl')
    {
        const HSLA = color.replace(/[^0-9,.]/g, "").split(",");
        const H = parseFloat(HSLA.shift());
        const S = parseFloat(HSLA.shift()) / 100;
        const L = parseFloat(HSLA.shift()) / 100;
        const A = HSLA.length ? parseFloat(HSLA.shift()) / 1 : 1;
        const C = (1 - Math.abs(2 * L - 1)) * S;
        const X = C * (1 - Math.abs(Fmod((H / 60), 2) - 1));
        const M = L - C / 2;
        if (H < 60)
        {
            RGBA = [C, X, 0];
        }
        else if (H < 120)
        {
            RGBA = [X, C, 0];
        }
        else if (H < 180)
        {
            RGBA = [0, C, X];
        }
        else if (H < 240)
        {
            RGBA = [0, X, C];
        }
        else if (H < 300)
        {
            RGBA = [X, 0, C];
        }
        else
        {
            RGBA = [C, 0, X];
        }
        for (let i in RGBA)
        {
            RGBA[i] = Math.floor((RGBA[i] + M) * 255);
        }
        if (includeAlpha)
        {
            RGBA.push(A);
        }
        return RGBA;
    }
    if (color.substr(0, 3).toLowerCase() === "rgb")
    {
        RGBA = color.replace(/[^0-9,.]/g, "").split(",");
        for (let i in RGBA)
        {
            RGBA[i] = i < 3 ? parseInt(RGBA[i], 10) : parseFloat(RGBA[i]);
        }
        return includeAlpha ? RGBA : RGBA.slice(0, 3);
    }
    return false;
}

/**
 * Calculate a sting height.
 * @param string str - The string.
 * @param number fontSize - Font size.
 * @param string fontFamily - Font family.
 * @return number - Calculated string height.
 */

export const StringHeight = (str, fontSize, fontFamily) =>
{
    const Size = StringSize(str, fontSize, fontFamily);
    return Size[1];
}

/**
 * Calculate a sting size.
 * @param string str - The string.
 * @param number fontSize - Font size.
 * @param string fontFamily - Font family.
 * @return array - Calculated size as [width, height].
 */

export const StringSize = (str, fontSize, fontFamily) =>
{
    const Element = document.createElement("span");
    document.body.appendChild(Element);
    Element.style.fontFamily = fontFamily; 
    Element.style.fontSize = fontSize + "px"; 
    Element.style.height = "auto"; 
    Element.style.width = "auto"; 
    Element.style.position = "absolute"; 
    Element.style.whiteSpace = "no-wrap";
    Element.style.lineHeight = "normal";
    Element.innerHTML = str;
    // I hate using these random scale factors.
    // const W = Element.offsetWidth * 1.0676;
    // const H = Element.offsetHeight * 1.0392;
    const W = Element.offsetWidth;
    const H = Element.offsetHeight;
    document.body.removeChild(Element);
    return [W, H];
}

/**
 * Calculate a sting width.
 * @param string str - The string.
 * @param number fontSize - Font size.
 * @param string fontFamily - Font family.
 * @return number - Calculated string width.
 */

export const StringWidth = (str, fontSize, fontFamily) =>
{
    const Size = StringSize(str, fontSize, fontFamily);
    return Size[0];
}

export const StripTags = (str) =>
{
    return typeof str === "string" ? str.replace(/<[^>]*>/g, "") : "";
}

/**
 * Return the current time stamp.
 * @return integer - The current time.
 */

export const Time = () =>
{
    return (new Date()).getTime();
}

/**
 * Parse timestamp.
 * @param string time - Unparsed timestamp.
 * @param boolean seconds - Whether to include seconds.
 * @return string - Formatted timestamp.
 */

export const TimeParse = (time, seconds = false) =>
{
    if (typeof time === "number")
    {
        return TimeStamp(seconds, new Date(time));
    }
    const [H, M, S] = (time || "").split(":");
    const Time = [
        PadNumber(CapFloat(parseInt(H, 10) || 0, 0, 23)),
        PadNumber(CapFloat(parseInt(M, 10) || 0, 0, 59)),
        PadNumber(CapFloat(parseInt(S, 10) || 0, 0, 59))
   ];
    if (!seconds)
    {
        Time.pop();
    }
    return Time.join(":");
}

export const TimeSince = (time, fromGmt = false) =>
{
    let T;
    if (typeof time === "number" && fromGmt)
    {
        const Gmt = new Date(time * 1000);
        T = new Date(Gmt.toString().replace(/\+.*/, ""));
    }
    else
    {
        T = typeof time === "number" ? new Date(time * 1000) : time;
    }
    const N = new Date();
    if (typeof T.getTime !== "function")
    {
        return "unknown time"
    }
    const S = (N.getTime() - T.getTime()) / 1000;
    if (S < 300)
    {
        return "just now";
    }
    if (S < 3600)
    {
        return Math.round(S / 60) + " minutes ago";
    }
    if (S < 86400)
    {
        const H = Math.round(S / 3600);
        return H === 1 ? "about 1 hour ago" : `over ${H} hours ago`;
    }
    if (S < 604800)
    {
        const D = Math.round(S / 86400);
        return D === 1 ? "about 1 day ago" : `${D} days ago`;
    }
    if (S < 2592000)
    {
        const W = Math.round(S / 604800);
        return W === 1 ? "about 1 week ago" : `${W} weeks ago`;
    }
    if (S < 31536000)
    {
        const M = Math.round(S / 2592000);
        return M === 1 ? "about 1 month ago" : `${M} months ago`;
    }
    const Y = Math.round(S / 31536000);
    return Y === 1 ? "about 1 year ago" : `${Y} years ago`;
}

/**
 * Get the current timestamp.
 * @param boolean seconds - Whether to include seconds.
 * @return string - The timestamp.
 */

export const TimeStamp = (seconds = false, date) =>
{
    const D = date || new Date();
    const H = D.getHours();
    const M = D.getMinutes();
    if (!seconds)
    {
        return PadNumber(H) + ":" + PadNumber(M);
    }
    const S = D.getSeconds();
    return PadNumber(H) + ":" + PadNumber(M) + ":" + PadNumber(S);
}

/**
 * Stip non-apphabetic chars from the start and end of a string.
 * @param string str - The string.
 * @return string - The parsed string.
 */

export const Trim = (str) =>
{
    return str.replace(/^\s+|\s+$/, "");
}

/**
 * Make the first char of a string uppercase.
 * @param string str - The string.
 * @return string - The parsed string.
 */

export const UcFirst = (str) =>
{
    const First = str.substr(0, 1);
    const Rest = str.substr(1);
    return First.toUpperCase() + Rest;
}

/**
 * Wrap a number at a lower- and upper limit.
 * @param number number - The number.
 * @param number lower - The lower limit.
 * @param number upper - The upper limit.
 * @return number - The capped number.
 */

export const WrapFloat = (number, lower = -1, upper = 1) =>
{
    if (typeof number !== "number")
    {
        return number;
    }
    const Delta = upper - lower;
    if (number > upper)
    {
        return lower + (number - upper) % Delta;
    }
    if (number < lower)
    {
        return upper - (lower - number) % Delta;
    }
    return number;
}