
/**!
 *  Communication between frames.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Auth from "Class/Auth";
import Globals from "Class/Globals";
import Fuse from "Class/Fuse";
import {renderToString} from "react-dom/server";

import ToggleButton from "Components/UI/ToggleButton";

class Broadcast
{
    /**
     * Receive message from another frame.
     * 
     * @param object e - The message event object.
     * @return void.
     */

    OnMessage = (e) =>
    {
        if (this.OriginAllowed.indexOf(e.origin) < 0)
        {
            return;
        }
        let Data;
        try
        {
            Data = JSON.parse(e.data);
        }
        catch (e)
        {
            return;
        }
        const {type, data} = Data;
        const {
            action,
            attributes,
            className,
            content,
            contentId,
            context,
            contextId,
            draft,
            edit,
            host,
            hover,
            id,
            key,
            params = {},
            request,
            size,
            scope,
            token,
            user,
            userData,
            vars,
            view,
            widgetData,
            widgets,
            x,
            y
        } = data || {};
        Globals.Trigger(`broadcast-${type}`, data);
        switch (type)
        {
            case "action":
                Globals.ViewAction(id, action);
                break;
            case "active":
                Globals.ViewActive(id, false);
                break;
            case "attributes":
                Globals.ViewAttributes(id, attributes);
                break;
            case "button":
                const Button = renderToString(<ToggleButton
                    className={className}
                    size={size}
                />);
                this.SendMessage({
                    type: "button",
                    button: Button
                });
                break;
            case "clear":
                Globals.ViewClear(id, content, scope, edit);
                break;
            case "clear-drafts":
                Globals.ViewClearDrafts(id);
                break;
            case "content":
                Globals.ContentUpdate(id, content);
                break;
            case "draft":
                Globals.ViewDraft(id, draft);
                break;
            case "hover":
                Globals.ViewHover(id, hover, false);
                break;
            case "initial":
                Globals.ViewInitial(id, widgets);
                break;
            case "load":
                Globals.SetVars(vars);
                Globals.Var("params", params);
                Auth.SetCredentials(user, token, key, userData);
                if (context)
                {
                    Fuse.SetContext(context, contextId);
                }
                if (host)
                {
                    Fuse.SetHost(host);
                    this.SetFuseHost(host);
                }
                break;
            case "manager-widgets":
                Globals.ViewManagerWidgetsData(id, widgetData);
                break;
            case "position":
                Globals.ViewPosition(id, x, y);
                break;
            case "register":
                Globals.ViewRegister(id, view);
                break;
            case "request":
                Globals.ViewRequest(id, request);
                break;
            case "restore":
                Globals.ViewRestore(id);
                break;
            case "save":
                Globals.ViewSave(id, draft, contentId);
                break;
            case "unregister":
                Globals.ViewUnregister(id);
                break;
        }
    }

    /**
     * Send message to other frames.
     * 
     * @param mixed message - Message data.
     * @return void.
     */

    SendMessage = (message) =>
    {
        if (!this.OriginFuse)
        {
            console.error("No origin has been defined.")
            return;
        }
        window.parent.postMessage(JSON.stringify(message), this.OriginFuse);
    }

    /**
     * Set Fuse host.
     * 
     * @param mixed message - Message data.
     * @return void.
     */

    SetFuseHost = (host) =>
    {
        if (this.OriginAllowed.indexOf(host) < 0)
        {
            console.error("Unallowed origin.", host);
            return;
        }
        this.OriginFuse = host;
    }

    Setup()
    {
        const {host, protocol} = window.location;
        this.OriginAllowed = Globals.Setting("OriginAllowed", []);
        this.OriginFuse = "";
        this.OriginSelf = `${protocol}//${host}`;
        this.Supported = typeof window.postMessage === "function";
        if (!this.Supported)
        {
            console.error("Post Message is not supported in this browser.");
        }
        else
        {
            window.addEventListener("message", this.OnMessage, false);
        }
    }

    /**
     * Broadcast a widget.
     * 
     * @param object - Widget object.
     * @return void.
     */

    Widget = (widget) =>
    {
        this.SendMessage({
            type: "widget",
            widget
        });
    }
}

export default new Broadcast();