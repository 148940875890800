/**!
 *  SuccessFactors event widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./successfactors.scss";
import Globals from "Class/Globals";
import SuccessFactors from "Class/SuccessFactors";
import {StripTags}from "Functions";
import Button from "Components/UI/Button";
import Collapsable from "Components/Layout/Collapsable";
import IconButton from "Components/UI/IconButton";
import IconItem from "Components/UI/IconItem";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import Preview from "Components/Layout/Preview";
import Slider from "Components/UI/Slider";
import Spinner from "Components/Feedback/Spinner";
import TextField from "Components/UI/Field/TextField";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetSuccessFactors extends Widget
{
    constructor(props)
    {
        super(props);
        this.Debug = 0;
        this.Fields = this.SetFields({
            title:
            {
                default: "Events",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            showFilter:
            {
                default: true,
                label: "Show filter options",
                type: "checkbox"
            },
            listView:
            {
                label: "Display as list",
                type: "checkbox"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Name = "SuccessFactors";
        this.state =
        {
            done: false,
            error: false,
            expand: false,
            events: [],
            filter: "",
            filterPattern: false,
            loading: false,
            overflow: false,
            showOverflow: false
        };
    }

    /**
     * Load events on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Load();
    }

    /**
     * Hide expanded event.
     * @return void
     */

    Collapse = () =>
    {
        this.setState({
            expand: false,
            overflow: false,
            showOverflow: false 
        });
    }

    DebugItems = () =>
    {
        return [
            {
                componentID: 1,
                description: "Lorem ipsum dolot sit amet.",
                title: "Debug event #1"
            },
            {
                componentID: 2,
                description: "Lorem ipsum dolot sit amet.",
                title: "Debug event #2"
            },
            {
                componentID: 3,
                description: "Lorem ipsum dolot sit amet.",
                title: "Debug event #3"
            },
            {
                componentID: 4,
                description: "Lorem ipsum dolot sit amet.",
                title: "Debug event #4"
            },
            {
                componentID: 5,
                description: "Lorem ipsum dolot sit amet.",
                title: "Debug event #5"
            },
            {
                componentID: 6,
                description: "Lorem ipsum dolot sit amet.",
                title: "Debug event #6"
            }
        ];
    }

    /**
     * Expand an event.
     * @param int index - Event index.
     * @return void
     */

    Expand = (expand) =>
    {
        this.setState({expand});
    }

    /**
     * Output an expanded event view.
     * @return JSX - The expanded view.
     */

    ExpandedView = () =>
    {
        const {events, expand, overflow, showOverflow }= this.state;
        const CA = ["WidgetSuccessFactorsExpand"];
        if (events[expand] === undefined)
        {
            return "";
        }
        if (overflow)
        {
            CA.push("HasOverflow");
        }
        if (showOverflow)
        {
            CA.push("ShowOverflow");
        }
        const {
            componentID: id,
            componentTypeID: typeId,
            description,
            revisionDate: date,
            title
        } = events[expand];
        const Url = Globals.Setting("SuccessFactorsEventUrl", "").replace(/\$(DATE|ID|TYPE)\$/g, (m, slug) =>
        {
            switch (slug)
            {
                case "DATE": return date;
                case "TYPE": return typeId;
                default: return id;
            }
        });
        return (
            <div className={CA.join(" ")}>
                <IconButton
                    className="WidgetSuccessFactorsClose"
                    onClick={this.Collapse}
                    feather="X"
                    size={30}
                />
                <div className="WidgetSuccessFactorsExpandContent">
                    <h2>{title}</h2>
                    <Collapsable
                        className="WidgetSuccessFactorsExpandText"
                        collapsed={!showOverflow}
                        collapseHeight={100 }
                        onOverflow={this.OnOverflow}
                    >
                        {description ? this.Parse(description) : (
                            <p className="WidgetSuccessFactorsExpandNoText">
                                This event has no description.
                            </p>
                        )}
                    </Collapsable>
                    {overflow ? <IconItem
                        className="WidgetSuccessFactorsExpandMore"
                        feather={showOverflow ? "ChevronUp" : "ChevronDown"}
                        label={showOverflow ? "Show less" : "Read more"}
                        onClick={this.ToggleMore}
                    /> : ""}
                    <div className="WidgetSuccessFactorsExpandButtons">
                        <Button href={Url} label="Register Now"/>
                        <Button hollow={true} label="Close" onClick={this.Collapse}/>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Output event item.
     * @param object event - Event object.
     * @param int index - Event index.
     * @return JSX - The event item.
     */

    Item = (event, index) =>
    {
        const {rowHeight} = this.props;
        const {filterPattern} = this.state;
        const {listView, showFilter} = this.Content();
        const {componentID: id, description, title} = event;
        const Description = StripTags(description || "");
        const Title = StripTags(title || "");
        const PreviewUrl = Globals.Setting("SuccessFactorsImageUrl", "").replace(/%componentID%/g, id);
        if (showFilter && filterPattern && !Title.match(filterPattern) && !Description.match(filterPattern))
        {
            return "";
        }
        return listView ? (
            <Link
                className="WidgetSuccessFactorsListItem"
                key={`${id}-list`}
                onClick={() => this.Expand(index)}
            >
                <Preview
                    className="ItemPreview"
                    content="externallink"
                    image={PreviewUrl}
                />
                <div className="ItemContent">
                    <div className="ItemName">{Title}</div>
                    <div className="ItemInfo">{Description}</div>
                </div>
            </Link>
        ) : (
            <div className="WidgetSuccessFactorsItemWrapper" key={id}>
                <div
                    className="WidgetSuccessFactorsItem"
                    onClick={() => this.Expand(index)}
                    style={{height: 270 * rowHeight}}
                >
                    <LoadImage
                        className="WidgetSuccessFactorsItemBackground"
                        src={PreviewUrl}
                    />
                    <div className="WidgetSuccessFactorsItemLabel">{Title}</div>
                </div>
            </div>
        );
    }

    /**
     * Load events from SuccessFactors.
     * @param boolean clear - Whether to clear the widget before loading new events.
     * @return void
     */

    Load = (clear) =>
    {
        if (this.Debug)
        {
            this.setState({events: this.DebugItems()});
            return;
        }
        const State =
        {
            error: false,
            loading: true
        };
        if (clear)
        {
            State.events = [];
        }
        this.setState(State);
        SuccessFactors.Learning(events =>
        {
            if (!events)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({events, loading: false});
            }
        });
    }

    /**
     * Callback when the filter field is edited.
     * @param object e - The event object.
     * @param string filter - The new filter.
     * @return void.
     */

    OnFilter = (e, filter) =>
    {
        const FilterPattern = filter ? new RegExp(filter, "i") : false;
        this.setState({filter, filterPattern: FilterPattern});
    }

    /**
     * Toggle the visibility of the 'Read more' button depending on if the
     * text container has an overflow.
     * @param bool overflow - Whether the text container has an overflow.
     * @return void.
     */

    OnOverflow = (overflow) =>
    {
        this.setState({overflow});
    }

    /**
     * Parse a event description text in order to render paragraphs and links.
     * @param string input - Unparsed text.
     * @param mixed key - Prepend a key to each element.
     * @return JSX - Parsed text.
     */

    Parse = (input, key = "") =>
    {
        if (!input)
        {
            return "";
        }
        const Content = [];
        const Pattern = /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]|\b[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}\b/gi;
        const IsMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}/i;
        const Clean = input.replace(/"+/g, "\"").replace(/^"|"$/g, "");
        const Rows = Clean.split("\n");
        Rows.forEach((row, index) =>
        {
            if (!row.match(/[a-z0-9]/i))
            {
                return;
            }
            const Paragraph = [];
            const Links = row.match(Pattern) || [];
            const Text = row.split(Pattern);
            Text.forEach((text, i) =>
            {
                Paragraph.push(text);
                if (Links[i])
                {
                    const Href = Links[i].match(IsMail) ? `mailto:${Links[i]}` : Links[i];
                    Paragraph.push(
                        <Link key={key + index + "_" + i} href={Href}>{Links[i]}</Link>
                    );
                }
            });
            Content.push(<p key={key + index}>{Paragraph}</p>);
        });
        return Content;
    }

    ToggleMore = () =>
    {
        const {showOverflow}= this.state;
        this.setState({showOverflow: !showOverflow});
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover, rowHeight} = this.props;
        const {events, filter, loading} = this.state;
        const {listView, showFilter} = WidgetContent;
        const CA = ["Widget", "WidgetSuccessFactors", "White", "BorderRadius"];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");   
        }
        if (loading)
        {
            CA.push("Loading");
        }
        const Content = [];
        const Events = [];
        events.forEach(event => Events.push(event));
        if (!Events.length && loading)
        {
            Content.push(<Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (!Events.length)
        {
            Content.push(<div
                className="WidgetEmpty"
                key="empty1"
            >No events</div>);
        }
        else
        {
            const Items = [];
            Events.forEach((event, index) =>
            {
                const Item = this.Item(event, index);
                if (Item)
                {
                    Items.push(Item);
                }
            });
            if (Items.length)
            {
                Content.push(listView ? (
                    <div className="WidgetSuccessFactorsItemsList" key="list">{Items}</div>
                ) : (
                    <div className="WidgetSuccessFactorsAlign" key="items">
                        <Slider
                            className="WidgetSuccessFactorsItems"
                            style={{height: 280 * rowHeight}}
                        >{Items}</Slider>
                    </div>
                ));
            }
            else
            {
                Content.push(<div className="WidgetEmpty" key="empty2">No matching events</div>);
            }
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.ExpandedView()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetSuccessFactorsWrapper"
                    toolbar={showFilter ? <TextField
                        className="WidgetSuccessFactorsFilter"
                        feather="Search"
                        onChange={this.OnFilter}
                        onInput={this.OnFilter}
                        placeholder="Search..."
                        value={filter}
                    /> : ""}
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetSuccessFactors;