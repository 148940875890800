/*!
 *  Color select form field.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value. 
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onChange - Callback function.
 *  @prop string value - Field value.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./colorfield.scss";
import Globals from "Class/Globals";
import {RandomToken} from "Functions";
import IconButton from "Components/UI/IconButton";
import IconItem from "Components/UI/IconItem";
import NumberField from "Components/UI/Field/NumberField";
import Sticky from "Components/Layout/Sticky";

class ColorField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Input1 = false;
        this.Input2 = false;
        this.Token = RandomToken();
        this.state =
        {
            focus: false,
            reset: 0,
            value: "#ffffff",
            width: 300
        };
    }

    /**
     * Set initial value and options and add listeners.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        this.SetValue(value);
        this.SetSize();
        window.addEventListener("resize", this.SetSize);
    }

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        const {value: v3} = this.state;
        if (v1 !== v2 && v1 !== v3)
        {
            this.SetValue(v1);
        }
    }

    ColorItem = (color, key) =>
    {
        const {value} = this.state;
        const CA = ["ColorFieldItem"];
        if (value === color)
        {
            CA.push("Selected");
        }
        return (
            <div
                className={CA.join(" ")}
                onClick={e => this.OnPalette(e, color)}
                key={key}
            >
                <div className="ColorFieldItemPreview" style={{backgroundColor: color}}/>
                <span>{this.ParseName(key)}</span>
            </div>
        );
    }

    ColorItems = (items) =>
    {
        const Items = [];
        for (let key in items)
        {
            if (typeof items[key] === "object")
            {
                Items.push(this.ColorGroup(items[key], key));
            }
            else
            {
                Items.push(this.ColorItem(items[key], key));
            }
        }
        return Items;
    }

    ColorGroup = (group, name) =>
    {
        return (
            <div className="ColorFieldGroup" key={name}>
                <div className="ColorFieldGroupName">{name}</div>
                <div className="ColorFieldGroupColors">
                    {this.ColorItems(group)}
                </div>
            </div>
        );
    }

    FormatDirection = (direction) =>
    {
        const unformatted = parseFloat(direction);
        const wrapped = unformatted < 0 ? unformatted * -1 % 360 * -1 : unformatted % 360;
        return wrapped < 0 ? 360 + wrapped : wrapped;
    }

    GetColors = () =>
    {
        const {gradient} = this.props;
        const {value} = this.state;
        if (!Array.isArray(value))
        {
            return [value];
        }
        if (!gradient)
        {
            return [value[0]];
        }
        return value;
    }

    OnBlur = () =>
    {
        this.BlurTimer = setTimeout(() => this.setState({focus: false}), 200);
    }

    OnBlurDirection = (e) =>
    {
        const {reset} = this.state;
        this.setState({reset: reset + 1});
    }

    OnClear = (e, index) =>
    {
        const {
            disabled,
            gradient,
            emptyValue,
            id,
            onChange
        } = this.props;
        if (disabled)
        {
            return;
        }
        if (!gradient)
        {
            this.setState({value: emptyValue});
            onChange(e, emptyValue, id)
            return;
        }
        const {value} = this.state;
        const Value = Array.isArray(value) ? value : [value];
        Value[index] = emptyValue;
        this.setState({value: Value});
        onChange(e, Value, id)
    }

    /**
     * Clear the field value.
     * @param object e - The event object.
     * @return void
     */

    OnClearField = (e) =>
    {
        e.preventDefault();
        e.stopPropagation();
        const {id, onClear} = this.props;
        onClear(e, id);
    }

    OnDirection = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        if (disabled)
        {
            return;
        }
        const Value = this.FormatDirection(e.currentTarget.value);
        const Set = this.SetValue(Value, 2);
        onChange(e, Set, id);
    }

    OnFocus = (e) =>
    {
        clearTimeout(this.BlurTimer);
        const {disabled} = this.props;
        if (disabled)
        {
            return;
        }
        const Index = parseInt(e.currentTarget.id.slice(-1), 10) - 1;
        this.setState({focus: Index});
    }

    OnGradient = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        if (disabled)
        {
            return;
        }
        const {value} = this.state;
        const {pink} = Globals.Setting("Colors", {});
        const Value = Array.isArray(value) ? value : [value];
        Value[1] = pink;
        Value[2] = 135;
        this.setState({value: Value});
        onChange(e, Value, id);
    }

    OnGradientRemove = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        if (disabled)
        {
            return;
        }
        const {value} = this.state;
        const Value = Array.isArray(value) ? value[0] : value;
        this.setState({value: Value});
        onChange(e, Value, id);
    }

    OnInput = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        if (disabled)
        {
            return;
        }
        const Value = e.currentTarget.value;
        const Index = parseInt(e.currentTarget.id.slice(-1), 10) - 1;
        const Set = this.SetValue(Value, Index);
        onChange(e, Set, id);
    }

    OnPalette = (e, value) =>
    {
        const {disabled, id, onChange} = this.props;
        const {focus: index} = this.state;
        if (disabled)
        {
            return;
        }
        const Set = this.SetValue(value, index);
        onChange(e, Set, id);
    }

    OnPick = (e) =>
    {
        const {disabled, id, onChange} = this.props;
        if (disabled)
        {
            return;
        }
        const Value = e.currentTarget.value;
        const Index = parseInt(e.currentTarget.id.slice(-1), 10) - 1;
        const Set = this.SetValue(Value, Index);
        onChange(e, Set, id);
    }

    ParseName = (name) =>
    {
        return name.replace(/^[a-z]|[A-Z]/g, first =>
        {
            return first.match(/[A-Z]/) ? " " + first.toLowerCase() : first.toUpperCase();
        })
    }

    /**
     * Reset to inital state.
     * @return void
     */

    Reset = () =>
    {
        this.setState({value: this.props.value});
    }

    /**
     * Adjust the width of the dropdown menu when the client resizes.
     * @return void
     */

    SetSize = () =>
    {
        if (!this.Input1)
        {
            return;
        }
        this.setState({width: this.Input1.offsetWidth});
    }

    /**
     * Set the field value
     * @return mixed - The new field value.
     */

    SetValue = (set, index = null) =>
    {
        const {gradient} = this.props;
        if (index !== null)
        {
            const {value} = this.state;
            if (!gradient)
            {
                this.setState({value: set});
                return set;
            }
            const Value = Array.isArray(value) ? value : [value];
            Value[index] = set;
            this.setState({value: Value});
            return Value;
        }
        if (Array.isArray(set))
        {
            this.setState({value: set});
            return set;
        }
        const {orange, pink, purple, white} = Globals.Setting("Colors", {});
        let Value;
        switch (set)
        {
            case "gradient":
                Value = gradient ? [orange, pink, 135] : orange;
                break;
            case "orange":
                Value = gradient ? [orange] : orange;
                break;
            case "pink":
                Value = gradient ? [pink] : pink;
                break;
            case "purple":
                Value = gradient ? [purple] : purple;
                break;
            case "white":
                Value = gradient ? [white] : white;
                break;
            default:
                Value = gradient ? [set] : set;

        }
        this.setState({expand: false, value: Value});
        return Value;
    }

    /**
     * Get the selected color.
     * @return string - The selected color.
     */

    Value = () =>
    {
        return this.state.value;
    }

    render()
    {
        const {
            className,
            clear,
            disabled,
            error,
            flip,
            gradient,
            label,
            minimal,
            palette
        } = this.props;
        const {focus, reset, width} = this.state;
        const [color1, color2, direction] = this.GetColors();
        const CA = ["Field", "ColorField"];
        const Colors = palette || Globals.Setting("Colors", {});
        const NumColors = Object.keys(Colors).length;
        let Selector1, Selector2;
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (focus !== false && NumColors)
        {
            CA.push("Expand");
            const Items = this.ColorItems(Colors);
            const Selector = (
                <Sticky
                    align="right"
                    className="ColorFieldSelector"
                    flip={flip}
                    width={width}
                >
                    <div className="ColorFieldItems">
                        {Items}
                    </div>
                </Sticky>
            );
            if (focus)
            {
                Selector2 = Selector;
            }
            else
            {
                Selector1 = Selector;
            }
        }
        if (flip)
        {
            CA.push("Flip");
        }
        if (focus)
        {
            CA.push("Focus");
        }
        if (className)
        {
            CA.push(className);
        }
        if (minimal)
        {
            CA.push("Minimal");
            return (
                <div className={CA.join(" ")}>
                    <label
                        className="ColorFieldPicker"
                        htmlFor={this.Token + "p1"}
                        style={{backgroundColor: color1}}
                    >
                        <input
                            disabled={disabled}
                            id={this.Token + "p1"}
                            onChange={this.OnPick}
                            type="color"
                            value={color1}
                        />
                    </label>
                </div>
            );
        }
        return (
            <div className={CA.join(" ")}>
                {label ? (
                    <label htmlFor={this.Token + "1"}>
                        {label}
                        {clear ? (
                            <IconButton
                                disabled={disabled}
                                feather="XCircle"
                                onClick={this.OnClearField}
                                title="Reset this field"
                            />
                        ) : ""}
                    </label>
                ) : ""}
                <div className="ColorField1">
                    <label
                        className="ColorFieldPicker"
                        htmlFor={this.Token + "p1"}
                        style={{backgroundColor: color1}}
                    >
                        <input
                            disabled={disabled}
                            id={this.Token + "p1"}
                            onChange={this.OnPick}
                            type="color"
                            value={color1}
                        />
                    </label>
                    <input
                        autoComplete="off"
                        className="Input"
                        disabled={disabled}
                        id={this.Token + "1"}
                        onBlur={this.OnBlur}
                        onChange={this.OnInput}
                        onFocus={this.OnFocus}
                        onInput={this.OnInput}
                        ref={input => this.Input1 = input}
                        type="text"
                        value={color1}
                    />
                    <IconButton id={0} feather="X" onClick={this.OnClear}/>
                    {Selector1}
                </div>
                {(gradient && color2) ? [
                    <div className="ColorField2" key="field">
                        <label
                            className="ColorFieldPicker"
                            htmlFor={this.Token + "p2"}
                            style={{backgroundColor: color2}}
                        >
                            <input
                                disabled={disabled}
                                id={this.Token + "p2"}
                                onChange={this.OnPick}
                                type="color"
                                value={color2}
                            />
                        </label>
                        <input
                            autoComplete="off"
                            className="Input"
                            disabled={disabled}
                            id={this.Token + "2"}
                            onBlur={this.OnBlur}
                            onChange={this.OnInput}
                            onFocus={this.OnFocus}
                            onInput={this.OnInput}
                            ref={input => this.Input2 = input}
                            type="text"
                            value={color2}
                        />
                        <IconButton id={1} feather="X" onClick={this.OnClear}/>
                        {Selector2}
                    </div>,
                    <NumberField
                        disabled={disabled}
                        key={`direction${reset}`}
                        label="Gradient Direction"
                        onBlur={this.OnBlurDirection}
                        onChange={this.OnDirection}
                        onInput={this.OnDirection}
                        value={direction}
                    />,
                    <IconItem
                        disabled={disabled}
                        feather="X"
                        key="remove"
                        label="Remove Gradient"
                        onClick={this.OnGradientRemove}
                    />
                ] : (gradient ? <IconItem
                    disabled={disabled}
                    feather="Plus"
                    label="Add Gradient"
                    onClick={this.OnGradient}
                /> : "")}
            </div>            
        );
    }
}

ColorField.propTypes =
{
    className: PropTypes.string,
    clear: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    emptyValue: PropTypes.string,
    flip: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    selectedLabel: PropTypes.func
};

ColorField.defaultProps =
{
    className: "",
    clear: false,
    disabled: false,
    error: false,
    emptyValue: "transparent",
    flip: false,
    gradient: false,
    id: "",
    label: "",
    minimal: false,
    palette: false,
    onBlur: () => {},
    onChange: () => {},
    onClear: () => {},
    onFocus: () => {},
    placeholder: "",
    selectedLabel: () => {},
    value: "#ffffff"
};

export default ColorField;