/**!
 *  Widget containing a grid of sub-widgets.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./multi.scss";
import Globals from "Class/Globals";
import {ArrayClone, CapFloat} from "Functions";
import Grid from "Components/UI/Grid";
import ViewWidget from "Components/View/Widget";

class WidgetMulti extends Widget
{
    constructor(props)
    {
        super(props);
        this.Breaks = [[600, 2], [900, 4]];
        this.ColumnsMax = 6;
        this.ColumnsMin = 1;
        this.Fields =
        {
            columns:
            {
                default: this.ColumnsMax,
                label: "Number of columns",
                type: "number",
                maxValue: this.ColumnsMax,
                minValue: this.ColumnsMin
            },
            widgets:
            {
                columns: "columns",
                label: "Widgets",
                type: "widgets"
            },
            backgroundImage:
            {
                label: "Background Image",
                type: "image"
            },
            backgroundColorMulti:
            {
                displayIf: ["backgroundImage", "===", "empty"],
                gradient: true,
                label: "Background Color",
                type: "color",
                default: "transparent"
            }
        };
        this.Items = false;
        this.Name = "Multiple";
        this.RefGrid = false;
        this.RefWidget = false;
        this.RefWidgets = {};
        this.state =
        {
            autoAdjusts: {},
            fills: {},
            heights: {}
        };
    }

    /**
     * Trigger a re-adjustment of the widget grid.
     * @return void
     */

    Adjust = () =>
    {
        if (!this.RefGrid)
        {
            return;
        }
        this.RefGrid.SetItems(this.Items);
    }

    /**
     * Check if the first widget in the grid is a tab widget.
     * Used to offset the widget toolbar when needed.
     * @return bool - Whether the first widget in the grid is a tab widget.
     */

    FirstWidgetIsTabWidget = () =>
    {
        const {content = {}} = this.props;
        const {widgets: widgetsObject = {}} = content;
        const {widgets = []} = widgetsObject;
        let FirstId;
        widgets.forEach(({id, x, y}) =>
        {
            if (x === 0 && y === 0)
            {
                FirstId = id;
            }
        });
        const Template = Globals.Var(`template-${FirstId}`);
        return Template === "Tabs";
    }

    /**
     * Callback when the size of the grid is adjusted.
     * @param object e - The event object.
     * @param integer [width] - Adjusted width in pixels.
     * @param integer [height] - Adjusted height in pixels.
     * @return void
     */

    OnAdjust = (e, [width, height]) =>
    {
        this.OnHeight(e, height);
    }

    /**
     * Callback when a sub-widgets auto-adjust attribute is toggled.
     * @param object e - The event object.
     * @param boolean autoAdjust - Whether the sub-widget should auto-adjust its' height.
     * @param string id - The unique id of the sub-widget.
     * @return void
     */

    OnItemAutoAdjust = (e, autoAdjust, id) =>
    {
        const {autoAdjusts} = this.state;
        if (autoAdjusts[id] === autoAdjust)
        {
            return;
        }
        autoAdjusts[id] = autoAdjust;
        this.setState({autoAdjusts});
    }

    /**
     * Callback when a sub-widgets fill attribute is toggled.
     * @param object e - The event object.
     * @param boolean fill - Whether the sub-widget should extend to the edge(s) of the grid.
     * @param string id - The unique id of the sub-widget.
     * @return void
     */

    OnItemFill = (e, fill, id) =>
    {
        const {fills} = this.state;
        if (fills[id] === fill)
        {
            return;
        }
        fills[id] = fill;
        this.setState({fills});
    }

    /**
     * Callback when a sub-widgets height has been adjusted.
     * @param object e - The event object.
     * @param integer height - The adjusted height of the sub-widget.
     * @param string id - The unique id of the sub-widget.
     * @return void
     */

    OnItemHeight = (e, height, id) =>
    {
        const {heights} = this.state;
        const Rows = Math.ceil(height / Globals.RowHeight);
        if (heights[id] === Rows)
        {
            return;
        }
        const Heights = ArrayClone(heights);
        Heights[id] = Rows;
        this.setState({heights: Heights});
    }

    /**
     * Trigger a sub widget adjust when its' container has finished
     * transitioning.
     * @param object e - The event object.
     * @param string id - The unique id of the sub-widget.
     * @return void
     */

    OnTransitionEnd = (e, id) => {

        const Widget = this.RefWidgets[id];
        if (!Widget)
        {
            return;
        }
        Widget.Adjust();
    }

    /**
     * Re-draw grid when the widget is updated.
     * @return void
     */

    OnUpdate = () =>
    {
        this.Adjust();
    }

    render()
    {
        const
        {
            active,
            ancestors,
            appearance,
            autoAdjust,
            content,
            contentId,
            context,
            contextId,
            editContainer,
            editContent,
            hover,
            toolbarOffset
        } = this.props;
        const {autoAdjusts, fills, heights} = this.state;
        const {
            background,
            backgroundColorMulti,
            backgroundImage,
            columns,
            widgets: widgetsValue
        } = content;
        const CA = ["Widget", "WidgetMulti", "White"];
        let RowHeights, Widgets;
        if (Array.isArray(widgetsValue))
        {
            Widgets = widgetsValue;
            RowHeights = [];
        }
        else if (typeof widgetsValue === "object")
        {
            const {rowHeights, widgets} = widgetsValue;
            Widgets = widgets;
            RowHeights = rowHeights;
        }
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        const Ancestors = ArrayClone(ancestors);
        const Columns = CapFloat(parseInt(columns, 10) || this.ColumnsMax, this.ColumnsMin, this.ColumnsMax);
        this.Items = [];
        Ancestors.push(contentId);
        let First = false;
        let HadFirst = false;
        (Widgets || []).forEach(widget =>
        {
            const {id, width, height, x, y} = widget;
            if (!id)
            {
                return;
            }
            const X = parseInt(x, 10) || 0;
            const Y = parseInt(y, 10) || 0;
            const W = parseInt(width, 10) || 0;
            const H = parseInt(height, 10) || 0;
            const Height = autoAdjusts[id] ? Math.max(heights[id] || 1, H) : H;
            let RowHeight = 0;
            for (let i = 0; i < Height; i++)
            {
                RowHeight += RowHeights[Y + i] || 1;
            }
            // Detect the first widget in the grid, ie. the one in the top left
            // corner in order to offset its' toolbar so it lies under the
            // Multi Widgets own toolbar. Since multiple widgets can request
            // the top left position, only detect the first occurrence.
            First = !HadFirst && X === 0 && Y === 0;
            if (First)
            {
                HadFirst = true;
            }
            const Item =
            {  
                id,
                width: W || 2,
                height: Height,
                x: X,
                y: Y,
                fill: fills[id] || false,
            };
            Item.content = (
                <ViewWidget
                    ancestors={Ancestors}
                    appearance={appearance}
                    autoAdjust={false}
                    context={context}
                    contextId={contextId}
                    name={id}
                    onAutoAdjust={this.OnItemAutoAdjust}
                    onFill={this.OnItemFill}
                    onHeight={this.OnItemHeight}
                    parent={contentId}
                    ref={widget => this.RefWidgets[id] = widget}
                    rowHeight={RowHeight}
                    toolbarOffset={(First && (editContainer || editContent)) ? toolbarOffset + 1 : 0}
                    universal={true}
                    x={X}
                    y={Y}
                />
            );
            this.Items.push(Item);
        });
        // Fix: Use backgroundColorMulti instead of backgroundColor to avoid
        // setting unwanted background colors in old widgets.
        const Style = this.Style();
        delete Style.background;
        delete Style.backgroundColor;
        if ((background || backgroundColorMulti) && (!backgroundImage || !backgroundImage.length))
        {
            this.StyleBackgroundColor(backgroundColorMulti, Style);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={Style}>
                {this.BackgroundImage()}
                <Grid
                    autoAdjust={autoAdjust}
                    className="WidgetMultiContent"
                    columns={Columns}
                    columnBreaks={this.Breaks}
                    forceFullRowHeightOnNarrowScreens={true}
                    interactive={false}
                    items={this.Items}
                    maxWidth={1200}
                    onAdjust={this.OnAdjust}
                    onTransitionEnd={this.OnTransitionEnd}
                    ref={grid => this.RefGrid = grid}
                    rowHeight={Globals.RowHeight}
                    rowHeights={RowHeights}    
                />
                {this.Toolbar(true, this.FirstWidgetIsTabWidget())}
            </div>
       );
    }
}

export default WidgetMulti;