import React from "react";
import "./videoplayer.scss";
import Fuse from "Class/Fuse";
import {ObjectCompare, PadNumber} from "Functions";
import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";
import LoadImage from "Components/Layout/LoadImage";
import Progress from "Components/Feedback/Progress";
import Spinner from "Components/Feedback/Spinner";

class VideoPlayer extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Player = false;
        this.Mounted = false;
        this.state =
        {
            data: false,
            error: false,
            id: false,
            labelLeft: "00:00",
            labelRight: "00:00",
            liked: false,
            likes: 0,
            loading: false,
            muted: false,
            play: false,
            played: false,
            playing: false,
            progress: 0,
            views: 0
        }
    }

    componentDidMount()
    {
        const {contentId, muted} = this.props;
        this.Mounted = true;
        this.Load(contentId);
        this.setState({muted});
    }

    componentDidUpdate(prevProps)
    {
        const {contentId: i1} = this.props;
        const {contentId: i2} = prevProps;
        if (!ObjectCompare(i1, i2))
        {
            this.Load(i1);
        }
    }

    componentWillUnmount()
    {
        clearInterval(this.ProgressInterval);
        this.Mounted = false;
    }

    Controls = () =>
    {
        const {interactions} = this.props;
        const {
            data,
            labelLeft,
            labelRight,
            liked,
            muted,
            played,
            progress
        } = this.state;
        const {
            external_url
        } = data || {};
        return (
            <div className="VideoPlayerControls" key="controls">
                <div className="VideoPlayerControlsLeft">
                    <IconButton
                        feather={played ? "Pause" : "Play"}
                        onClick={this.TogglePlay}
                    />
                    <IconButton
                        feather={muted ? "VolumeX" : "Volume2"}
                        onClick={this.ToggleMute}
                    />
                </div>
                {interactions ? <div className="VideoPlayerControlsRight">
                    <IconButton
                        active={liked}
                        feather={"Heart"}
                        onClick={this.ToggleLike}
                    />
                    <IconButton
                        feather="ExternalLink"
                        href={external_url}
                    />
                </div> : ""}
                <div className="VideoPlayerControlsSeek">
                    <Progress
                        leftLabel={labelLeft}
                        interactive={true}
                        onInteraction={this.OnSeek}
                        onInteractionEnd={this.OnSeekEnd}
                        onInteractionStart={this.OnSeekStart}
                        progress={progress}
                        rightLabel={labelRight}
                    />
                </div>
            </div>
        );
    }

    Load = (contentId) =>
    {
        if (!contentId)
        {
            return;
        }
        this.setState({
            error: false,
            loading: true
        });
        Fuse.Content(contentId, "video", file =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {id, onLoad} = this.props;
            if (!file || !file.raw)
            {
                this.setState({
                    error: true,
                    loading: false
                }, () => onLoad(false, contentId, id));
            }
            else
            {
                this.setState({
                    data: file.raw,
                    id: file.id,
                    liked: file.raw.liked_by_me,
                    likes: file.raw.likes_count,
                    loading: false,
                    views: file.raw.total_view_count
                }, () => onLoad(file.raw?.composite?.high_quality_link || false, contentId, id));
            }
        });
    }

    OnPause = (e) =>
    {
        const {onPause, src} = this.props;
        clearInterval(this.ProgressInterval);
        this.setState({
            played: false,
            playing: false
        });
        onPause(e, src);
    }

    OnPlay = (e) =>
    {
        const {onPlay, src} = this.props;
        this.ProgressInterval = setInterval(this.OnUpdate, 33);
        this.setState({
            played: true,
            playing: true
        });
        onPlay(e, src);
    }

    OnPlayer = (player) =>
    {
        this.Player = player;
    }

    OnPlayPoster = () =>
    {
        this.setState({
            play: true,
            playing: true
        });
    }

    OnPoster = (src) =>
    {
        const {id, onPoster} = this.props;
        onPoster(src, id);
    }

    OnSeek = (e, progress) =>
    {
        if (!this.Player)
        {
            return;
        }
        this.Player.currentTime = progress * this.Player.duration;
        this.OnUpdate();
    }

    OnSeekEnd = () =>
    {
        if (this.PlayOnSeek)
        {
            this.Play();
        }
    }

    OnSeekStart = () =>
    {
        const {played} = this.state;
        if (played)
        {
            this.PlayOnSeek = true;
            this.Pause();
        }
    }

    OnUpdate = () =>
    {
        if (!this.Player)
        {
            return;
        }
        const {currentTime, duration} = this.Player;
        const S1 = Math.round(currentTime % 60);
        const S2 = Math.round(duration % 60);
        const M1 = Math.floor(currentTime / 60);
        const M2 = Math.floor(duration / 60);
        const L1 = PadNumber(M1) + ":" + PadNumber(S1);
        const L2 = PadNumber(M2) + ":" + PadNumber(S2);
        this.setState({
            labelLeft: L1,
            labelRight: L2,
            progress: currentTime / duration
        });
    }

    Pause = () =>
    {
        if (!this.Player)
        {
            return;
        }
        const {played} = this.state;
        if (played)
        {
            this.Player.pause();
        }
        this.setState({
            played: false
        });
    }

    Play = () =>
    {
        if (!this.Player)
        {
            return;
        }
        const {played} = this.state;
        if (!played)
        {
            this.Player.play();
        }
        this.setState({
            played: true
        });
    }

    TogglePlay = () =>
    {
        if (!this.Player)
        {
            return;
        }
        const {played} = this.state;
        if (played)
        {
            this.Player.pause();
        }
        else
        {
            this.Player.play();
        }
        this.setState({
            played: !played
        });
    }

    ToggleLike = () =>
    {
        const {id, liked} = this.state;
        if (!id)
        {
            return;
        }
        const Like = !liked;
        this.setState({liked: Like});
        if (Like)
        {
            Fuse.Request(`contents/${id}/like`, {}, null, "POST");
        }
        else
        {
            Fuse.Request(`contents/${id}/like`, {}, null, "DELETE");
        }
    }

    ToggleMute = () =>
    {
        const {muted} = this.state;
        this.setState({muted: !muted});
    }

    render()
    {
        const {className, posterImage, showPreview} = this.props;
        const {
            data,
            error,
            interactions,
            likes,
            loading,
            muted,
            play,
            playing,
            views
        } = this.state;
        const CA = ["VideoPlayer"];
        const Content = [];
        if (error)
        {
            CA.push("Error");
        }
        else if (loading)
        {
            CA.push("Loading");
            Content.push(
                <Spinner
                    className="VideoPlayerSpinner"
                    key="spinner"
                    overlay={true}
                />
            );
        }
        else if (!data)
        {
            CA.push("NoSrc");
        }
        else
        {
            const {
                composite,
                preview
            } = data;
            const {
                high_quality_link
            } = composite || {};
            const PosterImage = posterImage || preview;
            if (play)
            {
                CA.push("ShowVideo");
                const M1 = high_quality_link.match(/\.([a-z0-9]{3})\?/);
                const E1 = M1 ? M1[1] : "mp4";
                Content.push(
                    <video
                        autoPlay={true}
                        key={high_quality_link}
                        muted={muted}
                        onPause={this.OnPause}
                        onPlay={this.OnPlay}
                        playsInline="playsInline"
                        poster={showPreview ? PosterImage : null}
                        ref={this.OnPlayer}
                    >
                        <source src={high_quality_link} type={`video/${E1}`}/>
                    </video>,
                    this.Controls()
                );
                if (playing)
                {
                    CA.push("Playing");
                }
            }
            else
            {
                Content.push(
                    <div className="VideoPlayerPoster" key="poster">
                        {showPreview ? <LoadImage src={PosterImage} onLoad={this.OnPoster}/> : ""}
                        <div className="VideoPlayerPosterContent">
                            <IconButton
                                className="VideoPlayerPosterPlay"
                                feather="Play"
                                onClick={this.OnPlayPoster}
                            />
                            {interactions ? <div className="VideoPlayerPosterInfo">
                                <div className="VideoPlayerPosterInfoCell">
                                    <Icon feather="Eye"/>
                                    <span>{views}</span>
                                </div>
                                <div className="VideoPlayerPosterInfoCell">
                                    <Icon feather="Heart"/>
                                    <span>{likes}</span>
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                );
            }
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                {Content}
            </div>
        );
    }
}

VideoPlayer.defaultProps =
{
    className: "",
    contentId: 0,
    id: "",
    interactions: true,
    loop: false,
    muted: false,
    onPause: () => {},
    onPlay: () => {},
    onPoster: () => {},
    onLoad: () => {},
    posterImage: 0,
    showPreview: true
};

export default VideoPlayer;