/*!
 *  Search/select Fuse content.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onEnter - Callback for when the Enter key is pressed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop array types - Types of content to search for (class_names in search/new)
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./contentfield.scss";

import API from "Class/API";
import Fuse from "Class/Fuse";
import {ArrayClone, ObjectCompare, RandomToken} from "Functions";

import Icon from "Components/Layout/Icon";
import IconButton from "Components/UI/IconButton";
import Preview from "Components/Layout/Preview";
import Spinner from "Components/Feedback/Spinner";
import Sticky from "Components/Layout/Sticky";
import TextField from "Components/UI/Field/TextField";

class ContentField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Input = false;
        this.Mounted = false;
        this.Request = false;
        this.RequestDelay = 300;
        this.RequestTimer = false;
        this.SaveCache = false;
        this.Token = "";
        this.state =
        {        
            error: false,
            expand: false,
            filter: "",
            ids: [],
            results: [],
            searching: false,
            width: 200,
            value: []
        };
   }

    /**
     * Set initial value and add event listeners on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        this.Mounted = true;
        this.Token = this.Input ? this.Input.Token : "";
        this.SetContent(value);
        this.SetSize();
        window.addEventListener("resize", this.SetSize);
    }

    /**
     * Load content info when the value prop changes.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {ids} = this.state;
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        if (!ObjectCompare(v1, v2) && !ObjectCompare(v1, ids))
        {
            this.SetContent(v1);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        window.removeEventListener("resize", this.SetSize);
    }

    /**
     * Add a content object to the field value.
     * @param object content - The content object.
     * @param bool save - Whether to cache meta in the backend server.
     * @return void
     */

    AddContent = (content, save) =>
    {
        const {id: contentId, type} = content || {};
        const {id, multiple, onBlur, onChange} = this.props;
        const {ids, value} = this.state;
        const Ids = ArrayClone(ids);
        const Value = ArrayClone(value);
        if (!contentId || Ids.indexOf(contentId) >= 0 || (!multiple && Ids.length))
        {
            return;
        }
        if (save)
        {
            API.Request('content/cache-write', {id: contentId, type});
        }
        Ids.push(contentId);
        Value.push(content);
        this.setState({
            expand: false,
            filter: "",
            ids: Ids,
            results: [],
            value: Value
        });
        const FieldValue = this.ParseValue(Ids, Value);
        onBlur(null, FieldValue, id);
        onChange(null, FieldValue, id);
    }

    /**
     * Error handler when content loading fails at some stage.
     * @return void
     */

    ErrorContent = (id) =>
    {
        this.UpdateContent(id,
        {
            id,
            name: `Unable to load content: ${id}`,
            type: "error"
        });
    }

    /**
     * Hide search results on blur.
     * @return void
     */

    Collapse = () =>
    {
        const {id, onBlur} = this.props;
        const {ids, value} = this.state;
        const FieldValue = this.ParseValue(ids, value);
        onBlur(null, FieldValue, id);
        this.setState({expand: false});
    }

    /**
     * Show search results on focus.
     * @return void
     */

    Expand = () =>
    {
        const {id, onFocus} = this.props;
        const {ids, value} = this.state;
        const FieldValue = this.ParseValue(ids, value);
        onFocus(null, FieldValue, id);
        this.setState({expand: true});
    }

    /**
     * Render a content item for value/results.
     * @param object content - Content object.
     * @param bool result - Whether this is a result item.
     * @return JSX|string - The content item.
     */

    Item = (content, result) =>
    {
        const {disabled, disabledTitle} = this.props;
        const {ids} = this.state;
        const {id, loading, name, preview, type} = content;
        if (!id || !name)
        {
            return "";
        }
        const CA = ["ContentFieldItem"];
        const Disabled = disabled === true || (Array.isArray(disabled) && disabled.indexOf(id) >= 0);
        let Title = "";
        if (Disabled || (result && ids.indexOf(id) >= 0))
        {
            CA.push("Disabled");
            Title = disabledTitle;
        }
        if (result)
        {
            CA.push("ResultItem");
        }
        else
        {
            CA.push("ValueItem");
        }
        return (
            <div
                className={CA.join(" ")}
                key={id}
                onClick={(!Disabled && result) ? () => this.AddContent(content, this.SaveCache) : () => {}}
                title={Title || `Content ID ${id} - ${name}`}
            >
                {loading ? <Spinner
                    className="ContentFieldItemSpinner"
                    size={20}
                /> : <Preview
                    className="ContentFieldItemPreview"
                    content={type}
                    preview={preview}
                    size={20}
                />}
                <span className="ContentFieldItemName">{name}</span>
                {result ? <span className="ContentFieldItemId">[{id}]</span> : <IconButton
                    className="ContentFieldItemRemove"
                    feather="X"
                    onClick={() => this.RemoveContent(id)}
                />}
            </div>
        );
    }

    /**
     * Load content data from Fuse.
     * @param integer id - The content id.
     * @param string type - The content type.
     * @return void
     */

    LoadContent = (id, type) =>
    {
        Fuse.Content(id, type, content =>
        {
            if (!this.Mounted)
            {
                return;
            }
            if (!content)
            {
                this.ErrorContent(id);
            }
            else
            {
                this.UpdateContent(id, content);
            }
        });
    }

   /**
     * Format field value.
     * @param array ids - Content ids.
     * @param array value - Array of content objects.
     * @return array - Formatted value.
     */

    ParseValue = (ids, value) =>
    {
        const Value = [];
        value.forEach(({id, type}) =>
        {
            Value.push([id, type]);
        });
        return Value;
    }

    /**
     * Remove a content object from the field value.
     * @param integer contentId - The content id.
     * @return void
     */

    RemoveContent = (contentId) =>
    {
        const {id, onChange} = this.props;
        const {ids, value} = this.state;
        const Ids = ArrayClone(ids);
        const Value = ArrayClone(value);
        const Index = Ids.indexOf(contentId);
        if (Index < 0)
        {
            return;
        }
        Ids.splice(Index, 1);
        Value.splice(Index, 1);
        this.setState({
            ids: Ids,
            value: Value
        });
        const FieldValue = this.ParseValue(Ids, Value);
        onChange(null, FieldValue, id);
    }

    /**
     * Make a (delayed) search request when the filter changes.
     * @param object e - The event object.
     * @param string filter - The new search filter.
     * @return void
     */

    Search = (e, filter) =>
    {
        if (filter === this.state.filter)
        {
            return;
        }
        // Put the request on a timeout to avoid hammering the API.
        clearTimeout(this.RequestTimer);
        if (!filter)
        {
            this.setState({
                error: false,
                filter,
                results: [],
                searching: false
            });
        }
        else
        {
            this.setState({
                error: false,
                filter,
                results: [],
                searching: true
            });
            this.RequestTimer = setTimeout(() =>
            {
                const {id, onResults, types} = this.props;
                const Request = this.Request = RandomToken();
                const Params = {q: filter};
                if (types)
                {
                    Params.class_names = types.join(",");
                }
                Fuse.Request("search", Params, response =>
                {
                    if (!this.Mounted || Request !== this.Request)
                    {
                        return;
                    }
                    const {items} = response || {};
                    if (!items)
                    {
                        this.setState({error: true, searching: false});
                    }
                    else
                    {
                        const Results = [];
                        items.forEach(item =>
                        {
                            const {
                                description,
                                id,
                                preview_path,
                                title,
                                type
                            } = item;
                            Results.push({
                                accessible: true,
                                description,
                                id,
                                name: title,
                                preview: preview_path,
                                type: type.toLowerCase()
                            });
                        });
                        this.setState({
                            searching: false,
                            results: Results
                        }, () => onResults(Results, id));
                    }
                });
            }, this.RequestDelay);
        }
    }

    /**
     * Load content meta data form the Fuse API and then add the content object.
     * @param array content - An array of content ids.
     * @return void
     */

    SetContent = (content) =>
    {
        const {multiple, types} = this.props;
        const {ids, value} = this.state;
        const Ids = [];
        const Value = [];
        content.forEach(item =>
        {
            if (!multiple && Ids.length)
            {
                return;
            }
            let Id, Type;
            if (typeof item === "object")
            {
                Id = parseInt(item[0], 10);
                Type = item[1];
            }
            else
            {
                Id = parseInt(item, 10); 
                Type = types[0];
            }
            const Index = ids.indexOf(Id);
            if (Index >= 0)
            {
                Ids.push(Id);
                Value.push(ArrayClone(value[Index]));
            }
            else if (Type)
            {
                Ids.push(Id);
                Value.push({
                    id: Id,
                    name: "Loading...",
                    loading: true,
                    type: Type
                });
                this.LoadContent(Id, Type);
            }
            else
            {
                Ids.push(Id);
                Value.push({
                    id: Id,
                    name: "Loading...",
                    loading: true
                });
                API.Request("content/cache-read", {id: Id}, response =>
                {
                    if (!this.Mounted)
                    {
                        return;
                    }
                    const {cache, error} = response;
                    if (error)
                    {
                        this.ErrorContent(Id);
                    }
                    else
                    {
                        this.LoadContent(Id, cache.type);
                    }
                });
            }
        });
        this.setState({ids: Ids, value: Value});
    }

    /**
     *   Adjust the width of the dropdown menu when the client resizes.
     *   @return void
     */

    SetSize = () =>
    {
        if (!this.Field)
        {
            return;
        }
        this.setState({width: this.Field.offsetWidth});
    }

    /**
     * Update a content object.
     * @param string id - Content id
     * @param object content - New content object.
     * @return void
     */

    UpdateContent = (id, content) =>
    {
        const {ids, value} = this.state;
        const Value = ArrayClone(value);
        const Index = ids.indexOf(id);
        if (Index < 0)
        {
            return;
        }
        Value[Index] = content;
        this.setState({value: Value});
    }

    /**
     * Get the name of the (first) content item.
     * @return string - The name of the (first) content item.
     */

    Value = () =>
    {
        const {value} = this.state;
        return value.length ? value[0].name : "";
    }

    render()
    {
        const {className, disabled, flip, label, multiple, placeholder} = this.props;
        const {expand, filter, ids, results, searching, value, width} = this.state;
        const CA = ["Field", "ContentField"];
        const Disabled = disabled === true;
        if (!Disabled && expand)
        {
            CA.push("Expand");
        }
        if (!multiple && ids.length)
        {
            CA.push("Filled");
        }
        if (flip)
        {
            CA.push("Flip");
        }
        if (className)
        {
            CA.push(className);
        }
        const Content = [];
        const Results = [];
        if (value.length)
        {
            value.forEach(item =>
            {
                Content.push(this.Item(item));
            });
        }
        if (results.length)
        {
            results.forEach(result =>
            {
                Results.push(this.Item(result, true));
            });
        }
        else if (searching)
        {
            Results.push(
                <div
                    className="ContentFieldEmpty"
                    key="searching"
                >Searching...</div>
            );
        }
        else if (!filter)
        {
            Results.push(
                <div
                    className="ContentFieldEmpty"
                    key="empty"
                >Waiting for your input</div>
            );
        }
        else
        {
            Results.push(
                <div
                    className="ContentFieldEmpty"
                    key="noresults"
                >No results for <b>{filter}</b></div>
            );
        }
        return (
            <div className={CA.join(" ")} ref={node => this.Field = node}>
                {label ? <label htmlFor={this.Token}>{label}</label> : ""}
                {Content.length ? <div className="ContentFieldContent">{Content}</div> : ""}
                {(!Content.length || multiple) ? (
                    <div className="ContentFieldInput">
                        <TextField
                            {...this.props}
                            className="ContentFieldTextField"
                            disabled={Disabled}
                            label=""
                            onChange={this.Search}
                            onFocus={this.Expand}
                            onInput={this.Search}
                            placeholder={placeholder}
                            ref={node => this.Input = node}
                            value={filter}
                        />
                        {searching ? <Spinner
                            className="ContentFieldSpinner"
                            size={18}
                        /> : <Icon
                            className="ContentFieldIcon"
                            feather="Search"
                        />}
                        {(!Disabled && expand) ? <Sticky
                            align="right"
                            className="ContentFieldResults"
                            flip={flip}
                            onClose={this.Collapse}
                            width={width}
                        >
                            {Results}
                        </Sticky>: ""}
                    </div>
                ) : ""}
            </div>
        );
    }
}

ContentField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    flip: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    types: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    value: PropTypes.array
};

ContentField.defaultProps =
{
    className: "",
    disabled: false,
    disabledTitle: "",
    flip: false,
    id: "",
    label: "",
    multiple: false,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onResults: () => {},
    placeholder: "Search for content...",
    types: false,
    value: []
};

export default ContentField;