/*!
 *  Read- and write permissions form field.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop array value - Field value.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./permissionfield.scss";
import {ObjectCompare, RandomToken} from "Functions";
import ContentField from "Components/UI/Field/ContentField";
import IconButton from "Components/UI/IconButton";
import IconItem from "Components/UI/IconItem";
import SelectField from "Components/UI/Field/SelectField";

class PermissionField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Roles = ["Anyone", "Managers"];
        this.Tokens = {read: [], write: []};
        this.state =
        {
            focus: false,
            read: [],
            write: []
        }
    }

    /**
     * Set initial value on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        const {read, write} = value || {};
        const Read = read || [];
        const Write = write || [];
        // Create unique token for each item to use as component keys.
        Read.forEach(() => this.Tokens.read.push(RandomToken()));
        Write.forEach(() => this.Tokens.write.push(RandomToken()));
        this.setState({read: Read, write: Write});
    }

    /**
     * Reset field if it receives a new value.
     * @return void
     */
    
    componentDidUpdate()
    {
        const {value} = this.props;
        const {read: r1, write: w1} = value || {};
        const {read: r2, write: w2} = this.state;
        const Read = r1 || [];
        const Write = w1 || [];
        if (!ObjectCompare(Read, r2) || !ObjectCompare(Write, w2))
        {
            // Reset component keys.
            this.Tokens = {read: [], write: []};
            Read.forEach(() => this.Tokens.read.push(RandomToken()));
            Write.forEach(() => this.Tokens.write.push(RandomToken()));
            this.setState({read: Read, write: Write});
        }
    }

    /**
     * Callback when the adding read permission.
     * @param object e - The event object.
     * @return void
     */

    AddRead = (e) =>
    {
        const {id, onChange} = this.props;
        const {read, write} = this.state;
        read.push({community: [], role: 0});
        this.Tokens.read.push(RandomToken());
        this.setState({read});
        onChange(e, {read, write}, id);
    }

    /**
     * Callback when the adding write permission.
     * @param object e - The event object.
     * @return void
     */

    AddWrite = (e) =>
    {
        const {id, onChange} = this.props;
        const {read, write} = this.state;
        write.push({community: []});
        this.Tokens.write.push(RandomToken());
        this.setState({write});
        onChange(e, {read, write}, id);
    }

    /**
     * Callback when a content field loses focus.
     * @param string type - 'read' or 'write'.
     * @param integer index - Item index.
     * @return void
     */

    OnBlur = (type, index) =>
    {
        const {focus} = this.state;
        const [Type, Index] = focus || [];
        if (type !== Type || index !== Index)
        {
            return;
        }
        this.setState({focus: false});
    }

    /**
     * Callback when a content field gains focus.
     * @param string type - 'read' or 'write'.
     * @param integer index - Item index.
     * @return void
     */

    OnFocus = (type, index) =>
    {
        this.setState({focus: [type, index]});
    }

    /**
     * Callback when the removing an read permission.
     * @param object e - The event object.
     * @param integer index - Read item index.
     * @return void
     */

    RemoveRead = (e, index) =>
    {
        const {id, onChange} = this.props;
        const {read, write} = this.state;
        if (read[ index ] === undefined)
        {
            return;
        }
        read.splice(index, 1);
        this.Tokens.read.splice(index, 1);
        this.setState({read});
        onChange(e, {write, read}, id);
    }

    /**
     * Callback when the removing an write permission.
     * @param object e - The event object.
     * @param integer index - Write item index.
     * @return void
     */

    RemoveWrite = (e, index) =>
    {
        const {id, onChange} = this.props;
        const {read, write} = this.state;
        if (write[index] === undefined)
        {
            return;
        }
        write.splice(index, 1);
        this.Tokens.write.splice(index, 1);
        this.setState({write});
        onChange(e, {read, write}, id);
    }

    /**
     * Write the selected label in the role select field.
     * @param integer role - Role index.
     * @return void
     */

    SelectLabel = (role) =>
    {
        const Role = (this.Roles[ role ] || this.Roles[0]).toLowerCase();
        return `...and ${Role} in`;
    }

    /**
     * Callback when a read permission community is set.
     * @param object e - The event object.
     * @param array community - The community ID wrapped in an array.
     * @param integer index - Read item index.
     * @return void
     */

    SetReadCommunity = (e, community, index) =>
    {
        const {id, onChange} = this.props;
        const {read, write} = this.state;
        if (read[index] === undefined)
        {
            return;
        }
        read[index].community = community;
        this.setState({read});
        onChange(e, {read, write}, id);
    }

    /**
     * Callback when a write permission community is set.
     * @param object e - The event object.
     * @param array community - The community ID wrapped in an array.
     * @param integer index - Write item index.
     * @return void
     */

    SetWriteCommunity = (e, community, index) =>
    {
        const {id, onChange} = this.props;
        const {read, write} = this.state;
        if (write[ index ] === undefined)
        {
            return;
        }
        write[index].community = community;
        this.setState({write});
        onChange(e, {read, write}, id);
    }

    /**
     * Callback when a read permission role is set.
     * @param object e - The event object.
     * @param integer role - User role index.
     * @param integer index - Read item index.
     * @return void
     */

    SetReadRole = (e, role, index) =>
    {
        const {id, onChange} = this.props;
        const {read, write} = this.state;
        if (read[index] === undefined)
        {
            return;
        }
        read[index].role = role;
        this.setState({read});
        onChange(e, {read, write}, id);
    }

    /**
     * Return a blank string as value.
     * @return string - A blank string.
     */

    Value = () =>
    {
        return "";
    }

    render()
    {
        const {className, disabled, label} = this.props;
        const {read, focus, write} = this.state;
        const {read: readTokens, write: writeTokens} = this.Tokens;
        const CA = [ "PermissionField" ];
        const LabelRead = label ? `${label} - View` : "View";
        const LabelWrite = label ? `${label} - Edit` : "Edit";
        const [ Type, Index ] = focus || [];
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (className)
        {
            CA.push(className);
        }
        const Read = [<div className="PermissionFieldDefault" key="default">{read.length ? "Admins can view..." : "Anyone can view..."}</div>];
        const Write = [<div className="PermissionFieldDefault" key="default">Admins can edit...</div>];
        write.forEach((p, index) =>
        {
            const {community} = p;
            const ICA = ["PermissionFieldItem"];
            if (Type === "write" && Index === index)
            {
                ICA.push("Focus");
            }
            Write.push(
                <div className={ICA.join(" ")} key={writeTokens[index] || index}>
                    <div className="PermissionFieldLeft">...and managers in</div>
                    <div className="PermissionFieldRight">
                        <ContentField
                            id={index}
                            disabled={disabled}
                            onBlur={() => this.OnBlur("write", index)}
                            onChange={ this.SetWriteCommunity }
                            onFocus={() => this.OnFocus("write", index)}
                            placeholder="[Search for community...]"
                            types={["community"]}
                            value={community}
                        />
                    </div>
                <IconButton
                    className="PermissionFieldItemRemove"
                    disabled={disabled}
                    feather="X"
                    onClick={e => this.RemoveWrite(e, index)}
                />
            </div>);
        });
        read.forEach((p, index) =>
        {
            const {community, role} = p;
            const ICA = ["PermissionFieldItem"];
            if (Type === "read" && Index === index)
            {
                ICA.push("Focus");
            }
            Read.push(
                <div className={ICA.join(" ")} key={readTokens[index] || index}>
                    <div className="PermissionFieldLeftSelect">
                        <SelectField
                            id={index}
                            disabled={disabled}
                            onChange={this.SetReadRole}
                            options={this.Roles}
                            selectedLabel={this.SelectLabel}
                            value={role}
                        />
                </div>
                <div className="PermissionFieldRight">
                    <ContentField
                        id={index}
                        disabled={disabled}
                        onBlur={() => this.OnBlur("read", index)}
                        onChange={this.SetReadCommunity}
                        onFocus={() => this.OnFocus("read", index)}
                        placeholder="[Search for community...]"
                        types={["community"]}
                        value={community}
                    />
                </div>
                <IconButton
                    className="PermissionFieldItemRemove"
                    disabled={disabled}
                    feather="X"
                    onClick={e => this.RemoveRead(e, index)}
                />
            </div>);
        });
        return (

            <div className={CA.join(" ")}>
                <label>{LabelWrite}</label>
                <div className="PermissionFieldList">{Write}</div>
                <IconItem
                    className="PermissionFieldAdd"
                    disabled={disabled}
                    feather="Plus"
                    label="Add edit permission"
                    onClick={this.AddWrite}
                />
                <label>{LabelRead}</label>
                <div className="PermissionFieldList">{Read}</div>
                <IconItem
                    className="PermissionFieldAdd"
                    disabled={disabled}
                    feather="Plus"
                    label="Add view permission"
                    onClick={this.AddRead}
                />
            </div>
       );
    }
}

PermissionField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

PermissionField.defaultProps =
{
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onChange: () => {},
    value: false
};

export default PermissionField;