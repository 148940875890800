import React from "react";
import API from "Class/API";
import Auth from "Class/Auth";
import {TimeSince} from "Functions";
import Button from "Components/UI/Button";
import Error from "Components/Feedback/Error";
import IconItem from "Components/UI/IconItem";
import Item from "Components/UI/Item";
import Preview from "Components/Layout/Preview";
import ScrollView from "Components/UI/ScrollView";
import Spinner from "Components/Feedback/Spinner";
import User from "Components/UI/User";

class ViewArticlesHome extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Mounted = false;
        this.state =
        {
            error: false,
            list: false,
            loading: false,
            selected: -1,
            users: {}
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
        this.Load();
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    GetLocalDraft = (id) =>
    {
        const {drafts, templates} = this.props;
        if (drafts[id] === undefined)
        {
            return false;
        }
        return drafts[id];
    }

    Item = (item) =>
    {
        const {drafts, templates} = this.props;
        const {id, permission, status, title, updated, user} = item;
        const {selected, users} = this.state;
        // Non-draft timestamps needs to be converted from GMT.
        const Draft = drafts[id] !== undefined;
        return (
            <Item
                active={selected === id}
                className="ViewArticlesListItem"
                id={id}
                key={id}
                onClick={this.OnSelect}
            >
                <Preview
                    className="ItemPreview"
                    content="content"
                />
                <div className="ItemContent">
                    <div className="ItemName">{title || `Unnamed Article (${id})`}</div>
                    <div className="ItemInfo ItemUser">
                        <User
                            className="ItemUserAvatar"
                            onLoad={this.OnUser}
                            size={20}
                            user={user}
                        />
                        <div className="ItemUserName">
                            {users[user] || "Loading..."}
                        </div>
                    </div>
                    <div className="ItemInfo ItemHidden">
                        {templates ? (
                            <div className="ViewArticlesListItemItems">
                                <IconItem
                                    feather="Copy"
                                    label="Use"
                                    onClick={() => this.OnLoad(id, true)}
                                    title="Create a new article using this template"
                                />
                                {permission > 1 ? <IconItem
                                    feather="Edit2"
                                    label="Edit"
                                    onClick={() => this.OnLoad(id)}
                                    title="Load this template into the editor"
                                /> : ""}
                                {permission > 1 ? <IconItem
                                    feather="Trash"
                                    label="Delete"
                                    onClick={() => this.props.onDelete(id)}
                                    title="Pemanently remove this template"
                                /> : ""}
                            </div>
                        ) : (
                            <div className="ViewArticlesListItemItems">
                                <IconItem
                                    feather="Folder"
                                    label="Load"
                                    onClick={() => this.OnLoad(id)}
                                    title="Load this article into the editor"
                                />
                                <IconItem
                                    feather="Copy"
                                    label="Duplicate"
                                    onClick={() => this.OnLoad(id, true)}
                                    title="Create a duplicate of this article"
                                />
                                {permission > 1 ? <IconItem
                                    feather="Trash"
                                    label="Delete"
                                    onClick={() => this.props.onDelete(id)}
                                    title="Pemanently remove this article"
                                /> : ""}
                            </div>
                        )}
                        <div className="ViewArticlesListItemInfo">
                            {status}
                        </div>
                        <div className="ViewArticlesListItemInfo">
                            Updated {TimeSince(updated, !Draft)}
                        </div>
                    </div>
                </div>
            </Item>
        );
    }

    Load = () =>
    {
        const {templates} = this.props;
        this.setState({error: false, list: [], loading: true});
        API.RequestWhenReady(templates ? "articles/templates" : "articles/list", response =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {articles, error} = response || {};
            if (!articles || error)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({list: this.ParseList(articles), loading: false});
            }
        });
    }

    OnDuplicate = (id) =>
    {
        const {selected} = this.state;
        const Id = id || selected;
        this.OnLoad(Id, true);
    }

    OnDuplicateSelected = () =>
    {
        this.OnDuplicate();
    }

    OnLoad = (id, duplicate = false) =>
    {
        const {drafts, onDuplicate, onLoad, onLoadState} = this.props;
        const {selected} = this.state;
        const Callback = duplicate ? onDuplicate : onLoad;
        const Id = id || selected;
        const EditorState = drafts[Id] ? onLoadState(Id) : false;
        if (EditorState)
        {
            Callback(EditorState, true);
        }
        else
        {
            this.setState({error: false, loading: true});
            API.Request("articles/load", {id: Id}, response =>
            {
                if (!this.Mounted)
                {
                    return;
                }
                const {data, error, permission} = response || {};
                if (!data || error)
                {
                    console.error("Unable to load article", response);
                    this.setState({error: true, loading: false});
                }
                else
                {
                    this.setState({loading: false}, () =>
                    {
                        if (!duplicate)
                        {
                            data.id = Id;
                            data.permission = permission;
                        }
                        Callback(data);
                    });
                }
            });
        }
    }

    OnLoadSelected = () =>
    {
        this.OnLoad();
    }

    OnSelect = (e, id) =>
    {
        const {selected} = this.state;
        this.setState({selected: selected === id ? -1 : id});
    }

    OnUser = (id, data) =>
    {
        const {users} = this.state;
        const {name} = data;
        users[id] = name;
        this.setState({users});
    }

    ParseList = (list) =>
    {
        const {drafts, onLoadState, templates} = this.props;
        const Claimed = [];
        const Parsed = [];
        list.forEach(({draft, id, title, permission, updated, user}) =>
        {
            const Local = this.GetLocalDraft(id);
            Parsed.push({
                id,
                local: Local,
                permission: permission || 2,
                public: true,
                title,
                updated: Local ? Math.max(Local / 1000, draft || updated) : draft || updated,
                status: Local ? 'Local draft' : (draft ? 'Public draft' : (templates ? 'Template' : 'Public')),
                user
            });
            Claimed.push(id);
        });
        for (let id in drafts)
        {
            if (Claimed.indexOf(id) >= 0)
            {
                continue;
            }
            const {title, type} = onLoadState(id) || {};
            if ((templates && type !== "template") || (!templates && type === "template"))
            {
                continue;
            }
            Parsed.push({
                id,
                local: drafts[id],
                permission: 2,
                public: false,
                title,
                updated: drafts[id] / 1000,
                status: "Local draft",
                user: Auth.UserId
            });
        }
        Parsed.sort((a, b) =>
        {
            if (a.updated > b.updated) return -1;
            if (a.updated < b.updated) return 1;
            return 0;
        });
        return Parsed;
    }

    Reload = () =>
    {
        this.Load();
    }

    render()
    {
        const {
            children,
            onClose,
            onCreateNew,
            onTab,
            templates,
            title
        } = this.props;
        const {
            error,
            list,
            loading,
            selected
        } = this.state;
        const CA = ["ViewArticlesHome"];
        if (children)
        {
            CA.push("HasContent");
        }
        if (title)
        {
            CA.push("HasTitle");
        }
        let Content;
        if (loading && (!list || !list.length))
        {
            Content = <Spinner overlay={true}/>;
        }
        else if (error)
        {
            Content = <Error
                className="ViewArticlesError"
                button="Try again"
                label={templates ? "Unable to list templates" : "Unable to list articles"}
                onClick={this.Reload}
            />;
        }
        else if (!list || !list.length)
        {
            Content = <h2 className="ViewArticlesEmpty">No {templates ? "templates" : "articles"} have been created</h2>;
        }
        else
        {
            const Items = [];
            list.forEach(item =>
            {
                Items.push(this.Item(item));
            });
            Content = (
                <ScrollView className="ViewArticlesList">
                    <div className="ViewArticlesListItems">
                        {Items}
                    </div>
                </ScrollView>
            );
        }
        return (
            <div className={CA.join(" ")}>
                {title ? <h2>{title}</h2> : ""}
                {children ? <div className="ViewArticlesHomeContent">{children}</div> : ""}
                <div className="ViewArticlesListWrapper">
                    {Content}
                </div>
                {templates ? (
                    <div className="ViewArticlesTray">
                    <Button
                        disabled={loading}
                        label="Create empty"
                        onClick={onCreateNew}
                    />
                    <Button
                        disabled={loading || selected === -1}
                        label="Use selected template"
                        loading={loading}
                        onClick={this.OnDuplicateSelected}
                    />
                    <Button
                        hollow={true}
                        label="Load Previous"
                        onClick={e => onTab(e, "load")}
                    />
                    <Button
                        hollow={true}
                        label="Close"
                        onClick={onClose}
                    />
                </div>
                ) : (
                    <div className="ViewArticlesTray">
                        <Button
                            disabled={loading || selected === -1}
                            label="Load selected"
                            loading={loading}
                            onClick={this.OnLoadSelected}
                        />
                        <Button
                            hollow={true}
                            label="Close"
                            onClick={onClose}
                        />
                    </div>
                )}
            </div>
        )
    }
}

ViewArticlesHome.defaultProps =
{
    drafts: {},
    onCreateNew: () => {},
    onDelete: () => {},
    onDuplicate: () => {},
    onLoad: () => {},
    onLoadState: () => {},
    onTab: () => {},
    templates: false,
    title: ""
};

export default ViewArticlesHome;