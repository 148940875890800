/**!
 *  Ask question widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./question.scss";
import Fuse from "Class/Fuse";
import {ArrayClone, NoOrphans} from "Functions";
import Button from "Components/UI/Button";
import IconButton from "Components/UI/IconButton";
import TextareaField from "Components/UI/Field/TextareaField";
import User from "Components/UI/User";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetQuestion extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Contact Us",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            users:
            {
                addLabel: "Add user",
                label: "Users",
                onLabel: ["content", "user", 0],
                type: "repeater",
                fields:
                {
                    user:
                    {    
                        label: "User",
                        placeholder: "Search for user...",
                        type: "content",
                        types: ["user"]   
                    },
                    displayName:
                    {
                        label: "Display name",
                        type: "text"
                    },
                    contentNotice:
                    {
                        label: "If specified, the display name will replace the selected users full name.",
                        type: "notice"
                    },
                    description:
                    {
                        insert: true,
                        label: "Description",
                        type: "text"
                    }
                }
            },
            community:
            {
                label: "Community",
                placeholder: "Search for communities...",
                type: "content",
                types: ["community"]
            },
            contentNotice:
            {
                displayIf: ["community", "==", 0],
                label: "If no community is specified, the question will be posted to the current community.",
                type: "notice"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Mounted = false;
        this.Name = "Question";
        this.state =
        {
            index: 0,
            questions: {},
            sending: false,
            sent: [],
            userInfo: {}
        };
    }

    /**
     * Register mount.
     * @return void
     */

    componentDidMount()
    {    
        this.Mounted = true;   
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Close the send confirmation pop-up.
     * @param object e - Event object.
     * @param integer|string userId - External user id.
     * @return void
     */

    Close = (e, userId) =>
    {
        const {questions, sent} = this.state;
        const Index = sent.indexOf(userId);
        if (Index < 0)
        {
            return;
        }
        const Questions = ArrayClone(questions);
        const Sent = ArrayClone(sent);
        Questions[userId] = "";
        Sent.splice(Index, 1);
        this.setState({questions: Questions, sent: Sent});
    }

    /**
     * Get the ID of the specified or current community.
     * @return integer|boolean - Community ID or 'false'.
     */

    Community = () =>
    {
        const {community} = this.Content();
        const {context, contextId} = this.props;
        if (community && typeof community === "object" && typeof community[0] === "object")
        {
            return community[0][0];
        }
        return context === "community" ? contextId : false;
    }

    /**
     * Navigate to the previous user.
     * @return void
     */

    NavigateLeft = () =>
    {
        const {content} = this.props;
        const {index} = this.state;
        const {users} = content;
        const Users = users ? users.length : 0;
        if (Users < 2)
        {
            return;
        }
        this.setState({index: index ? index - 1 : Users - 1, left: true});
    }

    /**
     * Navigate to the next user.
     * @return void
     */

    NavigateRight = () =>
    {
        const {content} = this.props;
        const {index} = this.state;
        const {users} = content;
        const Users = users ? users.length : 0;
        if (Users < 2)
        {
            return;
        }
        this.setState({index: index < Users - 1 ? index + 1 : 0, left: false});
    }

    /**
     * Callback when an error occurres when loading a user.
     * @param integer|string userId - External user id.
     * @return void
     */

    OnError = (userId) =>
    {
        this.OnLoad(userId, false);
    }

    /**
     * Callback when a user finishes loading.
     * @param integer|string userId - External user id.
     * @param object user - User data.
     * @return void
     */

    OnLoad = (userId, user) =>
    {
        const {userInfo} = this.state;
        const Info = ArrayClone(userInfo);
        Info[userId] = user;
        this.setState({userInfo: Info});
    }

    /**
     * Callback when a question is edited.
     * @param object e - Event object.
     * @param string question - The edited question.
     * @param integer|string userId - External user id.
     * @return void
     */

    OnQuestion = (e, question, userId) =>
    {
        const {questions} = this.state;
        const Questions = ArrayClone(questions);
        Questions[userId] = question;
        this.setState({questions: Questions});
    }

    /**
     * Post a question.
     * @param object e - Event object.
     * @param integer|string userId - External user id.
     * @param string userName - Recipient user name.
     * @return void
     */

    Send = (e, userId, userName) =>
    {
        const {questions, sending, sent} = this.state;
        const Community = this.Community();
        const Question = questions[userId];
        const Sent = ArrayClone(sent);
        if (sending || !Question || !Community || Sent.indexOf(userId) >= 0)
        {
            return;
        }
        const Words = Question.split(" ");
        const Maxlength = 40;
        let Name = "";
        for (let i = 0; i < Words.length; i++)
        {
            if (i && Name.length + Words[i].length + 1 > Maxlength)
            {
                Name += "...";
                break;
            }
            else
            {
                Name += " " + Words[i];
            }
        }
        this.setState({sending: true});
        Fuse.Request("contents/question",
        {
            name: Name,
            description: Question,
            community_ids: Community,
            share_attributes:
            {
                user_ids: userId
            }
        }, response =>
        {
            if (!this.Mounted)
            {
                return;
            }
            Sent.push(userId);
            this.setState({sending: false, sent: Sent});
        }, "POST");
    }

    /**
     * Output a question form.
     * @param object raw - Raw user object.
     * @param integer i - Form index.
     * @return JSX - Question form.
     */

    User = (raw, i) =>
    {
        const {description, displayName, id, user} = raw;
        const {index, questions, sending, sent, userInfo} = this.state;
        const [UserObj] = user || [];
        const UserId = typeof UserObj === "object" ? UserObj[0] : UserObj;
        const {given_name, name} = userInfo[UserId] || {};
        const CA = ["WidgetQuestionUser"];
        const Sent = sent.indexOf(UserId) >= 0;
        const Name = displayName || name;
        if (i === index)
        {
            CA.push("Active");
        }
        if (!name)
        {
            CA.push("Loading");
        }
        if (Sent)
        {
            CA.push("Sent");
        }
        const Question = questions[UserId] || "";
        return (
            <div className={CA.join(" ")} key={id}>
                <div className="WidgetQuestionUserContent">
                    <User
                        className="WidgetQuestionUserLink"
                        user={UserId}
                        onLoad={this.OnLoad}
                        size={80}
                    >
                        <h2>{Name || "Loading..."}</h2>
                        {description ? <p>{description}</p> : ""}
                    </User>
                    <TextareaField
                        disabled={sending || Sent}
                        id={UserId}
                        maxRows={3}
                        onChange={this.OnQuestion}
                        onInput={this.OnQuestion}
                        placeholder="Your question..."
                        value={Question}  
                    />
                    {name ? <Button
                        disabled={!Question || !this.Community()}
                        done={Sent}
                        onClick={e => this.Send(e, UserId, Name)}
                        label={`Ask ${given_name}`}
                        loading={sending}
                    /> : ""}
                </div>
                {Sent ? <div className="WidgetQuestionConfirm">
                    <span>{NoOrphans(`Your question has been sent to ${given_name}!`)}</span>
                    <Button
                        onClick={e => this.Close(e, UserId)}
                        label="Close"
                    />
                </div> : ""}
            </div>
        );
    }

    render()
    {
        const {active, hover, rowHeight} = this.props;
        const {left, sending} = this.state;
        const {title, users} = this.Content();
        const CA = this.ClassNames(["WidgetQuestion", "BorderRadius"]);
        const Empty = !users || !users.length;
        const Multiple = Empty ? false : users.length > 1;
        if (active)
        {
            CA.push("Active");   
        }
        if (hover)
        {
            CA.push("Hover");   
        }
        if (Empty)
        {
            CA.push("Empty");
        }
        else if (Multiple)
        {
            CA.push("Multiple");
        }
        if (left)
        {
            CA.push("NavLeft");
        }
        else
        {
            CA.push("NavRight");
        }
        if (rowHeight < .85)
        {
            CA.push("Shrink1");
        }
        if (rowHeight < .65)
        {
            CA.push("Shrink2");
        }
        const Users = [];
        if (!Empty)
        {
            users.forEach((user, index) =>
            {
                Users.push(this.User(user, index));
            });
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <WidgetWrapper
                    className="WidgetQuestionWrapper"
                    title={title}
                    toolbar={Multiple ? <div className="WidgetQuestionNavigation">
                        <IconButton
                            disabled={sending}
                            feather="ArrowLeft"
                            onClick={this.NavigateLeft}
                        />
                        <IconButton
                            disabled={sending}
                            feather="ArrowRight"
                            onClick={this.NavigateRight}
                        />
                    </div> : ""}
                >
                    <div className="WidgetQuestionUsers">
                        {Users}
                    </div>
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetQuestion;