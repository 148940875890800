/**!
 *  Overlay dialogs handler.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./dialog.scss";
import Globals from "Class/Globals";
import Button from "Components/UI/Button";
import DraftOverview from "Components/UI/DraftsOverview";
import FileUpload from "Components/UI/FileUpload";
import Form from "Components/UI/Form";
import ImageDetails from "Components/UI/ImageDetails";
import ImageGallery from "Components/UI/ImageGallery";
import Overlay from "Components/Layout/Overlay";
import WidgetGallery from "Components/UI/WidgetGallery";
import ViewLog from "Components/View/Log";

class ViewDialog extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Mounted = false;
        this.state = {
            dialogs: {},
        };
    }

    /**
     * Listen for dialog requests.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.setState({dialogs: Globals.Dialogs()});
        Globals.Listen("dialog-create", this.OnDialog);
        Globals.Listen("dialog-destroy", this.OnDialog);
        Globals.Listen("dialog-update", this.OnDialog);
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        Globals.Remove("dialog-create", this.OnDialog);
        Globals.Remove("dialog-destroy", this.OnDialog);
        Globals.Remove("dialog-update", this.OnDialog);
    }

    /**
     * Create a overlay dialog.
     * @param object dialog - Dialog object.
     * @param string id - The unique ID of the dialog.
     * @return JSX - The overlay dialog.
     */

    DialogItem = (dialog, id) =>
    {
        const {
            buttons = "",
            cancelLabel,
            confirm,
            confirmLabel,
            content = "",
            message,
            props,
            title,
            type,
            width
        } = dialog;
        let Content = "";
        let DefaultTitle = "";
        let OverlayClose = true;
        let Width;
        switch (type)
        {
            // Confirm action.
            case "confirm":
                Content = (
                    <div className="DialogConfirm">
                        <div className="DialogMessage">{message}</div>
                        <div className="DialogButtons">
                            <Button
                                label={confirmLabel || "OK"}
                                onClick={() => this.OnConfirm(id)}
                            />
                            {buttons}
                            <Button
                                hollow={true}
                                label={cancelLabel || "Cancel"}
                                onClick={() => this.OnClose(id)}
                            />
                        </div>
                    </div>
                );
                DefaultTitle = "Confirm action";
                break;
            // Widget container drafts overview.
            case "drafts":
                Content = <DraftOverview {...props} onClose={() => this.OnClose(id)}/>;
                DefaultTitle = "Draft versions overview";
                Width = 480;
                break;
            // Image details interface.
            case "image":
                Content = <ImageDetails {...props} onClose={() => this.OnClose(id)}/>;
                DefaultTitle = "Image details";
                OverlayClose = false;
                break;
            // Form fields.
            case "form":
                Content = (
                    <div className="DialogForm">
                        <Form {...props}/>
                        {confirm ? (
                            <>
                                <Button
                                    className="DialogFormDone"
                                    label={confirmLabel || "OK"}
                                    onClick={() => this.OnConfirm(id)}
                                />
                                {buttons}
                                <Button
                                    hollow={true}
                                    label={cancelLabel || "Cancel"}
                                    onClick={() => this.OnClose(id)}
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    className="DialogFormDone"
                                    label={confirmLabel || "Done"}
                                    onClick={() => this.OnClose(id)}
                                />
                                {buttons}
                            </>
                        )}
                    </div>
                );
                DefaultTitle = "Edit";
                OverlayClose = false;
                Width = 480;
                break;
            // Image gallery.
            case "gallery":
                Content = <ImageGallery {...props} onClose={() => this.OnClose(id)}/>;
                DefaultTitle = "Pick file";
                OverlayClose = false;
                Width = 480;
                break;
            // Log.
            case "log":
                Content = <ViewLog {...props} onClose={() => this.OnClose(id)}/>;
                DefaultTitle = "Event log";
                Width = 480;
                break;
            // Menu.
            case "menu":
                Content = this.RenderMenu({buttons, cancelLabel, ...props, id});
                DefaultTitle = "Menu";
                Width = 480;
                break;
            // Upload file.
            case "upload":
                Content = <FileUpload {...props} onClose={() => this.OnClose(id)}/>;
                DefaultTitle = "Upload file";
                break;
            // Widget content gallery. (Load)
            case "widgets":
                Content = <WidgetGallery {...props} onClose={() => this.OnClose(id)}/>
                DefaultTitle = "Load widget content";
                Width = 480;
                break;
            default:
                Content = content;
                Width = width || 480;
        }
        return (
            <Overlay
                className="Dialog"
                key={id}
                title={title || DefaultTitle}
                onClose={() => this.OnClose(id)}
                overlayClose={OverlayClose}
                width={Width}
            >
                {Content}
            </Overlay>
        );
    }

    /**
     * Callback when a dialog is confirmed.
     * @return void
     */

    OnConfirm = (id) =>
    {
        const {dialogs} = this.state;
        const Dialog = dialogs[id];
        if (!Dialog)
        {
            return;
        }
        if (typeof Dialog.onConfirm === "function")
        {
            Dialog.onConfirm();
        }
        Globals.DialogDestroy(id, true);
    }

    /**
     * Callback when the dialog is closed.
     * @return void
     */

    OnClose = (id) =>
    {
        Globals.DialogDestroy(id);
    }

    /**
     * Callback when a dialog id created/destroyed.
     * @return void
     */

    OnDialog = () =>
    {
        if (!this.Mounted)
        {
            return;
        }
        this.setState({dialogs: Globals.Dialogs()});
    }

    RenderMenu = ({buttons, cancelLabel, id, items = []}) =>
    {
        const RenderedItems = [];
        items.forEach(({action, label, text}, index) =>
        {
            if (!label)
            {
                return;
            }
            RenderedItems.push(
                <div className="DialogMenuItem" key={index}>
                    <Button label={label} onClick={action}/>
                    {text ? <div className="DialogMenuItemText">{text}</div> : ""}
                </div>
            );
        });
        return (
            <>
                <nav className="DialogMenu">
                    {RenderedItems}
                </nav>
                <div className="DialogButtons">
                    {buttons}
                    <Button
                        hollow={true}
                        label={cancelLabel || "Close"}
                        onClick={() => this.OnClose(id)}
                    />
                </div>
            </>
        );
    }

    render()
    {
        const {dialogs} = this.state;
        const Dialogs = [];
        for (let id in dialogs)
        {
            Dialogs.push(this.DialogItem(dialogs[id], id));
        }
        return <div className="Dialogs">{Dialogs}</div>;
    }
}

export default ViewDialog;