/**!
 *  Image gallery view
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./imagegallery.scss";
import API from "Class/API";
import Fuse from "Class/Fuse";
import Globals from "Class/Globals";
import { ArrayClone, RandomToken, Time } from "Functions";
import Button from "Components/UI/Button";
import Error from "Components/Feedback/Error";
import FileUpload from "Components/UI/FileUpload";
import FilterField from "Components/UI/Field/FilterField";
import IconItem from "Components/UI/IconItem";
import ImageItem from "Components/UI/ImageItem";
import ScrollView from "Components/UI/ScrollView";
import Spinner from "Components/Feedback/Spinner";
import TabMenu from "Components/UI/TabMenu";
import TextField from "Components/UI/Field/TextField";

class ImageGallery extends React.Component
{
    constructor(props)
    {
        super(props);
        this.DeleteDialog = false;
        this.DetailsDialog = false;
        this.Limit = 20;
        this.LoadToken = false;
        this.Mounted = false;
        this.ScrollTimer = false;
        this.SearchTimer = false;
        this.Tabs = ["Gallery", "Upload"];
        this.TabKeys = Object.keys(this.Tabs);
        this.state =
        {
            ctrl: false,
            done: false,
            downloaded: false,
            error: false,
            filterCommunity: false,
            filterQuery: "",
            filterUser: false,
            images: {},
            loading: false,
            ready: false,
            shift: false,
            selected: [],
            tab: 0
        }
    }

    /**
     *  Load gallery and add listeners on mount.
     *  @return void.
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {selected, src} = this.props;
        this.Load(src, true, selected);
        window.addEventListener("keydown", this.OnKeyDown);
        window.addEventListener("keyup", this.OnKeyUp);
        Globals.Listen("upload-done", this.OnUploadDone);
        Globals.Listen("upload-error", this.OnUploadError);
        Globals.Listen("upload-progress", this.OnUploadProgress);
        Globals.Listen("upload-start", this.OnUploadStart);

    }

    /**
     *  Reload gallery if the source endpoint changes.
     *  @return void.
     */

    componentDidUpdate(prevProps)
    {
        const {selected, src: s1} = this.props;
        const {src: s2} = prevProps;
        if (s1 !== s2)
        {
            this.Load(s1, true, selected);
        }
    }

    /**
     *  Remove listeners on unmount.
     *  @return void.
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        window.removeEventListener("keydown", this.OnKeyDown);
        window.removeEventListener("keyup", this.OnKeyUp);
        Globals.Remove("upload-done", this.OnUploadDone);
        Globals.Remove("upload-error", this.OnUploadError);
        Globals.Remove("upload-progress", this.OnUploadProgress);
        Globals.Remove("upload-start", this.OnUploadStart);
        Globals.DialogDestroy(this.DeleteDialog);
        Globals.DialogDestroy(this.DetailsDialog);
    }

    /**
     *  Delete image(s).
     *  @param array tokens - An array of image tokens.
     *  @return void.
     */

    DeleteImages = (tokens) =>
    {
        const {images} = this.state;
        const Delete = [];
        tokens.forEach(token =>
        {
            if (images[token] === undefined)
            {
                return;
            }
            images[token].deleted = true;
            Delete.push(images[token].id);
        });
        this.setState({
            images,
            ready: false,
            selected: []
        });
        API.Request("files/delete", {ids: Delete}, response =>
        {
            const {error, message} = response;
            if (error)
            {
                console.error(message || "Unable to delete image(s).");
            }
        });
    }

    /**
     *  Set the label of the filter field.
     *  @return string - The label.
     */

    FilterLabel = () =>
    {
        const {filterCommunity, filterUser} = this.state;
        if (filterCommunity && filterUser)
        {
            return "Uploaded by you in the current community";
        }
        if (filterCommunity)
        {
            return "Uploaded in the current community";
        }
        if (filterUser)
        {
            return "Uploaded by you";
        }
        return "No filters selected";
    }

    /**
     *  Load gallery images.
     *  @param string endpoint - API endpoint to fetch from.
     *  @param boolean clear - Whether to clear the gallery before loading.
     *  @param array selected - Optional array of selected ids in order to sync.
     *  @param boolean clearSelected - Whether to the clear the selection before fetching.
     *  @return void.
     */

    Load = (endpoint, clear, selected, clearSelected) =>
    {
        const {src} = this.props;
        const {filterCommunity, filterQuery, filterUser, images} = this.state;
        const Exclude = [];
        const State =
        {
            error: false,
            loading: true,
            tab: 0
        };
        if (clear)
        {
            State.images = {};
        }
        else
        {
            for (let token in images)
            {
                const {id} = images[token];
                Exclude.push(id);
            }
        }
        if (clearSelected)
        {
            State.selected = [];
        }
        const Token = this.LoadToken = RandomToken();
        this.setState(State);
        API.Request(endpoint || src,
        {
            filterCommunity: filterCommunity ? Fuse.ContextId : 0,
            filterUser: filterUser ? 1 : 0,
            filterQuery: filterQuery,
            exclude: Exclude,
            include: selected || [],
            limit: this.Limit
        }, response =>
        {
            if (!this.Mounted || Token !== this.LoadToken)
            {
                return;
            }
            const {error, gallery} = response || {};
            if (error || !gallery)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                const Images = clear ? {} : ArrayClone(images);
                const Selected = [];
                gallery.forEach(image =>
                {
                    const {id, token} = image;
                    Images[token] = image;
                    if (clear && selected.indexOf(id) >= 0)
                    {
                        Selected.push(token);
                    }
                });
                const State =
                {
                    done: gallery.length < this.Limit,
                    images: Images,
                    loading: false
                };
                if (clear)
                {
                    State.ready = this.Ready(Selected, Images);
                    State.selected = Selected;
                }
                this.setState(State);
            }
        });
    }

    /**
     *  Cancel all active upload processes.
     *  @return void.
     */

    OnAbort = () =>
    {
        if (!this.Upload)
        {
            return;
        }
        // Delegate to file upload component
        this.Upload.OnAbort();
    }

    /**
     *  Callback when the close button is clicked.
     *  @return void.
     */

    OnClose = () =>
    {
        const {id, onClose} = this.props;
        this.OnAbort();
        onClose(id);
    }

    /**
     *  Callback when an image will be deleted.
     *  @param object e - The event object.
     *  @param string token - Image token.
     *  @return void.
     */

    OnDelete = (e, token) =>
    {
        this.DeleteDialog = Globals.DialogCreate({
            confirmLabel: "Delete image",
            message: "Are you sure you want to delete this image?",
            onConfirm: () =>
            {
                Globals.DialogDestroy(this.DetailsDialog);
                this.DeleteImages([token]);
            },
            title: "Delete image",
            type: "confirm"
        });
    }

    /**
     *  Callback when a selection of images will be deleted.
     *  @param object e - The event object.
     *  @return void.
     */

    OnDeleteSelected = (e) =>
    {
        const {selected} = this.state;
        if (selected.length < 1)
        {
            return;
        }
        const Label = selected.length > 1 ? "image(s)" : "image";
        this.DeleteDialog = Globals.DialogCreate({
            confirmLabel: `Delete ${Label}`,
            message: `Are you sure you want to delete the selected ${Label}?`,
            onConfirm: () =>
            {
                this.DeleteImages([selected]);
            },
            title: `Delete ${Label}`,
            type: "confirm"
        });
    }

    /**
     *  Callback when the details button is clicked
     *  @return void.
     */

    OnDetails = () =>
    {
        const {images, selected} = this.state;
        const Image = images[selected[0]];
        if (!Image)
        {
            return;
        }
        this.DetailsDialog = Globals.DialogCreate({
            props:
            {
                image: Image,
                onDelete: this.OnDelete,
                onRegenerate: this.OnRegenerate,
                onSave: this.OnUpdateMeta
            },
            type: "image"
        });
    }

    /**
     *  Callback when the download button is clicked
     *  @return void.
     */

    OnDownload = () =>
    {
        const {images, selected} = this.state;
        const Image = images[selected[0]];
        if (!Image)
        {
            return;
        }
        clearTimeout(this.DownloadTimeout);
        this.setState({downloaded: 1}, async () =>
        {
            try
            {
                const ImageData = await fetch(Image.urls.large);
                const ImageBlob = await ImageData.blob();
                const ImageUrl = URL.createObjectURL(ImageBlob);
                const TempLink = document.createElement("a");
                TempLink.href = ImageUrl;
                TempLink.download = Image.filename.replace(/[^a-z0-9-_]/gi, "");
                document.body.appendChild(TempLink);
                TempLink.click();
                document.body.removeChild(TempLink);
                this.DownloadTimeout = setTimeout(() =>
                {
                    this.setState({downloaded: 2});
                }, 1000);
            }
            catch(e)
            {
                console.error("Download failed", e.message);
                this.setState({downloaded: -1});
            }
        });
    }

    /**
     *  Callback when the gallery filters updates.
     *  @param object e - Event object.
     *  @param mixed value - Filter value.
     *  @param string id - Filter field id.
     *  @return void
     */

    OnFilter = (e, value, id) =>
    {
        const {src} = this.props;
        switch(id)
        {
            case "params":
                const {community, user} = value;
                clearTimeout(this.SearchTimer);
                this.setState({
                    filterCommunity: community,
                    filterUser: user
                });
                this.SearchTimer = setTimeout(() => this.Load(src, true, [], true), 0);
                break;
            case "query":
                if (value === this.state.filterQuery)
                {
                    break;
                }
                clearTimeout(this.SearchTimer);
                this.setState({
                    filterQuery: value,
                    loading: true
                });
                this.SearchTimer = setTimeout(() => this.Load(src, true, [], true ), 300);
                break;
            default:
        }
    }

    /**
     *  Callback when a gallery item is clicked.
     *  @param object e - The event object.
     *  @param object image - The corresponding image object.
     *  @param string token - The item/image token.
     *  @return void.
     */

    OnImage = (e, image, token) =>
    {
        if (e.button !== 0)
        {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        clearTimeout(this.DownloadTimeout);
        const {multiple} = this.props;
        const {ctrl, images, selected, shift} = this.state;
        const Index = selected.indexOf(token);
        const IsSelected = Index >= 0;
        let Selected = [];
        if ((!multiple || !selected.length || (!ctrl && !shift)) && !IsSelected)
        {
            Selected.push(token);
        }
        else if (multiple && ctrl)
        {
            Selected = ArrayClone(selected);
            if (IsSelected)
            {
                Selected.splice(Index, 1);
            }
            else
            {
                Selected.push(token);
            }
        }
        else if (multiple && shift)
        {
            const First = selected[0];
            const Tokens = Object.keys(images);
            const From = Tokens.indexOf(First);
            const To = Tokens.indexOf(token);
            if (From >= 0 && To >= 0)
            {
                const Step = To > From ? 1 : -1;
                for (let i = From; i !== To + Step; i += Step)
                {
                    Selected.push(Tokens[i]);
                }
            }
        }
        this.setState({
            downloaded: false,
            ready: this.Ready(Selected),
            selected: Selected
        });
    }

    /**
     *  Callback when any button is pressed when the gallery is mounted.
     *  Catches shift and ctrl in order to change selection mode.
     *  @param object e - The event object.
     *  @return void.
     */

    OnKeyDown = (e) =>
    {
        const {shift} = this.state;
        switch(e.key)
        {
            case "Shift":
                this.setState({ctrl: false, shift: true});
                break;
            case "Control":
            case "Meta":
                e.preventDefault();
                if (shift)
                {
                    break;
                }
                this.setState({ctrl: true});
                break;
            default:
        }
    }

    /**
     *  Callback when any button is released when the gallery is mounted.
     *  @param object e - The event object.
     *  @return void.
     */

    OnKeyUp = (e) =>
    {
        switch(e.key)
        {
            case "Shift":
                this.setState({shift: false});
                break;
            case "Control":
            case "Meta":
                e.preventDefault();
                this.setState({ctrl: false});
                break;
            default:
        }
    }

    /**
     *  Callback when an image should request a regeneration.
     *  @param object e - The event object.
     *  @param string token - Image token.
     *  @param function callback - Optional callback when the request is finished.
     *  @return void.
     */

    OnRegenerate = (e, token, callback) =>
    {
        this.RegenerateImages([token], callback);
    }

    /**
     *  Callback when the select button is clicked. Gathers all image objects
     *  into an array and puts it into a callback function.
     *  @return void.
     */

    OnSelect = () =>
    {
        const {id, onSelect} = this.props;
        const {images, selected} = this.state;
        const Images = [];
        selected.forEach(token =>
        {
            if (images[token] === undefined)
            {
                return;
            }
            Images.push(images[token]);
        });
        onSelect(Images, id);
    }

    /**
     *  Callback when an images meta data has been saved.
     *  @param object e - The event object.
     *  @param object meta - Updated meta data-
     *  @param string token - Image token.
     *  @return void.
     */

    OnUpdateMeta = (e, meta, token) =>
    {
        const {images} = this.state;
        const {communitites, description, filename, users} = meta;
        if (images[ token ] === undefined)
        {
            return;
        }
        images[token].filename = filename;
        images[token].description = description;
        images[token].communitites = communitites;
        images[token].updated = Time();
        images[token].users = users;
    }

    /**
     *  Callback when an image finished uploading.
     *  @param string token - The image token.
     *  @param object data - An object containing the file object.
     *  @return void.
     */

    OnUploadDone = (token, data) =>
    {
        const {images, selected} = this.state;
        const {file} = data;
        images[token] = file;
        this.setState({
            images,
            ready: this.Ready(selected, images)
        });
    }

    /**
     *  Callback when an error occurrs when uploading an image.
     *  @param string token - The image token.
     *  @return void.
     */

    OnUploadError = (token) =>
    {
        const {images} = this.state;
        const Image = images[token];
        if (!Image)
        {
            return;
        }
        Image.error = true;
        this.setState({images});
    }

    /**
     *  Callback when an image file upload progress refreshed.
     *  @param string token - The image token.
     *  @param object data - An object containing progress data.
     *  @return void.
     */

    OnUploadProgress = (token, data) =>
    {
        const {images} = this.state;
        const {loaded, total} = data;
        const Image = images[token];
        const Progress = loaded / total;
        if (!Image)
        {
            return;
        }
        Image.progress = Progress;
        this.setState({images});
    }

    /**
     *  Callback when an image upload starts.
     *  @param string token - The image token.
     *  @param object data - Initial image info.
     *  @return void.
     */

    OnUploadStart = (token, data) =>
    {
        const {images, selected} = this.state;
        const {filename, index, modified, preview} = data;
        const Tokens = Object.keys(images);
        const Images = {};
        const Selected = index ? selected : [];
        if (Tokens.indexOf(token) >= 0)
        {
            return;
        }
        Images[token] =
        {
            filename,
            modified,
            token,
            preview,
            progress: 0
        };
        Selected.push(token);
        Tokens.forEach(t => Images[t] = images[t]);
        const State =
        {
            images: Images,
            selected: Selected
        };
        // Switch to the gallery tab when the (first) upload starts.
        if (!index)
        {
            State.tab = 0;
        }
        this.setState(State);
    }

    /**
     *  Check whether the current selection is ready, ie. that the selection
     *  is not empty and that all images are loaded.
     *  @param array selection - Optional input. Defaults to state.selected.
     *  @param array gallery - Optional gallery. Defaults to state.images.
     *  @return boolean - Whether the selection is ready.
     */

    Ready = (selection, gallery) =>
    {
        const {images, selected} = this.state;
        const Selected = selection || selected;
        const Gallery = gallery || images; 
        if (!Selected.length)
        {
            return false;
        }
        let Ready = true;
        Selected.forEach(token =>
        {
            const {urls} = Gallery[token] || {};
            if (!urls)
            {
                Ready = false;
            }
        });
        return Ready;
    }

    /**
     *  Regenerate image(s).
     *  @param array tokens - An array of image tokens.
     *  @param function callback - Callback when the request is finished.
     *  @return void.
     */

    RegenerateImages = (tokens, callback) =>
    {
        const {images} = this.state;
        const Regenerate = [];
        tokens.forEach(token =>
        {
            if (images[token] === undefined)
            {
                return;
            }
            Regenerate.push(images[token].id);
        });
        API.Request("files/regenerate", {ids: Regenerate}, response =>
        {
            const {error, message} = response;
            if (error)
            {
                console.error(message || "Unable to regenerate image(s).");
            }
            if (typeof callback === "function")
            {
                callback(error, message);
            }
        });
    }

    /**
     *  Callback when a tab menu item is clicked.
     *  @param object e - The event object.
     *  @param integer tab - The index of the clicked tab item.
     *  @return void.
     */

    SetTab = (e, tab) =>
    {
        const Tab = this.TabKeys.indexOf(tab);
        this.setState({tab: Tab});
    }

    render()
    {
        const {className, multiple} = this.props;
        const { 
            done,
            downloaded,
            error,
            filterCommunity,
            filterQuery,
            filterUser,
            images,
            loading,
            ready,
            regenerating,
            selected,
            tab
        } = this.state;
        const CA = ["ImageGallery"];
        const Content = [];
        const Empty = !Object.keys(images).length;
        if (className)
        {
            CA.push(className);
        }
        Content.push(
            <div className="ImageGalleryFilter" key="filter">
                <TextField
                    feather="Search"
                    id="query"
                    loading={loading}
                    onChange={this.OnFilter}
                    onInput={this.OnFilter}
                    placeholder="Search gallery..."
                    value={filterQuery}
                />
                <FilterField
                    closeOnChange={false}
                    fields={{
                        user:
                        {
                            label: "Uploaded",
                            text: "By you",
                            type: "checkbox"
                        },
                        community:
                        {
                            disabled: Fuse.Context !== "community",
                            text: "In current community",
                            type: "checkbox"
                        }
                    }}
                    id="params"
                    onChange={this.OnFilter}
                    selectedLabel={this.FilterLabel}
                    values={{community: filterCommunity, user: filterUser}}
                />
            </div>
        );
        if (loading && Empty)
        {
            Content.push( <Spinner
                className="ImageGallerySpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (error && Empty)
        {
            Content.push( <Error
                className="ImageGalleryError"
                button="Try Again"
                key="error"
                label="Unable to load gallery"
                onClick={() => this.Load()}
            />);
        }
        else if ((filterQuery || filterCommunity || filterUser) && Empty)
        {
            Content.push(<div
                className="ImageGalleryEmpty"
                key="empty"
            >No images match the current filters</div>);
        }
        else if (Empty)
        {
            Content.push(<div
                className="ImageGalleryEmpty"
                key="empty"
            >No images in gallery</div>);
        }
        else
        {
            const Gallery = [];
            for (let token in images)
            {
                Gallery.push(<ImageItem
                    active={selected.indexOf(token) >= 0}
                    className="ImageGalleryItem"
                    id={token}
                    image={images[token]}
                    key={token}
                    onClick={this.OnImage}
                />);
            }
            Gallery.push(<IconItem
                className="ImageGalleryMore"
                disabled={done}
                label="Show more"
                loading={loading}
                onClick={() => this.Load()}
                key="load"
                feather="PlusCircle"
            />);
            Content.push(<ScrollView
                className="ImageGalleryItems"
                key="items"
            >{Gallery}</ScrollView>);
        }
        return (
            <div className={CA.join(" ")}>
                <TabMenu
                    className="ImageGalleryTabMenu"
                    disabled={loading}
                    items={this.Tabs}
                    onClick={this.SetTab}
                    selected={this.TabKeys[tab]}
                />
                <div className="ImageGalleryTabs">
                    <div className={tab === 0 ? "ImageGalleryTab Active" : "ImageGalleryTab"}>
                        {Content}
                    </div>
                    <div className={tab === 1 ? "ImageGalleryTab Active" : "ImageGalleryTab"}>
                        <FileUpload
                            accept={["image/gif", "image/jpeg", "image/png"]}
                            multiple={multiple}
                            ref={component => this.Upload = component}
                        />
                    </div>
                </div>
                <div className="ImageGalleryTray">
                    <Button
                        disabled={!ready || tab !== 0}
                        label="Select"
                        onClick={this.OnSelect}
                    />
                    <Button
                        hollow={true}
                        label="Close"
                        onClick={this.OnClose}
                    />
                </div>
                <div className="ImageGalleryTraySecond">
                    <IconItem
                        disabled={!ready || tab !== 0}
                        feather={downloaded < 0 ? "X" : (downloaded === 2 ? "Check" : "Download")}
                        label="Download"
                        loading={downloaded === 1}
                        onClick={this.OnDownload}
                    />
                    <IconItem
                        disabled={!ready || tab !== 0}
                        feather="Info"
                        label="Details"
                        onClick={this.OnDetails}
                    />
                    <IconItem
                        disabled={!ready || tab !== 0}
                        feather="Trash2"
                        label="Delete"
                        onClick={this.OnDeleteSelected}
                    />
                </div>
            </div>
        );
    }
}

ImageGallery.propTypes =
{
    className: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiple: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    src: PropTypes.string
};

ImageGallery.defaultProps =
{
    className: "",
    id: "",
    multiple: true,
    onClose: () => {},
    onSelect: () => {},
    selected: [],
    src: "files/image-gallery"
};

export default ImageGallery;