/*!
 *  Lists initial (default) widgets in the current Fuse landing page.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string|number value - Field value.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import Broadcast from "Class/Broadcast";
import Globals from "Class/Globals";
import SelectField from "Components/UI/Field/SelectField";

class InitialField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            value: 0,
            widgets: {0: "First supported widget"}
        };
    }

    /**
     * Add listeners and request initial widgets on mount.
     * @return void
     */

    componentDidMount()
    {
        const {value} = this.props;
        Globals.Listen(`initial-field`, this.OnInitial);
        this.RequestInitial();
        this.SetValue(value);
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {value: v1} = this.props;
        const {value: v2} = prevProps;
        if (v1 !== v2)
        {
            this.SetValue(v1);
        }
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        Globals.Remove(`initial-field`, this.OnInitial);
    }

    /**
     * Callback for when the field value changes.
     * @param object e - The event object.
     * @param integer value - The new value.
     * @return void
     */

    OnChange = (e, value) =>
    {
        const {id, onChange} = this.props;
        this.setState({value});
        onChange(e, value, id);
    }

    /**
     * Callback when initial widget contents is received.
     * @param array widgets - Widgets as [[type, html, id] ...]
     * @return void
     */

    OnInitial = (widgets) =>
    {
        const {filter} = this.props;
        const Widgets = {0: "First supported widget"};
        widgets.forEach(([type, html, id]) =>
        {
            if (filter && filter.indexOf(type) < 0)
            {
                return;
            }
            const Count = parseInt(id.substr(0, 1), 10) + 1;
            Widgets[id] = `${type} (${Count})`;
        });
        this.setState({widgets: Widgets});
    }

    /**
     * Request the contents of the initial (default) widgets in the current landing page.
     * @return void
     */

    RequestInitial = () =>
    {
        Broadcast.SendMessage({
            id: "field",
            type: "initial"
        });
    }

    SetValue = (value) =>
    {
        this.setState({value});
    }

    render()
    {
        const {className, id} = this.props;
        const {value, widgets} = this.state;
        const CA = ["InitialField"];
        if (className)
        {
            CA.push(className);
        }
        return (
            <SelectField
                {...this.props}
                className={CA.join(" ")}
                id={id}
                onChange={this.OnChange}
                options={widgets}
                value={value}
            />
        );
    }
}

InitialField.propTypes =
{
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

InitialField.defaultProps =
{
    className: "",
    filter: false,
    id: "",
    onChange: () => {},
    value: -1
};

export default InitialField;