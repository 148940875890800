/**!
 *  Dropdown segments widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./dropdown.scss";
import Globals from "Class/Globals";
import {CanForEach} from "Functions";
import Collapsable from "Components/Layout/Collapsable";
import ContentButton from "Components/UI/ContentButton";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import ScrollView from "Components/UI/ScrollView";

class WidgetDropdown extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            dropdowns:
            {
                addLabel: "Add dropdown",
                label: "Dropdown segments",
                type: "repeater",
                nameKey: "label",
                fields:
                {
                    label:
                    {
                        insert: true,
                        label: "Label",
                        type: "text"
                    },
                    text:
                    {
                        insert: true,
                        label: "Text",
                        type: "textarea"
                    },
                    image:
                    {
                        label: "Image",
                        type: "image"
                    },
                    links:
                    {
                        addLabel: "Add link",
                        onLabel: ["content", "content", 0],
                        label: "Links",
                        type: "repeater",
                        nameKey: "label",
                        fields:
                        {
                            externalLink:
                            {
                                label: "External link",
                                type: "checkbox"
                            },
                            content:
                            {
                                displayIf: ["externalLink", "!=", true],
                                label: "Content",
                                type: "content",
                                types: ["content::article", "community", "externalLink", "event", "question", "scormcourse", "uploadedfile", "video"]
                            },
                            url:
                            {
                                displayIf: ["externalLink", "==", true],
                                label: "URL",
                                type: "url",
                                default: "https://"
                            },
                            urlBlank:
                            {
                                displayIf: ["externalLink", "==", true],
                                label: "Open in a new tab",
                                type: "checkbox",
                                default: true
                            },
                            urlBlank2:
                            {
                                displayIf: ["externalLink", "!=", true],
                                label: "Open in a new tab",
                                type: "checkbox",
                                default: false
                            },
                            label:
                            {  
                                label: "Label" 
                            }
                        }
                    },
                    expand:
                    {
                        label: "Expanded by default",
                        type: "checkbox",
                        default: false
                    }
                }
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.ScrollView = false;
        this.state = {expand: {}};
    }

    componentDidMount()
    {
        const {dropdowns} = this.Content();
        const {expand} = this.state;
        if (CanForEach(dropdowns))
        {
            dropdowns.forEach(({expand: e, id}) =>
            {
                let Expand = Globals.Client(`expand-${id}`);
                expand[id] = Expand === undefined ? e : Expand;
            });
        }
        this.setState({expand});
    }

    componentDidUpdate(prevProps)
    {
        const {dropdowns} = this.Content();
        if (CanForEach(dropdowns))
        {
            return;
        }
        const {expand} = this.state;
        let Update = false;
        dropdowns.forEach(({expand: e, id}) =>
        {
            if (expand[id] === undefined)
            {
                let Expand = Globals.Client(`expand-${id}`);
                expand[id] = Expand === undefined ? e : Expand;
                Update = true;
            }
        });
        if (Update)
        {
            this.setState({expand});
        }
    }

    OnComplete = () =>
    {
        if (!this.ScrollView)
        {
            return;
        }
        this.ScrollView.OnScroll();
    }

    OnToggle = (e, id) =>
    {
        const {expand} = this.state;
        expand[id] = !expand[id];
        Globals.Client(`expand-${id}`, expand[id]);
        this.setState({expand});
    }

    render()
    {
        const {dropdowns, title} = this.Content();
        const {expand} = this.state;
        const CA = ["Widget", "WidgetDropdown", "BorderRadius"];
        const Segments = [];
        if (CanForEach(dropdowns))
        {
            dropdowns.forEach(({id, image, label, links, text}) =>
            {
                const Links = [];
                if (CanForEach(links))
                {
                    links.forEach((link) =>
                    {
                        Links.push(<ContentButton content={link} key={link.id}/>);
                    });
                }
                Segments.push(
                    <div className={expand[id] ? "WidgetDropdownSegment Expanded" : "WidgetDropdownSegment Collapsed"} key={id}>
                        <Link className="WidgetDropdownSegmentToggle" id={id} onClick={this.OnToggle}>
                            <div className="WidgetDropdownSegmentToggleLabel Heading">{label}</div>
                            <Icon feather="ChevronDown"/>
                        </Link>
                        <Collapsable collapsed={!expand[id]} onComplete={this.OnComplete}>
                            <div className="WidgetDropdownSegmentContent">
                                {image ? <LoadImage
                                    autoAdjust={true}
                                    className="WidgetDropdownSegmentContentImage"
                                    srcId={image[0]}
                                /> : ""}
                                {text}
                                {Links.length ? (
                                    <div className="WidgetDropdownSegmentContentButtons">
                                        {Links}
                                    </div>
                                ) : ""}
                            </div>
                        </Collapsable>
                    </div>
                );
            });
        }
        const CS = this.ClassNames(CA, true);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                {title ? (
                    <div className="WidgetDropdownTitle">
                        <h3>{title}</h3>
                    </div>
                ) : ""}
                <div className="WidgetDropdownSegments">
                    <ScrollView ref={scrollView => this.ScrollView = scrollView}>{Segments}</ScrollView>
                </div>  
            </div>
        );
    }
}

export default WidgetDropdown;