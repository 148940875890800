/**!
 *  SuccessFactors functionality
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import API from "Class/API";
import Auth from "Class/Auth";
import Globals from "Class/Globals";
import Fuse from "Class/Fuse";

class SuccessFactors
{
    constructor()
    {
        this.ApiCompany = Globals.Setting("SuccessFactorsCompany");
        this.ApiUrl = Globals.Setting("SuccessFactorsUrl");
        this.UserId = "";
        this.UserIdRequested = false;
        this.Queue = [];
        if (this.ApiUrl)
        {
            this.ApiUrl = this.ApiUrl.replace(/\/?$/, "/");
        }
    }

    /**
     * Decode a SuccessFactors API response.
     * @param string response - Unparsed response.
     * @return object|bool - Decoded JSON or 'false' on fail.
     */

    Decode = (response) =>
    {
        return JSON.parse(response);
    }

    /**
     * Load events.
     * @param function callback - Called when the request is completed.
     * @return array|bool - Events array or 'false' on fail.
     */

    Learning = (callback) =>
    {
        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        this.Request("learning", {func: "CatalogItems"}, response =>
        {
            const {value} = response || {};
            callback(value || false);
        });
    }

    /*
     * API request or queue and request credentials depending on state.
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when the request is completed.
     * @param string method - Request method. Defaults to 'GET'.
     * @return object - The XHR object.
     */

    Request = (endpoint, data, callback, method = "GET") =>
    {
        if (this.UserId)
        {
            this.RequestSend(endpoint, data, callback, method);
        }
        else
        {
            this.Queue.push([endpoint, data, callback, method]);
            if (!this.UserIdRequested)
            {
                this.UserIdRequested = true;
                Fuse.Request(`manage/users/${Auth.UserId}`, response =>
                {
                    const {profile} = response || {};
                    if (!profile)
                    {
                        console.error("Unable to load SuccessFactors credentials.");
                        return;
                    }
                    this.UserId = profile["IMDL ID"];
                    this.Queue.forEach(([endpoint, data, callback, method]) =>
                    {
                        this.RequestSend(endpoint, data, callback, method);
                    });
                });
            }
        }
    }

    /*
     * Make a XHR request to the SuccessFactors API.
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when the request is completed.
     * @param string method - Request method. Defaults to 'GET'.
     * @return object - The XHR object.
     */

    RequestSend = (endpoint, data, callback, method) =>
    {
        // Since data is optional, the second parameter may instead
        // be the callback method.
        if (!callback)
        {
            callback = data;
        }
        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        if (!this.ApiCompany || !this.ApiUrl || !this.UserId)
        {
            console.error("Missing SuccessFactors credentials.");
            callback(false);
            return false;
        }
        const Data = data || {};
        const Query = [`company=${this.ApiCompany}`, `user=${this.UserId}`];
        let FormData = null;
        if (method === "POST")
        {
            FormData = API.FormData(Data);
        }
        else
        {
            for (let key in Data)
            {
                Query.push(key + "=" + encodeURI(Data[key]));
            }
        }
        const Url = this.ApiUrl + endpoint + "?" + Query.join("&");
        const Xhr = new XMLHttpRequest();
        Xhr.open(method, Url, true);
        // On complete.
        Xhr.addEventListener("load", (e) =>
        {
            // Check for 200.
            if (Xhr.readyState !== XMLHttpRequest.DONE)
            {
                return;
            }
            // Check that the response is a proper JSON.
            const Response = this.Decode(Xhr.response);
            callback(Response);
        }, false);
        // On fail.
        Xhr.addEventListener("error", e => callback(false), false);
        Xhr.send(FormData);
        return Xhr;
    }
}

export default new SuccessFactors();