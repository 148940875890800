
/*!
 *  WYSUWYG editor field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value. 
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./wysiwygfield.scss";
import Wysiwyg from "Components/UI/Wysiwyg";

class WysiwygField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Editor = false;
    }

    /**
     * Set focus on this field.
     * @return void
     */

    Focus = () =>
    {
        if (this.Editor)
        {
            //this.Input.focus();
        }
    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * @param object e - The event object.
     * @return void
     */

    OnKeyDown = (e) =>
    {
        e.stopPropagation();
    }

    /**
     * Reset to inital state.
     * @return void
     */

    Reset = () =>
    {
        if (this.Editor)
        {
            this.Editor.Reset();
        }
    }

    /**
     * Get the field value.
     * @return string - The field value.
     */

    Value = () =>
    {
        return this.Editor ? this.Editor.state.html : "";
    }

    render()
    {
        const {
            className,
            disabled,
            error,
            label,
            value,
            ...inheritProps
        } = this.props;
        const CA = ["Field", "WysiwygField"];
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (value)
        {
            CA.push("HasValue");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                {label ? <label>{label}</label> : ""}
                <div className="InputWrapper">
                    <Wysiwyg
                        {...inheritProps}
                        className="Input"
                        disabled={disabled}
                        ref={editor => this.Editor = editor}
                        value={value}
                    />
                </div>
            </div>
       );
    }
}

WysiwygField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
};

WysiwygField.defaultProps =
{
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    value: ""
};

export default WysiwygField;