/*!
 *  Taxonomy field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value. 
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function|object pool - Taxonomy pool for providing term suggestions.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./taxonomyfield.scss";
import Globals from "Class/Globals";
import {ObjectCompare, Slug, StripTags} from "Functions";
import IconItem from "Components/UI/IconItem";
import IconButton from "Components/UI/IconButton";
import Item from "Components/UI/Item";
import TextareaField from "Components/UI/Field/TextareaField";
import TextField from "Components/UI/Field/TextField";
import Wysiwyg from "Components/UI/Wysiwyg";

class TaxonomyField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.AllowReorder = false;
        this.Block = false;
        this.Mounted = false;
        this.TermCache = {};
        this.TermInputs = {};
        this.TermEditInputs = {};
        this.state =
        {
            focus: false,
            suggestions: false,
            value: {}
        };
    }

    /**
     * Set initial value.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {value} = this.props;
        this.setState({value});
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        if (this.Block)
        {
            return;
        }
        const {value: v1} = this.props;
        const {value: v2} = this.state;
        const {value: v3} = prevProps;
        if (!ObjectCompare(v1, v2) && !ObjectCompare(v1, v3))
        {   
            this.Block = true;
            this.setState({value: v1}, () => this.Block = false);
        }
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    AddTerm = (taxonomyKey, termName) =>
    {
        const {id, onChange, onUpdatePool} = this.props;
        const Name = StripTags(termName).replace("\n", "");
        const Key = Slug(Name);
        const Pool = this.GetPool();
        const Value = this.Value();
        if (!Key.length || Pool[taxonomyKey] === undefined || Value[taxonomyKey]?.terms[Key] !== undefined)
        {
            this.TermInputs[taxonomyKey]?.SetContent("");
            return;
        }
        if (Value[taxonomyKey] === undefined)
        {
            Value[taxonomyKey] = {description: "", name: Pool[taxonomyKey].name, order: -1, terms: {}};
        }
        Value[taxonomyKey].terms[Key] = [Name, -1];
        onChange(null, Value, id);
        if (Pool[taxonomyKey] !== undefined && Pool[taxonomyKey].terms[Key] === undefined)
        {
            Pool[taxonomyKey].terms[Key] = [Name, -1];
            onUpdatePool(null, Pool, id);
        }
        setTimeout(() =>
        {
            this.setState({value: Value}, () => this.TermInputs[taxonomyKey]?.SetContent(""));
        });
    }

    GetPool = () =>
    {
        const {id, pool} = this.props;
        const {value} = this.state;
        return typeof pool === "function" ? pool(id) : pool || (typeof value === "object" ? value : {});
    }

    OnAddTaxonomy = (e) =>
    {
        const {id, onChange, onUpdatePool} = this.props;
        const Pool = this.GetPool();
        const Value = this.Value();
        let Key = "category";
        let Name = "Category";
        let Index = 1;
        while (Pool[Key] !== undefined)
        {
            Index++;
            Key = "category" + Index;
            Name = "Category " + Index;
        }
        const Taxonomy = {description: "", name: Name, order: -1, terms: {}};
        Value[Key] = Pool[Key] = Taxonomy;
        onChange(e, Value, id);
        onUpdatePool(e, Pool, id);
        setTimeout(() =>
        {
            if (!this.Mounted)
            {
                return;
            }
            this.setState({value: Value});
        });
    }

    OnClickSuggestion = (e, [taxonomyKey, termName]) =>
    {
        clearTimeout(this.BlurTimeout);
        this.AddTerm(taxonomyKey, termName);
        this.setState({suggestions: false});
    }

    OnRemoveTaxonomy = (e, key) =>
    {
        Globals.DialogCreate({
            confirmLabel: "Delete Taxonomy",
            message: "Are you sure that you want to delete this taxomomy?",
            onConfirm: () =>
            {
                const {id, onChange, onUpdatePool, onRemoveTaxonomy} = this.props;
                const Pool = this.GetPool();
                const Value = this.Value();
                delete Pool[key];
                delete Value[key];
                onChange(e, Value, id);
                onUpdatePool(e, Pool, id);
                onRemoveTaxonomy(e, key, id);
                setTimeout(() =>
                {
                    if (!this.Mounted)
                    {
                        return;
                    }
                    this.setState({value: Value});
                });
            },
            title: "Delete Taxonomy",
            type: "confirm"
        });
    }

    OnSortTaxonomyDown = (e, key) => this.OnSortTaxonomyMove(e, key, +1);
    OnSortTaxonomyUp = (e, key) => this.OnSortTaxonomyMove(e, key, -1);

    OnSortTaxonomyMove = (e, key, direction) =>
    {
        const CurrentOrder = this.SortTaxonomies().map(({key}) => key);
        const IndexFrom = CurrentOrder.indexOf(key);
        const IndexTo = IndexFrom + direction;
        if (IndexFrom < 0 || IndexTo < 0 || IndexTo >= CurrentOrder.length)
        {
            return;
        }
        const NewOrder = Array.from(CurrentOrder);
        NewOrder.splice(IndexTo, 0, NewOrder.splice(IndexFrom, 1)[0]);
        console.log("New Order", NewOrder);
    }

    OnTaxonomyDescription = (e, description, key) =>
    {
        const {id, onTaxonomyDescription, onUpdatePool} = this.props;
        const Pool = this.GetPool();
        const Value = this.Value();
        const PoolTaxonomy = Pool[key] || {};
        const ValueTaxonomy = Value[key] || {};
        PoolTaxonomy.description = ValueTaxonomy.description = description;
        onUpdatePool(e, Pool, id);
        onTaxonomyDescription(e, key, description, id);
        setTimeout(() =>
        {
            if (!this.Mounted)
            {
                return;
            }
            this.setState({value: Value});
        });
    }

    OnTaxonomyName = (e, newName, oldKey) =>
    {
        const {id, onTaxonomyName, onUpdatePool} = this.props;
        const Pool = this.GetPool();
        const Value = this.Value();
        const DesiredKey = newName ? Slug(newName) : "category";
        const DesiredName = newName || "Category";
        const PoolTaxonomy = Pool[oldKey] || {};
        const ValueTaxonomy = Value[oldKey] || {};
        let Key = DesiredKey;
        let Name = DesiredName;
        let Index = 1;
        while (Pool[Key] !== undefined)
        {
            Index++;
            Key = DesiredKey + Index;
            Name = DesiredName + " " + Index;
        }
        PoolTaxonomy.name = ValueTaxonomy.name = Name;
        delete Pool[oldKey];
        delete Value[oldKey];
        Pool[Key] = PoolTaxonomy;
        Value[Key] = ValueTaxonomy;
        onUpdatePool(e, Pool, id);
        onTaxonomyName(e, oldKey, Key, Name, id);
        setTimeout(() =>
        {
            if (!this.Mounted)
            {
                return;
            }
            this.setState({value: Value});
        });
    }

    OnTermBlur = (e, termName, taxonomyKey) =>
    {
        if (!this.Mounted)
        {
            return;
        }
        clearTimeout(this.BlurTimeout);
        this.BlurTimeout = setTimeout(() => 
        {
            this.AddTerm(taxonomyKey, termName);
            this.setState({suggestions: false});
        }, 200);
    }

    OnTermEditBlur = (e, termName, [termKey, taxonomyKey]) =>
    {
        const {id, onEditTerm} = this.props;
        const OldName = this.TermCache[taxonomyKey + termKey] || "";
        const NewName = (termName || OldName).replace("\n", "");
        if (OldName !== NewName)
        {
            onEditTerm(e, OldName, NewName, termKey, taxonomyKey, id);
        }
        setTimeout(() =>
        {
            this.TermEditInputs[taxonomyKey + termKey]?.SetContent(NewName);
        });
    }

    OnTermEditFocus = (e, termName, [termKey, taxonomyKey]) =>
    {
        this.TermCache[taxonomyKey + termKey] = termName;
    }

    OnTermEditInput = (e, termName, [termKey, taxonomyKey]) =>
    {
        switch (e.getLastChangeType())
        {
            case "split-block":
                this.OnTermEditBlur(e, termName, [termKey, taxonomyKey]);
                break;
            default:
        }
    }

    OnTermInput = (e, value, key) =>
    {
        switch (e.getLastChangeType())
        {
            case "split-block":
                this.AddTerm(key, value);
                break;
            default:
                this.ShowSuggestions(key, value);
                break;
        }
    }

    OnTermInputClick = (e, key) =>
    {
        this.TermInputs[key]?.Focus();
    }

    OnTermRemove = (e, [taxonomyKey, termKey]) =>
    {
        this.RemoveTerm(taxonomyKey, termKey);
    }

    RemoveTerm = (taxonomyKey, termKey) =>
    {
        const {id, onChange, onUpdatePool} = this.props;
        const Pool = this.GetPool();
        const Value = this.Value();
        delete Value[taxonomyKey]?.terms[termKey];
        onChange(null, Value, id);
        onUpdatePool(null, Pool, id);
        setTimeout(() =>
        {
            this.setState({value: Value});
        });
    }

    /**
     * Reset to inital state.
     * @return void
     */

    Reset = () =>
    {
        const {id, onChange, value} = this.props;
        this.setState({value});
        onChange(null, value, id);
    }

    ShowSuggestions = (taxonomyKey, termInputValue) =>
    {
        const Pool = this.GetPool();
        const Value = this.Value();
        if (!termInputValue.match(/[a-z]/i) || Pool[taxonomyKey] === undefined)
        {
            this.setState({suggestions: false})
            return;
        }
        const AvailableTerms = Object.values(Pool[taxonomyKey].terms);
        const ExistingTerms = Object.values(Value[taxonomyKey]?.terms || {}).map(term => typeof term === "object" ? term[0] : term);
        const Match = new RegExp(termInputValue, "i");
        const List = [];
        AvailableTerms.forEach(term =>
        {
            const termName = typeof term === "object" ? term[0] : term;
            if (List.length >= 5 || !termName.match(Match) || ExistingTerms.indexOf(termName) >= 0)
            {
                return;
            }
            List.push(termName);
        });
        this.setState({suggestions: [taxonomyKey, List]})
    }

    SortTaxonomies = () =>
    {
        const Pool = this.GetPool();
        const Value = this.Value();
        const Taxonomies = [];
        for (let taxonomyKey in Pool)
        {
            const {description = "", name = "", order = -1, terms = []} = Pool[taxonomyKey];
            const AddedTerms = Object.keys(Value[taxonomyKey]?.terms || {});
            const Terms = [];
            for (let termKey in terms)
            {
                if (AddedTerms.indexOf(termKey) < 0)
                {
                    continue;
                }
                const [termName, termOrder = -1] = Array.isArray(terms[termKey]) ? terms[termKey] : [terms[termKey], -1];
                Terms.push({key: termKey, name: termName, order: termOrder});
            }
            Terms.sort(({name: nameA, order: orderA}, {name: nameB, order: orderB}) =>
            {
                if (orderA > orderB) return +1;
                if (orderA < orderB) return -1;
                return nameA.localeCompare(nameB);
            });
            Taxonomies.push({description, key: taxonomyKey, name, order, terms: Terms})
        }
        Taxonomies.sort(({name: nameA, order: orderA}, {name: nameB, order: orderB}) =>
        {
            if (orderA > orderB) return +1;
            if (orderA < orderB) return -1;
            if (nameA.substr(0, 8) !== "Category" && nameB.substr(0, 8) === "Category") return +1;
            if (nameA.substr(0, 8) === "Category" && nameB.substr(0, 8) !== "Category") return +1;
            return nameA.localeCompare(nameB);
        });
        return Taxonomies;
    }

    /**
     * Get the field value.
     * @return string - The field value.
     */

    Value = () =>
    {
        const {value} = this.state;
        return typeof value === "object" && !Array.isArray(value) ? value : {};
    }

    render()
    {
        const {
            className,
            disabled,
            error,
            label
        } = this.props;
        const CA = ["Field", "TaxonomyField"];
        const Pool = this.GetPool();
        const Value = this.Value();
        const Taxonomies = this.SortTaxonomies();
        const Labels = Object.keys(Pool);
        Labels.sort();
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (error)
        {
            CA.push("Error");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                {label ? <label>{label}</label> : ""}
                <div className="TaxonomyFieldList">
                    {/*Labels.map(key => this.renderTaxonomy(Pool[key], Value[key]?.terms, key))*/}
                    {Taxonomies.map(taxonomy => this.renderTaxonomy(taxonomy))}
                </div>
                <IconItem
                    disabled={disabled}
                    feather="Plus"
                    label="Add Taxonomy"
                    onClick={this.OnAddTaxonomy}
                />
            </div>
        );
    }

    renderSuggestions(taxonomyKey, list)
    {
        if (!list.length)
        {
            return "";
        }
        return (
            <div className="TaxonomyFieldTermInputSuggestions">
                {list?.map(termName => (
                    <Item
                        className="TaxonomyFieldTermInputSuggestionsItem"
                        id={[taxonomyKey, termName]}
                        key={termName}
                        label={termName}
                        onClick={this.OnClickSuggestion}
                    />
                ))}
            </div>
        );
    }

    renderTaxonomy({description = "", key = "", name = "", terms = []})
    {
        const {disabled} = this.props;
        const {suggestions} = this.state;
        const TermItems = [];
        terms.forEach(({key: termKey, name: termName}) => 
        {
            TermItems.push(
                <div className="TaxonomyFieldTermItem" key={termKey}>
                    <Wysiwyg
                        className="TaxonomyFieldEditTermInput"
                        disabled={disabled}
                        id={[termKey, key]}
                        minimal={2}
                        onBlur={this.OnTermEditBlur}
                        onFocus={this.OnTermEditFocus}
                        onChange={this.OnTermEditInput}
                        ref={input => this.TermEditInputs[key + termKey] = input}
                        value={termName}
                    />
                    <IconButton
                        feather="X"
                        id={[key, termKey]}
                        onClick={this.OnTermRemove}
                    />
                </div>
            );
        });
        return (
            <div className="TaxonomyFieldListItem" key={key}>
                <div className="TaxonomyFieldListItemButtons">
                    {this.AllowReorder ? (
                        <>
                            <IconButton
                                className="TaxonomyFieldListItemSortMove"
                                disabled={disabled}
                                feather="Move"
                                id={key}
                                onClick={this.OnSortTaxonomyStart}
                            />
                            <IconButton
                                className="TaxonomyFieldListItemSortUp"
                                disabled={disabled}
                                feather="ArrowUp"
                                id={key}
                                onClick={this.OnSortTaxonomyUp}
                            />
                            <IconButton
                                className="TaxonomyFieldListItemSortDown"
                                disabled={disabled}
                                feather="ArrowDown"
                                id={key}
                                onClick={this.OnSortTaxonomyDown}
                            />
                        </>
                    ) : ""}
                    <IconButton
                        className="TaxonomyFieldListItemRemove"
                        disabled={disabled}
                        feather="X"
                        id={key}
                        onClick={this.OnRemoveTaxonomy}
                    />
                </div> 
                <div className="TaxonomyFieldName">{name}</div>
                <TextField
                    className="TaxonomyFieldNameInput"
                    disabled={disabled}
                    feather="Edit2"
                    id={key}
                    onChange={this.OnTaxonomyName}
                    value={name}
                />
                <TextareaField
                    className="TaxonomyFieldDescriptionInput"
                    disabled={disabled}
                    id={key}
                    onChange={this.OnTaxonomyDescription}
                    placeholder="Description..."
                    value={description}
                />
                <div className="TaxonomyFieldTerms">
                    {TermItems}
                    <div className="TaxonomyFieldTermInputContainer">
                        <div className="TaxonomyFieldTermInputWrapper">
                            <Wysiwyg
                                className="TaxonomyFieldTermInput"
                                disabled={disabled}
                                id={key}
                                minimal={2}
                                onBlur={this.OnTermBlur}
                                onChange={this.OnTermInput}
                                placeholder="Add term..."
                                ref={input => this.TermInputs[key] = input}
                            />
                            <IconButton
                                feather="Plus"
                                id={key}
                                onClick={this.OnTermInputClick}
                            />
                        </div>
                        {suggestions && suggestions[0] === key ? this.renderSuggestions(...suggestions) : ""}
                    </div>
                </div>
            </div>
        );
    }
}

TaxonomyField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    onEditTerm: PropTypes.func,
    onRemoveTaxonomy: PropTypes.func,
    onTaxonomyDescription: PropTypes.func,
    onTaxonomyName: PropTypes.func,
    onUpdatePool: PropTypes.func,
    pool: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    value: PropTypes.object
};

TaxonomyField.defaultProps =
{
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onChange: () => {},
    onEditTerm: () => {},
    onRemoveTaxonomy: () => {},
    onTaxonomyDescription: () => {},
    onTaxonomyName: () => {},
    onUpdatePool: () => {},
    pool: false,
    value: {}
};

export default TaxonomyField;