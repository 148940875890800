
/**!
 *  Conditional widget field. Used to setup the conditional widget.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./conditionalwidgetfield.scss";

import Globals from "Class/Globals";
import RepeaterField from "Components/UI/Field/RepeaterField";

class ConditionalWidgetField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            description:
            {
                label: "Description",
                placeholder: "A short description to provide a better overview..."
            },
            conditions:
            {
                label: "Conditions",
                type: "conditions"
            }
        };
    }

    OnEditWidget = (e, index) =>
    {
        const {value} = this.props;
        const Widget = value[index];
        const {id} = Widget || {};
        if (!id)
        {
            return;
        }
        Globals.ViewActive(id);
    }

    render()
    {
        return (
            <RepeaterField
                {...this.props}
                additionalButtons={[["Edit", this.OnEditWidget, "Edit Widget"]]}
                addLabel="Add Widget"
                fields={this.Fields}
            />
        );
    }
}

ConditionalWidgetField.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    value: PropTypes.array
};

ConditionalWidgetField.defaultProps =
{
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onChange: () => {},
    value: []
};

export default ConditionalWidgetField;