/**!
 *  Recommended content widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./recommended.scss";
import Auth from "Class/Auth";
import Fuse from "Class/Fuse";
import { ArrayClone, ObjectCompare, RandomToken, StripTags } from "Functions";
import IconItem from "Components/UI/IconItem";
import Link from "Components/UI/Link";
import Preview from "Components/Layout/Preview";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetRecommended extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Recommended Content",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            communities:
            {
                label: "Filter from Communities",
                multiple: true,
                placeholder: "Search for community...",
                type: "content",
                types: ["community"]
            },
            externalLink:
            {
                label: "Open contents in a new tab",
                type: "checkbox"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.LoadToken = false;
        this.Mounted = false;
        this.Name = "Recommended";
        this.state =
        {
            done: false,
            content: [],
            error: false,
            loading: false,
            page: 0
        };
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {content} = this.props;
        this.Load(content, true);
    }

    /**
     * Reload content when a new community filter is received.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {communities: m1} = c1;
        const {communities: m2} = c2;
        if (!ObjectCompare(m1, m2))
        {
            this.Load(c1, true);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Output a content item.
     * @param object content - Content object.
     * @param integer index - Item index in list.
     * @return JSX - The content item.
     */

    Item = (content, index) =>
    {
        const {externalLink} = this.Content();
        const {content_type, description, id, preview, name} = content;
        const Url = Fuse.Url(`contents/${id}`, false);
        return (
            <Link
                className="WidgetRecommendedItem"
                key={`${index}.${id}`}
                href={Url}
                target={externalLink ? "_blank" : "_top"}
                title={name}
            >
                <Preview
                    className="ItemPreview"
                    content={content_type}
                    image={preview}
                />
                <div className="ItemContent">
                    <div className="ItemName">{name}</div>
                    <div className="ItemInfo">
                        {StripTags(description)}
                    </div>
                </div>
            </Link>
        );
    }

    /**
     * Load recommended content from the Fuse API.
     * @param object input - Input community filter.
     * @param boolean clear - Whether to clear the widget before loading new contents.
     * @return void
     */

    Load = (input, clear) =>
    {
        const {content: c1} = this.props;
        const {content: c2, page} = this.state;
        const {communities} = input || c1;
        const {UserId} = Auth;
        const Page = clear ? 1 : page || 1;
        if (!UserId)
        {
            return;
        }
        const Params =
        {
            page: Page,
            swagger_request: "true"
        };
        const State =
        {
            error: false,
            loading: true
        };
        if (communities && communities.length)
        {
            Params.community_ids = communities.join(",");
        }
        if (clear)
        {
            State.content = [];
            State.page = 1;
        }
        const Token = this.LoadToken = RandomToken();
        this.setState(State);
        Fuse.Request(`recommended_content/${UserId}`, Params, response =>
        {
            if (!this.Mounted || Token !== this.LoadToken)
            {
                return;
            }
            const {last_page, recommended_content} = response || {};
            if (!recommended_content)
            {
                this.setState({
                    error: true,
                    loading: false
                });
            }
            else
            {
                const Content = clear ? recommended_content : ArrayClone(c2).concat(recommended_content);
                this.setState({
                    done: last_page,
                    content: Content,
                    loading: false,
                    page: Page + 1
                });
            }
        });
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props; 
        const {content, done, loading} = this.state;
        const CA = ["Widget", "WidgetRecommended", "White", "BorderRadius"];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        const Content = [];
        const Empty = !content || !content.length;
        if (Empty && loading)
        {
            Content.push(<Spinner className="WidgetSpinner" key="spinner" overlay={true}/>);
        }
        else if (Empty)
        {
            Content.push(<div className="WidgetEmpty" key="empty">No content</div>);
        }
        else
        {
            const Items = [];
            content.forEach((c, i) =>
            {
                Items.push(this.Item(c, i));
            });
            Content.push(<div className="WidgetRecommendedItems" key="items">{Items}</div>);
            Content.push(<IconItem
                className="WidgetRecommendedButton"
                disabled={done}
                feather="PlusCircle"
                key="fetch"
                label="Show more"
                loading={loading}
                onClick={this.Load}
            />);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper {...WidgetContent} className="WidgetRecommendedWrapper">
                    {Content}
                </WidgetWrapper>
            </div>
       );
    }
}

export default WidgetRecommended;