/**!
 *  Hijack manager widgets.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./manager.scss";
import Broadcast from "Class/Broadcast";
import {ObjectCompare} from "Functions";

class WidgetManager extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
        title:
        {
            default: "",
            insert: true,
            label: "Title",
            reset: true,
            type: "text"
        },
        source:
        {
            filter: ["engagements", "learning_plan_progress", "recommendations", "reportee_content_views"],
            label: "Source Widget",
            type: "manager-widgets"
        },
        });
        this.state =
        {
            error: false,
            loading: false
        };
        this.Name = "Manager Widgets";
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        const {source} = this.Content();
        const [Grid, Widget] = source || [];
        this.Load(Grid, Widget);
    }

    /**
     * Update content when a new ID is received.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {source: s1} = c1 || {};
        const {source: s2} = c2 || {};
        if (!ObjectCompare(s1, s2))
        {
            const [Grid, Widget] = s1 || [];
            this.Load(Grid, Widget);
        }
    }

    /**
     * Load widget data.
     * @param int|string grid - Grid id.
     * @param int|string widget - Widget id.
     * @return void
     */

    Load = (grid, widget) =>
    {
        const {id} = this.props;
        Broadcast.SendMessage({
            id,
            type: "manager-widget",
            gridId: grid,
            widgetId: widget
        });
    }

    render()
    {
        const CA = ["WidgetManager"];
        const CS = this.ClassNames(CA, true);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                Managers
            </div>
        );
    }
}

export default WidgetManager;