import React from "react";
import API from "Class/API";
import ImageField from "Components/UI/Field/ImageField";

class ArticleImage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Mounted = false;
        this.state =
        {
            error: false,
            loading: false,
            src: ""
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
        const {imageId} = this.props;
        const Id = Array.isArray(imageId) ? imageId[0] : imageId;
        if (Id)
        {
            this.LoadSrc(Id);
        }
    }

    componentDidUpdate(prevProps)
    {
        const {imageId: i1} = this.props;
        const {imageId: i2} = prevProps;
        const Id1 = Array.isArray(i1) ? i1[0] : i1;
        const Id2 = Array.isArray(i2) ? i2[0] : i2;
        if (Id1 !== Id2)
        {
            this.LoadSrc(Id1);
        }
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    GetSrc = () =>
    {
        const {imageId, srcref = {}} = this.props;
        const {src} = this.state;
        const Id = Array.isArray(imageId) ? imageId[0] : imageId;
        return srcref[Id] || src;
    }

    LoadSrc = (imageId) =>
    {
        if (!imageId)
        {
            return this.SetSrc("");
        }
        this.setState({error: false, loading: imageId});
        API.Request("files/static", {id: imageId}, response =>
        {
            if (!this.Mounted || imageId !== this.state.loading)
            {
                return;
            }
            const {onUrl} = this.props;
            const {error, src} = response;
            if (!src || error)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                onUrl(imageId, src);
                this.SetSrc(src);
            }
        });

    }

    SetSrc = (imageSrc) =>
    {
        this.setState({loading: false, src: imageSrc});
    }

    render()
    {
        const {className, disabled, editable, id, onEdit} = this.props;
        const {loading} = this.state;
        const CA = ["cb-i"];
        const Src = this.GetSrc();
        if (loading)
        {
            CA.push("Loading");
        }
        if (className)
        {
            CA.push(className);
        }
        if (!Src)
        {
            return "";
        }
        const Image = Src ? (<img className={CA.join(" ")} src={Src}/>) : "";
        if (!editable)
        {
            return Image;
        }
        return (
            <>
                {editable ? <ImageField
                    className="ViewArticlesImageField"
                    disabled={disabled}
                    id={id}
                    onChange={onEdit}
                    minimal={true}
                /> : ""}
                {Image}
            </>
        );
    }
}

ArticleImage.defaultProps =
{
    className: "",
    editable: false,
    id: "",
    imageId: 0,
    onLoad: () => {},
    onSrc: () => {},
    onUrl: () => {},
    srcref: {}
};

export default ArticleImage;