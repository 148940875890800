/**!
 *  Content editor.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */
import React from "react";
import PropTypes from "prop-types";
import "./editor.scss";
import API from "Class/API";
import Auth from "Class/Auth";
import Broadcast from "Class/Broadcast";
import Fuse from "Class/Fuse";
import Globals from "Class/Globals";
import {ArrayClone, DateTimeStamp, DefaultValue, ObjectCompare, ParsedValue, RandomToken, Time} from "Functions";
import EditorTree from "./tree.js";
import Button from "Components/UI/Button";
import CheckboxField from "Components/UI/Field/CheckboxField";
import Form from "Components/UI/Form";
import Group from "Components/UI/Group";
import IconButton from "Components/UI/IconButton";
import IconItem from "Components/UI/IconItem";
import ColorField from "Components/UI/Field/ColorField";
import PermissionField from "Components/UI/Field/PermissionField";
import SelectField from "Components/UI/Field/SelectField";
import TabMenu from "Components/UI/TabMenu";
import TextareaField from "Components/UI/Field/TextareaField";
import TextField from "Components/UI/Field/TextField";
import NumberField from "Components/UI/Field/NumberField";

class ViewEditor extends React.Component
{
    constructor(props)
    {
        super(props);
        this.CloseLogOnActive = true;
        this.ConfirmDialog = false;
        this.DefaultAttributes = {autoAdjust: true, fill: false};
        this.DefaultContent = {description: "", name: "", template: "Blank"};
        this.DraftsDialog = false;
        this.EventLogTitle = "Event log";
        this.EventLogType = "log";
        this.IsUnsaved = null;
        this.LoadDialog = false;
        this.LogDialog = false;
        this.Mounted = false;
        this.Reserved = ["description", "name", "template"];
        this.TabLabels = ["Widgets", "Container", "Content"];
        this.SetDelay = 0;
        this.SetTimer = false;
        this.ShowArticleEditor = true;//["localhost:8000", "pmitest.staging.fuseuniversal.com"];
        this.StatusToken = false;
        this.Unsaved = [];
        this.UpdateDelay = 0;
        this.UpdateTimer = false;
        this.Widgets =
        {
            Ask: "Ask Question",
            AssignedLearning: "Assigned Learning",//"Assigned Learning (deprecated)",
            BannerLinks: "Banner with links",
            Blank: "Blank",
            Button: "Button",
            Carousel: "Carousel",
            Communities: "Community links",
            Conditional: "Conditional content",
            Content: "Content links",
            Conversation: "Conversations",
            CustomHtml: "Custom HTML",
            Dropdown: "Dropdowns",
            Events: "Events",
            Favourites: "Favourites",
            Hijack: "Hijack",
            ImageAndText: "Image and Text",
            //Leaderboard: "Leaderboard (deprecated)",
            LearningPlans: "Learning plans",
            Link: "Link",
            //Manager: "Manager Widgets",
            Multi: "Multi",
            Poll: "Poll",
            Question: "Question",
            Recommended: "Recommended",
            //Search: "Search",
            SuccessFactors: "SuccessFactors",
            Tabs: "Tabs",
            //Trending: "Trending Content (deprecated)",
            //UserActivities: "User Activities (deprecated)",
            Video: "Video",
            Wysiwyg: "Rich-text"
        };
        this.state =
        {
            active: false,
            error: false,
            loading: false,
            status: "\u00A0",
            tab: "0",
            views: {}
        };
    }

    /**
     * Add listeners on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.setState({views: ArrayClone(Globals.Views)});
        Globals.Listen("action-view", this.OnAction);
        Globals.Listen("active-view", this.OnActive);
        Globals.Listen("position-view", this.OnPosition);
        Globals.Listen("register-view", this.OnRegister);
        Globals.Listen("unregister-view", this.OnUnregister);
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        Globals.Remove("action-view", this.OnAction);
        Globals.Remove("active-view", this.OnActive);
        Globals.Remove("position-view", this.OnPosition);
        Globals.Remove("register-view", this.OnRegister);
        Globals.Remove("unregister-view", this.OnUnregister);
    }

    /**
     * Get the active view.
     * @return object|boolean - The active view or 'false' when no view is active.
     */

    Active = () =>
    {
        const {active, views} = this.state;
        return views[active] || false;
    }

    /**
     * Check if a view has unsaved changes.
     * @param object view - The view object.
     * @param object container - Current state of the container.
     * @param object content - Current state of the content.
     * @return boolean - Whether the view has unsaved changes.
     */

    ChangedView = (view, container, content) =>
    {
        const {draft, restore} = view;
        const Ct1 = container || draft.container;
        const Cn1 = content || draft.content;
        const {container: Ct2, content: Cn2} = restore;
        if (!Ct1 || !Ct2 || !Cn1 || !Cn2)
        {
            return true;
        }
        if (Cn2.id !== Ct1.content) return true;
        if (!ObjectCompare(Ct1.attributes, Ct2.attributes)) return true;
        if (!ObjectCompare(Ct1.scope, Ct2.scope)) return true;
        if (!ObjectCompare(Cn1.content, Cn2.content)) return true;
        if (!ObjectCompare(Cn1.scope, Cn2.scope)) return true;
        return false;
    }

    ClearAllDrafts = () =>
    {
        const {active, views} = this.state;
        const ids = Object.keys(views);
        ids.forEach(id =>
        {
            Broadcast.SendMessage({
                type: "clear-drafts",
                id
            });
            this.ClearDrafts(id, false);
        });
        API.Request(`widget/drafts-clear-all`, {names: ids});
        if (!active || views[active] === undefined)
        {
            this.setState({views});
        }
        else
        {
            this.setState({
                status: this.SetStatusFromDraft(views[active].draft, false),   
                views
            });
        }
    }
    
    /**
     * Clear local and public draft versions of a widget container.
     * @param string containerId - The widget container id.
     * @return object|boolean - A clean draft object or 'false' when failed.
     */

    ClearDraft = (containerId) =>
    {
        Globals.StorageRemove(`draft-${containerId}`);
        const {views, active} = this.state;
        const ContainerId = containerId || active;
        if (views[ContainerId] === undefined)
        {
            return false;
        }
        views[ContainerId].draft.id = false;
        views[ContainerId].draft.local = false;
        views[ContainerId].draft.unsaved = false;
        views[ContainerId].draft.user = Auth.UserId;
        const Public = []
        views[ContainerId].drafts.forEach((publicDraft, index) =>
        {
            if (publicDraft.user === Auth.UserId)
            {
                Public.push(index);
            }
        });
        Public.forEach((draftIndex, index) =>
        {
            views[ContainerId].drafts.splice(draftIndex - index, 1);
        });
        this.setState({
            status: this.SetStatusFromDraft(views[ContainerId].draft, false),   
            views
        });
        return views[ContainerId].draft;
    }

    /**
     * Clear all draft versions of a widget container.
     * @param string containerId - The widget container id.
     * @return object - The view object, if it's defined.
     */

    ClearDrafts = (containerId, setStatus = true) =>
    {
        const {active, views} = this.state;
        const ContainerId = containerId || active;
        Globals.StorageRemove(`draft-${ContainerId}`);
        if (views[containerId] === undefined)
        {
            return;
        }
        views[ContainerId].draft.id = false;
        views[ContainerId].draft.local = false;
        views[ContainerId].draft.unsaved = false;
        views[ContainerId].draft.user = Auth.UserId;
        views[ContainerId].drafts = [];
        if (setStatus)
        {
            this.setState({
                status: this.SetStatusFromDraft(views[ContainerId].draft, false),   
                views
            });
        }
    }

    /**
     * Clear the local draft from storage.
     * @param string containerId - The widget container id.
     * @return void
     */

    ClearLocalDraft = (containerId) =>
    {
        const {active} = this.state;
        const ContainerId = containerId || active;
        Globals.StorageRemove(`draft-${ContainerId}`);
    }

    /**
     * Request the widget to clear all poll results.
     * @return void
     */

    ClearPoll = () =>
    {
        const View = this.Active();
        const {container, content} = View.draft;
        const {id: containerId} = container || {};
        const {id: contentId} = content || {};
        Broadcast.SendMessage({
            id: contentId || containerId,
            type: "request",
            request: {type: "clearPoll", community: this.CurrentCommunity()}
        });
    }

    /**
     * Callback when the close icon has been clicked. Broadcasts a request for
     * the editor to hide.
     * @return void
     */

    Close = () =>
    {
        Broadcast.SendMessage({type: "close"});
    }

    /**
     * Get the current community id.
     * @return int - The community id.
     */

    CurrentCommunity = () =>
    {
        const {context, contextId} = this.props;
        return context === "community" ? contextId : 0;
    }

    /**
     * Export leaderboard from the current widget.
     * @return void
     */

    ExportLeaderboard = () =>
    {
        if (!Auth.Key)
        {
            return;
        }
        const View = this.Active();
        const {context, contextId} = this.props;
        const {container, content} = View.draft;
        const {community} = content.content || {};
        let Community = (typeof community === "object" && community.length) ? community[0][0] : false;
        if (!Community && context === "community")
        {
            Community = contextId;
        }
        if (!Community)
        {
            return;
        }
        const {id: containerId} = container || {};
        const {id: contentId} = content || {};
        const Id = contentId || containerId;
        const Host = btoa(API.Host);
        const Key = btoa(Auth.Key);
        const Url = API.Url(`widget/leaderboard-export/${Id}/${Community}/${Host}/${Key}`);
        window.open(Url);
    }

    /**
     * Export poll results for the current widget.
     * @return void
     */

    ExportPoll = () =>
    {
        if (!Auth.Key)
        {
            return;
        }
        const View = this.Active();
        const {container, content} = View.draft;
        const {id: containerId} = container || {};
        const {id: contentId} = content || {};
        const Id = contentId || containerId;
        const Host = btoa(API.Host);
        const Key = btoa(Auth.Key);
        const Community = this.CurrentCommunity();
        const Url = API.Url(`widget/poll-export/${Id}/${Host}/${Key}/${Community}`);
        window.open(Url);
    }

    /**
     * Filter all unsaved views ids into an array.
     * @return void
     */

    FilterUnsaved = () =>
    {
        setTimeout(() =>
        {
            const {views} = this.state;
            for (let id in views)
            {
                let Index = this.Unsaved.indexOf(id);
                let Unsaved = views[id].draft.unsaved;
                if (Unsaved && Index < 0)
                {
                    this.Unsaved.push(id);
                }
                else if (!Unsaved && Index >= 0)
                {
                    this.Unsaved.splice(Index, 1);
                }
            }
            // Broadcast save state when it changes.
            const IsUnsaved = !!this.Unsaved.length;
            if ((this.IsUnsaved && !IsUnsaved) || (!this.IsUnsaved && IsUnsaved))
            {
                Broadcast.SendMessage({
                    type: "unsaved",
                    unsaved: IsUnsaved
                });
                this.IsUnsaved = IsUnsaved;
            }
            this.forceUpdate();
        }, 0);
    }

    /**
     * List all local and public draft version of a widget container.
     * @param int containerId - The widget container id.
     * @return array - List of draft objects.
     */

    ListDrafts = (containerId) =>
    {
        const {active, views} = this.state;
        const View = views[containerId || active];
        const Drafts = [];
        if (!View)
        {
            return Drafts;
        }
        const {draft, drafts} = View;
        const Ids = [];
        if (drafts)
        {
            drafts.forEach(publicDraft =>
            {
                let Draft = ArrayClone(publicDraft);
                Draft.active = draft.id === publicDraft.id;
                Draft.local = false;
                Draft.container = Draft.data.container;
                Draft.content = Draft.data.content;
                delete Draft.data;
                Drafts.push(Draft);
                Ids.push(publicDraft.id);
            });
        }
        const Deleted = draft.id && Ids.indexOf(draft.id) < 0;
        if ((!draft.id && draft.unsaved) || Deleted)
        {
            let Draft = ArrayClone(draft);
            Draft.active = true;
            Draft.local = true;
            if (Deleted)
            {
                Draft.id = false;
                Draft.unsaved = true;
            }
            Drafts.push(Draft);
        }
        Drafts.sort((a, b) =>
        {
            if (a.updated > b.updated) return -1;
            if (a.updated < b.updated) return +1;
            return 0;
        });
        return Drafts;
    }

    /**
     * Load widget draft data from local storage or a drafts array.
     * @param int containerId - The widget container id.
     * @param array drafts - Saves drafts.
     * @return object - Saved data.
     */

    LoadDraft = (containerId, drafts) =>
    {
        const LocalDraft = this.LoadLocalDraft(containerId);
        if (LocalDraft)
        {
            const {container, content, id, unsaved, updated, user} = LocalDraft;
            return {
                container: container || false,
                content: content || false,
                id: id || false,
                local: !id || unsaved,
                own: Auth.UserId === user,
                updated,
                user
            };
        }
        if (drafts && drafts.length)
        {
            const {data, id, own, updated, user} = drafts[0];
            const {container, content} = data || {};
            return {
                container: container || false,
                content: content || false,
                id: id || false,
                local: false,
                own,
                updated,
                user
            };
        }
        return {
            container: false,
            content: false,
            id: false,
            local: false,
            own: false,
            updated: false,
            user: false
        };
    }

    /**
     * Load widget draft data from local storage.
     * @param int containerId - The widget continer id.
     * @return object|boolean - Saved data.
     */

    LoadLocalDraft = (containerId) =>
    {
        return Globals.StorageJson(`draft-${containerId}`, false);
    }

    /**
     * Switch editor tab when a toolbar button has been clicked.
     * @param string tab - The tab key, "0", "1" or "2".
     * @return void
     */

    OnAction = (tab) =>
    {
        if (tab === this.state.tab)
        {
            return;
        }
        this.setState({tab});
    }

    /**
     * Callback when a widget has gained focus.
     * @param string active - The unique id of the active widget.
     * @return void
     */

    OnActive = (active) =>
    {
        if (active === this.state.active)
        {
            return;
        }
        const {views} = this.state;
        // Updated log (if active).
        if (!this.CloseLogOnActive && this.LogDialog && views[active] !== undefined)
        {
            const {edit, id} = views[active];
            const {content} = edit || {};
            const {id: contentId} = content || {};
            Globals.DialogUpdate(this.LogDialog, {
                title: this.EventLogTitle,
                type: this.EventLogType,
                props: {
                    community: this.CurrentCommunity(),
                    container: id,
                    content: contentId
                }
            });
        }
        else if (this.CloseLogOnActive)
        {
            Globals.DialogDestroy(this.LogDialog);
        }
        Globals.DialogDestroy(this.ConfirmDialog);
        Globals.DialogDestroy(this.DraftsDialog);
        Globals.DialogDestroy(this.LoadDialog);
        this.setState({
            active,
            status: this.SetStatus(active)
        });
    }

    /**
     * Show the advanced actions dialog.
     * @return void
     */

    OnAdvanced = () =>
    {
        this.AdvancedDialog = Globals.DialogCreate({
            title: "Advanced actions",
            type: "menu",
            props:
            {
                items:
                [
                    {
                        action: this.OnClearAllDrafts,
                        label: "Clear all draft versions",
                        text: "Clear all draft versions (local and public) for ALL widgets currently on display."
                    }
                ]
            }
        });
    }

    /**
     * Open the article editor.
     * @return void
     */

    OnArticleEditor = () =>
    {
        Globals.OpenArticleEditor();
    }

    /**
     * Open the widget content dialog when the 'Load' button is clicked.
     * @return void
     */

    OnBrowse = () =>
    {
        this.LoadDialog = Globals.DialogCreate({
            title: "Load widget content",
            type: "widgets",
            props:
            {
                onCopy: this.OnLoad,
                onLoad: this.OnLoad
            }
        });
    }

    /**
     * Callback when a callback method is requested.
     * @param object e - The event object.
     * @param string callback - The name of the callback.
     * @return void
     */

    OnCallback = (e, callback) =>
    {
        switch (callback)
        {
            case "clearPoll":
                Globals.DialogCreate({
                    confirmLabel: "Clear Results",
                    message: "Are you sure that you want to clear the results of this poll? This action cannot be reversed.",
                    onConfirm: this.ClearPoll,
                    title: "Clear Results",
                    type: "confirm"
                });
                break;
            case "exportLeaderboard":
                this.ExportLeaderboard();
                break;
            case "exportPoll":
                this.ExportPoll();
                break;
            default:
        }
    }

    OnClearAllDrafts = () =>
    {
        const {active} = this.state;
        this.ConfirmDialog = Globals.DialogCreate({
            confirmLabel: "Clear all",
            message: "Do you want to permanently remove all draft versions of all widgets containers on display?",
            onConfirm: () =>
            {
                this.ClearAllDrafts();
            },
            title: "Clear all draft versions",
            type: "confirm"
        });
    }

    OnClearDrafts = () =>
    {
        const {active} = this.state;
        this.ConfirmDialog = Globals.DialogCreate({
            confirmLabel: "Clear",
            message: "Do you want to permanently remove all draft versions of this widget container?",
            onConfirm: () =>
            {
                if (active !== this.state.active)
                {
                    return;
                }
                Globals.DialogDestroy(this.DraftsDialog);
                this.ClearDrafts(active);
                // Broadcast reset.
                Broadcast.SendMessage({
                    type: "clear-drafts",
                    id: active
                });
                // Send request to backend.
                API.Request(`widget/drafts-clear`, {name: active});
            },
            title: "Clear all draft versions",
            type: "confirm"
        });
    }

    /**
     * Show the draft versions of the active widget.
     * @return void
     */

    OnDrafts = () =>
    {
        const Drafts = this.ListDrafts();
        if ((!Drafts.length))
        {
            return;
        }
        this.DraftsDialog = Globals.DialogCreate({
            title: "Draft versions overview",
            type: "drafts",
            props:
            {
                drafts: Drafts,
                onClear: this.OnClearDrafts,
                onLoad: this.OnLoadDraft
            }
        });
    }

    /**
     * Load content into the widget.
     * @param object e - The event object.
     * @param object content - Content object.
     * @param object scope - Content scope.
     * @param boolean edit - Whether the current user can edit this content.
     * @return void
     */

    OnLoad = (e, content, scope, edit = true) =>
    {
        Globals.DialogDestroy(this.LoadDialog);
        const View = this.Active();
        if (!View || !this.Mounted)
        {
            return;
        }
        // Broadcast load/copy.
        Broadcast.SendMessage({
            id: View.id,
            type: "clear",
            content,
            edit,
            scope
        });
        // Propagate load to backend.
        if (content.id)
        {
            API.Request(`widget/content-set/${View.id}`, {content: content.id}, response =>
            {
                const {error, message} = response;
                if (error)
                {
                    console.error(message || "Unable to update widget.");
                } 
            });
        }
        // Switch to content tab.
        this.setState({tab: "2"});
    }

    /**
     * Callback when a draft has been selected in the drafts overview.
     * @param object e - Event object.
     * @param object draft - Draft data object.
     * @return void
     */

    OnLoadDraft = (e, draft) =>
    {
        Globals.DialogDestroy(this.DraftsDialog);
        const {active, views} = this.state;
        if (views[active] === undefined)
        {
            return;
        }
        const {container, content, id, updated, user} = draft;
        const Draft = views[active].draft;
        Draft.container = container;
        Draft.content = content;
        Draft.id = id;
        Draft.own = user === Auth.UserId;
        Draft.updated = updated;
        Draft.user = user;
        this.setState({views});
        this.SaveLocalDraft(Draft);
        this.SetStatusFromDraft(Draft);
        Broadcast.SendMessage({
            id: container.id,
            type: "attributes",
            attributes: container.attributes
        });
        Broadcast.SendMessage({
            content: content.content,
            type: "content",
            id: content.id,
            widget: container.id
        });
    }

    /**
     * Show the event log.
     * @return void
     */

    OnLog = () =>
    {
        const Active = this.Active();
        let Container, Content;
        if (Active)
        {
            const {edit, id} = Active;
            const {content} = edit || {};
            const {id: contentId} = content || {};
            Container = id;
            Content = contentId;
        }
        this.LogDialog = Globals.DialogCreate({
            title: this.EventLogTitle,
            type: this.EventLogType,
            props:
            {
                community: this.CurrentCommunity(),
                container: Container,
                content: Content
            }
        });
    }

    /**
     * Clear all contents in the active widget.
     * @return void
     */

    OnNew = () =>
    {
        this.ConfirmDialog = Globals.DialogCreate({
            confirmLabel: "Create New",
            message: "Do you want to clear the active widget and insert new contents?",
            onConfirm: () =>
            {
                const View = this.Active();
                if (!View || !this.Mounted)
                {
                    return;
                }
                const {id, scope} = View;
                const Content = ArrayClone(this.DefaultContent);
                // Broadcast new content.
                Broadcast.SendMessage({
                    id,
                    type: "clear",
                    content: Content,
                    edit: true,
                    scope: ArrayClone(scope)
                });
            },
            title: "New Widget Content",
            type: "confirm"
        });
    }

    /**
     * Callback when a views position changes in the content grid.
     * @param string id - The unique id of the widget.
     * @param integer [x] - X position in columns.
     * @param integer [y] - Y position in rows.
     * @return void
     */

    OnPosition = (id, [x, y]) =>
    {
        const {views} = this.state;
        if (views[id] === undefined)
        {
            return;
        }
        views[id].x = x;
        views[id].y = y;
        this.setState({views});
    }

    /**
     * Add a view to the editor when its corresponding component mounts.
     * @param string id - The unique id of the widget.
     * @param object view - The view object.
     * @return void
     */

    OnRegister = (id, view) =>
    {
        const {views} = this.state;
        views[id] = this.SetupView(view);
        this.setState({views}, () =>
        {
            // Reset selected fields if this widget has been marked.
            // This happens when the template is changed in order to
            // restore selected attributes.
            if (id !== this.ResetId)
            {
                return;
            }
            this.ResetId = null;
            const {edit} = view || {};
            const {fields} = edit || {};
            if (fields)
            {
                for (let key in fields)
                {
                    const {default: defaultValue, reset, type} = fields[key];
                    if (reset)
                    {
                        this.SetField(null, key, defaultValue !== undefined ? defaultValue : DefaultValue(type));
                    }
                }
            }
        });
    }

    /**
     * Callback when a repeated item is closed.
     * @param object e - Event object.
     * @param int index - Item index.
     * @param mixed id - Field id.
     * @return void
     */

    OnRepeaterClose = (e, index, id) =>
    {
        const View = this.Active();
        const {container} = View.draft;
        const {id: containerId} = container || {};
        Broadcast.SendMessage({
            id: containerId,
            type: "request",
            request: {id, index, open: false, type: "repeaterItem"}
        });
    }

    /**
     * Callback when a repeated item is opened.
     * @param object e - Event object.
     * @param int index - Item index.
     * @param mixed id - Field id.
     * @return void
     */

    OnRepeaterOpen = (e, index, id) =>
    {
        const View = this.Active();
        const {container} = View.draft;
        const {id: containerId} = container || {};
        Broadcast.SendMessage({
            id: containerId,
            type: "request",
            request: {id, index, open: true, type: "repeaterItem"}
        });
    }

    /**
     * Restore the active view to its last saved state.
     * @return void
     */

    OnRestore = () =>
    {
        this.ConfirmDialog = Globals.DialogCreate({
            confirmLabel: "Restore Widget",
            message: "Are you sure that you want to restore this widget to its' last saved state?",
            onConfirm: () =>
            {
                const View = this.Active();
                if (!View || !this.Mounted)
                {
                    return;
                }
                const {draft} = View;
                const {id: containerId} = draft.container;
                this.ClearLocalDraft(containerId);
                // Broadcast reset.
                Broadcast.SendMessage({
                    type: "restore",
                    id: containerId
                });
            },
            title: "Restore Widget",
            type: "confirm"
        });
    }

    /**
     * Save the active view in the backend API.
     * @return void
     */

    OnSave = () =>
    {
        const View = this.Active();
        if (!View)
        {
            return;
        }
        const {container, content} = View.draft;
        this.Save(
            [JSON.stringify(container)],
            [JSON.stringify(content)],
            [container.id],
            [content.id],
            2
        );
    }

    /**
     * Save all registered, unsaved views in the backend API.
     * @return void
     */

    OnSaveAll = () =>
    {
        const {views} = this.state;
        const Containers = [];
        const ContainerIds = [];
        const Content = [];
        const ContentIds = [];
        for (let id in views)
        {
            let {container, content, unsaved} = views[id].draft;
            let {id: contentId} = content || {};
            if (!unsaved)
            {
                continue;
            }
            Containers.push(JSON.stringify(container));
            ContainerIds.push(id);
            if (content && (!contentId || ContentIds.indexOf(contentId) < 0))
            {
                Content.push(JSON.stringify(content));
                ContentIds.push(contentId);
            }
        }
        if (!Containers.length)
        {
            return;
        }
        this.Save(Containers, Content, ContainerIds, ContentIds, 1);
    }

    OnSaveDraft = () =>
    {
        const View = this.Active();
        if (!View)
        {
            return;
        }
        const {container, content} = View.draft;
        const Container = JSON.stringify(container);
        const Content = JSON.stringify(content);
        this.setState({
            error: false,
            loading: 3
        });
        API.Request("widget/draft",
        {
            id: container.id,
            community: this.CurrentCommunity(),
            container: Container,
            content: Content 
        }, response =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {error, message} = response;
            if (error)
            {
                this.setState({
                    error: message || true,
                    loading: false
                });
            }
            else {
                const {draft: draftId, error, message} = response;
                const DraftId = draftId ? parseInt(draftId, 10) : 0;
                if (error || !DraftId)
                {
                    this.setState({
                        error: message || true,
                        loading: false
                    });
                }
                else
                {
                    // Prepend/update saved draft.
                    const SavedDraft =
                    {
                        id: DraftId,
                        data:
                        {
                            container: ArrayClone(container),
                            content: ArrayClone(content)
                        },
                        own: true,
                        updated: Time(),
                        user: Auth.UserId
                    };
                    let Update = -1;
                    const Drafts = View.drafts || [];
                    Drafts.forEach((draft, index) =>
                    {
                        if (draft.id === DraftId)
                        {
                            Update = index;
                        }
                    });
                    if (Update < 0)
                    {
                        Drafts.unshift(SavedDraft);
                    }
                    else
                    {
                        Drafts[Update] = SavedDraft;
                    }
                    View.draft.id = DraftId;
                    View.draft.local = false;
                    View.draft.own = true;
                    View.latestDraft = DraftId;
                    this.SaveLocalDraft(View.draft);
                    this.setState({
                        loading: false,
                        status: this.SetStatusFromDraft(View.draft, false)
                    });
                    // Broadcast new draft.
                    Broadcast.SendMessage({
                        type: "draft",
                        id: container.id,
                        draft: SavedDraft
                    });
                }
            }
        });
    }

    /**
     * Remove a view from the editor when its corresponding component unmounts.
     * @param string id - The unique id of the widget.
     * @return void
     */

    OnUnregister = (id) =>
    {
        const {views} = this.state;
        if (views[id] === undefined)
        {
            return;
        }
        delete views[id];
        this.setState({views});
    }

    /**
     * Reset selected attributes when the template changes.
     * @return void
     */

    ResetFields = () =>
    {
        // At this stage we only mark the widget for reset. The actual
        // reset will happen when the widget re-registers.
        const {id} = this.Active() || {};
        this.ResetId = id;
    }

    /**
     * Save widget(s) in the backend API.
     * @param array containers - Container objects to save as JSONs.
     * @param array contents - Content objects to save as JSONs.
     * @param array containerIds - Ids of all containers to save.
     * @param array contentIds - Ids of all content to save.
     * @param integer button - 1 = Save all, 2 = Save
     * @return void
     */

    Save = (containers, contents, containerIds, contentIds, button = 1) =>
    {
        this.setState({
            error: false,
            loading: button
        });
        API.Request("widget/save",
        {
            community: this.CurrentCommunity(),
            containers,
            contents
        }, response =>
        {
            if (!this.Mounted)
            {
                return;
            }
            const {error, message} = response;
            if (error)
            {
                this.setState({
                    error: message || true,
                    loading: false
                });
            }
            else
            {
                const {views} = this.state;
                const {failed, map, saved} = response;
                const {containers: s1, contents: s2} = saved;
                const {containers: s3, contents: s4} = failed;
                const Failed = s3.length || s4.length;
                containerIds.forEach(containerId =>
                {
                    let View = views[containerId];
                    if (!View || s1.indexOf(containerId) < 0)
                    {
                        return;
                    }
                    //let {content: contentId} = View.draft.container;
                    let {id: contentId} = View.draft.content;
                    if (s2.indexOf(contentId) < 0)
                    {
                        return;
                    }
                    View.draft.isNew = false;
                    View.draft.unsaved = false;
                    View.drafts = [];
                    View.latestDraft = false;
                    // Broadcast save.
                    Broadcast.SendMessage({
                        type: "save",
                        id: containerId,
                        // Exchange new contents temporary id for its permanent.
                        contentId: map[contentId],
                        draft: View.draft
                    });
                    this.ClearDraft(containerId);
                    //this.ClearDraft(contentId);
                });
                this.FilterUnsaved();
                this.setState({
                    error: Failed ? "Unable to save some widgets" : false,
                    loading: false,
                    status: this.SetStatus()
                });
            }
        });
    }

    /**
     * Save widget draft data to local storage.
     * @param object draft - Draft data object.
     * @return void
     */

    SaveLocalDraft = (draft) =>
    {
        const {container, content, id, updated, user} = draft;
        Globals.Storage(`draft-${draft.container.id}`, JSON.stringify({
            id,
            container,
            content,
            updated: updated,
            user: user
        }));
    }

    /**
     * Callback when a widget is clicked in the overview.
     * Broadcast the active widget id and switch to the content tab.
     * @param object e - Event object.
     * @param string active - The active views unique id.
     * @return void
     */

    SetActive = (e, active) =>
    {
        Globals.ViewActive(active);
        this.setState({tab: "2"});
    }

    /**
     * Update a container attribute.
     * @param object e - Event object.
     * @param string value - New attribute value.
     * @param string key - Attribute key.
     * @return void
     */

    SetAttribute = (e, value, key) =>
    {
        const View = this.Active();
        if (!View || !this.Mounted)
        {
            return;
        }
        const {attributes} = View.draft.container;
        attributes[key] = value;
        this.UpdateView(View);
        this.forceUpdate();
        Broadcast.SendMessage({
            id: View.id,
            type: "attributes",
            attributes
        });
    }

    /**
     * Update content field value.
     * @param object e - Event object.
     * @param string key - Field key.
     * @param string value - New field value.
     * @return void
     */

    SetField = (e, key, value, callback) =>
    {
        const View = this.Active();
        if (!View || !this.Mounted)
        {
            return;
        }
        if (typeof callback !== "function")
        {
            callback = () => {};
        }
        const {draft, edit} = View;
        const {content} = draft.content;
        const {fields} = edit || {};
        const Id = content.id || View.id;
        const Field = fields[key];
        content[key] = Field ? ParsedValue(value, Field.type) : value;
        clearTimeout(this.SetTimer);
        this.SetTimer = setTimeout(() =>
        {
            this.UpdateView(View);
            this.forceUpdate(callback);
            Broadcast.SendMessage({
                content: content,
                type: "content",
                id: Id,
                widget: View.id
            });
        }, this.SetDelay);
    }

    /**
     * Delegate meta fields to SetField().
     * @param object e - Event object.
     * @param string value - New field value.
     * @param string key - Field key.
     * @return void
     */

    SetMetaField = (e, value, key, callback) =>
    {
        this.SetField(e, key, value, callback);
    }

    /**
     * Callback when the cursor leaves a widget item in the overview.
     * Triggers a broadcast to widget frames.
     * @param object e - Event object.
     * @param string id - The unique id of the widget.
     * @return void
     */

    SetMouseOff = (e, id) =>
    {
        Globals.ViewHover(id, false);
    }

    /**
     * Callback when the cursor enters a widget item in the overview.
     * Triggers a broadcast to widget frames.
     * @param object e - Event object.
     * @param string id - The unique id of the widget.
     * @return void
     */

    SetMouseOn = (e, id) =>
    {
        Globals.ViewHover(id, true);
    }

    /**
     * Update the read and write permission of the active container/content.
     * @param object e - Event object.
     * @param object scope - The updated permissions.
     * @param string type - "container" or "content".
     * @return void
     */

    SetScope = (e, scope, type) =>
    {
        const View = this.Active();
        if (!View || !View.draft[type] || !this.Mounted)
        {
            return;
        }
        View.draft[type].scope = scope;
        this.UpdateView(View);
        this.forceUpdate();
    }

    SetStatus = (viewId) =>
    {
        const {active, views} = this.state;
        const ViewId = viewId || active;
        if (views[ViewId] === undefined)
        {
            return "\u00A0";
        }
        // Detect when a public draft has been deleted, but is still in local storage.
        const {draft, drafts} = views[ViewId];
        const Ids = [];
        if (drafts)
        {
            drafts.forEach(publicDraft => Ids.push(publicDraft.id));
        }
        if (draft.id && Ids.indexOf(draft.id) < 0)
        {
            draft.id = false;
            draft.local = true;
            draft.unsaved = true;
        }
        return this.SetStatusFromDraft(draft, false);
    }

    SetStatusFromDraft = (draft, setState = true) =>
    {
        const Token = this.StatusToken = RandomToken();
        let Status = "\u00A0";
        if (draft)
        {
            const {id, local, own, unsaved, updated, user} = draft;
            const Updated = DateTimeStamp(new Date(updated), true);
            if (!unsaved) Status = "No unsaved changes in this widget";
            //else if (isNew) Status = `Unsaved new widget updated at ${Updated}`;
            else if (!id) Status = `Unsaved local draft updated at ${Updated}`;
            else if (local && own) Status = `Local draft updated at ${Updated} (by you)`;
            else if (own || !user) Status = `Public draft updated at ${Updated} (by you)`;
            else
            {
                Fuse.Content(user, "user", user =>
                {
                    if (!user || !user.name || !this.Mounted || Token !== this.StatusToken)
                    {
                        return;
                    }
                    this.setState({status: `Public draft updated at ${Updated} (by ${user.name})`});
                });
                Status = `Public draft updated at ${Updated} (by user #${user})`;
            }
        }
        if (setState)
        {
            this.setState({status: Status});
        }
        return Status;
    }

    /**
     * Switch tab when a tab menu item is clicked.
     * @param object e - Event object.
     * @param string|number tab - The new tab.
     * @return void
     */

    SetTab = (e, tab) =>
    {
        if (tab === this.state.tab)
        {
            return;
        }
        this.setState({tab});
    }

    SetTemplate = (e, template, key) =>
    {
        this.SetMetaField(e, template, key, this.ResetFields);
    }

    /**
     * Load draft versions of a widget container and content and append it to a
     * view object.
     * @param object view - The view object.
     * @return object - The view object.
     */

    SetupView = (view) =>
    {
        const {active} = this.state;
        const {attributes, drafts, edit, id, restore, scope: s1} = view;
        const {content: c1, fields} = edit || {};
        const {content: c2, scope: s2} = c1 || {};
        const {content: c3} = restore || {};
        const {id: contentId, template} = c2 || {};
        const Fields = fields || {};
        const ContentId = contentId || RandomToken();
        let {
            container: Ct,
            content: Cn,
            id: draftId,
            local: draftLocal,
            own: draftOwn,
            updated: draftUpdated,
            user: draftUser
        } = this.LoadDraft(id, drafts);
        const LoadedCt = !!Ct;
        //const LoadedCn = contentId ? !!Cn : false;
        const LoadedCn = !!Cn;
        if (typeof Ct !== "object") Ct = {};
        if (typeof Cn !== "object") Cn = {};
        // Broadcast this widgets template globally so it can be received by
        // a widget field.
        Globals.Var(`template-${id}`, [id, template]);
        Ct.content = Cn.id || ContentId;
        if (Ct.attributes === undefined) Ct.attributes = ArrayClone(attributes || this.DefaultAttributes);
        if (Ct.id === undefined) Ct.id = id; 
        if (Ct.scope === undefined) Ct.scope = ArrayClone(s1);
        if (Cn.content === undefined) Cn.content = ArrayClone(c2 || this.DefaultContent);
        if (Cn.id === undefined) Cn.id = ContentId;
        if (Cn.scope === undefined) Cn.scope = ArrayClone(s2 || s1);
        for (let key in this.DefaultAttributes)
        {
            if (Ct.attributes[key] !== undefined)
            {
                continue;
            }
            Ct.attributes[key] = this.DefaultAttributes[key];
        }
        for (let key in this.DefaultContent)
        {
            if (Cn.content[key] !== undefined)
            {
                continue;
            }
            Cn.content[key] = this.DefaultContent[key];
        }
        for (let key in Fields)
        {
            if (Cn.content[key] !== undefined)
            {
                Cn.content[key] = ParsedValue(Cn.content[key], Fields[key].type);
            }
            else
            {
                Cn.content[key] = Fields[key].default || DefaultValue(Fields[key].type);
            }
        }
        view.draft =
        {
            container: Ct,
            content: Cn,
            id: draftId,
            isNew: !c3 || !c3.id,
            local: draftLocal,
            own: draftOwn,
            unsaved: this.ChangedView(view, Ct, Cn),
            updated: draftUpdated || Time(),
            user: draftUser || Auth.UserId
        };
        view.drafts = drafts;
        view.latestDraft = (drafts && drafts.length) ? drafts[0].id : false;
        if (LoadedCt)
        {
            Broadcast.SendMessage({
                id: id,
                type: "attributes",
                attributes: Ct.attributes
            });
        }
        if (LoadedCn)
        {
            Broadcast.SendMessage({
                content: Cn.content,
                type: "content",
                id: contentId || id,
                widget: id
            });
        }
        this.FilterUnsaved();
        // Reset status if this widget has been restored, ie. re-registered.
        if (id === active)
        {
            this.SetStatusFromDraft(view.draft);
        }
        return view;
    }

    /**
     * Save a views' draft object to local storage when it has been edited.
     * @param object view - The view object.
     * @return object - The view object.
     */

    UpdateView = (view) =>
    {
        clearTimeout(this.UpdateTimer);
        this.UpdateTimer = setTimeout(() =>
        {
            // Disassociate the local draft from any public one.
            delete view.draft.id;
            view.draft.unsaved = this.ChangedView(view);
            view.draft.updated = Time();
            view.draft.user = Auth.UserId;
            this.SaveLocalDraft(view.draft);
            this.FilterUnsaved();
            this.SetStatusFromDraft(view.draft);
        }, this.UpdateDelay);
        return view;
    }

    render()
    {
        const Active = this.Active();
        const Colors = Globals.Setting("Colors", {});
        const {active, loading, status, tab, views} = this.state;
        const {draft, edit, editContainer, editContent, latestDraft} = Active || {};
        const {content: c1, container, id: draftId, isNew, unsaved} = draft || {};
        const {content: c2, scope: s2} = c1 || {};
        const {attributes, content: contentId, scope: s1} = container || {};
        const {fields} = edit || {};
        const {
            appearanceBorderRadius = 0,
            appearanceFontFaceHeading = "ubuntu",
            appearanceFontFaceText = "lato",
            appearanceInherit = true,
            appearanceColorHighlight = Colors.pink,
            appearanceColorItem = Colors.purple,
            appearanceColorItemFg = Colors.white,
            appearanceFontBold = false,
            appearanceFontItalic = false,
            appearanceFontUnderline = false,
            appearanceFontSize = "1.0em",
            name = "",
            description = "",
            template = ""
        } = c2 || {};
        const {autoAdjust, fill} = attributes || {};
        const HasFields = fields && !!Object.keys(fields).length;
        const EditContainer = editContainer;
        const EditContent = editContent;
        const Loading = !!loading;
        const NumDrafts = this.ListDrafts().length;
        const Context = Globals.Var("context") || [];
        const Host = (Context[2] || "").replace(/^https?:\/\//, "");
        let Tab = "";
        let Status = status;
        switch (tab)
        {
            case "1":
                Tab = (
                    <div className="EditorTab">
                        {EditContainer ? "" : <div className="EditorNotice">You don't have permission to edit this container.</div>}
                        <Group title="Attributes" name="attributes">
                            <CheckboxField
                                checked={fill}
                                disabled={!EditContainer || Loading || !Active}
                                id="fill"
                                label="Size parameters"
                                onChange={this.SetAttribute}
                                text="Extend to the grid edge(s)"
                            />
                            <CheckboxField
                                checked={autoAdjust}
                                disabled={!EditContainer || Loading || !Active}
                                id="autoAdjust"
                                onChange={this.SetAttribute}
                                text="Adjust height to fit the content"
                            />
                        </Group>
                        <Group collapsedDefault={true} title="Permissions" name="permissions">
                            <PermissionField
                                disabled={!EditContainer || Loading}
                                id="container"
                                onChange={this.SetScope}
                                value={s1}
                            />
                        </Group>
                    </div>
                );
                break;
            case "2":
                Tab = (
                    <div className="EditorTab">
                        {EditContent ? "" : (
                            <div className="EditorNotice">You don't have permission to edit this content.</div>
                        )}
                        <Group title="Meta" name="meta">
                            <TextField
                                disabled={!EditContent || Loading}
                                id="name"
                                label="Name"
                                onChange={this.SetMetaField}
                                onInput={this.SetMetaField}
                                placeholder="Content name"
                                value={name}
                            />
                            <TextareaField
                                disabled={!EditContent || Loading}
                                id="description"
                                label="Description"
                                onChange={this.SetMetaField}
                                onInput={this.SetMetaField}
                                placeholder="Short description of the content..."
                                value={description}
                            />
                            <SelectField
                                disabled={!EditContent || Loading}
                                id="template"
                                label="Template"
                                onChange={this.SetTemplate}
                                options={this.Widgets}
                                value={template}
                            />
                        </Group>
                        <Group collapsedDefault={true} title="Content" name="content">
                            {HasFields ? <Form
                                className="EditorContentForm"
                                content={c2}
                                disabled={!EditContent || Loading}
                                fields={fields}
                                key={Active.id}
                                onButton={this.OnCallback}
                                onEdit={this.SetField}
                                onRepeaterClose={this.OnRepeaterClose}
                                onRepeaterOpen={this.OnRepeaterOpen}
                                reserved={this.Reserved}
                            /> : <div className="EditorEmpty">This widget has no editable content.</div>}
                        </Group>
                        <Group collapsedDefault={true} title="Appearance" name="appearance">
                            <CheckboxField
                                checked={appearanceInherit}
                                disabled={!EditContent || Loading}
                                id="appearanceInherit"
                                label="Inherit Appearance"
                                onChange={this.SetMetaField}
                            />
                            {!appearanceInherit ? [
                                <SelectField
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceFontFaceHeading"
                                    key="font1"
                                    label="Heading Font Face"
                                    onChange={this.SetMetaField}
                                    options={{
                                        helvetica: "Helvetica",
                                        lato: "Lato",
                                        merriweather: "Merriweather",
                                        montserrat: "Montserrat",
                                        openSans: "Open Sans",
                                        playfairDisplay: "Playfair Display",
                                        roboto: "Roboto",
                                        ubuntu: ["Ubuntu", <i key="ubuntu"> (default)</i>]
                                    }}
                                    value={appearanceFontFaceHeading}
                                />,
                                <SelectField
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceFontFaceText"
                                    key="font2"
                                    label="Text Font Face"
                                    onChange={this.SetMetaField}
                                    options={{
                                        helvetica: "Helvetica",
                                        lato: ["Lato", <i key="lato"> (default)</i>],
                                        merriweather: "Merriweather",
                                        montserrat: "Montserrat",
                                        openSans: "Open Sans",
                                        playfairDisplay: "Playfair Display",
                                        roboto: "Roboto",
                                        ubuntu: "Ubuntu"
                                    }}
                                    value={appearanceFontFaceText}
                                />,
                                <SelectField
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceFontSize"
                                    key="fontSize"
                                    label="Text Size"
                                    onChange={this.SetMetaField}
                                    options={{
                                        "0.5em": "0.5 em",
                                        "0.6em": "0.6 em",
                                        "0.7em": "0.7 em",
                                        "0.8em": "0.8 em",
                                        "0.9em": "0.9 em",
                                        "1.0em": "1.0 em",
                                        "1.1em": "1.1 em",
                                        "1.2em": "1.2 em",
                                        "1.3em": "1.3 em",
                                        "1.4em": "1.4 em",
                                        "1.5em": "1.5 em",
                                        "1.6em": "1.6 em",
                                        "1.7em": "1.7 em",
                                        "1.8em": "1.8 em",
                                        "1.9em": "1.9 em",
                                        "2.0em": "2.0 em",
                                    }}
                                    value={appearanceFontSize}
                                />,
                                <CheckboxField
                                    checked={appearanceFontBold}
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceFontBold"
                                    key="bold"
                                    label="Text Formatting"
                                    text="Bold"
                                    onChange={this.SetMetaField}
                                />,
                                <CheckboxField
                                    checked={appearanceFontItalic}
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceFontItalic"
                                    key="italic"
                                    text="Italic"
                                    onChange={this.SetMetaField}
                                />,
                                <CheckboxField
                                    checked={appearanceFontUnderline}
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceFontUnderline"
                                    key="underline"
                                    text="Underline"
                                    onChange={this.SetMetaField}
                                />,
                                <ColorField
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceColorHighlight"
                                    key="color1"
                                    label="Highlight Color"
                                    onChange={this.SetMetaField}
                                    value={appearanceColorHighlight}
                                />,
                                <ColorField
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceColorItem"
                                    key="color2"
                                    label="Item Background Color"
                                    onChange={this.SetMetaField}
                                    value={appearanceColorItem}
                                />,
                                <ColorField
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceColorItemFg"
                                    key="color3"
                                    label="Item Foreground Color"
                                    onChange={this.SetMetaField}
                                    value={appearanceColorItemFg}
                                />,
                                <NumberField
                                    disabled={appearanceInherit || !EditContent || Loading}
                                    id="appearanceBorderRadius"
                                    key="borderradius"
                                    label="Border Radius (px)"
                                    onChange={this.SetMetaField}
                                    value={appearanceBorderRadius}
                                />
                            ] : ""}
                        </Group>
                        <Group collapsedDefault={true} title="Permissions" name="permissions">
                            <PermissionField
                                disabled={!EditContent || Loading}
                                id="content"
                                onChange={this.SetScope}
                                value={s2}
                            />
                        </Group>
                    </div>
                );
                break;
            default:
                Status = "\u00A0";
                Tab = (
                    <div className="EditorTab">
                        <Group title="Widgets on display" name="widgets">
                            <EditorTree
                                active={active}
                                onClick={this.SetActive}
                                onMouseEnter={this.SetMouseOn}
                                onMouseLeave={this.SetMouseOff}
                                views={views}
                            />
                        </Group>
                    </div>
                );
        }
        return (
            <div className="Editor">
                <IconButton
                    className="EditorClose"
                    feather="X"
                    onClick={this.Close}
                />
                <div className="EditorContent">
                    <TabMenu
                        className="EditorContentTabMenu"
                        disabled={Loading || (Active ? false : [0, 1, 1])}
                        items={this.TabLabels}
                        onClick={this.SetTab}
                        selected={tab}
                    />
                    {Tab}
                    <div className="EditorContentTray">
                        <div className="EditorContentTrayFirst">
                            <Button
                                disabled={(Loading && loading !== 1) || !this.Unsaved.length}
                                label="Save all"
                                loading={loading === 1}
                                onClick={this.OnSaveAll}
                                title="Publish all draft content on display"
                            />
                            <Button
                                disabled={(Loading && loading !== 2) || !Active || (!unsaved && !isNew) || tab === "0"}
                                hollow={true}
                                label="Save"
                                loading={loading === 2}
                                onClick={this.OnSave}
                            />
                            <Button
                                disabled={!!draftId || (Loading && loading !== 2) || !Active || (!unsaved && !isNew) || tab === "0"}
                                hollow={true}
                                label="Save draft"
                                loading={loading === 3}
                                onClick={this.OnSaveDraft}
                            />
                        </div>
                        <div className="EditorContentTraySecond">
                            <IconItem
                                disabled={!EditContainer || Loading || !Active || isNew || tab === "0"}
                                feather="FilePlus"
                                label="New"
                                onClick={this.OnNew}
                                title="Create new content in this container"
                            />
                            <IconItem
                                disabled={!EditContainer || Loading || !Active || tab === "0"}
                                feather="Folder"
                                label="Load"
                                onClick={this.OnBrowse}
                                title="Load existing content into this container"
                            />
                            <IconItem
                                disabled={(latestDraft && draftId === latestDraft) || Loading || !Active || !unsaved || isNew || !contentId || tab === "0"}
                                feather="CornerUpLeft"
                                label="Restore"
                                onClick={this.OnRestore}
                                title="Restore this content to its last save or draft"
                            />
                            <IconItem
                                disabled={!NumDrafts || !EditContainer || Loading || !Active || tab === "0"}
                                feather="Edit2"
                                label={`Drafts (${NumDrafts})`}
                                onClick={this.OnDrafts}
                                title={`There are ${NumDrafts} draft version(s) available`}
                            />
                            <IconItem
                                disabled={!NumDrafts || !EditContainer || Loading || !Active || tab === "0"}
                                feather="Trash2"
                                label="Clear drafts"
                                onClick={this.OnClearDrafts}
                                title="Clear all local and public draft versions of this widget"
                            />
                            {0 ? <IconItem
                                feather="List"
                                label="Log"
                                onClick={this.OnLog}
                                title="Show event log"
                            /> : ""}
                        </div>
                        <div className="EditorContentTrayThird">
                            <IconItem
                                feather="List"
                                label="Event log"
                                onClick={this.OnLog}
                                title="Show event log"
                            />
                            {this.ShowArticleEditor === true || this.ShowArticleEditor.indexOf(Host) >= 0 ? <IconItem
                                feather="Edit"
                                label="Article editor"
                                onClick={this.OnArticleEditor}
                                title="Open the article editor"
                            /> : ""}
                            <IconItem
                                disabled={Loading}
                                feather="AlertTriangle"
                                label="Advanced"
                                onClick={this.OnAdvanced}
                                title="Show advanced options"
                            />
                        </div>
                        <div className="EditorContentTrayStatus">
                            {Status}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ViewEditor.propTypes =
{
    context: PropTypes.string,
    contextId: PropTypes.string
};

ViewEditor.defaultProps =
{
    context: "",
    contextId: ""
};

export default ViewEditor;